import { Page } from "../../frameworks/joy/layouts/page";
import { InventoryOnHandForecastContainer } from "./container";

export const InventoryAtHandForecastPage = () => {
  return (
    <Page>
      <InventoryOnHandForecastContainer />
    </Page>
  );
};
