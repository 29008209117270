import { PeriodType } from "./period_type";

export enum SalesMeasure {
  QUANTITY = "QUANTITY",
  REVENUE = "REVENUE",
}

export type DiffParams = {
  runId0: string | null;
  runId1: string | null;
  minDate: Date | null;
  maxDate: Date | null;
  aggregatePeriodType: PeriodType | null;
  salesMeasure: SalesMeasure | null;
  heatMap: boolean | null;
};

export type Query = {
  runId0?: string | undefined;
  runId1?: string | undefined;
  minDate?: string | undefined;
  maxDate?: string | undefined;
  aggregatePeriodType?: string | undefined;
  salesMeasure?: string | undefined;
  heatMap?: string | null;
};

export const diffParamsToStrings = (diffParams: DiffParams) => {
  const {
    runId0,
    runId1,
    minDate,
    maxDate,
    aggregatePeriodType,
    salesMeasure,
    heatMap,
  } = diffParams;
  return {
    ...(runId0 !== null && { runId0 }),
    ...(runId1 !== null && { runId1 }),
    ...(minDate && { minDate: minDate.toISOString().split("T")[0] }),
    ...(maxDate && { maxDate: maxDate.toISOString().split("T")[0] }),
    ...(aggregatePeriodType && {
      aggregatePeriodType: aggregatePeriodType.valueOf(),
    }),
    ...(salesMeasure && {
      salesMeasure: salesMeasure.valueOf(),
    }),
    ...(heatMap !== null && { heatMap: heatMap ? "true" : "false" }),
  };
};

const urlSearchParamsToQuery = (query: URLSearchParams): Query => {
  return {
    runId0: query.get("runId0") || undefined,
    runId1: query.get("runId1") || undefined,
    minDate: query.get("minDate") || undefined,
    maxDate: query.get("maxDate") || undefined,
    aggregatePeriodType: query.get("aggregatePeriodType") || undefined,
    salesMeasure: query.get("salesMeasure") || undefined,
    heatMap: query.get("heatMap") || undefined,
  } as Query;
};

export const queryStringTodiffParams = (query: URLSearchParams) => {
  const {
    runId0,
    runId1,
    minDate,
    maxDate,
    aggregatePeriodType,
    salesMeasure,
    heatMap,
  } = urlSearchParamsToQuery(query);
  let minDateParsed = null;
  if (minDate !== undefined) {
    try {
      minDateParsed = new Date(minDate);
    } catch (err) {}
  }
  let maxDateParsed = null;
  if (maxDate !== undefined) {
    try {
      maxDateParsed = new Date(maxDate);
    } catch (err) {}
  }
  let aggregatePeriodTypeParsed: PeriodType | null = null;
  if (aggregatePeriodType !== undefined) {
    try {
      aggregatePeriodTypeParsed =
        PeriodType[aggregatePeriodType as PeriodType] || null;
    } catch (err) {}
  }

  let salesMeasureParsed: SalesMeasure | null = null;
  if (salesMeasure !== undefined) {
    try {
      salesMeasureParsed = SalesMeasure[salesMeasure as SalesMeasure] || null;
    } catch (err) {}
  }

  let heatMapParsed: boolean | null = null;
  if (heatMap !== undefined) {
    try {
      heatMapParsed = heatMap === "true";
    } catch (err) {}
  }

  const diffParams: DiffParams = {
    runId0: runId0 || null,
    runId1: runId1 || null,
    minDate: minDateParsed,
    maxDate: maxDateParsed,
    aggregatePeriodType: aggregatePeriodTypeParsed,
    salesMeasure: salesMeasureParsed,
    heatMap: heatMapParsed,
  };
  return diffParams;
};
