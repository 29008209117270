import { ReactNode, useContext, useState } from "react";
import { ApiContext } from "../api/context";
import { SysMessagesContext } from "../sys_messages/context";
import {
  PurchaseOrderStatus,
  useGetMyCustomerQuery,
  useGetMyPosWithLatestEditsQuery,
  useStoreMyPurchaseOrderEditWithSkusMutation,
  useStoreMyPurchaseOrderSnoozeAndStatusMutation,
} from "../../services/api/__generated__/backend_gateway-types";
import { SysMessageLevel } from "../sys_messages/type";
import { gql } from "@apollo/client";
import { PosContext } from "./context";
import { PoBoxSettings, PosType, StatusFilter } from "./type";

const { Provider } = PosContext;

export const PosProvider = (props: { children: ReactNode }) => {
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);
  const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);

  const [
    storeMyPurchaseOrderEditWithSkus,
    { loading: storeMyPurchaseOrderEditWithSkusLoading },
  ] = useStoreMyPurchaseOrderEditWithSkusMutation({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
      setMutationInProgress(false);
    },
  });

  const [storeSnoozeAndStatus, { loading: storeSnoozeAndStatusLoading }] =
    useStoreMyPurchaseOrderSnoozeAndStatusMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
        setMutationInProgress(false);
      },
    });

  const { data: customerData, loading: customerLoading } =
    useGetMyCustomerQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const hasPoInbox = customerData?.getMyCustomer?.features?.PoInbox || false;

  const [includeStatus, setIncludeStatus] = useState<StatusFilter[]>([
    StatusFilter.ATOMIC_SUGGESTION,
    StatusFilter.CUSTOMER_OWNED,
  ]);
  const [includeSnoozed, setIncludeSnoozed] = useState<boolean>(false);

  const {
    data: posData,
    loading: posLoading,
    refetch: posRefetch,
  } = useGetMyPosWithLatestEditsQuery({
    client: apiService.getClient(),
    variables: {
      include_status: includeStatus.map(
        (s) => s.valueOf() as PurchaseOrderStatus
      ),
      snoozed: includeSnoozed,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const refetchPos = (vars: PoBoxSettings | null = null) => {
    const variables = {
      include_status: (vars?.includeStatus || includeStatus).map(
        (s) => s.valueOf() as PurchaseOrderStatus
      ),
      snoozed: vars?.includeSnoozed || includeSnoozed,
    };
    posRefetch(variables);
  };

  const savePuchaseOrder = async (
    purchaseOrderId: string,
    need_by_date: string,
    ship_by_date: string,
    notes: string,
    skus: {
      sku: string;
      quantity: string;
      unit: string;
      unit_cost: string | null;
    }[]
  ): Promise<void> => {
    setMutationInProgress(true);
    await storeMyPurchaseOrderEditWithSkus({
      variables: {
        edit: {
          po_id: purchaseOrderId,
          edit_date: new Date().toISOString(),
          need_by_date,
          ship_by_date,
          notes,
          skus,
        },
      },
      update(cache) {
        cache.writeFragment({
          id: `PurchaseOrderWithEditAndSkus:${purchaseOrderId}`,
          fragment: gql`
            fragment PurchaseOrderWithEditAndSkusDetail on PurchaseOrderWithEditAndSkus {
              id
              pe {
                need_by_date
                ship_by_date
                notes
              }
              ps {
                sku
                quantity
                unit
                unit_cost
              }
            }
          `,
          data: {
            id: purchaseOrderId,
            pe: {
              need_by_date,
              ship_by_date,
              notes,
            },
            ps: skus,
          },
        });
      },
    });
    setMutationInProgress(false);
    refetchPos();
  };

  const savePurchaseOrderSnoozeAndStatus = async (
    purchaseOrderId: string,
    start_date: string | null,
    end_date: string | null,
    status: PurchaseOrderStatus
  ): Promise<void> => {
    await storeSnoozeAndStatus({
      variables: {
        edit: {
          po_id: purchaseOrderId,
          start_date,
          end_date,
          status,
        },
      },
      update(cache) {
        cache.writeFragment({
          id: `PurchaseOrderWithEditAndSkus:${purchaseOrderId}`,
          fragment: gql`
            fragment PurchaseOrderWithEditAndSkusDetail on PurchaseOrderWithEditAndSkus {
              id
              po {
                snooze_start_date
              }
            }
          `,
          data: {
            id: purchaseOrderId,
            po: {
              snooze_start_date: start_date,
              snooze_end_date: end_date,
              status,
            },
          },
        });
      },
    });
    refetchPos();
  };

  const setPoBoxSettings = (poBoxSettings: PoBoxSettings) => {
    const {
      includeStatus: newIncludeStatus,
      includeSnoozed: newIncludeSnoozed,
    } = poBoxSettings;
    setIncludeStatus(newIncludeStatus);
    setIncludeSnoozed(newIncludeSnoozed);
    refetchPos(poBoxSettings);
  };

  const value: PosType = {
    loading:
      customerLoading ||
      posLoading ||
      storeMyPurchaseOrderEditWithSkusLoading ||
      storeSnoozeAndStatusLoading ||
      mutationInProgress,
    hasPoInbox,
    poBoxSettings: {
      includeStatus,
      includeSnoozed,
    },
    setPoBoxSettings,
    pos: posData?.getMyPosWithLatestEdits || [],
    refetchPos,
    savePuchaseOrder,
    savePurchaseOrderSnoozeAndStatus,
    saving:
      storeMyPurchaseOrderEditWithSkusLoading ||
      storeSnoozeAndStatusLoading ||
      mutationInProgress,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
