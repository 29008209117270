import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Adjust from "@mui/icons-material/Adjust";
import Assignment from "@mui/icons-material/Assignment";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
// import Business from "@mui/icons-material/Business";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Checklist from "@mui/icons-material/Checklist";
import Compare from "@mui/icons-material/Compare";
import DirectionsRun from "@mui/icons-material/DirectionsRun";
//import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FormatListNumbered from "@mui/icons-material/FormatListNumbered";
import Group from "@mui/icons-material/Group";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Label from "@mui/icons-material/Label";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import QueryStats from "@mui/icons-material/QueryStats";
import Warehouse from "@mui/icons-material/Warehouse";
import Troubleshoot from "@mui/icons-material/Troubleshoot";

import Box from "@mui/joy/Box";
import Avatar from "@mui/joy/Avatar";
import LinearProgress from "@mui/joy/LinearProgress";
import List from "@mui/joy/List";
import ListDivider from "@mui/joy/ListDivider";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListSubheader from "@mui/joy/ListSubheader";
import Typography from "@mui/joy/Typography";

import { CustomerSelector } from "../../../../../components/customer_selector";
import { LogoText } from "../../../../../components/logo_text";
import { DrawerLinksToReports } from "../../../../../components/drawer_links_to_reports";
import { RunTrigger } from "../../../../../components/run_trigger";

export const DrawerComponent = (props: {
  navigateToRoot: () => void;
  navigateToRunHistory: () => void;
  navigateToRuns: () => void;
  navigateToRunComparison: () => void;
  navigateToForecastAccuracy: () => void;
  navigateToInventoryOnHand: () => void;
  navigateToInventoryOnHandForecast: () => void;
  navigateToEnginePresets: () => void;
  navigateToRunStatuses: () => void;
  navigateToCustomers: () => void;
  navigateToAllRuns: () => void;
  navigateToProfile: () => void;
  navigateToPlanOfRecord: () => void;
  navigateToValidationDashboard: () => void;
  navigateToPlanning: () => void;
  navigateToOperations: () => void;
  navigateToOdlToShipStation: () => void;
  navigateToGrossOdlGeneration: () => void;
  navigateToRunLoginDash: () => void;
  navigateToPOBox: () => void;
  navigateToPOBoxSnoozed: () => void;
  navigateToPOBoxArchived: () => void;
  currentPathName: string | null;
  selectedCustomerId: string | null;
  userName: string | null;
  email: string | null;
  picture: string | null;
  role: string | null;
  loading: boolean;
  drawerWidth: number;
  hasEngineRunHistory: boolean;
  hasEnginePresets: boolean;
  hasRunComparison: boolean;
  hasForecastAccuracy: boolean;
  hasInventoryOnHand: boolean;
  hasInventoryOnHandForecast: boolean;
  hasRun: boolean;
  hasValidationDashboard: boolean;
  hasFeatureStepOrchestrator: boolean;
  hasPoInbox: boolean;
  demoMode: boolean;
}) => {
  const {
    navigateToRoot,
    // navigateToRunHistory,
    navigateToRuns,
    navigateToRunComparison,
    navigateToForecastAccuracy,
    navigateToInventoryOnHand,
    navigateToInventoryOnHandForecast,
    navigateToEnginePresets,
    navigateToRunStatuses,
    navigateToCustomers,
    navigateToAllRuns,
    navigateToProfile,
    navigateToPlanOfRecord,
    navigateToValidationDashboard,
    navigateToPlanning,
    navigateToOperations,
    navigateToOdlToShipStation,
    navigateToGrossOdlGeneration,
    navigateToRunLoginDash,
    navigateToPOBox,
    navigateToPOBoxSnoozed,
    navigateToPOBoxArchived,
    currentPathName,
    selectedCustomerId,
    userName,
    email,
    picture,
    role,
    loading,
    drawerWidth,
    hasEngineRunHistory,
    hasEnginePresets,
    hasRunComparison,
    hasForecastAccuracy,
    hasInventoryOnHand,
    hasInventoryOnHandForecast,
    hasRun,
    hasValidationDashboard,
    hasFeatureStepOrchestrator,
    hasPoInbox,
    demoMode,
  } = props;

  const logoHeight = 100;

  return (
    <Box
      sx={{
        px: 2,
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: `${logoHeight}px auto 200px`,
        gap: 0,
        boxSizing: "border-box",
        minHeight: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          minHeight: logoHeight,
          maxHeight: logoHeight,
          minWidth: `${drawerWidth - 32}px`,
          maxWidth: `${drawerWidth - 32}px`,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          alignContent: "stretch",
          justifyContent: "center",
        }}
      >
        <LogoText />
      </Box>

      <Box
        sx={{
          mt: 2,
          overflowX: "hidden",
          overflowY: "auto",
          minHeight: `calc(100vh - ${logoHeight}px - 200px)`,
          maxHeight: `calc(100vh - ${logoHeight}px - 200px)`,
          minWidth: `${drawerWidth - 32}px`,
          maxWidth: `${drawerWidth - 32}px`,
        }}
      >
        {!demoMode && <CustomerSelector navigateToRoot={navigateToRoot} />}
        <List
          size="sm"
          sx={{
            "--List-item-radius": "8px",
            "--ListItemDecorator-size": "50px",
            mt: 2,
          }}
        >
          {selectedCustomerId && (
            <>
              {!hasValidationDashboard && <DrawerLinksToReports />}

              {hasValidationDashboard && (
                <>
                  <ListItemButton
                    selected={["plan_of_record"].includes(
                      currentPathName || ""
                    )}
                    onClick={navigateToPlanOfRecord}
                  >
                    <ListItemDecorator>
                      <QueryStats />
                    </ListItemDecorator>
                    <ListItemContent>Plan of Record</ListItemContent>
                  </ListItemButton>

                  <ListItemButton
                    selected={["validation_dashboard"].includes(
                      currentPathName || ""
                    )}
                    onClick={navigateToValidationDashboard}
                  >
                    <ListItemDecorator>
                      <Checklist />
                    </ListItemDecorator>
                    <ListItemContent>Validations</ListItemContent>
                  </ListItemButton>

                  <ListItemButton
                    selected={["planning"].includes(currentPathName || "")}
                    onClick={navigateToPlanning}
                  >
                    <ListItemDecorator>
                      <CalendarMonth />
                    </ListItemDecorator>
                    <ListItemContent>Planning</ListItemContent>
                  </ListItemButton>

                  <ListItemButton
                    selected={["operations"].includes(currentPathName || "")}
                    onClick={navigateToOperations}
                  >
                    <ListItemDecorator>
                      <Assignment />
                    </ListItemDecorator>
                    <ListItemContent>Operations</ListItemContent>
                  </ListItemButton>
                </>
              )}

              {hasRunComparison && (
                <ListItemButton
                  selected={["demand_pivot"].includes(currentPathName || "")}
                  onClick={navigateToRunComparison}
                >
                  <ListItemDecorator>
                    <Compare />
                  </ListItemDecorator>
                  <ListItemContent>Run Comparison</ListItemContent>
                </ListItemButton>
              )}

              {hasForecastAccuracy && (
                <ListItemButton
                  selected={["forecast_accuracy"].includes(
                    currentPathName || ""
                  )}
                  onClick={navigateToForecastAccuracy}
                >
                  <ListItemDecorator>
                    <Adjust />
                  </ListItemDecorator>
                  <ListItemContent>Forecast Accuracy</ListItemContent>
                </ListItemButton>
              )}
              {hasInventoryOnHand && (
                <ListItemButton
                  selected={["inventory_on_hand"].includes(
                    currentPathName || ""
                  )}
                  onClick={navigateToInventoryOnHand}
                >
                  <ListItemDecorator>
                    <Warehouse />
                  </ListItemDecorator>
                  <ListItemContent>Inventory KPIs</ListItemContent>
                </ListItemButton>
              )}
              {hasInventoryOnHandForecast && (
                <ListItemButton
                  selected={["inventory_on_hand_forecast"].includes(
                    currentPathName || ""
                  )}
                  onClick={navigateToInventoryOnHandForecast}
                >
                  <ListItemDecorator>
                    <Warehouse />
                  </ListItemDecorator>
                  <ListItemContent>Inventory KPIs</ListItemContent>
                </ListItemButton>
              )}

              {hasPoInbox && (
                <>
                  <ListSubheader>Purchase Orders</ListSubheader>
                  <ListItemButton onClick={navigateToPOBox}>
                    <ListItemDecorator>
                      <LocalShippingOutlinedIcon />
                    </ListItemDecorator>
                    <ListItemContent>New and Sent to Vendor</ListItemContent>
                  </ListItemButton>
                  <ListItemButton onClick={navigateToPOBoxSnoozed}>
                    <ListItemDecorator>
                      <AccessTimeOutlinedIcon />
                    </ListItemDecorator>
                    <ListItemContent>Snoozed</ListItemContent>
                  </ListItemButton>
                  <ListItemButton onClick={navigateToPOBoxArchived}>
                    <ListItemDecorator>
                      <AssignmentTurnedInOutlinedIcon />
                    </ListItemDecorator>
                    <ListItemContent>Archived</ListItemContent>
                  </ListItemButton>
                </>
              )}

              {!hasValidationDashboard && (
                <>
                  {hasEngineRunHistory && <ListSubheader>Runs</ListSubheader>}

                  {/* {hasEngineRunHistory && (
                    <ListItemButton
                      selected={["run_history"].includes(currentPathName || "")}
                      onClick={navigateToRunHistory}
                    >
                      <ListItemDecorator>
                        <FormatListNumbered />
                      </ListItemDecorator>
                      <ListItemContent>Run History</ListItemContent>
                    </ListItemButton>
                  )} */}

                  {hasEngineRunHistory && (
                    <ListItemButton
                      selected={["runs", "runs_with_details"].includes(
                        currentPathName || ""
                      )}
                      onClick={navigateToRuns}
                    >
                      <ListItemDecorator>
                        <FormatListNumbered />
                      </ListItemDecorator>
                      <ListItemContent>Run History</ListItemContent>
                    </ListItemButton>
                  )}

                  {hasEnginePresets && (
                    <ListItemButton
                      selected={[
                        "engine_presets",
                        "engine_presets_with_create",
                        "engine_presets_with_replace",
                        "engine_presets_with_run",
                      ].includes(currentPathName || "")}
                      onClick={navigateToEnginePresets}
                    >
                      <ListItemDecorator>
                        <Label />
                      </ListItemDecorator>
                      <ListItemContent>Parameter Presets</ListItemContent>
                    </ListItemButton>
                  )}
                </>
              )}

              {selectedCustomerId === "goodchop" && (
                <>
                  <ListSubheader>Operations</ListSubheader>
                  <ListItemButton onClick={navigateToGrossOdlGeneration}>
                    <ListItemDecorator>
                      <LocalShippingOutlinedIcon />
                    </ListItemDecorator>
                    <ListItemContent>Generate Gross ODL</ListItemContent>
                  </ListItemButton>
                  <ListItemButton onClick={navigateToOdlToShipStation}>
                    <ListItemDecorator>
                      <LocalShippingOutlinedIcon />
                    </ListItemDecorator>
                    <ListItemContent>ODL to Shipstation</ListItemContent>
                  </ListItemButton>
                </>
              )}
            </>
          )}

          {role === "ROOT" && !demoMode && (
            <>
              <ListDivider inset="gutter" sx={{ my: 2 }} />
              <ListSubheader>Internal Dashboards</ListSubheader>

              <ListItemButton onClick={navigateToRunLoginDash}>
                <ListItemDecorator>
                  <PendingActionsIcon />
                </ListItemDecorator>
                <ListItemContent>Runs and Logins</ListItemContent>
              </ListItemButton>

              {hasFeatureStepOrchestrator && (
                <ListItemButton
                  selected={[
                    "run_statuses",
                    "run_statuses_with_details",
                  ].includes(currentPathName || "")}
                  onClick={navigateToRunStatuses}
                >
                  <ListItemDecorator>
                    <Troubleshoot />
                  </ListItemDecorator>
                  <ListItemContent>Workflow details</ListItemContent>
                </ListItemButton>
              )}

              <ListItemButton
                selected={["customers", "customers_with_details"].includes(
                  currentPathName || ""
                )}
                onClick={navigateToCustomers}
              >
                <ListItemDecorator>
                  <Group />
                </ListItemDecorator>
                <ListItemContent>Customers</ListItemContent>
              </ListItemButton>

              <ListItemButton
                selected={["all_runs"].includes(currentPathName || "")}
                onClick={navigateToAllRuns}
              >
                <ListItemDecorator>
                  <DirectionsRun />
                </ListItemDecorator>
                <ListItemContent>Latest runs</ListItemContent>
              </ListItemButton>
            </>
          )}
        </List>
      </Box>

      <Box
        sx={{
          minHeight: "200px", // 100px + 13px divider + 87px run trigger
          maxHeight: "200px",
          minWidth: `${drawerWidth - 32}px`,
          maxWidth: `${drawerWidth - 32}px`,
          overflow: "hidden",
        }}
      >
        <List
          size="sm"
          sx={{
            "--List-item-radius": "8px",
            "--ListItemDecorator-size": "50px",
            mt: 2,
          }}
        >
          <RunTrigger enabled={hasRun} />
          <ListDivider sx={{ mb: "8px" }} />
          <ListItemButton
            selected={["profile"].includes(currentPathName || "")}
            onClick={navigateToProfile}
          >
            <ListItemDecorator sx={{ alignSelf: "flex-start" }}>
              <Avatar
                color="primary"
                size="md"
                {...(picture && { src: picture })}
              />
            </ListItemDecorator>
            <ListItemContent>
              {loading ? (
                <LinearProgress size="lg" />
              ) : (
                <>
                  <Typography level="body-sm" noWrap>
                    {userName}
                  </Typography>
                  <Typography level="body-xs" noWrap>
                    {email}
                  </Typography>
                </>
              )}
            </ListItemContent>
            <KeyboardArrowRight />
          </ListItemButton>
        </List>
      </Box>
    </Box>
  );
};
