import { Page } from "../../../frameworks/joy/layouts/page";
import { GoodchopGrossOdlGenerationContainer } from "./container";

export const GoodchopGrossOdlGenerationPage = () => {
  return (
    <Page>
      <GoodchopGrossOdlGenerationContainer />
    </Page>
  );
};
