import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import Chip from "@mui/joy/Chip";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import {
  EnginePreset,
  OrchestratorRun,
} from "../../services/api/__generated__/backend_gateway-types";
import { runStatusToEmoji } from "./run_status_to_emoji";
import { runStatusToText } from "./run_status_to_text";
dayjs.extend(utc);

const runToId = (run: OrchestratorRun): string =>
  `${run.runSettings.inputId}${run.runSettings.workflowId}${run.runSettings.variablesId}${run.runSettings.runAttemptId}`;

export const RunStatusesComponent = (props: {
  loading: boolean;
  runs: OrchestratorRun[];
  navigateToRunStatusesWithNewRun: () => void;
  navigateToRunStatusesWithRunDetails: (
    inputId: string,
    workflowId: string,
    variablesId: string,
    runAttemptId: number
  ) => void;
  presets: EnginePreset[];
}) => {
  const { loading, runs, navigateToRunStatusesWithRunDetails, presets } = props;
  const [selectedRunStatus, setSelectedRunStatus] = useState<string | null>(
    null
  );
  const sortedRuns = [...runs].sort((a: OrchestratorRun, b: OrchestratorRun) =>
    dayjs(a.createdAt).diff(b.createdAt) > 0 ? -1 : 1
  );

  const getPresetName = (id: string | null): string => {
    if (id === null) {
      return "";
    }
    const preset = presets.find((pr) => pr.id === parseInt(id));
    if (preset) {
      return `${preset.name || preset.id} ${preset.default ? "(default)" : ""}`;
    }
    return `${id}`;
  };
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
        backgroundColor: "atomic.highlightUnderlay",
      }}
    >
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 4,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <PageTitle>New engine workflows</PageTitle>

        {loading && <LinearProgress />}

        {!loading && (
          <Sheet
            sx={{
              "--TableCxell-height": "40px",
              "--TableHeader-height": "64px",
              minHeight: "10vh",
              maxHeight: "70vh",
              overflow: "auto",
              background: (
                theme
              ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
                    linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
                    radial-gradient(
                      farthest-side at 50% 0,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                    ),
                    radial-gradient(
                        farthest-side at 50% 100%,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                      )
                      0 100%`,
              backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "local, local, scroll, scroll",
              backgroundPosition:
                "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
              backgroundColor: (theme) =>
                `${theme.palette.atomic.highlightBackground}`,
            }}
          >
            <Table
              aria-labelledby="Runs"
              stickyHeader
              hoverRow={true}
              // stripe="odd"
              borderAxis="xBetween"
              sx={{
                maxWidth: "1200px",
                "--Table-headerUnderlineThickness": "1px",
                "--TableCell-paddingY": "4px",
                "--TableCell-paddingX": "8px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "150px",
                    }}
                  >
                    Started at
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "100px",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "250px",
                    }}
                  >
                    Flow
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "100px",
                    }}
                  >
                    Variables
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedRuns.map((run) => (
                  <tr
                    key={runToId(run)}
                    onClick={() => {
                      navigateToRunStatusesWithRunDetails(
                        run.runSettings.inputId,
                        run.runSettings.workflowId,
                        run.runSettings.variablesId,
                        run.runSettings.runAttemptId
                      );
                      setSelectedRunStatus(runToId(run));
                    }}
                    aria-checked={runToId(run) === selectedRunStatus}
                    role="checkbox"
                    style={
                      runToId(run) === selectedRunStatus
                        ? ({
                            // "--TableCell-dataBackground": colors.lightShadow,
                            // "--TableCell-headBackground": colors.lightShadow,
                            // "--TableCell-dataBackground": "atomic.lightShadow",
                            // "--TableCell-headBackground": "atomic.lightShadow",
                            "--TableCell-dataBackground": "rgba(0,0,0,0.2)",
                            "--TableCell-headBackground": "rgba(0,0,0,0.2)",
                          } as React.CSSProperties)
                        : {}
                    }
                  >
                    <td>
                      {dayjs
                        .utc(run.createdAt)
                        .local()
                        .format("MM/DD/YYYY hh:mm:ss a")}
                    </td>
                    <td>
                      <Chip size="sm">
                        {runStatusToEmoji(run.status)}{" "}
                        {runStatusToText(run.status)}
                      </Chip>
                    </td>
                    <td>{run.runSettings.workflowId}</td>
                    <td>{getPresetName(run.runSettings.variablesId)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        )}
      </Sheet>
    </PageFrame>
  );
};
