import { ReactNode } from "react";
import Box from "@mui/joy/Box";
import { Theme } from "@mui/joy/styles";
import { SxProps } from "@mui/system";

export const PageFrame = (props: {
  children?: ReactNode;
  sx?: SxProps<Theme> | undefined;
}) => {
  return (
    <Box
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 1.5,
          m: 5,
          minWidth: 0,
          maxWidht: "100%",
          overflow: "hidden",          
        },
      ]}
      {...props}
    />
  );
};
