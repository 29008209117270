import React, { ReactNode, useState } from "react";
import { DRAWER_WIDTH } from "./constants";
import { DrawerContext } from "./context";
import { DrawerType } from "./type";

const { Provider } = DrawerContext;

export const DrawerProvider = (props: { children: ReactNode }): JSX.Element => {
  const drawerWidth: number = DRAWER_WIDTH;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = (): void => {
    setDrawerOpen(!drawerOpen);
  };

  const closeDrawer = (): void => {
    setDrawerOpen(false);
  };

  const value: DrawerType = {
    drawerOpen,
    drawerWidth,
    handleDrawerToggle,
    closeDrawer,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
