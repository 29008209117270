import Papa from "papaparse";

export type IOHFData = {
  date: Date;
  total_cogs_on_hand: number;
};

export type RunData = {
  runId: number;
  url: string;
  data: IOHFData[] | null;
};

export class InventoryOnHandForecastData {
  private runData = new Map<number, RunData>();

  private setDataLoading: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;

  constructor(
    setDataLoading: React.Dispatch<
      React.SetStateAction<{
        [key: string]: boolean;
      }>
    >
  ) {
    this.setDataLoading = setDataLoading;
  }

  setRunIdsAndUrls = (
    runIds: number[],
    runIdsWithForecastUrls: Record<number, string>
  ) => {
    const exisingRunIds: number[] = Array.from(this.runData.keys());
    const toDeleteRunIds: number[] = [];
    const toAddRunIds: number[] = [...runIds];
    exisingRunIds.forEach((exisingRunId) => {
      if (toAddRunIds.includes(exisingRunId)) {
        const ix = toAddRunIds.indexOf(exisingRunId);
        delete toAddRunIds[ix];
      } else {
        toDeleteRunIds.push(exisingRunId);
      }
    });
    toDeleteRunIds.forEach((runId) => {
      this.runData.delete(runId);
    });
    toAddRunIds.forEach((runId) =>
      this.addAndFetchDataUrls(runId, runIdsWithForecastUrls[runId])
    );
  };

  addAndFetchDataUrls = (runId: number, url: string) => {
    const existingRunData = this.runData.get(runId);
    if (existingRunData !== undefined) {
      return;
    }

    this.runData.set(runId, {
      runId,
      url,
      data: [],
    });

    this.setDataLoading((state) => ({
      ...state,
      [runId]: true,
    }));
    Papa.parse<IOHFData>(url, {
      download: true,
      worker: true,
      header: true,
      dynamicTyping: true,
      step: (results) => {
        const { date, total_cogs_on_hand } = results.data;
        if (
          date !== undefined &&
          date !== null &&
          !["undefined", "NULL", "null", "None", ""].includes(`${date}`) &&
          total_cogs_on_hand !== undefined &&
          total_cogs_on_hand !== null &&
          !["undefined", "NULL", "null", "None", ""].includes(
            `${total_cogs_on_hand}`
          )
        ) {
          const runData = this.runData.get(runId);
          this.runData.set(runId, {
            ...runData!,
            data: [
              ...runData!.data!,
              {
                date: new Date(date),
                total_cogs_on_hand,
              },
            ],
          });
        }
      },
      error(error, file) {
        console.info("error", runId, url, error, file);
      },
      complete: () => {
        this.setDataLoading((state) => ({
          ...state,
          [runId]: false,
        }));
      },
    });
  };

  getData = () => {
    const o: Record<number, IOHFData[]> = {};
    Array.from(this.runData.keys()).forEach((runId: number) => {
      o[runId] = this.runData.get(runId)!.data!;
    });
    return o;
  };
}
