import { useContext, useEffect, useState } from "react";
import { DiffParams, SalesMeasure, Segmentation } from "../diff_params";
import { ForecastAccuracyFromCsv } from "../forecast_accuracy_from_csv";
import { SkuTagsFromCsv } from "../sku_hierarchy_from_csv";
import { ForecastAccuracyTableComponent } from "./table_component";
import { TableStructure } from "./table_structure";
import { TBodyContainer } from "./tbody_container";
import { ThComponent } from "./th_component";
import { useGetMyCustomerQuery } from "../../../services/api/__generated__/backend_gateway-types";
import { ApiContext } from "../../../contexts/api/context";

export const ForecastAccuracyTableContainer = (props: {
  diffParams: DiffParams;
  forecastAccuracy: ForecastAccuracyFromCsv;
  forecastAccuracyLoading: boolean;
  skuTags: SkuTagsFromCsv;
  skuTagsLoading: boolean;
}) => {
  const {
    diffParams,
    forecastAccuracy,
    forecastAccuracyLoading,
    skuTags,
    skuTagsLoading,
  } = props;
  const [categorizedData, setCategorizedData] = useState<
    Record<string, TableStructure>
  >({});
  const [totals, setTotals] = useState<TableStructure>({
    forecastValue: 0,
    actualValue: 0,
    deltaValue: 0,
    correctValue: 0,
    wmape: 0,
  });

  const { apiService } = useContext(ApiContext);
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });

  useEffect(() => {
    if (forecastAccuracyLoading || skuTagsLoading) {
      return;
    }
    const skuDict = skuTags.toDict();
    const uncategorizedData: Record<string, TableStructure> = {};

    forecastAccuracy.getItems().forEach((fa) => {
      const {
        sku,
        location,
        channel,
        actual_quantity,
        actual_revenue,
        forecast_quantity,
        forecast_revenue,
      } = fa;
      const forecastValue =
        diffParams.salesMeasure === SalesMeasure.QUANTITY
          ? forecast_quantity
          : forecast_revenue || 0;
      const actualValue =
        diffParams.salesMeasure === SalesMeasure.QUANTITY
          ? actual_quantity
          : actual_revenue || 0;
      const faTags = skuDict[sku] || {};
      let tagValue: string | null = null;

      switch (diffParams.segmentation) {
        case Segmentation.CHANNEL:
          tagValue = channel || "Unknown";
          break;
        case Segmentation.LOCATION:
          tagValue = location || "Unknown";
          break;
        case Segmentation.PRODUCT_TYPE:
          tagValue = faTags["product_type"] || "Uncategorized";
          break;
        case Segmentation.PRODUCT_FAMILY:
          tagValue = faTags["product_family"] || "Uncategorized";
          break;
        case Segmentation.PRODUCT_TITLE:
          tagValue = faTags["product_title"] || "Uncategorized";
          break;
        case Segmentation.PRODUCT_CATEGORY:
          tagValue = faTags["product_category"] || "Uncategorized";
          break;
      }
      if (!tagValue) {
        tagValue = "Uncategorized";
      }
      const key = `${tagValue}|${sku}`;
      let item: TableStructure | null = uncategorizedData[key] || null;
      if (item === null) {
        uncategorizedData[key] = {
          forecastValue: 0,
          actualValue: 0,
          deltaValue: 0,
          correctValue: 0,
          wmape: 0,
        };
        item = uncategorizedData[key];
      }
      item.forecastValue += forecastValue;
      item.actualValue += actualValue;
      item.deltaValue = Math.abs(item.forecastValue - item.actualValue);
      item.correctValue = Math.min(item.forecastValue, item.actualValue);
      item.wmape =
        item.deltaValue === 0
          ? 0
          : Math.abs(item.deltaValue / item.actualValue);
    });

    const categorizedData: Record<string, TableStructure> = {};
    let sumOfForecast: number = 0;
    let sumOfActuals: number = 0;
    let sumOfAbsDeltas: number = 0;
    let sumOfCorrectValues: number = 0;
    (Object.keys(uncategorizedData) as Array<string>).forEach((key) => {
      const [tagValue, sku] = key.split("|");

      let topItem = categorizedData[tagValue];
      if (topItem === undefined) {
        categorizedData[tagValue] = {
          forecastValue: 0,
          actualValue: 0,
          deltaValue: 0,
          correctValue: 0,
          wmape: 0,
          children: {},
        } as TableStructure;
        topItem = categorizedData[tagValue];
      }
      const child = uncategorizedData[key];
      topItem.children![sku] = child;
      topItem.forecastValue += child.forecastValue;
      topItem.actualValue += child.actualValue;
      topItem.deltaValue += child.deltaValue;
      topItem.correctValue += child.correctValue;
      topItem.wmape =
        topItem.deltaValue === 0 ? 0 : topItem.deltaValue / topItem.actualValue;

      sumOfForecast += child.forecastValue;
      sumOfActuals += child.actualValue;
      sumOfAbsDeltas += child.deltaValue;
      sumOfCorrectValues += child.correctValue;
    });

    setCategorizedData((oldCategorizedData) => {
      const keys = Object.keys(categorizedData) as Array<string>;
      keys.forEach((k) => {
        categorizedData[k].isExpanded =
          oldCategorizedData[k]?.isExpanded !== undefined
            ? oldCategorizedData[k].isExpanded
            : false;
      });
      return categorizedData;
    });
    setTotals({
      forecastValue: sumOfForecast,
      actualValue: sumOfActuals,
      deltaValue: sumOfAbsDeltas,
      correctValue: sumOfCorrectValues,
      wmape: sumOfAbsDeltas === 0 ? 0 : sumOfAbsDeltas / sumOfActuals,
    });
  }, [
    diffParams,
    forecastAccuracyLoading,
    forecastAccuracy,
    skuTagsLoading,
    skuTags,
  ]);

  const toggleLabelExpansion = (label: string) => {
    setCategorizedData((oldCategorizedData) => {
      const newCategorizedData: Record<string, TableStructure> = {};
      const labels = Object.keys(oldCategorizedData) as Array<string>;
      labels.forEach((l) => (newCategorizedData[l] = oldCategorizedData[l]));
      newCategorizedData[label].isExpanded =
        !newCategorizedData[label].isExpanded;
      return newCategorizedData;
    });
  };

  if (customerError) {
    console.error(
      "ForecastAccuracyTableContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }

  return (
    <ForecastAccuracyTableComponent>
      <ThComponent
        loading={forecastAccuracyLoading || skuTagsLoading || customerLoading}
        error={null}
        diffParams={diffParams}
        hasProductCategory={
          customerData?.getMyCustomer?.features?.SkuHierarchyProductCategory ||
          false
        }
        hasProductType={
          customerData?.getMyCustomer?.features?.SkuHierarchyProductType ||
          false
        }
        hasProductFamily={
          customerData?.getMyCustomer?.features?.SkuHierarchyProductFamily ||
          false
        }
        hasProductTitle={
          customerData?.getMyCustomer?.features?.SkuHierarchyProductTitle ||
          false
        }
      />
      <TBodyContainer
        data={categorizedData}
        totals={totals}
        loading={forecastAccuracyLoading || skuTagsLoading}
        toggleLabelExpansion={toggleLabelExpansion}
        salesMeasure={diffParams.salesMeasure || SalesMeasure.QUANTITY}
      />
    </ForecastAccuracyTableComponent>
  );
};
