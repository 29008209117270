import { useContext, useEffect, useState } from "react";
import { InventoryOnHandTotalCogsComponent } from "./component";
import { RunTriggerContext } from "../../../contexts/run_trigger/context";
import { InventoryOnHandData } from "../inventory_on_hand_data";
import { TotalCogs } from "./total_cogs";
import { SkuTotalCogs } from "./sku_total_cogs";

export const InventoryOnHandTotalCogsContainer = (props: {
  inventoryOnHandData: InventoryOnHandData;
  inventoryOnHandDataLoading: boolean;
}) => {
  const { inventoryOnHandData, inventoryOnHandDataLoading } = props;
  const { runLogs, isLogsLoading } = useContext(RunTriggerContext);
  const [totalCogs, setTotalCogs] = useState<TotalCogs[]>([]);
  const [skuTotalCogs, setSkuTotalCogs] = useState<SkuTotalCogs[]>([]);

  useEffect(() => {
    if (inventoryOnHandDataLoading === false && isLogsLoading === false) {
      const newTotalCogs: TotalCogs[] = [];
      const newSkuTotalCogs: SkuTotalCogs[] = [];
      console.log("inventoryOnHandData.getData()", inventoryOnHandData.getData());
      inventoryOnHandData.getData().forEach((runData) => {
        // const dateString =
        //   // runLogs.find((rl) => rl.id === runData.runId)?.startedAt ||
        //   runData.date || null;
        // if (dateString === null) {
        //   console.info(
        //     "There is no runlog or date with the id of the inventory data"
        //   );
        //   return;
        // }
        // const date = new Date(dateString);
        const totalCogs = (runData.data?.map((d) => d.total_cogs) || []).reduce(
          (s, i) => s + i,
          0
        );
        newTotalCogs.push({
          date: runData.date,
          totalCogs,
        });
        runData.data?.forEach((d) => {
          newSkuTotalCogs.push({
            date: runData.date,
            totalCogs: d.total_cogs,
            sku: d.sku,
            location: d.location,
            unitCogs: d.unit_cogs,
            quantityOnHand: d.quantity,
          });
        });
      });
      setTotalCogs(() => newTotalCogs);
      setSkuTotalCogs(() => newSkuTotalCogs);
    }
  }, [inventoryOnHandDataLoading, isLogsLoading, inventoryOnHandData, runLogs]);

  const downloadSummaryCsvBlob = () => {
    const csv = [
      "date,total_cogs",
      ...totalCogs
        .filter((stc) => stc.date !== undefined)
        .map((stc) =>
          [stc.date?.toISOString().split("T")[0], `${stc?.totalCogs}`].join(",")
        ),
    ].join("\r\n");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "total_cogs_summary.csv");
    pom.click();
    pom.remove();
  };

  const downloadDetailedCsvBlob = () => {
    const csv = [
      "date,sku,location,total_cogs,unit_cogs,quantity_on_hand",
      ...skuTotalCogs
        .filter((stc) => stc.date !== undefined && stc.sku !== undefined)
        .map((stc) =>
          [
            stc.date?.toISOString().split("T")[0],
            '"' + `${stc.sku}`.replaceAll('"', '""') + '"',
            '"' + `${stc.location}`.replaceAll('"', '""') + '"',
            `${stc.totalCogs}`,
            `${stc.unitCogs}`,
            `${stc.quantityOnHand}`,
          ].join(",")
        ),
    ].join("\r\n");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "total_cogs_detailed.csv");
    pom.click();
    pom.remove();
  };

  return (
    <InventoryOnHandTotalCogsComponent
      totalCogs={totalCogs}
      loading={inventoryOnHandDataLoading}
      downloadSummaryCsvBlob={downloadSummaryCsvBlob}
      downloadDetailedCsvBlob={downloadDetailedCsvBlob}
    />
  );
};
