import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import { TableRow } from "./table_row";
import { SalesMeasure } from "../diff_params";

export const TBodyComponent = (props: {
  loading: boolean;
  error: string | null;
  dataFlattened: TableRow[];
  toggleLabelExpansion: (label: string) => void;
  salesMeasure: SalesMeasure;
}) => {
  const { loading, dataFlattened, toggleLabelExpansion, salesMeasure } = props;
  const numberFormatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 3,
    ...(salesMeasure === SalesMeasure.REVENUE && {
      style: "currency",
      currency: "USD",
    }),
  });
  const wmapeFormatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 3,
  });

  return (
    <tbody>
      {!loading &&
        dataFlattened.map((dataRow) => (
          <tr key={dataRow.key}>
            <td>
              {dataRow.hasChildren && (
                <IconButton
                  onClick={() => {
                    toggleLabelExpansion(dataRow.label);
                  }}
                >
                  {dataRow.isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
            </td>
            <td>
              <Typography
                level="body-xs"
                sx={{
                  fontWeight:
                    dataRow.hasChildren || dataRow.isTotal ? 600 : "inherit",
                }}
              >
                {dataRow.label}
              </Typography>
            </td>
            <td>
              <Typography
                level="body-xs"
                sx={{
                  fontWeight: dataRow.isTotal ? 600 : "inherit",
                }}
              >
                {numberFormatter.format(dataRow.forecastValue)}
              </Typography>
            </td>
            <td>
              <Typography
                level="body-xs"
                sx={{
                  fontWeight: dataRow.isTotal ? 600 : "inherit",
                }}
              >
                {numberFormatter.format(dataRow.actualValue)}
              </Typography>
            </td>
            <td>
              <Typography
                level="body-xs"
                sx={{
                  fontWeight: dataRow.isTotal ? 600 : "inherit",
                }}
              >
                {numberFormatter.format(dataRow.correctValue)}
              </Typography>
            </td>
            <td>
              <Typography
                level="body-xs"
                sx={{
                  fontWeight: dataRow.isTotal ? 600 : "inherit",
                }}
              >
                {numberFormatter.format(dataRow.deltaValue)}
              </Typography>
            </td>
            <td>
              <Typography
                level="body-xs"
                sx={{
                  fontWeight: dataRow.isTotal ? 600 : "inherit",
                }}
              >
                {isNaN(dataRow.wmape)
                  ? ""
                  : wmapeFormatter.format(dataRow.wmape)}
              </Typography>
            </td>
          </tr>
        ))}
    </tbody>
  );
};
