import { PageWithRightPane } from "../../frameworks/joy/layouts/page_with_right_pane";
import { RunHistoryContainer } from "./container";

export const RunHistoryPage = () => {
  return (
    <PageWithRightPane>
      <RunHistoryContainer />
    </PageWithRightPane>
  );
};
