import { RunLog } from "../../../services/api/__generated__/backend_gateway-types";

export const runDescription = (runLogs: RunLog[], runId: number): string => {
  const runLog = runLogs.find((rl) => rl?.id === runId);
  if (runLog === undefined) {
    // console.error("runDescription: run not found for runId", runId);
    return "";
  }
  return `${runLog.name || ""}${
    runLog.name && runLog.description ? " - " : ""
  }${runLog.description || ""}`;
};
