import { useState } from "react";
import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import "./index.css";

export interface RunData {
  email: string;
  total_runs: number;
  avg_runs_per_week: number;
  latest_started_at: string;
  avg_started_time: string;
  company: string;
}

export interface LoginData {
  name: string;
  email: string;
  company: string;
  total_logins: number;
  avg_logins_per_week: string;
  last_login_time: string;
  total_refresh_token_exchanges: number;
  last_refresh_token_exchange_time: string;
  avg_refresh_token_exchanges_per_week: number;
}

interface DataTableProps<T> {
  data: T[];
  headers: (keyof T)[];
  dateColumns: (keyof T)[];
  filename: string;
}

interface SortConfig<T> {
  key: keyof T | null;
  direction: "ascending" | "descending";
}

const SortIndicator = ({
  direction,
}: {
  direction: "ascending" | "descending" | undefined;
}) => {
  if (!direction) return <span>↕</span>; // Default indicator
  return <span>{direction === "ascending" ? "↑" : "↓"}</span>;
};

const downloadCSV = <T,>(data: T[], filename: string, headers: (keyof T)[]) => {
  const csvRows = data.map((item) =>
    headers.map((header) => (item as any)[header]).join(",")
  );

  const csvString = [headers.join(","), ...csvRows].join("\n");
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
};

// const convertToEST = (dateString: string) => {
//   const date = new Date(dateString);
//   const estDateString = date.toLocaleString("en-US", {
//     timeZone: "America/New_York",
//     hour12: false,
//   });
//   return new Date(estDateString);
// };

const DataTable = <T extends { company: string }>({
  data,
  headers,
  dateColumns,
  filename,
}: DataTableProps<T>) => {
  const [sortConfig, setSortConfig] = useState<SortConfig<T>>({
    key: null,
    direction: "ascending",
  });
  const [selectedDateColumn, setSelectedDateColumn] = useState<keyof T | null>(
    null
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCompany, setSelectedCompany] = useState<string>("");

  const sortedData = [...data];
  if (sortConfig.key !== null) {
    sortedData.sort((a, b) => {
      const key = sortConfig.key as keyof T; // Safeguard for TypeScript
      if (a[key] < b[key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }

  const requestSort = (key: keyof T) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name: keyof T) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handleDateColumnChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedDateColumn(event.target.value as keyof T);
  };

  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompany(event.target.value);
  };

  const convertToEST = (dateString: string) => {
    const date = new Date(dateString);
    const estDateString = date.toLocaleString("en-US", {
      timeZone: "America/New_York",
      hour12: false,
    });
    return new Date(estDateString);
  };

  const filteredData = sortedData.filter((item) => {
    const dateColumnValue = selectedDateColumn
      ? convertToEST(item[selectedDateColumn]?.toString() || "")
      : null;

    const startDateTime = new Date(`${startDate}T00:00:00`);
    const endDateTime = new Date(`${endDate}T23:59:59`);

    const inDateRange =
      !dateColumnValue ||
      !startDate ||
      !endDate ||
      (dateColumnValue >= startDateTime && dateColumnValue <= endDateTime);

    const inCompanyFilter =
      selectedCompany === "" || item["company"] === selectedCompany;

    return inDateRange && inCompanyFilter;
  });

  const uniqueCompanies = Array.from(
    new Set(data.map((item: any) => item["company"]))
  );

  const dateColumnsExcludingAvgStartedTime = dateColumns.filter(
    (column) => column !== "avg_started_time"
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <button
          style={{
            margin: "0 8px",
            padding: "8px 16px",
            cursor: "pointer",
          }}
          onClick={() => downloadCSV(filteredData, filename, headers)}
        >
          Download CSV
        </button>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <select value={selectedCompany || ""} onChange={handleCompanyChange}>
            <option value="">Select Company</option>
            {uniqueCompanies.map((company) => (
              <option key={company} value={company}>
                {company}
              </option>
            ))}
          </select>
          <select
            value={selectedDateColumn ? String(selectedDateColumn) : ""}
            onChange={handleDateColumnChange}
            style={{ marginLeft: "10px" }}
          >
            <option value="" disabled>
              Select Date Column
            </option>
            {dateColumnsExcludingAvgStartedTime.map((column) => (
              <option key={column as string} value={column as string}>
                {(column as string).replace("_", " ")}
              </option>
            ))}
          </select>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Box>
      </Box>
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 4,
          overflow: "auto",
          minWidth: "0",
          maxWidth: "100%",
          maxHeight: "400px",
          borderRadius: "4px",
        }}
      >
        <Box
          component="table"
          sx={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <Box component="thead">
            <Box component="tr">
              {headers.map((column) => (
                <Box
                  component="th"
                  key={column as string}
                  sx={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    cursor: "pointer",
                    backgroundColor: "#f1f1f1",
                    textAlign: "left",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                  onClick={() => requestSort(column)}
                >
                  {(column as string).replace("_", " ").toUpperCase()}
                  <SortIndicator direction={getClassNamesFor(column)} />
                </Box>
              ))}
            </Box>
          </Box>
          <Box component="tbody">
            {filteredData.map((row, index) => (
              <Box component="tr" key={index}>
                {headers.map((header) => (
                  <Box
                    component="td"
                    key={header as string}
                    sx={{ border: "1px solid #ccc", padding: "8px" }}
                  >
                    {(row as any)[header]}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Sheet>
    </>
  );
};

export const RunLoginDashComponent = (props: {
  loading: boolean;
  data: {
    run_data: RunData[];
    login_data: LoginData[];
  };
  error: any;
}) => {
  const { loading, data /*error*/ } = props;
  const [activeTab, setActiveTab] = useState("runData");

  return (
    <PageFrame>
      <PageTitle>{"Run and Login Data"}</PageTitle>

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      {!loading && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 2,
            }}
          >
            <div>
              <button
                style={{
                  margin: "0 8px",
                  padding: "8px 16px",
                  cursor: "pointer",
                  background: activeTab === "runData" ? "#ccc" : "#fff",
                }}
                onClick={() => setActiveTab("runData")}
              >
                Run Data
              </button>
              <button
                style={{
                  margin: "0 8px",
                  padding: "8px 16px",
                  cursor: "pointer",
                  background: activeTab === "loginData" ? "#ccc" : "#fff",
                }}
                onClick={() => setActiveTab("loginData")}
              >
                Login Data
              </button>
            </div>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            {activeTab === "runData" && (
              <DataTable
                data={data.run_data}
                headers={[
                  "email",
                  "total_runs",
                  "avg_runs_per_week",
                  "latest_started_at",
                  "avg_started_time",
                  "company",
                ]}
                dateColumns={["latest_started_at"]}
                filename="run_data.csv"
              />
            )}
            {activeTab === "loginData" && (
              <DataTable
                data={data.login_data}
                headers={[
                  "name",
                  "email",
                  "company",
                  "total_logins",
                  "avg_logins_per_week",
                  "last_login_time",
                  "total_refresh_token_exchanges",
                  "last_refresh_token_exchange_time",
                  "avg_refresh_token_exchanges_per_week",
                ]}
                dateColumns={[
                  "last_login_time",
                  "last_refresh_token_exchange_time",
                ]}
                filename="login_data.csv"
              />
            )}
          </Box>
        </>
      )}
    </PageFrame>
  );
};
