import { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiContext } from "../../contexts/api/context";
import { AppearanceContext } from "../../contexts/appearance/context";
import {
  useGetMyCustomerQuery,
  User,
  useWhoAmIQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { WhoAmIComponent } from "./component";

export const WhoAmIContainer = () => {
  const { apiService } = useContext(ApiContext);
  const { demoMode, setDemoMode, modeString, setModeString } =
    useContext(AppearanceContext);
  const { logout } = useAuth0();
  const { data, loading, error } = useWhoAmIQuery({
    client: apiService.getClient(),
  });
  if (error) {
    console.error("WhoAmIContainer: useWhoAmIQuery fetch error", error);
  }

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });

  if (customerError) {
    console.error(
      "WhoAmIContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }

  return (
    <WhoAmIComponent
      loading={loading || customerLoading}
      user={data?.whoAmI as User}
      signOut={logout}
      hasDarkMode={customerData?.getMyCustomer?.features?.DarkMode || false}
      demoMode={demoMode}
      setDemoMode={setDemoMode}
      modeString={modeString}
      setModeString={setModeString}
    />
  );
};
