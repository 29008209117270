import Chip from "@mui/joy/Chip";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import {
  Customer,
  OrchestratorRun,
} from "../../services/api/__generated__/backend_gateway-types";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import { runStatusToEmoji } from "../run_statuses/run_status_to_emoji";
import { runStatusToText } from "../run_statuses/run_status_to_text";
dayjs.extend(utc);

const runToId = (run: OrchestratorRun): string =>
  `${run.runSettings.inputId}${run.runSettings.workflowId}${run.runSettings.variablesId}${run.runSettings.runAttemptId}`;

export const AllRunsComponent = (props: {
  loading: boolean;
  customers: Customer[];
  runs: OrchestratorRun[];
  navigateToFailedSteps: (
    customerId: string,
    inputId: string,
    workflowId: string,
    variablesId: string,
    runAttemptId: number
  ) => void;
}) => {
  const { loading, customers, runs, navigateToFailedSteps } = props;
  const [selectedRun, setSelectedRun] = useState<string | null>(null);
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
        backgroundColor: "atomic.highlightUnderlay",
      }}
    >
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 4,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <PageTitle>Latest runs</PageTitle>

        {loading && <LinearProgress />}

        {!loading && (
          <Sheet
            sx={{
              "--TableCxell-height": "40px",
              "--TableHeader-height": "64px",
              minHeight: "10vh",
              maxHeight: "70vh",
              overflow: "auto",
              background: (
                theme
              ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
                    linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
                    radial-gradient(
                      farthest-side at 50% 0,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                    ),
                    radial-gradient(
                        farthest-side at 50% 100%,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                      )
                      0 100%`,
              backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "local, local, scroll, scroll",
              backgroundPosition:
                "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
              backgroundColor: (theme) =>
                `${theme.palette.atomic.highlightBackground}`,
            }}
          >
            <Table
              aria-labelledby="Runs"
              stickyHeader
              hoverRow={true}
              // stripe="odd"
              borderAxis="xBetween"
              sx={{
                maxWidth: "1200px",
                "--Table-headerUnderlineThickness": "1px",
                "--TableCell-paddingY": "4px",
                "--TableCell-paddingX": "8px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "80px",
                    }}
                  >
                    Customer
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "180px",
                    }}
                  >
                    Workflow
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "100px",
                    }}
                  >
                    Start
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "80px",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "150px",
                    }}
                  >
                    Initiated By
                  </th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer) => {
                  return [
                    "export_to_csv",
                    "export_to_csv_legacy_db",
                    "raw_netsuite_to_inventory_on_hand",
                    "raw_inputs_to_inventory_on_hand",
                    "the_engine_scripts",
                    "the_engine_scripts_legacy_dbs",
                  ].map((workflowId) => {
                    const run = runs.find(
                      (r) =>
                        r.runSettings.workflowId === workflowId &&
                        r.customerId === customer.id
                    );
                    return (
                      run && (
                        <tr
                          key={runToId(run)}
                          onClick={() => {
                            navigateToFailedSteps(
                              run.customerId!,
                              run.runSettings.inputId,
                              run.runSettings.workflowId,
                              run.runSettings.variablesId,
                              run.runSettings.runAttemptId
                            );
                            setSelectedRun(runToId(run));
                          }}
                          aria-checked={runToId(run) === selectedRun}
                          role="checkbox"
                          style={
                            runToId(run) === selectedRun
                              ? ({
                                  // "--TableCell-dataBackground":
                                  //   "atomic.lightShadow",
                                  // "--TableCell-headBackground":
                                  //   "atomic.lightShadow",
                                  "--TableCell-dataBackground":
                                    "rgba(0,0,0,0.2)",
                                  "--TableCell-headBackground":
                                    "rgba(0,0,0,0.2)",
                                } as React.CSSProperties)
                              : {}
                          }
                        >
                          <td>{customer.name}</td>
                          <td>{workflowId}</td>
                          <td>
                            {dayjs
                              .utc(run.createdAt)
                              .local()
                              .format("MM/DD/YYYY hh:mm:ss a")}
                          </td>
                          <td>
                            <Chip size="sm">
                              {runStatusToEmoji(run.status)}{" "}
                              {runStatusToText(run.status)}
                            </Chip>
                          </td>
                          <td>{run.initiatedBy}</td>
                        </tr>
                      )
                    );
                  });
                })}
              </tbody>
            </Table>
          </Sheet>
        )}
      </Sheet>
    </PageFrame>
  );
};
