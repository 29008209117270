import { useContext, useEffect, useMemo } from "react";
import { StatusFilter } from "./status_filter";
import { useNavigate } from "react-router-dom";
import { PurchaseOrderStatus } from "../../services/api/__generated__/backend_gateway-types";
import { PoBoxComponent } from "./component";
import { PoBoxRow } from "./row_type";
import { PosContext } from "../../contexts/pos/context";

export const PoBoxContainer = (props: {
  includeStatus: StatusFilter[];
  snoozed: boolean;
}) => {
  const { includeStatus, snoozed } = props;
  const {
    hasPoInbox,
    pos: poPos,
    // refetchPos,
    setPoBoxSettings,
    loading: posLoading,
  } = useContext(PosContext);

  useEffect(() => {
    setPoBoxSettings({
      includeStatus,
      includeSnoozed: snoozed,
    });
  }, [includeStatus, snoozed, setPoBoxSettings]);

  const navigate = useNavigate();
  const navigateToPoDetails = (poId: string) => {
    navigate(`/po/${poId}`, { replace: true });
  };

  const pos: PoBoxRow[] = useMemo(() => {
    return poPos.map((p) => ({
      id: p.id,
      po_sim_id: p.po.po_sim_id,
      location: p.po.location,
      vendor: p.po.vendor,
      need_by_date:
        (p.pe?.need_by_date && new Date(p.pe?.need_by_date)) || null,
      total_cogs: p.po?.total_cogs || null,
      updated_at:
        ((p.po.updated_at || p.po.created_at) &&
          new Date(p.po.updated_at || p.po.created_at!)) ||
        null,
      snooze_end_date:
        (p.po.snooze_end_date &&
          new Date(p.po.snooze_end_date) > new Date() &&
          new Date(p.po.snooze_end_date)) ||
        null,
      status: p.po.status || PurchaseOrderStatus.AtomicSuggestion,
    }));
  }, [poPos]);

  if (!hasPoInbox) {
    return null;
  }

  return (
    <PoBoxComponent
      loading={posLoading}
      navigateToPoDetails={navigateToPoDetails}
      pos={pos}
    />
  );
};
