import { useContext, useEffect, useState } from "react";
import { RunComparisonComponent } from "./component";
import { DemandDiffControlContainer } from "./control/control_container";
import { DiffParams } from "./diff_params";
import { ApiContext } from "../../contexts/api/context";
import {
  useGetMyDemandMonthlyUrlLazyQuery,
  useGetMyDemandWeeklyUrlLazyQuery,
  useGetMySkuHierarchyUrlLazyQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { SkuTagsFromCsv } from "./sku_hierarchy_from_csv";
import { DemandFromCsv } from "./demand_from_csv";
import { PeriodType } from "./period_type";
import { DemandDiffTableContainer } from "./table/table_container";

export const RunComparisonContainer = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
}) => {
  const { diffParams, updateDiffParams } = props;
  const { apiService } = useContext(ApiContext);

  const [skuTagsLoading, setSkuTagsLoading] = useState<boolean>(false);
  const [skuTags] = useState<SkuTagsFromCsv>(
    new SkuTagsFromCsv(setSkuTagsLoading)
  );
  const [
    getMySkuHierarchyUrl,
    { loading: skuTagsURLLoading, error: skuTagsError },
  ] = useGetMySkuHierarchyUrlLazyQuery({
    client: apiService.getClient(),
  });

  const [monthlyDemand0Loading, setMonthlyDemand0Loading] =
    useState<boolean>(false);
  const [monthlyDemand0, setMonthlyDemand0] = useState<DemandFromCsv>(
    new DemandFromCsv(setMonthlyDemand0Loading, `Run 1`)
  );
  const [
    getMyDemandMonthly0Url,
    { loading: demandMonthly0URLLoading, error: demandMonthly0Error },
  ] = useGetMyDemandMonthlyUrlLazyQuery({
    client: apiService.getClient(),
  });
  const [monthlyDemand1Loading, setMonthlyDemand1Loading] =
    useState<boolean>(false);
  const [monthlyDemand1] = useState<DemandFromCsv>(
    new DemandFromCsv(setMonthlyDemand1Loading, `Run 2`)
  );
  const [
    getMyDemandMonthly1Url,
    { loading: demandMonthly1URLLoading, error: demandMonthly1Error },
  ] = useGetMyDemandMonthlyUrlLazyQuery({
    client: apiService.getClient(),
  });

  const [weeklyDemand0Loading, setWeeklyDemand0Loading] =
    useState<boolean>(false);
  const [weeklyDemand0, setWeeklyDemand0] = useState<DemandFromCsv>(
    new DemandFromCsv(setWeeklyDemand0Loading, `Run 1`)
  );
  const [
    getMyDemandWeekly0Url,
    { loading: demandWeekly0URLLoading, error: demandWeekly0Error },
  ] = useGetMyDemandWeeklyUrlLazyQuery({
    client: apiService.getClient(),
  });
  const [weeklyDemand1Loading, setWeeklyDemand1Loading] =
    useState<boolean>(false);
  const [weeklyDemand1] = useState<DemandFromCsv>(
    new DemandFromCsv(setWeeklyDemand1Loading, `Run 2`)
  );
  const [
    getMyDemandWeekly1Url,
    { loading: demandWeekly1URLLoading, error: demandWeekly1Error },
  ] = useGetMyDemandWeeklyUrlLazyQuery({
    client: apiService.getClient(),
  });

  const { runId0, runId1 } = diffParams;

  useEffect(() => {
    if (runId0 !== null) {
      getMySkuHierarchyUrl({
        variables: { engineRunId: parseInt(runId0) },
        onCompleted(data) {
          const url = data?.getMySkuHierarchyUrl;
          if (url) {
            skuTags.fetch(url);
          }
        },
      });
    }
  }, [runId0, getMySkuHierarchyUrl, skuTags]);

  useEffect(() => {
    if (runId0 === null) {
      const newMonthlyDemand0 = new DemandFromCsv(
        setMonthlyDemand0Loading,
        `Run 1`
      );
      setMonthlyDemand0(() => newMonthlyDemand0);
      setWeeklyDemand0(
        () => new DemandFromCsv(setWeeklyDemand0Loading, `Run 1`)
      );
    }
  }, [runId0, setMonthlyDemand0, setWeeklyDemand0]);

  useEffect(() => {
    if (runId1 === null) {
      monthlyDemand1.resetDemand();
      weeklyDemand1.resetDemand();
    }
  }, [runId1, monthlyDemand1, weeklyDemand1]);

  useEffect(() => {
    if (runId0 !== null) {
      monthlyDemand0.setRunId(`Run 1 (${runId0 || ""})`);
      getMyDemandMonthly0Url({
        variables: { engineRunId: parseInt(runId0) },
        onCompleted: async (data) => {
          const url = data?.getMyDemandMonthlyUrl;
          if (url) {
            await monthlyDemand0.fetch(url);
          }
        },
      });
    }
  }, [runId0, getMyDemandMonthly0Url, monthlyDemand0]);

  useEffect(() => {
    if (runId1 !== null) {
      monthlyDemand1.setRunId(`Run 2 (${runId1 || ""})`);
      getMyDemandMonthly1Url({
        variables: { engineRunId: parseInt(runId1) },
        onCompleted: async (data) => {
          const url = data?.getMyDemandMonthlyUrl;
          if (url) {
            await monthlyDemand1.fetch(url);
          }
        },
      });
    }
  }, [runId1, getMyDemandMonthly1Url, monthlyDemand1]);

  useEffect(() => {
    if (runId0 !== null) {
      weeklyDemand0.setRunId(`Run 1 (${runId0 || ""})`);
      getMyDemandWeekly0Url({
        variables: { engineRunId: parseInt(runId0) },
        onCompleted: async (data) => {
          const url = data?.getMyDemandWeeklyUrl;
          if (url) {
            await weeklyDemand0.fetch(url);
          }
        },
      });
    }
  }, [runId0, getMyDemandWeekly0Url, weeklyDemand0]);

  useEffect(() => {
    if (runId1 !== null) {
      weeklyDemand1.setRunId(`Run 2 (${runId1 || ""})`);
      getMyDemandWeekly1Url({
        variables: { engineRunId: parseInt(runId1) },
        onCompleted: async (data) => {
          const url = data?.getMyDemandWeeklyUrl;
          if (url) {
            await weeklyDemand1.fetch(url);
          }
        },
      });
    }
  }, [runId1, getMyDemandWeekly1Url, weeklyDemand1]);

  useEffect(() => {
    if (!skuTagsLoading && !monthlyDemand0Loading) {
      monthlyDemand0.addSkuTags(skuTags.toDict());
    }
  }, [skuTagsLoading, skuTags, monthlyDemand0Loading, monthlyDemand0]);

  useEffect(() => {
    if (!skuTagsLoading && !weeklyDemand0Loading) {
      weeklyDemand0.addSkuTags(skuTags.toDict());
    }
  }, [skuTagsLoading, skuTags, weeklyDemand0Loading, weeklyDemand0]);

  useEffect(() => {
    if (!skuTagsLoading && !monthlyDemand1Loading) {
      monthlyDemand1.addSkuTags(skuTags.toDict());
    }
  }, [skuTagsLoading, skuTags, monthlyDemand1Loading, monthlyDemand1]);

  useEffect(() => {
    if (!skuTagsLoading && !weeklyDemand1Loading) {
      weeklyDemand1.addSkuTags(skuTags.toDict());
    }
  }, [skuTagsLoading, skuTags, weeklyDemand1Loading, weeklyDemand1]);

  if (skuTagsError) {
    console.error(
      "RunComparisonContainer: useGetMySkuHierarchyUrlLazyQuery fetch error",
      skuTagsError
    );
  }

  if (demandMonthly0Error) {
    console.error(
      "RunComparisonContainer: useGetMyDemandMonthlyUrlLazyQuery fetch error",
      demandMonthly0Error
    );
  }

  if (demandMonthly1Error) {
    console.error(
      "RunComparisonContainer: useGetMyDemandMonthlyUrlLazyQuery fetch error",
      demandMonthly1Error
    );
  }

  if (demandWeekly0Error) {
    console.error(
      "RunComparisonContainer: useGetMyDemandWeeklyUrlLazyQuery fetch error",
      demandWeekly0Error
    );
  }

  if (demandWeekly1Error) {
    console.error(
      "RunComparisonContainer: useGetMyDemandWeeklyUrlLazyQuery fetch error",
      demandWeekly1Error
    );
  }

  const demand0 =
    diffParams.aggregatePeriodType === PeriodType.MONTH
      ? monthlyDemand0
      : weeklyDemand0;

  const demand0Loading =
    diffParams.aggregatePeriodType === PeriodType.MONTH
      ? monthlyDemand0Loading
      : weeklyDemand0Loading;

  const demand1 =
    diffParams.aggregatePeriodType === PeriodType.MONTH
      ? monthlyDemand1
      : weeklyDemand1;

  const demand1Loading =
    diffParams.aggregatePeriodType === PeriodType.MONTH
      ? monthlyDemand1Loading
      : weeklyDemand1Loading;

  const [pivotObject, setPivotObject] = useState<any>(null);

  return (
    <RunComparisonComponent
      control={
        <DemandDiffControlContainer
          diffParams={diffParams}
          updateDiffParams={updateDiffParams}
          demand0={demand0}
          demand1={demand1}
          pivotObject={pivotObject}
        />
      }
      table={
        <DemandDiffTableContainer
          diffParams={diffParams}
          demand0={demand0}
          demand0Loading={demand0Loading}
          demand1={demand1}
          demand1Loading={demand1Loading}
          skuTagsLoading={skuTagsLoading}
          setPivotObject={setPivotObject}
        />
      }
      loading={
        skuTagsURLLoading ||
        skuTagsLoading ||
        demandMonthly0URLLoading ||
        demandMonthly1URLLoading ||
        demandWeekly0URLLoading ||
        demandWeekly1URLLoading
      }
    />
  );
};
