import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../../contexts/api/context";
import { DrawerContext } from "../../../contexts/drawer/context";
import { WorkflowsContext } from "../../../contexts/workflows/context";
import {
  EnginePreset,
  useGetMyCustomerQuery,
  useGetMyEnginePresetsQuery,
  useGetMyLockedDemandOutputRunIdsQuery,
} from "../../../services/api/__generated__/backend_gateway-types";
import { RunEnginePresetComponent } from "./component";

export const RunEnginePresetContainer = (props: { presetId: string }) => {
  const { presetId } = props;
  const { apiService } = useContext(ApiContext);
  const {
    isDisabled,
    isLoading,
    isTriggerInProgress,
    lastEngineRun,
    lastExportRun,
    theEngineScriptsRuns,
    startTheEngineScriptsWithAPreset,
  } = useContext(WorkflowsContext);
  const { closeDrawer } = useContext(DrawerContext);
  const navigate = useNavigate();
  const { data: customerData, loading: customerLoading } =
    useGetMyCustomerQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          console.error(error);
          // addSystemMessage({
          //   level: SysMessageLevel.DANGER,
          //   message: error.message!,
          // });
        }
      },
    });
  const { data: presetsData, loading: presetsLoading } =
    useGetMyEnginePresetsQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          console.error(error);
          // addSystemMessage({
          //   level: SysMessageLevel.DANGER,
          //   message: error.message!,
          // });
        }
      },
    });

  const { data: runIdsData, loading: runIdsLoading } =
    useGetMyLockedDemandOutputRunIdsQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          console.error(error);
          // addSystemMessage({
          //   level: SysMessageLevel.DANGER,
          //   message: error.message!,
          // });
        }
      },
    });

  const navigateToEnginePresets = () => {
    closeDrawer();
    navigate("/engine_presets", { replace: true });
  };

  const preset: EnginePreset | null =
    presetsData?.getMyEnginePresets?.find((p) => p.id === parseInt(presetId)) ||
    null;

  const runPreset = async (loadDemandOfRunId: number | null) => {
    if (preset === null) {
      return;
    }
    startTheEngineScriptsWithAPreset(
      loadDemandOfRunId !== undefined ? loadDemandOfRunId : null,
      preset.id
    );
  };

  return (
    <RunEnginePresetComponent
      preset={preset}
      loading={presetsLoading || customerLoading || runIdsLoading || isLoading}
      navigateBack={navigateToEnginePresets}
      runPreset={runPreset}
      runningInProgress={
        isTriggerInProgress ||
        lastEngineRun?.status === "RUN_IN_PROGRESS" ||
        lastExportRun?.status === "RUN_IN_PROGRESS"
      }
      runDisabled={isDisabled}
      customer={customerData?.getMyCustomer || null}
      theEngineScriptsRuns={theEngineScriptsRuns}
      runIdsWithOutputs={runIdsData?.getMyLockedDemandOutputRunIds || []}
    />
  );
};
