import { ReactNode, useContext, useState } from "react";
import { PoEditContext } from "./context";
import { ApiContext } from "../../../contexts/api/context";
import { useGetMyCustomerQuery } from "../../../services/api/__generated__/backend_gateway-types";
import { PoEditType } from "./type";
import { SysMessagesContext } from "../../../contexts/sys_messages/context";
import { SysMessageLevel } from "../../../contexts/sys_messages/type";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { PoSkuRow } from "../sku_list/row_type";
import { PosContext } from "../../../contexts/pos/context";

const { Provider } = PoEditContext;

export const PoEditsProvider = (props: {
  children: ReactNode;
  purchaseOrderId: string | null;
}) => {
  const { purchaseOrderId } = props;
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);
  const { savePuchaseOrder: poSavePuchaseOrder, saving: poSaving } =
    useContext(PosContext);

  const { data: customerData, loading: customerLoading } =
    useGetMyCustomerQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const hasPoInbox = customerData?.getMyCustomer?.features?.PoInbox || false;

  const [invoiceNotes, setInvoiceNotes] = useState<string>("");
  const [shipByDate, setShipByDate] = useState<string>(
    new Date().toISOString()
  );
  const [needByDate, setNeedByDate] = useState<string>(
    new Date().toISOString()
  );

  const savePuchaseOrder = async () => {
    if (!purchaseOrderId) {
      return;
    }
    const rowIds = gridApiRef.current.getAllRowIds(); // Get all row IDs
    const allRows: PoSkuRow[] = rowIds.map(
      (id) => gridApiRef.current.getRow(id)!
    );
    await poSavePuchaseOrder(
      purchaseOrderId,
      needByDate,
      shipByDate,
      invoiceNotes,
      allRows.map((row) => ({
        sku: row.sku,
        quantity: `${row.quantity}`,
        unit: row.unit || "",
        unit_cost: row.unit_cost ? `${row.unit_cost}` : null,
      }))
    );
  };

  const gridApiRef = useGridApiRef();

  const value: PoEditType = {
    loading: customerLoading || poSaving,
    purchaseOrderId,
    hasPoInbox,
    gridApiRef,
    invoiceNotes,
    setInvoiceNotes,
    shipByDate,
    setShipByDate,
    needByDate,
    setNeedByDate,
    savePuchaseOrder,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
