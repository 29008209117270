import { gql } from "@apollo/client";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import {
  GetMyLockedEngineRunIdDocument,
  OrchestratorRun,
  useGetMyCustomerQuery,
  useGetMyLockedEngineRunIdQuery,
  useGetMyOrchestratorRunsQuery,
  useSetMyLockedEngineRunIdMutation,
  useTriggerMyorchestratorStartTheEngineScriptsMutation,
} from "../../services/api/__generated__/backend_gateway-types";
import { ApiContext } from "../api/context";
import { SysMessagesContext } from "../sys_messages/context";
import { SysMessageLevel } from "../sys_messages/type";
import { WorkflowsContext } from "./context";
import { WorkflowsType } from "./type";

const { Provider } = WorkflowsContext;

export const WorkflowsProvider = (props: { children: ReactNode }) => {
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);

  const { data: customerData, loading: customerLoading } =
    useGetMyCustomerQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          console.error(error.message);
          // addSystemMessage({
          //   level: SysMessageLevel.DANGER,
          //   message: error.message!,
          // });
        }
      },
    });

  const canRunTheEngine =
    customerData?.getMyCustomer?.features?.EngineRuns || false;
  const usesStepsForRunningTheEngine =
    customerData?.getMyCustomer?.features?.StepsUsedForEngineRuns || false;
  const hasEngineRunHistory =
    customerData?.getMyCustomer?.features?.EngineRunHistory || false;
  const hasLockedRuns =
    customerData?.getMyCustomer?.features?.EngineLoadLockedDemandForecast ||
    false;
  const {
    data: getLockedRunIdData,
    loading: getLockedRunIdLoading,
    refetch: getLockedRunIdDataRefetch,
  } = useGetMyLockedEngineRunIdQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        console.error(error.message);
        // addSystemMessage({
        //   level: SysMessageLevel.DANGER,
        //   message: error.message!,
        // });
      }
    },
  });

  const {
    data: runsData,
    loading: runsLoading,
    refetch: runsRefetch,
  } = useGetMyOrchestratorRunsQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        console.error(error.message);
        // addSystemMessage({
        //   level: SysMessageLevel.DANGER,
        //   message: error.message!,
        // });
      }
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      runsRefetch();
    }, 8000);
    return () => clearInterval(intervalId);
  }, [runsRefetch]);

  const runsOrdered: OrchestratorRun[] = useMemo(() => {
    const runs: OrchestratorRun[] = [
      ...(runsData?.getMyOrchestratorRuns || []),
    ];
    runs.sort((a, b) => {
      const aDate = new Date(a.createdAt);
      const bDate = new Date(b.createdAt);
      if (aDate < bDate) {
        return 1;
      }
      return -1;
    });
    return runs;
  }, [runsData?.getMyOrchestratorRuns]);

  const lastEngineRun = useMemo(
    () =>
      (hasEngineRunHistory &&
        runsOrdered.find((r) =>
          ["the_engine_scripts", "the_engine_scripts_legacy_dbs"].includes(
            r.runSettings.workflowId
          )
        )) ||
      null,
    [hasEngineRunHistory, runsOrdered]
  );
  const lastStartedAt =
    hasEngineRunHistory && lastEngineRun ? lastEngineRun.createdAt : null;
  const lastExportRun = useMemo(
    () =>
      (hasEngineRunHistory &&
        runsOrdered.find((r) =>
          ["export_to_csv", "export_to_csv_legacy_db"].includes(
            r.runSettings.workflowId
          )
        )) ||
      null,
    [hasEngineRunHistory, runsOrdered]
  );
  const lastExportedAt =
    hasEngineRunHistory && lastExportRun ? lastExportRun.createdAt : null;

  const [triggerEngineInProgress, setTriggerEngineInProgress] =
    useState<boolean>(false);
  const [theEngineScriptsTrigger, { loading: theEngineScriptsTriggerLoading }] =
    useTriggerMyorchestratorStartTheEngineScriptsMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
        setTriggerEngineInProgress(false);
      },
    });

  const startTheEngineScriptsWithDefaults = async (): Promise<void> => {
    if (!canRunTheEngine) {
      return;
    }
    setTriggerEngineInProgress(true);
    await theEngineScriptsTrigger({
      variables: {
        loadDemandOfRunId: null,
        variablesId: null,
      },
      optimisticResponse: () => ({
        triggerMyorchestratorStartTheEngineScripts: "OK",
      }),
      update(cache) {
        const createdAt = new Date().toISOString();
        cache.writeFragment({
          id: `OrchestratorRun:NEW_export_to_csv`,
          fragment: gql`
            fragment OrchestratorRunDetail on OrchestratorRun {
              id
              runSettings
              status
              createdAt
              updatedAt
            }
          `,
          data: {
            id: "NEW_export_to_csv",
            runSettings: {
              inputId: createdAt,
              workflowId: "export_to_csv",
              variablesId: "default",
              runAttemptId: 0,
            },
            status: "RUN_IN_PROGRESS",
            createdAt,
            updatedAt: createdAt,
          },
        });
      },
      onCompleted: async () => {
        setTimeout(async () => {
          await runsRefetch();
          setTriggerEngineInProgress(false);
        }, 10000);
      },
    });
  };

  const startTheEngineScriptsWithAPreset = async (
    loadDemandOfRunId: number | null,
    presetId: number | null
  ): Promise<void> => {
    if (!canRunTheEngine) {
      return;
    }
    setTriggerEngineInProgress(true);
    await theEngineScriptsTrigger({
      variables: {
        loadDemandOfRunId:
          loadDemandOfRunId !== null ? `${loadDemandOfRunId}` : null,
        variablesId: presetId !== null ? `${presetId}` : null,
      },
      optimisticResponse: () => ({
        triggerMyorchestratorStartTheEngineScripts: "OK",
      }),
      update(cache) {
        const createdAt = new Date().toISOString();
        // cache.writeFragment({
        //   id: `OrchestratorRun:NEW_the_engine_scripts`,
        //   fragment: gql`
        //     fragment OrchestratorRunDetail on OrchestratorRun {
        //       id
        //       runSettings
        //       status
        //       createdAt
        //       updatedAt
        //     }
        //   `,
        //   data: {
        //     id: "NEW_the_engine_scripts",
        //     runSettings: {
        //       inputId: createdAt,
        //       workflowId: "the_engine_scripts",
        //       variablesId: `${presetId}`,
        //       runAttemptId: 0,
        //     },
        //     status: "RUN_IN_PROGRESS",
        //     createdAt,
        //     updatedAt: createdAt,
        //   },
        // });
        cache.writeFragment({
          id: `OrchestratorRun:NEW_export_to_csv`,
          fragment: gql`
            fragment OrchestratorRunDetail on OrchestratorRun {
              id
              runSettings
              status
              createdAt
              updatedAt
            }
          `,
          data: {
            id: "NEW_export_to_csv",
            runSettings: {
              inputId: createdAt,
              workflowId: "export_to_csv",
              variablesId: `${presetId}`,
              runAttemptId: 0,
            },
            status: "RUN_IN_PROGRESS",
            createdAt,
            updatedAt: createdAt,
          },
        });
      },
      onCompleted: async () => {
        setTimeout(async () => {
          await runsRefetch();
          setTriggerEngineInProgress(false);
        }, 10000);
      },
    });
  };

  const lockedRunId: number | null =
    getLockedRunIdData?.getMyLockedEngineRunId || null;

  const [setMyLockedRunIdInProgress, setSetMyLockedRunIdInProgress] =
    useState<boolean>(false);
  const [setMyLockedRunId, { loading: setLockedRunIdLoading }] =
    useSetMyLockedEngineRunIdMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
        setSetMyLockedRunIdInProgress(false);
      },
    });

  const setLockedRun = (runId: number) => {
    const run = runsOrdered.find((r) => r.oldRunId === runId);
    if (!run || run.status !== "RUN_COMPLETED") {
      addSystemMessage({
        level: SysMessageLevel.NEUTRAL,
        message: "Only successfully completed runs can be locked",
      });
      return;
    }
    setMyLockedRunId({
      variables: {
        engineRunId: runId,
      },
      update(cache) {
        cache.writeQuery({
          query: GetMyLockedEngineRunIdDocument,
          data: {
            runId,
          },
        });
      },
      onCompleted() {
        getLockedRunIdDataRefetch();
      },
    });
  };

  const value: WorkflowsType = {
    isDisabled: !(canRunTheEngine && usesStepsForRunningTheEngine),
    isTriggerInProgress:
      theEngineScriptsTriggerLoading || triggerEngineInProgress,
    isLoading:
      theEngineScriptsTriggerLoading ||
      triggerEngineInProgress ||
      setLockedRunIdLoading ||
      setMyLockedRunIdInProgress ||
      customerLoading ||
      getLockedRunIdLoading ||
      runsLoading,
    lastEngineRun,
    lastExportRun,
    lastStartedAt,
    lastExportedAt,
    startExportToCsv: async () => {},
    startTheEngineScriptsWithDefaults,
    startTheEngineScriptsWithAPreset,
    allRuns: runsOrdered,
    theEngineScriptsRuns: [],
    exportToCsvRuns: [],
    reset: () => {},
    lockedRun: lockedRunId,
    setLockedRun,
    hasLockedRuns,
    hasEngineRunHistory,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
