import * as React from "react";
import { Dayjs } from "dayjs";
import Input, { InputProps } from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import {
  unstable_useDateField as useDateField,
  UseDateFieldProps,
} from "@mui/x-date-pickers/DateField";
import {
  DateFieldSlotsComponent,
  DateFieldSlotsComponentsProps,
} from "@mui/x-date-pickers/DateField/DateField.types";
import { useClearableField } from "@mui/x-date-pickers/hooks";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers/models";

interface JoyFieldProps extends InputProps {
  label?: React.ReactNode;
  InputProps?: {
    ref?: React.Ref<any>;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
  };
  formControlSx?: InputProps["sx"];
}

type JoyFieldComponent = ((
  props: JoyFieldProps & React.RefAttributes<HTMLDivElement>
) => React.JSX.Element) & { propTypes?: any };

const JoyField = React.forwardRef(
  (props: JoyFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      disabled,
      id,
      label,
      InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
      formControlSx,
      endDecorator,
      startDecorator,
      slotProps,
      ...other
    } = props;

    return (
      <FormControl
        disabled={disabled}
        id={id}
        sx={[
          ...(Array.isArray(formControlSx) ? formControlSx : [formControlSx]),
        ]}
        ref={ref}
      >
        <FormLabel>{label}</FormLabel>
        <Input
          ref={ref}
          disabled={disabled}
          startDecorator={
            <React.Fragment>
              {startAdornment}
              {startDecorator}
            </React.Fragment>
          }
          endDecorator={
            <React.Fragment>
              {endAdornment}
              {endDecorator}
            </React.Fragment>
          }
          slotProps={{
            ...slotProps,
            root: { ...slotProps?.root, ref: containerRef },
          }}
          {...other}
          fullWidth // TODO: this should come from slotProps see below
        />
      </FormControl>
    );
  }
) as JoyFieldComponent;

interface JoyDateFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      DateValidationError
    > {}

const JoyDateField = React.forwardRef(
  (props: JoyDateFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      inputRef: externalInputRef,
      slots,
      slotProps,
      ...textFieldProps
    } = props;

    const {
      onClear,
      clearable,
      ref: inputRef,
      ...fieldProps
    } = useDateField<Dayjs, typeof textFieldProps>({
      props: textFieldProps,
      inputRef: externalInputRef,
    });

    /* If you don't need a clear button, you can skip the use of this hook */
    const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } =
      useClearableField<
        {},
        typeof textFieldProps.InputProps,
        DateFieldSlotsComponent,
        DateFieldSlotsComponentsProps<Dayjs>
      >({
        onClear,
        clearable,
        fieldProps,
        InputProps: fieldProps.InputProps,
        slots,
        slotProps,
      });

    return (
      <JoyField
        ref={ref}
        slotProps={{
          input: {
            ref: inputRef,
          },
        }}
        {...processedFieldProps}
        InputProps={ProcessedInputProps}
      />
    );
  }
);

const JoyDatePicker = React.forwardRef(
  (props: DatePickerProps<Dayjs>, ref: React.Ref<HTMLDivElement>) => {
    return (
      <DatePicker
        ref={ref}
        {...props}
        // @ts-ignore
        slots={{ field: JoyDateField, ...props.slots }}
        slotProps={{
          ...props.slotProps,
          field: {
            ...props.slotProps?.field,
            formControlSx: {
              flexDirection: "row",
            },
          } as any,
        }}
      />
    );
  }
);

export const JoyCompatibleDatePicker = (props: {
  value: Dayjs;
  disabled?: boolean;
  disablePast: boolean;
  minDate?: Dayjs;
  maxDate: Dayjs;
  onChange: (v: Dayjs | null) => void;
}) => {
  const { value, disabled, disablePast, minDate, maxDate, onChange } = props;
  return (
    <JoyDatePicker
      value={value}
      disabled={disabled || false}
      disablePast={disablePast}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      slotProps={{
        field: { clearable: true },
        textField: { fullWidth: true }, // TODO: this doesn't seem to work, overridden above
      }}
    />
  );
};
