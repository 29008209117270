import { Fragment } from "react";
import LinearProgress from "@mui/joy/LinearProgress";
import List from "@mui/joy/List";
import { PowerBi } from "../../services/api/__generated__/backend_gateway-types";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import { WorkspaceReportsContainer } from "./workspace_container";

export const ReportsComponent = (props: {
  powerBis: PowerBi[];
  loading: boolean;
}) => {
  const { powerBis, loading } = props;

  return (
    <>
      <PageFrame>
        {loading ? (
          <LinearProgress />
        ) : (
          powerBis.map((powerBi) => (
            <Fragment key={powerBi.id}>
              <PageTitle>{powerBi.name}</PageTitle>
              <List
                size="sm"
                sx={{
                  "--List-item-radius": "8px",
                  "--ListItemDecorator-size": "50px",
                  mt: 2,
                }}
              >
                <WorkspaceReportsContainer workspaceId={powerBi.workspaceId} />
              </List>
            </Fragment>
          ))
        )}
      </PageFrame>
    </>
  );
};
