import { Dispatch } from "react";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import {
  ExcelExport,
  GroupingBar,
  FieldList,
  IDataSet,
  Inject,
  //   PivotActionCompleteEventArgs,
  PivotViewComponent,
  VirtualScroll,
  IDataOptions,
} from "@syncfusion/ej2-react-pivotview";
import { DiffParams, SalesMeasure } from "../diff_params";
import { DemandWithSkuTags } from "../demand_from_csv";
import "./syncfusion.css";

export const DemandDiffTableComponent = (props: {
  diffParams: DiffParams;
  hasLicence: boolean;
  loading: boolean;
  dataLoading: boolean;
  demand: DemandWithSkuTags[];
  maxRevenue: number | null;
  maxQuantity: number | null;
  setPivotObject: Dispatch<any>;
  hasProductCategory: boolean;
  hasProductType: boolean;
  hasProductFamily: boolean;
  hasProductTitle: boolean;
  modeString: string;
}) => {
  const {
    diffParams,
    hasLicence,
    loading,
    dataLoading,
    demand,
    maxRevenue,
    maxQuantity,
    setPivotObject,
    hasProductCategory,
    hasProductType,
    hasProductFamily,
    hasProductTitle,
    modeString,
  } = props;

  const { salesMeasure } = diffParams;
  const dataSourceSettings: IDataOptions = {
    dataSource: demand as IDataSet[],
    columns: [
      { name: "order_placed_date", caption: "Date", showRemoveIcon: false },
    ],
    filters: [
      ...((hasProductCategory && [
        {
          name: "product_category",
          caption: "Product Category",
          showRemoveIcon: false,
        },
      ]) ||
        []),
      ...((hasProductType && [
        {
          name: "product_type",
          caption: "Product Type",
          showRemoveIcon: false,
        },
      ]) ||
        []),
      ...((hasProductFamily && [
        {
          name: "product_family",
          caption: "Product Family",
          showRemoveIcon: false,
        },
      ]) ||
        []),
      ...((hasProductTitle && [
        {
          name: "product_title",
          caption: "Product Title",
          showRemoveIcon: false,
        },
      ]) ||
        []),
      { name: "location", caption: "Location", showRemoveIcon: false },
      { name: "channel", caption: "Channel", showRemoveIcon: false },
      { name: "sku", caption: "SKU", showRemoveIcon: false },
    ],
    formatSettings: [
      {
        format: "###",
      },
    ],
    rows: [
      {
        name: "runId",
        caption: "Run",
        showRemoveIcon: false,
        showSortIcon: false,
        showFilterIcon: false,
        allowDragAndDrop: false,
      },
      //   {
      //     name: "product_category",
      //     caption: "Product Category",
      //     showRemoveIcon: true,
      //   },
      //   {
      //     name: "product_family",
      //     caption: "Product Family",
      //     showRemoveIcon: true,
      //   },
      //   { name: "product_type", caption: "Product Type", showRemoveIcon: true },
      //   { name: "location", caption: "Location", showRemoveIcon: true },
      { name: "channel", caption: "Channel", showRemoveIcon: true },
      { name: "sku", caption: "SKU", showRemoveIcon: true },
    ],
    values: [
      {
        name: salesMeasure === SalesMeasure.QUANTITY ? "quantity" : "revenue",
        caption:
          salesMeasure === SalesMeasure.QUANTITY ? "Quantity" : "Revenue",
        showRemoveIcon: false,
      },
    ],
    showSubTotals: false,
    showGrandTotals: false,
  };

  if (!hasLicence) {
    return null;
  }

  const pivotCss =
    modeString === "light"
      ? `
    #PivotView {
        opacity: ${loading || dataLoading ? "0.4" : "1.0"};
    }`
      : `
    #PivotView {
        opacity: ${loading || dataLoading ? "0.4" : "1.0"};
        background-color: #141414 !important;
    }
    .e-pivotview .e-grouping-bar .e-axis-value {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }

    .e-axis-value .e-axiscontainer {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }

    e-pivotview .e-group-values {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }

    .e-pivotview .e-grouping-bar .e-right-axis-fields {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }

    .e-pivotview .e-grouping-bar .e-axis-filter {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }

    .e-pivotview .e-grouping-bar .e-axis-column {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-pivotview {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-group-columns {
      background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-group-grouping-bar {
      background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-group-values {
      background-color: #141414 !important;
      color: #f7f7f7 !important;
      border-color: #333333 !important;
    }
    .e-axiscontainer {
      background-color: #141414 !important;
      color: #f7f7f7 !important;
      border-color: #333333 !important;
    }
    .e-axis-value {
      background-color: #141414 !important;
      color: #f7f7f7 !important;
      border-color: #333333 !important;
    }
    .e-right-axis-fields {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-axis-filter {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-axis-column {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-grid {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-gridheader {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-gridcontent {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-lib {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-headercontent {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-headercell {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-pivotview .e-headercell:not(.e-columnsheader) {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-pivotview .e-grouping-bar .e-left-axis-fields {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-pivotview .e-leftfreeze.e-freezeleftborder {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-content {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-table {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-rowcell {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-leftfreeze {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-freezeleftborder {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-rowsheader {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    .e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-remove {
      color: #f7f7f7 !important;
    }
    .e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pv-filter {
      color: #f7f7f7 !important;
    }
    .e-pivotview .e-group-row .e-group-rows .e-pvt-btn-div .e-pivot-button .e-sort {
      color: #f7f7f7 !important;
    }
    .e-pivotview .e-grid .e-icons {
      color: #f7f7f7 !important;
    }
    div[class^="e-"], div[class*=" e-"] {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    span[class^="e-"], div[class*=" e-"] {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    tr[class^="e-"], div[class*=" e-"] {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    th[class^="e-"], div[class*=" e-"] {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    td[class^="e-"], div[class*=" e-"] {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    
    // Value Cells
    .e-pivotview .e-summary:not(.e-gtot) {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }
    // Columns Headers
    .e-pivotview .e-headercell {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }

    // Rows Headers
    .e-pivotview .e-rowsheader {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }

    // Summary Cells
    .e-pivotview .e-gtot  {
        background-color: #141414 !important;
        color: #f7f7f7 !important;
        border-color: #333333 !important;
    }

    .e-field-list-container {
      background-color: #141414 !important;
    }
    .e-pivotfieldlist-container {
      background-color: #141414 !important;
    }
    .e-pivotfieldlist-container .e-field-list-container {
      background-color: #141414 !important;
    }

    .e-popup .e-popup-open {
      background-color: #141414 !important;
    }
    `;
  return (
    <Sheet
      variant="outlined"
      color="neutral"
      sx={{
        p: 1,
        m: 2,
        mt: 0,
        overflow: "auto",
        minWidth: "0",
        maxWidht: "100%",
        borderRadius: "6px",
      }}
    >
      <LinearProgress
        sx={{ display: loading || dataLoading ? "inherit" : "none" }}
      />
      <style>{pivotCss}</style>
      <PivotViewComponent
        id="PivotView"
        ref={(d) => setPivotObject(d)}
        enableVirtualization={true}
        dataSourceSettings={dataSourceSettings}
        height="600px"
        showGroupingBar={true}
        showFieldList={true}
        gridSettings={{ rowHeight: 25, allowResizing: false }}
        enableValueSorting={true}
        allowExcelExport={true}
        aggregateTypes={["Sum"]}
        //   actionComplete={actionComplete}
        //   enginePopulated={onEnginePopulated}
        //   fieldDrop={fieldDrop}
        cellTemplate={(args: any) => {
          const value = parseInt(args?.cellInfo?.value);
          if (
            diffParams.heatMap &&
            args?.cellInfo &&
            args?.cellInfo?.axis === "value" &&
            args?.cellInfo?.rowHeaders.indexOf("Run Delta (1 - 2)") > -1
          ) {
            if (
              (args?.cellInfo?.actualText === "quantity" ||
                args?.cellInfo?.actualText === "revenue") &&
              !isNaN(value) &&
              maxQuantity !== null &&
              maxRevenue !== null &&
              args?.targetCell
            ) {
              const diffRatio = Math.abs(
                args?.cellInfo?.actualText === "quantity"
                  ? value / maxQuantity
                  : value / maxRevenue
              );
              const diffBad = value > 0;
              // args?.cellInfo?.actualText === "quantity"
              //   ? value > 0
              //   : value < 0;
              if (diffRatio < 0.0000000001) {
                args.targetCell.style = `background-color: ${
                  modeString === "light" ? "white" : "transparent"
                }`;
              } else if (diffRatio < 0.1) {
                args.targetCell.style = `background-color: ${
                  // diffBad ? colors.green200 : colors.red200
                  diffBad
                    ? modeString === "light"
                      ? "#F0FCD7"
                      : "rgba(187, 247, 57, 0.1) !important"
                    : modeString === "light"
                    ? "#FCDCD7"
                    : "rgba(247, 85, 57, 0.1) !important"
                }`;
              } else if (diffRatio < 0.2) {
                args.targetCell.style = `background-color: ${
                  // diffBad ? colors.green400 : colors.red400
                  // diffBad ? "atomic.green2" : "atomic.red2"
                  diffBad
                    ? modeString === "light"
                      ? "#E4FCAE"
                      : "rgba(187, 247, 57, 0.2) !important"
                    : modeString === "light"
                    ? "#FCBAAE"
                    : "rgba(247, 85, 57, 0.2) !important"
                }`;
              } else if (diffRatio < 0.3) {
                args.targetCell.style = `background-color: ${
                  // diffBad ? colors.green600 : colors.red600
                  // diffBad ? "atomic.green3" : "atomic.red3"
                  diffBad
                    ? modeString === "light"
                      ? "#D5FA87"
                      : "rgba(187, 247, 57, 0.33) !important"
                    : modeString === "light"
                    ? "#FA9887"
                    : "rgba(247, 85, 57, 0.33) !important"
                }`;
              } else if (diffRatio < 0.5) {
                args.targetCell.style = `background-color: ${
                  // diffBad ? colors.green800 : colors.red800
                  // diffBad ? "atomic.green4" : "atomic.red4"
                  diffBad
                    ? modeString === "light"
                      ? "#CAFA61"
                      : "rgba(187, 247, 57, 0.46) !important"
                    : modeString === "light"
                    ? "#FA7861"
                    : "rgba(247, 85, 57, 0.46) !important"
                }`;
              } else {
                args.targetCell.style = `background-color: ${
                  // diffBad ? colors.greenPrimary : colors.redPrimary
                  // diffBad ? "atomic.greenPrimary" : "danger.redPrimary"
                  diffBad
                    ? modeString === "light"
                      ? "#BBF739"
                      : "rgba(187, 247, 57, 0.6) !important"
                    : modeString === "light"
                    ? "#F75539"
                    : "rgba(247, 85, 57, 0.6) !important"
                }`;
              }
            }
          }
          if (
            args?.cellInfo &&
            args?.cellInfo?.axis === "value" &&
            !isNaN(value) &&
            args?.targetCell
          ) {
            if (args?.cellInfo?.actualText === "quantity") {
              args.targetCell.innerText = `${value.toLocaleString()}`;
            } else if (args?.cellInfo?.actualText === "revenue") {
              args.targetCell.innerText = `$ ${value.toLocaleString()}`;
            }
          }
        }}
      >
        <Inject
          services={[GroupingBar, ExcelExport, VirtualScroll, FieldList]}
        />
      </PivotViewComponent>
    </Sheet>
  );
};
