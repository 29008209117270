import { useContext, useState } from "react";
import Uppy, { UppyFile } from "@uppy/core";
import AwsS3, { AwsS3UploadParameters } from "@uppy/aws-s3";
import { Dashboard } from "@uppy/react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import { LazyQueryExecFunction } from "@apollo/client";

import {
  Exact,
  GetMyGoodchopTransformedDataUrlsQuery,
  useCreateMyGoodchopPresignUploadUrlMutation,
  useStartMyGoodchopOdlTaskMutation,
  useGetMyGoodchopOdlTaskLazyQuery,
  Scalars,
  InputMaybe,
} from "../../../services/api/__generated__/backend_gateway-types";
import { ApiContext } from "../../../contexts/api/context";
import { TaskStatus } from "./enums";
import { AppearanceContext } from "../../../contexts/appearance/context";

type FileUploaderComponentProps = {
  getAllTransformedDataUrlsGoodchop: LazyQueryExecFunction<
    GetMyGoodchopTransformedDataUrlsQuery,
    Exact<{
      page?: InputMaybe<Scalars['Int']['input']>;
    }>
  >;
};

const uppyTheme = {
  light: "light",
  dark: "dark",
  system: "auto",
};

export const FileUploaderComponent = (props: FileUploaderComponentProps) => {
  const { getAllTransformedDataUrlsGoodchop } = props;

  const { modeString } = useContext(AppearanceContext);
  const { apiService } = useContext(ApiContext);

  const [
    createMyPresignUploadUrlGoodchopMutation,
    { error: createMyPresignUploadUrlGoodchopError },
  ] = useCreateMyGoodchopPresignUploadUrlMutation({
    client: apiService.getClient(),
  });

  const [startMyGoodchopOdlTaskMutation] = useStartMyGoodchopOdlTaskMutation({
    client: apiService.getClient(),
  });

  const [getMyGoodchopOdlTaskLazyQuery] = useGetMyGoodchopOdlTaskLazyQuery({
    client: apiService.getClient(),
  });

  const [errorMessage, setErrorMessage] = useState<String>("");

  const [uppy] = useState(() =>
    new Uppy({
      meta: { type: "avatar" },
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [".xlsx"],
      },
      onBeforeFileAdded: (currentFile, file) => {
        const date = new Date();
        let dateTimeString = date
          .toISOString()
          .replace("T", "-")
          .replace(/:/g, "-")
          .substring(0, 19);

        const fileName = `input-odl-${dateTimeString}.xlsx`;
        const modifiedFile = {
          ...currentFile,
          name: fileName,
        };
        return modifiedFile;
      },
    })
      .use(AwsS3, {
        async getUploadParameters(
          file: UppyFile
        ): Promise<AwsS3UploadParameters> {
          // Make a request to your server to get the signed URL
          let error = undefined;
          try {
            if (file?.name === null && file?.type === null) {
              throw Error(
                "Error: uploaded file missing `name` or `type` parameters"
              );
            }
            const response = await createMyPresignUploadUrlGoodchopMutation({
              variables: {
                fileName: file.name,
                contentType: file.type as string,
              },
            });
            const result = response.data?.createMyGoodchopPresignUploadUrl;

            if (!result || createMyPresignUploadUrlGoodchopError) {
              error = "Error: failed to create presigned urls in S3";
              throw Error(error);
            }
            return {
              method: "PUT",
              url: result,
              fields: {},
              headers: {
                "Content-Type": file.type || "",
              },
            };
          } catch (error) {
            uppy.emit("upload-error", String(error), String(error));
            throw Error(String(error));
          }
        },
      })
      .on("file-added", (file) => {
        uppy.setOptions({
          locale: {
            strings: {
              uploading: "Uploading",
            },
          },
        });
        setErrorMessage("");
      })
      .on("upload-progress", (file, progress) => {
        if (file) {
          const adjustedProgress =
            (progress.bytesUploaded / progress.bytesTotal) * 0.5;
          uppy.setFileState(file.id, {
            progress: {
              ...file.progress,
              bytesUploaded: adjustedProgress * file.size,
              percentage: adjustedProgress * 100,
              uploadComplete: false,
            },
          });
        }
      })
      .on("upload-success", async (file, response) => {
        try {
          if (file && file.name && file.type) {
            uppy.setFileState(file.id, {
              progress: { ...file.progress, percentage: 50 },
            });

            uppy.setOptions({
              locale: {
                strings: {
                  uploading: "Beginning data computations",
                },
              },
            });

            const startTaskResponse = await startMyGoodchopOdlTaskMutation({
              variables: {
                fileName: file.name,
              },
            });

            const startTaskResult =
              startTaskResponse.data?.startMyGoodchopOdlTask;
            const taskId = startTaskResult?.taskId;

            if (!startTaskResult || !taskId) {
              const error =
                "Error: failed to fetch task from backend or start data computation in backend";
              throw Error(error);
            }

            // poll get task end point
            const intervalId = setInterval(async () => {
              const taskStatusResponse = await getMyGoodchopOdlTaskLazyQuery({
                variables: { taskId: taskId },
              });
              const taskStatusResult = await taskStatusResponse.refetch({
                taskId: taskId,
              });
              const taskStatusData = taskStatusResult.data.getMyGoodchopOdlTask;
              let counter = 0; // wait 5 (5s) iterations of the interval time because their could be a delay in getting the task

              if (taskStatusData) {
                const taskStatus = taskStatusData.status;
                if (taskStatus === TaskStatus.SUCCESS) {
                  uppy.setFileState(file.id, {
                    progress: {
                      ...file.progress,
                      percentage: 100,
                      uploadComplete: true,
                    },
                  });
                  uppy.setOptions({
                    locale: undefined, // Setting it to an empty object
                  });
                  (await getAllTransformedDataUrlsGoodchop()).refetch();
                  clearInterval(intervalId);
                } else if (taskStatus === TaskStatus.RUNNING_VALIDATION) {
                  uppy.setOptions({
                    locale: {
                      strings: {
                        uploading: "Performing data validation",
                      },
                    },
                  });

                  uppy.setFileState(file.id, {
                    progress: { ...file.progress, percentage: 50 },
                  });
                } else if (taskStatus === TaskStatus.RUNNING_TRANSFORM) {
                  uppy.setOptions({
                    locale: {
                      strings: {
                        uploading: "Performing data transform",
                      },
                    },
                  });
                  uppy.setFileState(file.id, {
                    progress: { ...file.progress, percentage: 75 },
                  });
                } else if (taskStatus === TaskStatus.RUNNING_QUEUE) {
                  uppy.setOptions({
                    locale: {
                      strings: {
                        uploading: "Queuing data computations",
                      },
                    },
                  });
                  uppy.setFileState(file.id, {
                    progress: { ...file.progress, percentage: 75 },
                  });
                } else if (taskStatus === TaskStatus.RUNNING_TASK) {
                  uppy.setOptions({
                    locale: {
                      strings: {
                        uploading: "Starting data computations",
                      },
                    },
                  });
                  uppy.setFileState(file.id, {
                    progress: { ...file.progress, percentage: 75 },
                  });
                } else if (taskStatus === TaskStatus.STARTED) {
                  uppy.setOptions({
                    locale: {
                      strings: {
                        uploading: "Beginning data computations",
                      },
                    },
                  });
                } else if (taskStatus === TaskStatus.FAILED) {
                  const error = taskStatusData.error;
                  const formattedError = `Error: ${error}`;
                  uppy.emit("upload-error", formattedError, formattedError);
                  clearInterval(intervalId);
                }
              } else {
                // retry logic
                counter += 1;
                if (counter >= 5) {
                  const error = "Error: failed to fetch task from backend";
                  uppy.emit("upload-error", error, error);
                  clearInterval(intervalId);
                }
              }
            }, 1000);
          }
        } catch (error) {
          uppy.emit("upload-error", String(error), String(error));
        }
        console.log("upload successful...");
      })
      .on("upload-error", (file, error, response) => {
        uppy.setOptions({
          locale: undefined,
        });
        setErrorMessage(String(error));
      })
  );

  return (
    <>
      <Dashboard
        uppy={uppy}
        theme={
          (uppyTheme[modeString as keyof typeof uppyTheme] as
            | "light"
            | "dark"
            | "auto") || "auto"
        }
        plugins={[]}
        proudlyDisplayPoweredByUppy={false}
        height={"25vh"}
        width={"100%"}
        hideRetryButton={true}
      />
      {errorMessage && (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              p: 2,
              my: 2,
              overflow: "auto",
              minWidth: "0",
              maxWidth: "100%",
              borderRadius: "4px",
            }}
          >
            <b style={{ padding: "5px 0px" }}>{errorMessage}</b>
          </Sheet>
        </Box>
      )}
    </>
  );
};
