import { createContext } from "react";
import { WorkflowsType } from "./type";

const defaultValue: WorkflowsType = {
  isDisabled: true,
  isTriggerInProgress: false,
  isLoading: false,
  lastEngineRun: null,
  lastExportRun: null,
  lastStartedAt: null,
  lastExportedAt: null,
  startExportToCsv: async () => {},
  startTheEngineScriptsWithDefaults: async () => {},
  startTheEngineScriptsWithAPreset: async (
    loadDemandOfRunId: number | null,
    presetId: number
  ) => {},
  allRuns: [],
  theEngineScriptsRuns: [],
  exportToCsvRuns: [],
  reset: () => {},
  lockedRun: null,
  setLockedRun: (runId: number) => {},
  hasLockedRuns: false,
  hasEngineRunHistory: false,
};

export const WorkflowsContext = createContext(defaultValue);
