import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
// import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { styled } from "@mui/material/styles";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import { MaterialEmbed } from "../../frameworks/joy/material_embed";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../frameworks/joy/layouts/drawer_layout/constants";
import { PurchaseOrderStatus } from "../../services/api/__generated__/backend_gateway-types";
import { PoBoxRow } from "./row_type";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  cursor: "pointer",
  "& .super-app-theme--ATOMIC_SUGGESTION": {
    fontWeight: 600,
  },
}));

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format;

const columns: GridColDef[] = [
  {
    field: "status",
    headerName: "Status",
    renderCell: (
      props: GridRenderCellParams<any, PurchaseOrderStatus | null>
    ) => {
      const {
        // hasFocus,
        value,
      } = props;
      let i = <TipsAndUpdatesOutlinedIcon />;
      if (value === PurchaseOrderStatus.AtomicSuggestion) {
        i = <TipsAndUpdatesOutlinedIcon />;
      }
      if (value === PurchaseOrderStatus.CustomerOwned) {
        i = <LocalShippingOutlinedIcon />;
      }
      if (value === PurchaseOrderStatus.Completed) {
        i = <AssignmentTurnedInOutlinedIcon />;
      }
      if (value === PurchaseOrderStatus.Canceled) {
        i = <DeleteOutlineOutlinedIcon />;
      }
      return (
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {i}
        </Box>
      );
    },
    minWidth: 50,
  },
  { field: "location", headerName: "Location", minWidth: 180 },
  { field: "vendor", headerName: "Vendor", minWidth: 180 },
  {
    field: "need_by_date",
    headerName: "Need By Date",
    valueFormatter: (params: Date | null) =>
      params === null ? "" : dayjs.utc(params).format("ll"),
    minWidth: 120,
  },
  {
    field: "total_cogs",
    headerName: "Total COGS",
    valueFormatter: (params: number | null) =>
      params === null ? "" : currencyFormatter(params),
    minWidth: 120,
  },
  {
    field: "updated_at",
    headerName: "Updated At",
    valueFormatter: (params: Date | null) =>
      params === null ? "" : dayjs(params).format("l LT"),
    minWidth: 200,
  },
  {
    field: "snooze_end_date",
    headerName: "Snoozed",
    // valueFormatter: (params: Date | null) =>
    //   params === null ? "" : dayjs(params).format("ll"),
    minWidth: 50,
    renderCell: (props: GridRenderCellParams<any, Date | null>) => {
      const {
        // hasFocus,
        value,
      } = props;
      if (value !== null) {
        return (
          <Box
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <AccessTimeOutlinedIcon />
          </Box>
        );
      }
      return null;
    },
  },
];

export const PoBoxComponent = (props: {
  loading: boolean;
  navigateToPoDetails: (poId: string) => void;
  pos: PoBoxRow[];
}) => {
  const { loading, navigateToPoDetails, pos } = props;

  const handleRowClick = (event: { row: PoBoxRow }) => {
    const {
      row: { id },
    } = event;
    navigateToPoDetails(id);
  };

  return (
    <>
      <PageFrame
        sx={{
          alignItems: "stretch",
          alignContent: "flex-start",
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "auto auto",
          minHeight: {
            xs: `calc(100vh - ${FOOTER_HEIGHT}px - ${HEADER_HEIGHT}px)`,
            sm: `calc(100vh - ${HEADER_HEIGHT}px)`,
          },
          maxHeight: {
            xs: `calc(100vh - ${FOOTER_HEIGHT}px - ${HEADER_HEIGHT}px)`,
            sm: `calc(100vh - ${HEADER_HEIGHT}px)`,
          },
        }}
      >
        <PageTitle sx={{ m: 4 }}>Purchase Orders</PageTitle>

        {loading && (
          <Box sx={{ height: "87px" }}>
            <LinearProgress />
          </Box>
        )}

        {!loading && (
          <Sheet
            variant="outlined"
            color="neutral"
            sx={{
              m: 4,
              mb: 0,
              overflow: "auto",
              minWidth: "0",
              maxWidht: "100%",
              borderRadius: "6px",
              borderBottomRightRadius: "0",
              borderBottomLeftRadius: "0",
              backgroundColor: "atomic.highlightBackground",
            }}
          >
            <MaterialEmbed>
              <StyledDataGrid
                columns={columns}
                rows={pos}
                onRowClick={handleRowClick}
                hideFooter={true}
                sx={{
                  p: 2,
                  border: "none",
                  "& .MuiDataGrid-columnHeader, .MuiDataGrid-filler, .MuiDataGrid-topContainer":
                    {
                      backgroundColor: "atomic.highlightBackground",
                    },
                }}
                getRowClassName={(params) =>
                  `super-app-theme--${params.row.status}`
                }
              />
            </MaterialEmbed>
          </Sheet>
        )}
      </PageFrame>
    </>
  );
};
