import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import { DiffParams, Segmentation } from "../diff_params";
import Tooltip from "@mui/joy/Tooltip";
import Box from "@mui/joy/Box";

export const ThComponent = (props: {
  loading: boolean;
  error: string | null;
  diffParams: DiffParams;
  hasProductCategory: boolean;
  hasProductType: boolean;
  hasProductFamily: boolean;
  hasProductTitle: boolean;
}) => {
  const {
    loading,
    error,
    diffParams,
    // hasProductCategory,
    // hasProductType,
    // hasProductFamily,
    // hasProductTitle,
  } = props;
  let segmentationText: string = "Segmentation";
  switch (diffParams.segmentation) {
    case Segmentation.CHANNEL:
      segmentationText = "Channel";
      break;
    case Segmentation.LOCATION:
      segmentationText = "Location";
      break;
    case Segmentation.PRODUCT_TYPE:
      segmentationText = "Product Type";
      break;
    case Segmentation.PRODUCT_FAMILY:
      segmentationText = "Product Family";
      break;
    case Segmentation.PRODUCT_TITLE:
      segmentationText = "Product Title";
      break;
    case Segmentation.PRODUCT_CATEGORY:
      segmentationText = "Product Category";
      break;
  }
  return (
    <thead>
      <tr style={{ height: 64 }}>
        <th
          style={{
            width: 40,
            boxSizing: "border-box",
          }}
        >
          {loading && <CircularProgress size="sm" variant="plain" />}
          {!loading && error && <ErrorIcon />}
        </th>
        <th
          style={{
            width: 200,
            boxSizing: "border-box",
            textAlign: "left",
          }}
        >
          <Typography level="body-xs" sx={{ fontWeight: 600 }}>
            {segmentationText}
          </Typography>
        </th>
        <th
          style={{
            width: 130,
            boxSizing: "border-box",
            textAlign: "left",
          }}
        >
          <Typography level="body-xs" sx={{ fontWeight: 600 }}>
            Forecast
          </Typography>
        </th>
        <th
          style={{
            width: 130,
            boxSizing: "border-box",
            textAlign: "left",
          }}
        >
          <Typography level="body-xs" sx={{ fontWeight: 600 }}>
            Actual
          </Typography>
        </th>
        <th
          style={{
            width: 130,
            boxSizing: "border-box",
            textAlign: "left",
          }}
        >
          <Typography level="body-xs" sx={{ fontWeight: 600 }}>
            Correct Forecast
          </Typography>
        </th>
        <th
          style={{
            width: 130,
            boxSizing: "border-box",
            textAlign: "left",
          }}
        >
          <Typography level="body-xs" sx={{ fontWeight: 600 }}>
            Absolute Delta
          </Typography>
        </th>
        <th
          style={{
            width: 130,
            boxSizing: "border-box",
            textAlign: "left",
          }}
        >
          <Typography level="body-xs" sx={{ fontWeight: 600 }}>
            WMAPE
            <Tooltip
              placement="top"
              title={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 320,
                    justifyContent: "center",
                    p: 1,
                  }}
                >
                  WMAPE = Sum(Absolute Deltas) / Sum(Actuals)
                </Box>
              }
              variant="soft"
              arrow
            >
              <Typography sx={{ ml: 1, textDecoration: "underline" }}>
                ?
              </Typography>
            </Tooltip>
          </Typography>
        </th>
      </tr>
    </thead>
  );
};
