import { Page } from "../../frameworks/joy/layouts/page";
import { ReportsContainer } from "./container";

export const ReportsPage = () => {
  return (
    <Page>
      <ReportsContainer />
    </Page>
  );
};
