import { useContext, useEffect, useState } from "react";
import { ForecastAccuracyComponent } from "./component";
import { ForecastAccuracyControlContainer } from "./control/control_container";
import { ForecastAccuracyTableContainer } from "./table/table_container";
import { DiffParams } from "./diff_params";
import { ApiContext } from "../../contexts/api/context";
import {
  GetMyForecastAccuracyUrlQuery,
  useGetMyForecastAccuracyUrlLazyQuery,
  useGetMySkuHierarchyUrlLazyQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { SkuTagsFromCsv } from "./sku_hierarchy_from_csv";
import { ForecastAccuracyFromCsv } from "./forecast_accuracy_from_csv";
import { ForecastAccuracyAlertsContainer } from "./alerts/alerts_container";

export const ForecastAccuracyContainer = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
}) => {
  const { diffParams, updateDiffParams } = props;
  const { apiService } = useContext(ApiContext);

  const [skuTagsLoading, setSkuTagsLoading] = useState<boolean>(false);
  const [skuTags, setSkuTags] = useState<SkuTagsFromCsv>(
    new SkuTagsFromCsv(setSkuTagsLoading)
  );
  const [
    getMySkuHierarchyUrl,
    { loading: skuTagsURLLoading, error: skuTagsError },
  ] = useGetMySkuHierarchyUrlLazyQuery({
    client: apiService.getClient(),
  });

  const { actualsEngineRunId, forecastEngineRunId, minDate, maxDate } =
    diffParams;

  const [forecastAccuracyLoading, setForecastAccuracyLoading] =
    useState<boolean>(false);
  const [forecastAccuracy, setForecastAccuracy] =
    useState<ForecastAccuracyFromCsv>(
      new ForecastAccuracyFromCsv(setForecastAccuracyLoading)
    );
  const [
    getMyForecastAccuracyUrl,
    { loading: forecastAccuracyUrlLoading, error: forecastAccuracyUrlError },
  ] = useGetMyForecastAccuracyUrlLazyQuery({
    client: apiService.getClient(),
  });

  const skuTagsSetOnCompleteCallback = skuTags.setOnCompleteCallback;
  const skuTagsFetch = skuTags.fetch;

  useEffect(() => {
    const fetchSkuHierarchy = async () => {
      if (actualsEngineRunId !== null && skuTagsSetOnCompleteCallback) {
        getMySkuHierarchyUrl({
          variables: { engineRunId: parseInt(actualsEngineRunId) },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: "network-only",
          nextFetchPolicy: "network-only",
          onCompleted(data) {
            const url = data?.getMySkuHierarchyUrl;
            skuTagsSetOnCompleteCallback(setSkuTags);
            if (url) {
              skuTagsFetch(url);
            }
          },
        });
      }
    };
    fetchSkuHierarchy();
  }, [
    actualsEngineRunId,
    getMySkuHierarchyUrl,
    skuTagsSetOnCompleteCallback,
    skuTagsFetch,
  ]);

  const forecastAccuracySetOnCompleteCallback =
    forecastAccuracy.setOnCompleteCallback;
  const forecastAccuracyFetch = forecastAccuracy.fetch;

  useEffect(() => {
    const fetchAccuracy = async () => {
      if (
        actualsEngineRunId !== null &&
        forecastEngineRunId !== null &&
        forecastAccuracySetOnCompleteCallback
      ) {
        setForecastAccuracyLoading(true);
        let retries = 30;
        const getForecast = (
          onCompleted: (
            data: GetMyForecastAccuracyUrlQuery,
            forceReload: boolean
          ) => Promise<void>,
          forceReload: boolean = false
        ) => {
          getMyForecastAccuracyUrl({
            variables: {
              actualsEngineRunId: parseInt(actualsEngineRunId),
              forecastEngineRunId: parseInt(forecastEngineRunId),
              minDate: minDate ? minDate.toISOString().split("T")[0] : null,
              maxDate: maxDate ? maxDate.toISOString().split("T")[0] : null,
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: forceReload ? "network-only" : "cache-first",
            // TODO: this is triggered even when the url is read from cache:
            onCompleted: (data) => onCompleted(data, forceReload),
          });
        };
        const onCompleted = async (
          data: GetMyForecastAccuracyUrlQuery,
          forceReload: boolean
        ) => {
          const url = data?.getMyForecastAccuracyUrl;
          forecastAccuracySetOnCompleteCallback(setForecastAccuracy);
          if (url) {
            await forecastAccuracyFetch(url, forceReload);
          } else if (retries > 0) {
            retries -= 1;
            setTimeout(() => getForecast(onCompleted, true), 3000);
          }
        };
        getForecast(onCompleted);
      }
    };
    fetchAccuracy();
  }, [
    actualsEngineRunId,
    forecastEngineRunId,
    getMyForecastAccuracyUrl,
    forecastAccuracySetOnCompleteCallback,
    forecastAccuracyFetch,
    minDate,
    maxDate,
  ]);

  if (skuTagsError) {
    console.error(
      "ForecastAccuracyContainer: useGetMySkuHierarchyUrlLazyQuery fetch error",
      skuTagsError
    );
  }

  if (forecastAccuracyUrlError) {
    console.error(
      "ForecastAccuracyContainer: useGetMyForecastAccuracyUrlLazyQuery fetch error",
      forecastAccuracyUrlError
    );
  }

  return (
    <ForecastAccuracyComponent
      control={
        <ForecastAccuracyControlContainer
          diffParams={diffParams}
          updateDiffParams={updateDiffParams}
        />
      }
      alerts={
        <ForecastAccuracyAlertsContainer
          diffParams={diffParams}
          forecastAccuracy={forecastAccuracy}
          forecastAccuracyLoading={forecastAccuracyLoading}
        />
      }
      table={
        <ForecastAccuracyTableContainer
          diffParams={diffParams}
          forecastAccuracy={forecastAccuracy}
          forecastAccuracyLoading={forecastAccuracyLoading}
          skuTags={skuTags}
          skuTagsLoading={skuTagsLoading}
        />
      }
      loading={
        skuTagsURLLoading ||
        skuTagsLoading ||
        forecastAccuracyLoading ||
        forecastAccuracyUrlLoading
      }
    />
  );
};
