import {
  PurchaseOrderStatus,
  PurchaseOrderWithEditAndSkus,
} from "../../services/api/__generated__/backend_gateway-types";

export enum StatusFilter {
  ATOMIC_SUGGESTION = "ATOMIC_SUGGESTION",
  CUSTOMER_OWNED = "CUSTOMER_OWNED",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export type PoBoxSettings = {
  includeStatus: StatusFilter[];
  includeSnoozed: boolean;
};

export type PosType = {
  loading: boolean;
  hasPoInbox: boolean;
  poBoxSettings: PoBoxSettings;
  setPoBoxSettings: (poBoxSettings: PoBoxSettings) => void;
  pos: PurchaseOrderWithEditAndSkus[];
  refetchPos: () => void;
  savePuchaseOrder: (
    purchaseOrderId: string,
    need_by_date: string,
    ship_by_date: string,
    notes: string,
    skus: {
      sku: string;
      quantity: string;
      unit: string;
      unit_cost: string | null;
    }[]
  ) => Promise<void>;
  savePurchaseOrderSnoozeAndStatus: (
    purchaseOrderId: string,
    start_date: string | null,
    end_date: string | null,
    status: PurchaseOrderStatus
  ) => Promise<void>;
  saving: boolean;
};
