import { PageWithRightPane } from "../../frameworks/joy/layouts/page_with_right_pane";
import { RunStatusesContainer } from "./container";

export const RunStatusesPage = () => {
  return (
    <PageWithRightPane>
      <RunStatusesContainer />
    </PageWithRightPane>
  );
};
