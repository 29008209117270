import { FC } from "react";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import LinearProgress from "@mui/joy/LinearProgress";
import { Customer } from "../../services/api/__generated__/backend_gateway-types";

interface CustomerSelectorComponentProps {
  navigateToRoot: () => void;
  customers: Customer[];
  selectedCustomerId: string | null;
  setSelectedCustomerId: (customerId: string | null) => void;
  role: string | null;
  loading: boolean;
}

export const CustomerSelectorComponent: FC<CustomerSelectorComponentProps> = (
  props
) => {
  const {
    role,
    selectedCustomerId,
    customers,
    setSelectedCustomerId,
    navigateToRoot,
    loading,
  } = props;

  if (role === null || !["ROOT"].includes(role!)) {
    return null;
  }

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Select
      placeholder="Select customer"
      value={selectedCustomerId}
      slotProps={{
        listbox: {
          sx: {
            maxHeight: 300,
            overflow: "auto", // required for scrolling
            zIndex: 1201, // Because of the floating drawer on mobile
          },
        },
      }}
    >
      <Option
        value="NO CUSTOMER SELECTED"
        key="NO CUSTOMER SELECTED"
        onClick={() => {
          setSelectedCustomerId(null);
          navigateToRoot();
        }}
      >
        Clear selection
      </Option>
      {customers?.map((customer) => (
        <Option
          value={customer.id}
          key={customer.id}
          onClick={() => {
            setSelectedCustomerId(customer.id);
            navigateToRoot();
          }}
        >
          {customer.name || customer.id}
        </Option>
      ))}
    </Select>
  );
};
