import { ReactNode } from "react";
import Sheet from "@mui/joy/Sheet";
import { PageFrame } from "../../../components/page_frame";

export const PlanningForecastAccuracyComponent = (props: {
  control?: ReactNode;
  table?: ReactNode;
  loading: boolean;
}) => {
  const { control, table } = props;
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
        backgroundColor: "atomic.highlightUnderlay",
      }}
    >
      {control}
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 2,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        {table}
      </Sheet>
    </PageFrame>
  );
};
