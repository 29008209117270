import { useContext } from "react";
import {
  GetMyLogoDocument,
  useGetMyLogoQuery,
  useSetMyLogoMutation,
} from "../../../services/api/__generated__/backend_gateway-types";
import { PoDetailsEditLogoComponent } from "./component";
import { ApiContext } from "../../../contexts/api/context";
import { SysMessagesContext } from "../../../contexts/sys_messages/context";
import { SysMessageLevel } from "../../../contexts/sys_messages/type";
import { useLocation, useNavigate } from "react-router-dom";

export const PoDetailsEditLogoContainer = () => {
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);

  const { data: logoData, loading: logoLoading } = useGetMyLogoQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const [setLogo, { loading: setLogoLoading }] = useSetMyLogoMutation({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
    refetchQueries: [
      {
        query: GetMyLogoDocument,
      },
    ],
  });

  const setLogoAndText = async (imgB64: string, text: string) => {
    await setLogo({
      variables: {
        logo: {
          logo_name: text,
          img_b64: imgB64,
        },
      },
    });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const navigateBack = () => {
    const poPathFragments = location.pathname.split("/");
    if (poPathFragments.length >= 3) {
      const poPath = `/${poPathFragments[1]}/${poPathFragments[2]}`;
      navigate(`${poPath}${location.search ? "?" + location.search : ""}`, {
        replace: true,
      });
    }
  };

  return (
    <PoDetailsEditLogoComponent
      loading={logoLoading || setLogoLoading}
      navigateBack={navigateBack}
      logoImgB64={logoData?.getMyLogo?.img_b64 || null}
      logoText={logoData?.getMyLogo?.logo_name || null}
      setLogoAndText={setLogoAndText}
    />
  );
};
