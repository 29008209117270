import { useContext, useEffect } from "react";
import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";

import { useGetMyGoodchopTransformedDataUrlsLazyQuery } from "../../../services/api/__generated__/backend_gateway-types";
import { ApiContext } from "../../../contexts/api/context";
import { FileUploaderComponent } from "./file_uploader_component";
import { DownloadTableComponent } from "./download_table_component";
import { PageFrame } from "../../../components/page_frame";
import { PageTitle } from "../../../components/page_title";

import "./index.css";
import { PresignedUrls } from "./entities";

export const GoodchopOdlToShipStationComponent = (props: {
  loading: boolean;
}) => {
  const { apiService } = useContext(ApiContext);
  const { loading } = props;

  const [
    getAllTransformedDataUrlsGoodchop,
    {
      refetch: refetchTransformedDataUrls,
      data: getAllTransformedDataUrlsGoodchopData,
      loading: getAllTransformedDataUrlsGoodchopLoading,
    },
  ] = useGetMyGoodchopTransformedDataUrlsLazyQuery({
    client: apiService.getClient(),
    variables: {
      page: 0,
    },
  });

  useEffect(() => {
    getAllTransformedDataUrlsGoodchop();
  }, [getAllTransformedDataUrlsGoodchop]);

  return (
    <PageFrame>
      <PageTitle>{"ODL to Shipstation"}</PageTitle>

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      {!loading && (
        <>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Sheet
              variant="outlined"
              color="neutral"
              sx={{
                p: 4,
                overflow: "auto",
                minWidth: "0",
                maxWidth: "100%",
                borderRadius: "4px",
              }}
            >
              <FileUploaderComponent
                getAllTransformedDataUrlsGoodchop={
                  getAllTransformedDataUrlsGoodchop
                }
              />
            </Sheet>
          </Box>
          <Box
            sx={{
              width: "100%",
              minHeight: "10vh",
              maxHeight: "50vh",
              overflow: "auto",
            }}
          >
            <Sheet
              variant="outlined"
              color="neutral"
              sx={{
                p: 4,
                overflow: "auto",
                minWidth: "0",
                maxWidth: "100%",
                borderRadius: "4px",
              }}
            >
              {getAllTransformedDataUrlsGoodchopLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <DownloadTableComponent
                  tableData={
                    getAllTransformedDataUrlsGoodchopData?.getMyGoodchopOdlTransformedDataUrls as PresignedUrls
                  }
                  refetchTransformedDataUrls={refetchTransformedDataUrls}
                  loading={getAllTransformedDataUrlsGoodchopLoading}
                />
              )}
            </Sheet>
          </Box>
        </>
      )}
    </PageFrame>
  );
};
