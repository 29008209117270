import { createContext } from "react";
import { SysMessagesType } from "./type";

const defaultValue: SysMessagesType = {
  systemMessages: [],
  addSystemMessage: () => {},
  removeSystemMessage: () => {},
};

export const SysMessagesContext = createContext(defaultValue);
