import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AddCustomerComponent } from "./component";
import { ApiContext } from "../../../contexts/api/context";
import { SysMessageLevel } from "../../../contexts/sys_messages/type";
import { SysMessagesContext } from "../../../contexts/sys_messages/context";
import {
  GetAllCustomersDocument,
  GetAllCustomersQuery,
  useCreateCustomerMutation,
  Customer,
} from "../../../services/api/__generated__/backend_gateway-types";

export const AddCustomerContainer = () => {
  const navigate = useNavigate();
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);

  const navigateToCustomers = () => {
    navigate("/customers", { replace: true });
  };

  const [createCustomerMutation, { loading: createCustomerLoading }] =
    useCreateCustomerMutation({
      client: apiService.getClient(),
    });

  const createCustomer = async (
    id: string,
    name: string,
    defaultRole: string
  ): Promise<Customer | undefined> => {
    const { data } = await createCustomerMutation({
      variables: {
        id,
        name,
        defaultRole,
      },
      update(cache) {
        const existingCustomers = cache.readQuery<GetAllCustomersQuery>({
          query: GetAllCustomersDocument,
        });

        if (existingCustomers && existingCustomers.getAllCustomers) {
          cache.writeQuery({
            query: GetAllCustomersDocument,
            data: {
              getAllCustomers: [
                ...existingCustomers.getAllCustomers,
                { id, name, __typename: "Customer" },
              ],
            },
          });
        }
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });
    return data?.createCustomer ? (data.createCustomer as Customer) : undefined;
  };

  return (
    <AddCustomerComponent
      navigateBack={navigateToCustomers}
      addCustomer={createCustomer}
      loading={createCustomerLoading}
    />
  );
};
