import { OrchestratorStepStatus } from "../../services/api/__generated__/backend_gateway-types";

export const stepStatusToText = (
  stepStatus: OrchestratorStepStatus
): string => {
  switch (stepStatus) {
    case OrchestratorStepStatus.ToExecute:
      return "To Execute";
    case OrchestratorStepStatus.ExecutionQueued:
      return "Queued";
    case OrchestratorStepStatus.ExecutionStarted:
      return "Started";
    case OrchestratorStepStatus.ExecutionCompleted:
      return "Completed";
    case OrchestratorStepStatus.ExecutionFailed:
      return "Failed";
  }
};
