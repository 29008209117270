import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataGridPro,
  GridActionsCellItem,
  GridApiPro,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { PoSkuRow } from "./row_type";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import { useContext } from "react";
import { PoEditContext } from "../po_edit_context/context";
// import Typography from "@mui/joy/Typography";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
}).format;

const POTableSkusFooter = (props: {
  gridApiRef: React.MutableRefObject<GridApiPro>;
}) => {
  const { gridApiRef } = props;

  //   // Get all rows and remove `id` from objects
  //   const rowIds = gridApiRef.current.getAllRowIds();
  //   const allRows = rowIds.map((id) => gridApiRef.current.getRow(id));

  //   // Calculate newTotalCogsPO
  //   const newTotalCogsPO: number = allRows.reduce(
  //     (accumulator, currentValue) =>
  //       accumulator + (currentValue.cogs ? parseFloat(currentValue.cogs) : 0),
  //     0.0
  //   );
  //   const formattedNewTotalCogsPO =
  //     newTotalCogsPO === null ||
  //     newTotalCogsPO === undefined ||
  //     isNaN(newTotalCogsPO)
  //       ? ""
  //       : currencyFormatter(newTotalCogsPO);

  // Add new row
  const handleAddRow = () => {
    const rowIds = gridApiRef.current.getAllRowIds();
    const newRowId = rowIds.length;
    const newRow = { id: newRowId, sku: "New SKU", quantity: 0, total_cogs: 0 };
    gridApiRef.current.updateRows([newRow]);
  };

  return (
    <>
      <Divider />
      <Stack
        direction="row"
        alignContent={"center"}
        justifyContent={"space-between"}
        padding={2}
      >
        <Stack direction="row" spacing={2}>
          <Button
            size="sm"
            onClick={handleAddRow}
            startDecorator={<AddIcon />}
            color="neutral"
            variant="plain"
          >
            Add SKU
          </Button>
        </Stack>
        {/* <Typography level="h4">Total: {formattedNewTotalCogsPO}</Typography> */}
      </Stack>
    </>
  );
};

export const PoDetailsSkuListComponent = (props: { skus: PoSkuRow[] }) => {
  const { skus } = props;
  const { gridApiRef } = useContext(PoEditContext);

  // Remove row
  const handleRemoveRow = (id: GridRowId) => {
    gridApiRef!.current.updateRows([{ id, _action: "delete" }]);
  };

  const columns: GridColDef[] = [
    { field: "sku", headerName: "SKU", editable: true },
    {
      field: "unit",
      headerName: "Unit",
      editable: true,
      type: "string",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      editable: true,
      type: "number",
    },
    {
      field: "unit_cost",
      headerName: "Unit Cost",
      editable: true,
      type: "number",
      cellClassName: "font-tabular-nums",
      valueFormatter: (params: number | undefined | null) =>
        params === null || params === undefined
          ? ""
          : currencyFormatter(params),
    },
    {
      field: "cogs",
      headerName: "Amount",
      editable: false,
      type: "number",
      cellClassName: "font-tabular-nums",
      valueGetter: (value, row) => {
        return row.unit_cost !== null ? row.quantity * row.unit_cost! : 0;
      },
      valueFormatter: (params: number | null) =>
        params === null || params === undefined || isNaN(params)
          ? ""
          : currencyFormatter(params),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Remove SKU",
      minWidth: 120,
      cellClassName: "actions",
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Remove SKU"
          onClick={() => handleRemoveRow(id)}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <MaterialEmbed>
      <DataGridPro
        apiRef={gridApiRef!}
        rows={skus}
        columns={columns}
        sx={{
          border: 0,
        }}
        slots={{
          footer: () => <POTableSkusFooter gridApiRef={gridApiRef!} />,
        }}
        rowReordering
        checkboxSelection
        disableRowSelectionOnClick
      />
    </MaterialEmbed>
  );
};
