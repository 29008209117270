import { PageWithRightPane } from "../../frameworks/joy/layouts/page_with_right_pane";
import { PresetsContainer } from "./container";

export const PresetsPage = () => {
  return (
    <PageWithRightPane>
      <PresetsContainer />
    </PageWithRightPane>
  );
};
