import { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { WorkflowsContext } from "../../../contexts/workflows/context";
import { SysMessagesContext } from "../../../contexts/sys_messages/context";
import {
  OrchestratorRunStatus,
  useGetMyEnginePresetsQuery,
  useGetMyOrchestratorStepsQuery,
  useSetMyOrchestratorRunDescriptionMutation,
  useSetMyOrchestratorRunLockedMutation,
  useSetMyOrchestratorRunNameMutation,
} from "../../../services/api/__generated__/backend_gateway-types";
import { SysMessageLevel } from "../../../contexts/sys_messages/type";
import { ApiContext } from "../../../contexts/api/context";
import { RunsDetailsComponent } from "./component";

export const RunsDetailsContainer = (props: {
  inputId: string;
  workflowId: string;
  variablesId: string;
  runAttemptId: string;
}) => {
  const { inputId, workflowId, variablesId, runAttemptId } = props;
  const { allRuns, isLoading, hasLockedRuns } = useContext(WorkflowsContext);
  const { addSystemMessage } = useContext(SysMessagesContext);
  const { apiService } = useContext(ApiContext);

  const run = useMemo(() => {
    return (
      allRuns.find(
        (r) =>
          r.runSettings.inputId === inputId &&
          r.runSettings.workflowId === workflowId &&
          r.runSettings.variablesId === variablesId &&
          r.runSettings.runAttemptId === parseInt(runAttemptId)
      ) || null
    );
  }, [allRuns, inputId, workflowId, variablesId, runAttemptId]);

  const {
    data: stepsData,
    loading: stepsLoading,
    refetch: stepsRefetch,
  } = useGetMyOrchestratorStepsQuery({
    client: apiService.getClient(),
    variables: {
      runSettings: {
        inputId,
        workflowId,
        variablesId,
        runAttemptId: parseInt(runAttemptId),
      },
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const [setMyOrchestratorName, { loading: setMyOrchestratorNameLoading }] =
    useSetMyOrchestratorRunNameMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const setRunName = async (name: string): Promise<void> => {
    if (!run) {
      return;
    }
    setMyOrchestratorName({
      variables: {
        runSettings: {
          inputId: run.runSettings.inputId,
          workflowId: run.runSettings.workflowId,
          variablesId: run.runSettings.variablesId,
          runAttemptId: run.runSettings.runAttemptId,
        },
        name,
      },
    });
  };

  const [
    setMyOrchestratorDescription,
    { loading: setMyOrchestratorDescriptionLoading },
  ] = useSetMyOrchestratorRunDescriptionMutation({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const setRunDescription = async (description: string): Promise<void> => {
    if (!run) {
      return;
    }
    setMyOrchestratorDescription({
      variables: {
        runSettings: {
          inputId: run.runSettings.inputId,
          workflowId: run.runSettings.workflowId,
          variablesId: run.runSettings.variablesId,
          runAttemptId: run.runSettings.runAttemptId,
        },
        description,
      },
    });
  };

  const [setMyOrchestratorLocked, { loading: setMyOrchestratorLockedLoading }] =
    useSetMyOrchestratorRunLockedMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const setRunLocked = async (locked: boolean): Promise<void> => {
    if (!run) {
      return;
    }
    setMyOrchestratorLocked({
      variables: {
        runSettings: {
          inputId: run.runSettings.inputId,
          workflowId: run.runSettings.workflowId,
          variablesId: run.runSettings.variablesId,
          runAttemptId: run.runSettings.runAttemptId,
        },
        locked,
      },
    });
  };

  const { data: presetsData, loading: presetsLoading } =
    useGetMyEnginePresetsQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate("/runs", { replace: true });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (run?.status === OrchestratorRunStatus.RunInProgress) {
        stepsRefetch();
      }
    }, 8000);
    if (run?.status !== OrchestratorRunStatus.RunInProgress) {
      setTimeout(stepsRefetch, 8000);
    }
    return () => clearInterval(intervalId);
  }, [stepsRefetch, run]);

  return (
    <RunsDetailsComponent
      loading={
        isLoading ||
        stepsLoading ||
        setMyOrchestratorNameLoading ||
        setMyOrchestratorDescriptionLoading ||
        setMyOrchestratorLockedLoading ||
        presetsLoading
      }
      navigateBack={navigateBack}
      run={run}
      steps={stepsData?.getMyOrchestratorSteps || []}
      hasLockedRuns={hasLockedRuns}
      setRunName={setRunName}
      setRunDescription={setRunDescription}
      setRunLocked={setRunLocked}
      presets={presetsData?.getMyEnginePresets || []}
    />
  );
};
