import { ReactNode } from "react";
import Box from "@mui/joy/Box";
import { FOOTER_HEIGHT, HEADER_HEIGHT } from "../drawer_layout/constants";

export const Page = (props: { children?: ReactNode; sx?: any }) => {
  return (
    <Box
      sx={{
        p: 0,
        m: 0,
        pt: `${HEADER_HEIGHT}px`,
        boxSizing: "border-box",
        display: {
          xs: "inherit",
          md: "inherit",
        },
        gridRow: 1,
        gridColumn: {
          xs: 1,
          sm: "1 / span 2",
          md: "2 / span 2",
        },
        height: {
          xs: `calc(100vh - ${FOOTER_HEIGHT}px)`,
          sm: "100vh",
        },
        overflow: "auto",
        // bgcolor: colors.black50,
        bgcolor: "atomic.grey1",
      }}
      aria-label="main-content"
      {...props}
    />
  );
};
