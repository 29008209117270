import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { deploymentConfig } from "../../configs/deployment";
dayjs.extend(utc);

export const stepDirectLogLink = (
  start: string,
  durationMS: number | null = null
): string => {
  const startEpoch = dayjs.utc(start).subtract(1, "s").valueOf();
  const endEpoch = durationMS
    ? dayjs
        .utc(start)
        .add(durationMS + 1000, "ms")
        .valueOf()
    : dayjs.utc(start).add(3, "m").valueOf();
  const { deploymentId } = deploymentConfig;
  return `https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/$252Faws$252Flambda$252F${deploymentId}-steps-StepsDirectLambda-${
    deploymentId === "prod" ? "YGJJHNpLaQKH" : "Hd9ylV6Du5ni"
  }/log-events$3Fstart$3D${startEpoch}$26end$3D${endEpoch}`;
};

export const stepEngineLogLink = (
  start: string,
  durationMS: number | null = null
): string => {
  const startEpoch = dayjs.utc(start).subtract(1, "s").valueOf();
  const endEpoch = durationMS
    ? dayjs
        .utc(start)
        .add(durationMS + 1000, "ms")
        .valueOf()
    : dayjs.utc(start).add(3, "m").valueOf();
  const { deploymentId } = deploymentConfig;
  return `https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/${deploymentId}-engine/log-events$3Fstart$3D${startEpoch}$26end$3D${endEpoch}`;
};

export const stepOrchestrationLogLink = (start: string): string => {
  const startEpoch = dayjs.utc(start).subtract(1, "s").valueOf();
  const endEpoch = dayjs.utc(start).add(1, "m").valueOf();
  const { deploymentId } = deploymentConfig;
  return `https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/${deploymentId}-orchestrator/log-events$3Fstart$3D${startEpoch}$26end$3D${endEpoch}`;
};

export const stepAzureAsyncLogLink = (
  start: string,
  durationMS: number | null = null
): string => {
  const startEpoch = dayjs.utc(start).subtract(1, "s").valueOf();
  const endEpoch = durationMS
    ? dayjs
        .utc(start)
        .add(durationMS + 1000, "ms")
        .valueOf()
    : dayjs.utc(start).add(3, "m").valueOf();
  const { deploymentId } = deploymentConfig;
  return `https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/${deploymentId}-azurequeryasync/log-events$3Fstart$3D${startEpoch}$26end$3D${endEpoch}`;
};

export const stepExportImportLogLink = (
  start: string,
  durationMS: number | null = null
): string => {
  const startEpoch = dayjs.utc(start).subtract(1, "s").valueOf();
  const endEpoch = durationMS
    ? dayjs
        .utc(start)
        .add(durationMS + 1000, "ms")
        .valueOf()
    : dayjs.utc(start).add(3, "m").valueOf();
  const { deploymentId } = deploymentConfig;
  return `https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/${deploymentId}-exportimport/log-events$3Fstart$3D${startEpoch}$26end$3D${endEpoch}`;
};

export const stepPoLogLink = (
  start: string,
  durationMS: number | null = null
): string => {
  const startEpoch = dayjs.utc(start).subtract(1, "s").valueOf();
  const endEpoch = durationMS
    ? dayjs
        .utc(start)
        .add(durationMS + 1000, "ms")
        .valueOf()
    : dayjs.utc(start).add(3, "m").valueOf();
  const { deploymentId } = deploymentConfig;
  return `https://us-east-1.console.aws.amazon.com/cloudwatch/home?region=us-east-1#logsV2:log-groups/log-group/${deploymentId}-po/log-events$3Fstart$3D${startEpoch}$26end$3D${endEpoch}`;
};
