import React from "react";

import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";

import AccountBox from "@mui/icons-material/AccountBox";
import SsidChart from "@mui/icons-material/SsidChart";
import Tune from "@mui/icons-material/Tune";

export const FooterComponent = (props: {
  navigateToReports: () => void;
  navigateToRunHistory: () => void;
  navigateToProfile: () => void;
  currentPathName: string | null;
}) => {
  const { navigateToReports, navigateToRunHistory, navigateToProfile } = props;
  const [index, setIndex] = React.useState(-1);

  return (
    <Box
      sx={{
        flexGrow: 1,
        // justifyContent: 'space-evenly',
        display: {
          xs: "flex",
          sm: "none",
        },
      }}
    >
      <Tabs
        size="lg"
        aria-label="Bottom Navigation"
        value={index}
        onChange={(event, value) => {
          setIndex(value as number);
          if (value === 0) {
            navigateToReports();
          } else if (value === 1) {
            navigateToRunHistory();
          } else if (value === 2) {
            navigateToProfile();
          }
        }}
        sx={{
          "--Tabs-gap": "8px",
          mx: "auto",
          width: "100vw",
          [`& .${tabClasses.root}`]: {
            boxShadow: "none",
            borderRadius: "xs",
            whiteSpace: "nowrap",
            transition: "0.3s",
            fontWeight: "xs",
            bgcolor: "transparent",
            flex: 2,
            p: 1,
            [`&:not(.${tabClasses.selected}):not(:hover)`]: {
              opacity: 0.72,
            },
          },
        }}
      >
        <TabList
          variant="plain"
          sx={{
            "--List-decorator-size": "28px",
            p: 0,
          }}
        >
          <Tab orientation="vertical" variant={index === 0 ? "soft" : "plain"}>
            <ListItemDecorator>
              <SsidChart />
            </ListItemDecorator>
            <Typography
              sx={{
                ...(index === 0 && {
                  fontWeight: "lg",
                }),
              }}
            >
              My Reports
            </Typography>
          </Tab>

          <Tab orientation="vertical" variant={index === 1 ? "soft" : "plain"}>
            <ListItemDecorator>
              <Tune />
            </ListItemDecorator>
            <Typography
              sx={{
                ...(index === 1 && {
                  fontWeight: "lg",
                }),
              }}
            >
              Run History
            </Typography>
          </Tab>

          <Tab orientation="vertical" variant={index === 2 ? "soft" : "plain"}>
            <ListItemDecorator>
              <AccountBox />
            </ListItemDecorator>
            <Typography
              sx={{
                ...(index === 2 && {
                  fontWeight: "lg",
                }),
              }}
            >
              My Profile
            </Typography>
          </Tab>
        </TabList>
      </Tabs>
    </Box>
  );
};
