import { useContext } from "react";
import { ApiContext } from "../../../contexts/api/context";
import { RunTriggerContext } from "../../../contexts/run_trigger/context";
import { useUpdateMyRunMutation } from "../../../services/api/__generated__/backend_gateway-types";
import { EditRunComponent } from "./component";
import { DrawerContext } from "../../../contexts/drawer/context";
import { useNavigate } from "react-router-dom";

export const EditRunContainer = (props: { runId: string }) => {
  const { runId } = props;
  const { closeDrawer } = useContext(DrawerContext);
  const navigate = useNavigate();
  const { apiService } = useContext(ApiContext);
  const { runLogs, isLogsLoading } = useContext(RunTriggerContext);
  const [
    updateMyRun,
    { loading: updateMyRunLoading, error: updateMyRunError },
  ] = useUpdateMyRunMutation({
    client: apiService.getClient(),
  });

  const navigateBack = () => {
    closeDrawer();
    navigate("/run_history", { replace: true });
  };

  const runIdInt = parseInt(runId);

  const run = runLogs.find((r) => r.id === runIdInt) || null;

  const updateRun = async (name: string, description: string) => {
    await updateMyRun({
      variables: {
        run: {
          id: runIdInt,
          name,
          description,
        },
      },
      optimisticResponse: {
        updateMyRun: {
          __typename: "RunLog",
          ...run,
          id: runIdInt,
          name,
          description,
        },
      },
      // updateQueries: {
      //   GetMyRunLogs: (previousQueryResult, { mutationResult }) => {
      //     const updated = [...previousQueryResult.getMyRunLogs].map((r) => {
      //       if (r.id === runIdInt) {
      //         return mutationResult.data?.updateMyRun;
      //       }
      //       return r;
      //     });
      //     return { getMyRunLogs: updated };
      //   },
      // },
    });
    await apiService.getClient().refetchQueries({
      include: ["GetMyRunLogs"],
    });
  };

  if (updateMyRunError) {
    console.error(
      "EditRunContainer::updateMyRun mutation failed:",
      updateMyRunError
    );
  }

  return (
    <EditRunComponent
      runLog={run}
      loading={isLogsLoading}
      navigateBack={navigateBack}
      updateRun={updateRun}
      updateLoading={updateMyRunLoading}
    />
  );
};
