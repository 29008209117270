import Snackbar from "@mui/joy/Snackbar";
import {
  SysMessageLevel,
  SysMessageWithLevel,
} from "../../contexts/sys_messages/type";
import Button from "@mui/joy/Button";

const levelToLevel = (level: SysMessageLevel) => {
  switch (level) {
    case "NEUTRAL":
      return "neutral";
    case "EMPHASIZED":
      return "primary";
    case "WARNING":
      return "warning";
    case "DANGER":
      return "danger";
    case "SUCCESS":
      return "success";
  }
  return "neutral";
};

export const SystemMessagesComponent = (props: {
  systemMessages: SysMessageWithLevel[];
  removeSystemMessage: (i: number) => void;
}) => {
  const { systemMessages, removeSystemMessage } = props;
  return (
    <>
      {systemMessages.map((m, i) => (
        <Snackbar
          key={i}
          variant="soft"
          color={levelToLevel(m.level)}
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          endDecorator={
            <Button
              onClick={() => removeSystemMessage(i)}
              size="sm"
              variant="soft"
              color={levelToLevel(m.level)}
            >
              Dismiss
            </Button>
          }
        >
          {m.message}
        </Snackbar>
      ))}
    </>
  );
};
