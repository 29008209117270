import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { DrawerProvider } from "../../../../contexts/drawer/provider";
import { LayoutContainer } from "./container";
import { Drawer } from "./drawer";
import { Header } from "./floating_header";
import { Footer } from "./xs_footer";

const Layout = (props: { children: ReactNode }) => {
  return (
    <DrawerProvider>
      <LayoutContainer
        drawerContent={Drawer}
        headerContent={Header}
        footerContent={Footer}
        {...props}
      >
        <Outlet />
      </LayoutContainer>
    </DrawerProvider>
  );
};

export default Layout;
