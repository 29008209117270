import { useContext } from "react";
import { ApiContext } from "../../../contexts/api/context";
import { useWhoAmIQuery } from "../../../services/api/__generated__/backend_gateway-types";
import { GoodchopOdlToShipStationComponent } from "./component";

export const GoodchopOdlToShipStationContainer = () => {
  const { apiService } = useContext(ApiContext);

  const { loading, error } = useWhoAmIQuery({
    client: apiService.getClient(),
  });

  if (error) {
    console.error(
      "GoodchopOdlToShipStationContainer: useWhoAmIQuery fetch error",
      error
    );
  }

  return <GoodchopOdlToShipStationComponent loading={loading} />;
};
