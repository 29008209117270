import { OrchestratorRunStatus } from "../../services/api/__generated__/backend_gateway-types";

export const runStatusToEmoji = (runStatus: OrchestratorRunStatus): string => {
  switch (runStatus) {
    case OrchestratorRunStatus.ToRun:
      return "⏳";
    case OrchestratorRunStatus.RunInProgress:
      return "🛠️";
    case OrchestratorRunStatus.RunCompleted:
      return "✅";
    case OrchestratorRunStatus.RunFailed:
      return "🛑";
    case OrchestratorRunStatus.CancelInProgress:
      return "⏳";
    case OrchestratorRunStatus.CancelCompleted:
      return "🚫";
    case OrchestratorRunStatus.CancelFailed:
      return "🛑";
  }
};
