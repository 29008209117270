import { useContext, useMemo } from "react";
import {
  GetMyLocationsDocument,
  useGetMyLocationsQuery,
  useSetMyLocationMutation,
} from "../../../services/api/__generated__/backend_gateway-types";
import { PoDetailsEditLocationComponent } from "./component";
import { ApiContext } from "../../../contexts/api/context";
import { SysMessagesContext } from "../../../contexts/sys_messages/context";
import { SysMessageLevel } from "../../../contexts/sys_messages/type";
import { useLocation, useNavigate } from "react-router-dom";

export const PoDetailsEditLocationContainer = (props: { id: string }) => {
  const { id } = props;
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);

  const { data: locationsData, loading: locationsLoading } =
    useGetMyLocationsQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const location = useMemo(
    () => locationsData?.getMyLocations?.find((l) => l.id === id) || null,
    [locationsData, id]
  );

  const [setLocation, { loading: setLocationLoading }] =
    useSetMyLocationMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
      refetchQueries: [
        {
          query: GetMyLocationsDocument,
        },
      ],
    });

  const setLocationAddress = async (address: string, email: string) => {
    await setLocation({
      variables: {
        location: {
          id,
          address,
          address_override: address,
          email,
        },
      },
    });
  };

  const navigate = useNavigate();
  const loc = useLocation();
  const navigateBack = () => {
    const poPathFragments = loc.pathname.split("/");
    if (poPathFragments.length >= 3) {
      const poPath = `/${poPathFragments[1]}/${poPathFragments[2]}`;
      navigate(`${poPath}${loc.search ? "?" + loc.search : ""}`, {
        replace: true,
      });
    }
  };

  return (
    <PoDetailsEditLocationComponent
      loading={locationsLoading || setLocationLoading}
      navigateBack={navigateBack}
      location={location}
      setLocation={setLocationAddress}
    />
  );
};
