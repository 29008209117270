import { useCallback } from "react";
import {
  Box,
  Sheet,
  Select,
  Option,
  Typography,
  FormLabel,
  Input,
  Stack,
} from "@mui/joy";
import debounce from "lodash/debounce";

interface RunSelectionComponentProps {
  jobPlan: { dagsterJobName: string; jobName: string; step: number }[] | null;
  setSelectedRun: React.Dispatch<React.SetStateAction<string | null>>;
  setRunName: React.Dispatch<React.SetStateAction<string | null>>;
  setJobName: React.Dispatch<React.SetStateAction<string | null>>;
}

export const RunSelectionComponent = (props: RunSelectionComponentProps) => {
  const { jobPlan, setSelectedRun, setRunName, setJobName } = props;

  const handleSelectRun = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    const selectedJobPlan =
      jobPlan &&
      jobPlan.find(
        (option: { dagsterJobName: string; jobName: string; step: number }) =>
          option.dagsterJobName === newValue
      );
    const selectedJobName = selectedJobPlan?.jobName || null;
    setSelectedRun(newValue);
    setJobName(selectedJobName);
  };

  // const debouncedHandleInput = useCallback(
  //   debounce((value: string | null) => {
  //     setRunName(value);
  //   }, 500),
  //   []
  // );

  const debouncedSetRunName = debounce((value: string | null) => {
    setRunName(value);
  }, 100);

  const handleInputRunName = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    const newValue = event?.target.value || null;
    debouncedSetRunName(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Sheet
        sx={{
          overflow: "visible",
        }}
      >
        <Stack spacing={2}>
          <Typography level="h4" component="h1">
            <b>Control Panel</b>
          </Typography>
          <FormLabel>Select Job</FormLabel>
          <Select placeholder="Select a job..." onChange={handleSelectRun}>
            {jobPlan &&
              jobPlan.map((item: any) => {
                return (
                  <Option key={item.jobName} value={item.dagsterJobName}>
                    {item.jobName}
                  </Option>
                );
              })}
          </Select>
          <FormLabel>Enter Run Name</FormLabel>
          <Input
            placeholder="Enter a name for the run ..."
            onChange={handleInputRunName}
          />
        </Stack>
      </Sheet>
    </Box>
  );
};
