import { PageWithRightPane } from "../../frameworks/joy/layouts/page_with_right_pane";
import { AllRunsContainer } from "./container";

export const AllRunsPage = () => {
  return (
    <PageWithRightPane>
      <AllRunsContainer />
    </PageWithRightPane>
  );
};
