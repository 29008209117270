import { useLocation, useNavigate } from "react-router-dom";
import {
  PurchaseOrder,
  PurchaseOrderEdit,
  PurchaseOrderSku,
  useGetMyLocationsQuery,
  useGetMyLogoQuery,
  useGetMyVendorsQuery,
} from "../../../services/api/__generated__/backend_gateway-types";
import { PoDetailsControlPanelComponent } from "./component";
import { useContext, useMemo } from "react";
import { ApiContext } from "../../../contexts/api/context";
import { SysMessagesContext } from "../../../contexts/sys_messages/context";
import { SysMessageLevel } from "../../../contexts/sys_messages/type";

export const PoDetailsControlPanelContainer = (props: {
  id: string;
  po: PurchaseOrder | null;
  latestPe: PurchaseOrderEdit | null;
  latestPs: PurchaseOrderSku[];
}) => {
  const { po, latestPe } = props;
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);

  const { data: locationsData, loading: locationsLoading } =
    useGetMyLocationsQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const { data: vendorsData, loading: vendorsLoading } = useGetMyVendorsQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const { data: logoData, loading: logoLoading } = useGetMyLogoQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const location = useMemo(() => {
    return locationsData?.getMyLocations.find((l) => l.id === po?.location);
  }, [locationsData, po]);

  const vendor = useMemo(() => {
    return vendorsData?.getMyVendors.find((v) => v.id === po?.vendor);
  }, [vendorsData, po]);

  const navigate = useNavigate();
  const loc = useLocation();
  const poPathFragments = loc.pathname.split("/");
  const poPath = `/${poPathFragments[1]}/${poPathFragments[2]}`;

  const navigateToEditLocation = () => {
    navigate(
      `${poPath}/edit_location/${po?.location}${
        loc.search ? "?" + loc.search : ""
      }`,
      {
        replace: true,
      }
    );
  };
  const navigateToEditVendor = () => {
    navigate(
      `${poPath}/edit_vendor/${po?.vendor}${
        loc.search ? "?" + loc.search : ""
      }`,
      {
        replace: true,
      }
    );
  };
  const navigateToMessages = () => {
    navigate(`${loc.pathname}${loc.search ? "?" + loc.search : ""}`, {
      replace: true,
    });
  };
  const navigateToEditLogo = () => {
    navigate(`${poPath}/edit_logo${loc.search ? "?" + loc.search : ""}`, {
      replace: true,
    });
  };
  return (
    <PoDetailsControlPanelComponent
      loading={locationsLoading || vendorsLoading || logoLoading}
      po={po}
      latestPe={latestPe}
      location={location || null}
      vendor={vendor || null}
      logoImgB64={logoData?.getMyLogo?.img_b64 || null}
      logoText={logoData?.getMyLogo?.logo_name || null}
      navigateToEditLocation={navigateToEditLocation}
      navigateToEditVendor={navigateToEditVendor}
      navigateToEditLogo={navigateToEditLogo}
      navigateToMessages={navigateToMessages}
    />
  );
};
