import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../contexts/api/context";
import {
  EnginePreset,
  useGetMyEnginePresetsQuery,
  useMakeMyEnginePresetDefaultMutation,
} from "../../services/api/__generated__/backend_gateway-types";
import { DrawerContext } from "../../contexts/drawer/context";
import { WorkflowsContext } from "../../contexts/workflows/context";
import { PresetsComponent } from "./component";
import { SysMessageLevel } from "../../contexts/sys_messages/type";
import { SysMessagesContext } from "../../contexts/sys_messages/context";

export const PresetsContainer = () => {
  const { apiService } = useContext(ApiContext);
  const { isDisabled, isLoading, isTriggerInProgress, lastEngineRun } =
    useContext(WorkflowsContext);
  const { closeDrawer } = useContext(DrawerContext);
  const { addSystemMessage } = useContext(SysMessagesContext);
  const navigate = useNavigate();
  const { data: presetsData, loading: presetsLoading } =
    useGetMyEnginePresetsQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const [makeMyEnginePresetDefault, { loading: makeDefaultLoading }] =
    useMakeMyEnginePresetDefaultMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const navigateToEnginePresetsWithCreate = () => {
    closeDrawer();
    navigate("/engine_presets/new", { replace: true });
  };

  const navigateToEnginePresetsWithReplace = (id: number) => {
    closeDrawer();
    navigate(`/engine_presets/edit/${id}`, { replace: true });
  };

  const navigateToEnginePresetsWithRun = (id: number) => {
    closeDrawer();
    navigate(`/engine_presets/run/${id}`, { replace: true });
  };

  const enginePresets: EnginePreset[] | null =
    (presetsData?.getMyEnginePresets || null) as EnginePreset[] | null;

  const makePresetDefault = async (id: number) => {
    await makeMyEnginePresetDefault({
      variables: {
        enginePresetId: id,
      },
    });
    await apiService.getClient().refetchQueries({
      include: ["GetMyEnginePresets"],
    });
  };

  return (
    <PresetsComponent
      loading={presetsLoading || isLoading}
      enginePresets={enginePresets}
      navigateToEnginePresetsWithCreate={navigateToEnginePresetsWithCreate}
      navigateToEnginePresetsWithReplace={navigateToEnginePresetsWithReplace}
      // navigateToEnginePresetsWithRun={navigateToEnginePresetsWithRun}
      runPreset={navigateToEnginePresetsWithRun}
      runningInProgress={
        isTriggerInProgress || lastEngineRun?.status === "RUN_IN_PROGRESS"
      }
      runDisabled={isDisabled || isLoading}
      makePresetDefault={makePresetDefault}
      makeDefaultLoading={makeDefaultLoading}
    />
  );
};
