import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../../contexts/api/context";
import {
  OrchestratorRun,
  OrchestratorRunStatus,
  useCancelMyOrchestratorRunMutation,
  useGetMyCustomerQuery,
  useGetMyEnginePresetsQuery,
  useGetMyOrchestratorRunsQuery,
  useGetMyOrchestratorStepsQuery,
  useRerunMyOrchestratorStepMutation,
  useRetryMyOrchestratorRunMutation,
  useSetMyOrchestratorRunDescriptionMutation,
  useSetMyOrchestratorRunLockedMutation,
  useSetMyOrchestratorRunNameMutation,
  useStartMyOrchestratorRunMutation,
  useTriggerMyOrchestratorRunNextStepsMutation,
  useWhoAmIQuery,
} from "../../../services/api/__generated__/backend_gateway-types";
import { RunDetailsComponent } from "./component";
import { SysMessagesContext } from "../../../contexts/sys_messages/context";
import { SysMessageLevel } from "../../../contexts/sys_messages/type";
import { gql } from "@apollo/client";

export const RunDetailsContainer = (props: {
  inputId: string;
  workflowId: string;
  variablesId: string;
  runAttemptId: string;
}) => {
  const { inputId, workflowId, variablesId, runAttemptId } = props;
  const navigate = useNavigate();
  const { apiService, selectedCustomerId } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        console.error(error.message);
        // addSystemMessage({
        //   level: SysMessageLevel.DANGER,
        //   message: error.message!,
        // });
      }
    },
  });

  const customerId =
    selectedCustomerId || whoAmIData?.whoAmI?.customerId || null;

  const navigateBack = () => {
    navigate("/run_statuses", { replace: true });
  };

  const {
    data: runsData,
    loading: runsLoading,
    refetch: runsRefetch,
  } = useGetMyOrchestratorRunsQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        console.error(error.message);
        // addSystemMessage({
        //   level: SysMessageLevel.DANGER,
        //   message: error.message!,
        // });
      }
    },
  });

  const {
    data: stepsData,
    loading: stepsLoading,
    refetch: stepsRefetch,
  } = useGetMyOrchestratorStepsQuery({
    client: apiService.getClient(),
    variables: {
      runSettings: {
        inputId,
        workflowId,
        variablesId,
        runAttemptId: parseInt(runAttemptId),
      },
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        console.error(error.message);
        // addSystemMessage({
        //   level: SysMessageLevel.DANGER,
        //   message: error.message!,
        // });
      }
    },
  });

  const { data: customerData, loading: customerLoading } =
    useGetMyCustomerQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          console.error(error.message);
          // addSystemMessage({
          //   level: SysMessageLevel.DANGER,
          //   message: error.message!,
          // });
        }
      },
    });

  const [rerunMyOrchestratorStep, { loading: rerunMyOrchestratorStepLoading }] =
    useRerunMyOrchestratorStepMutation({
      client: apiService.getClient(),
    });

  const [rerunInProgress, setRerunInProgress] = useState<boolean>(false);
  const [rerunStepId, setRerunStepId] = useState<string | null>(null);

  const rerunStep = async (
    run: OrchestratorRun,
    stepId: string
  ): Promise<void> => {
    setRerunInProgress(true);
    setRerunStepId(stepId);
    rerunMyOrchestratorStep({
      variables: {
        runSettings: {
          inputId: run.runSettings.inputId,
          workflowId: run.runSettings.workflowId,
          variablesId: run.runSettings.variablesId,
          runAttemptId: run.runSettings.runAttemptId,
        },
        stepId,
      },
      onCompleted: () => {
        setTimeout(async () => {
          await runsRefetch();
          setRerunInProgress(false);
          setRerunStepId(null);
        }, 10000);
      },
      update(cache) {
        const updatedAt = new Date().toISOString();
        const id = JSON.stringify(run.runSettings);
        cache.writeFragment({
          id: `OrchestratorRun:${id}`,
          fragment: gql`
            fragment OrchestratorRunDetail on OrchestratorRun {
              id
              runSettings
              status
              createdAt
              updatedAt
            }
          `,
          data: {
            id,
            runSettings: run.runSettings,
            status: "RUN_IN_PROGRESS",
            updatedAt,
          },
        });
      },
      onError: (error) => {
        setRerunInProgress(false);
        setRerunStepId(null);
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });
  };

  const [setMyOrchestratorName, { loading: setMyOrchestratorNameLoading }] =
    useSetMyOrchestratorRunNameMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const setRunName = async (
    run: OrchestratorRun,
    name: string
  ): Promise<void> => {
    setMyOrchestratorName({
      variables: {
        runSettings: {
          inputId: run.runSettings.inputId,
          workflowId: run.runSettings.workflowId,
          variablesId: run.runSettings.variablesId,
          runAttemptId: run.runSettings.runAttemptId,
        },
        name,
      },
    });
  };

  const [
    setMyOrchestratorDescription,
    { loading: setMyOrchestratorDescriptionLoading },
  ] = useSetMyOrchestratorRunDescriptionMutation({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const setRunDescription = async (
    run: OrchestratorRun,
    description: string
  ): Promise<void> => {
    setMyOrchestratorDescription({
      variables: {
        runSettings: {
          inputId: run.runSettings.inputId,
          workflowId: run.runSettings.workflowId,
          variablesId: run.runSettings.variablesId,
          runAttemptId: run.runSettings.runAttemptId,
        },
        description,
      },
    });
  };

  const [setMyOrchestratorLocked, { loading: setMyOrchestratorLockedLoading }] =
    useSetMyOrchestratorRunLockedMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const setRunLocked = async (
    run: OrchestratorRun,
    locked: boolean
  ): Promise<void> => {
    setMyOrchestratorLocked({
      variables: {
        runSettings: {
          inputId: run.runSettings.inputId,
          workflowId: run.runSettings.workflowId,
          variablesId: run.runSettings.variablesId,
          runAttemptId: run.runSettings.runAttemptId,
        },
        locked,
      },
    });
  };

  const [startMyOrchestratorRun, { loading: startMyOrchestratorRunLoading }] =
    useStartMyOrchestratorRunMutation({
      client: apiService.getClient(),
    });

  const rerun = async (run: OrchestratorRun): Promise<void> => {
    setRerunInProgress(true);
    const { inputId, workflowId, variablesId } = run.runSettings;
    const useLockedOutputs = !!run.usesLockedInputId;
    const runAttemptId = run.runSettings.runAttemptId + 1;
    await startMyOrchestratorRun({
      variables: {
        runSettings: {
          inputId,
          workflowId,
          variablesId,
          runAttemptId,
          useLockedOutputs,
        },
      },
      onCompleted: () => {
        setTimeout(async () => {
          await runsRefetch();
          setRerunInProgress(false);
        }, 10000);
      },
      update(cache) {
        const updatedAt = new Date().toISOString();
        const id = JSON.stringify(run.runSettings);
        cache.writeFragment({
          id: `OrchestratorRun:${id}`,
          fragment: gql`
            fragment OrchestratorRunDetail on OrchestratorRun {
              id
              runSettings
              status
              createdAt
              updatedAt
            }
          `,
          data: {
            id,
            runSettings: run.runSettings,
            status: "RUN_IN_PROGRESS",
            updatedAt,
          },
        });
      },
      onError: (error) => {
        setRerunInProgress(false);
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });
    await apiService.getClient().refetchQueries({
      include: ["GetMyOrchestratorRuns"],
    });
    navigate(
      `/run_statuses/run_details/${inputId}/${workflowId}/${variablesId}/${runAttemptId}`,
      { replace: true }
    );
  };

  const [cancelMyOrchestratorRun, { loading: cancelMyOrchestratorRunLoading }] =
    useCancelMyOrchestratorRunMutation({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const cancelRun = async (run: OrchestratorRun): Promise<void> => {
    const { inputId, workflowId, variablesId, runAttemptId } = run.runSettings;
    await cancelMyOrchestratorRun({
      variables: {
        runSettings: {
          inputId,
          workflowId,
          variablesId,
          runAttemptId,
        },
      },
    });
    await apiService.getClient().refetchQueries({
      include: ["GetMyOrchestratorRuns"],
    });
  };

  const [
    triggerMyOrchestratorRunNextSteps,
    { loading: triggerMyOrchestratorRunNextStepsLoading },
  ] = useTriggerMyOrchestratorRunNextStepsMutation({
    client: apiService.getClient(),
  });

  const triggerNextSteps = async (run: OrchestratorRun): Promise<void> => {
    setRerunInProgress(true);
    const { inputId, workflowId, variablesId, runAttemptId } = run.runSettings;
    await triggerMyOrchestratorRunNextSteps({
      variables: {
        runSettings: {
          inputId,
          workflowId,
          variablesId,
          runAttemptId,
        },
      },
      onCompleted: () => {
        setTimeout(async () => {
          await runsRefetch();
          setRerunInProgress(false);
        }, 10000);
      },
      update(cache) {
        const updatedAt = new Date().toISOString();
        const id = JSON.stringify(run.runSettings);
        cache.writeFragment({
          id: `OrchestratorRun:${id}`,
          fragment: gql`
            fragment OrchestratorRunDetail on OrchestratorRun {
              id
              runSettings
              status
              createdAt
              updatedAt
            }
          `,
          data: {
            id,
            runSettings: run.runSettings,
            status: "RUN_IN_PROGRESS",
            updatedAt,
          },
        });
      },
      onError: (error) => {
        setRerunInProgress(false);
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });
    await apiService.getClient().refetchQueries({
      include: ["GetMyOrchestratorRuns"],
    });
  };

  const [retryMyOrchestratorRun, { loading: retryMyOrchestratorRunLoading }] =
    useRetryMyOrchestratorRunMutation({
      client: apiService.getClient(),
    });

  const retryRun = async (run: OrchestratorRun): Promise<void> => {
    const { inputId, workflowId, variablesId, runAttemptId } = run.runSettings;
    setRerunInProgress(true);
    await retryMyOrchestratorRun({
      variables: {
        runSettings: {
          inputId,
          workflowId,
          variablesId,
          runAttemptId,
        },
      },
      onCompleted: () => {
        setRerunInProgress(false);
      },
      update(cache) {
        const updatedAt = new Date().toISOString();
        const id = JSON.stringify(run.runSettings);
        cache.writeFragment({
          id: `OrchestratorRun:${id}`,
          fragment: gql`
            fragment OrchestratorRunDetail on OrchestratorRun {
              id
              runSettings
              status
              createdAt
              updatedAt
            }
          `,
          data: {
            id,
            runSettings: run.runSettings,
            status: "RUN_IN_PROGRESS",
            updatedAt,
          },
        });
      },
      onError: (error) => {
        setRerunInProgress(false);
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });
    await apiService.getClient().refetchQueries({
      include: ["GetMyOrchestratorRuns"],
    });
  };
  const { data: presetsData, loading: presetsLoading } =
    useGetMyEnginePresetsQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const run = runsData?.getMyOrchestratorRuns?.find(
    (r) =>
      r.runSettings.inputId === inputId &&
      r.runSettings.workflowId === workflowId &&
      r.runSettings.variablesId === variablesId &&
      r.runSettings.runAttemptId === parseInt(runAttemptId)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (run?.status === OrchestratorRunStatus.RunInProgress) {
        stepsRefetch();
      }
    }, 8000);
    if (run?.status !== OrchestratorRunStatus.RunInProgress) {
      setTimeout(stepsRefetch, 8000);
    }
    return () => clearInterval(intervalId);
  }, [stepsRefetch, run]);

  return (
    <RunDetailsComponent
      loading={
        runsLoading ||
        stepsLoading ||
        customerLoading ||
        rerunMyOrchestratorStepLoading ||
        setMyOrchestratorNameLoading ||
        setMyOrchestratorDescriptionLoading ||
        setMyOrchestratorLockedLoading ||
        startMyOrchestratorRunLoading ||
        cancelMyOrchestratorRunLoading ||
        triggerMyOrchestratorRunNextStepsLoading ||
        presetsLoading ||
        retryMyOrchestratorRunLoading ||
        whoAmILoading
      }
      run={run}
      steps={stepsData?.getMyOrchestratorSteps || []}
      navigateBack={navigateBack}
      hasFeatureStepOrchestratorStepDetails={
        customerData?.getMyCustomer?.features?.StepsStepDetails || false
      }
      rerunStep={rerunStep}
      setRunName={setRunName}
      setRunDescription={setRunDescription}
      setRunLocked={setRunLocked}
      rerun={rerun}
      cancelRun={cancelRun}
      triggerNextSteps={triggerNextSteps}
      retryRun={retryRun}
      rerunInProgress={rerunInProgress}
      rerunStepId={rerunStepId}
      presets={presetsData?.getMyEnginePresets || []}
      customerId={customerId}
    />
  );
};
