import { createContext } from "react";
import { RunTriggerType } from "./type";

const defaultValue: RunTriggerType = {
  isDisabled: true,
  isRunning: false,
  lastStartedAt: null,
  addNewRun: async () => {},
  addNewRunWithValues: async (
    orderToDeliveryDaysOffset: number | null,
    weightingMethod: string | null,
    orderSimulationEndDate: string | null,
    historicalDatePatterns: boolean | null,
    weightingWindowDays: number | null,
    bindToSKUOverrides: boolean | null,
    bindToForecastUpload: boolean | null,
    modifyExistingPos: boolean | null,
    dyeYieldLoss: number | null,
    sewYieldLoss: number | null,
    cutYieldLoss: number | null,
    weeksOfSupply: number | null,
    useBufferForecast: boolean | null,
    desiredMinWeeksOnHand: number | null,
    leadTimeInWeeks: number | null,
    assumedCustomerGrowth: number | null,
    excludeForecast: boolean | null,
    excludeYieldPlan: boolean | null,
    truckOrderToPackDays: number | null,
    onHoldToAvailableDays: number | null,
    productionStartToEndDays: number | null,
    darkRoastRuntime: number | null,
    mediumRoastRuntime: number | null,
    lightRoastRuntime: number | null,
    defaultChangeoverTime: number | null,
    decafChangeoverTime: number | null,
    turnOnProductionBatching: boolean | null,
    organicMillingNextProductionRun: string | null,
    cnpNextProductionRun: string | null,
    taraNextProductionRun: string | null,
    auroraNextProductionRun: string | null,
    firmWindowLookAhead: number | null,
    loadDemandOfRunId: number | null,
    presetId: number
  ) => {},
  runLogs: [],
  isLogsLoading: true,
  reset: () => {},
  lockedRun: null,
  setLockedRun: (runId: number) => {},
  setStartedAtOverrideToNow: () => {},
  deleteStartedAtOverrideWithDelay: async (delay?: number) => {},
};

export const RunTriggerContext = createContext(defaultValue);
