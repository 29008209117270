import { useColorScheme } from "@mui/joy/styles";
import { ReactNode, useEffect, useState } from "react";
import { AppearanceContext } from "./context";
import { AppearanceType } from "./type";

const { Provider } = AppearanceContext;

export const AppearanceProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  const [demoMode, setDemoMode] = useState(
    localStorage.getItem("demoMode") === "true"
  );
  const [modeString, setModeString] = useState(
    localStorage.getItem("modeString") || "light"
  );
  const {
    // mode,
    setMode,
  } = useColorScheme();

  useEffect(() => {
    localStorage.setItem("demoMode", demoMode ? "true" : "false");
  }, [demoMode]);

  useEffect(() => {
    localStorage.setItem("modeString", modeString);
    setMode(modeString as any);
  }, [modeString, setMode]);

  const value: AppearanceType = {
    demoMode,
    setDemoMode,
    modeString,
    setModeString,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
