import { ChangeEvent, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import FormControl from "@mui/joy/FormControl";
import LinearProgress from "@mui/joy/LinearProgress";
import Radio from "@mui/joy/Radio";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Check from "@mui/icons-material/Check";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import { EnginePreset } from "../../services/api/__generated__/backend_gateway-types";

export const PresetsComponent = (props: {
  loading: boolean;
  enginePresets: EnginePreset[] | null;
  navigateToEnginePresetsWithCreate: () => void;
  navigateToEnginePresetsWithReplace: (id: number) => void;
  // navigateToEnginePresetsWithRun: (id: number) => void;
  runPreset: (id: number) => void;
  runningInProgress: boolean;
  runDisabled: boolean;
  makePresetDefault: (id: number) => void;
  makeDefaultLoading: boolean;
}) => {
  const {
    loading,
    enginePresets,
    navigateToEnginePresetsWithCreate,
    navigateToEnginePresetsWithReplace,
    // navigateToEnginePresetsWithRun,
    runPreset,
    runningInProgress,
    runDisabled,
    makePresetDefault,
    makeDefaultLoading,
  } = props;
  const [selectedPresetId, setSelectedPresetId] = useState<number | null>(null);
  const presets = enginePresets ? [...enginePresets] : [];
  presets.sort((x, y) => (x.id > y.id ? 1 : -1));

  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
        backgroundColor: "atomic.highlightUnderlay",
      }}
    >
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 4,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <PageTitle>Run Parameter Presets</PageTitle>

        {loading && <LinearProgress />}

        {!loading && (
          <Sheet
            sx={{
              "--TableCxell-height": "40px",
              "--TableHeader-height": "64px",
              minHeight: "10vh",
              maxHeight: "70vh",
              overflow: "auto",
              background: (
                theme
              ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
                    linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
                    radial-gradient(
                      farthest-side at 50% 0,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                    ),
                    radial-gradient(
                        farthest-side at 50% 100%,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                      )
                      0 100%`,
              backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "local, local, scroll, scroll",
              backgroundPosition:
                "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
              backgroundColor: (theme) =>
                `${theme.palette.atomic.highlightBackground}`,
            }}
          >
            <Table
              aria-labelledby="Run parameter presets"
              stickyHeader
              hoverRow={true}
              // stripe="odd"
              borderAxis="xBetween"
              sx={{
                maxWidth: "800px",
                "--Table-headerUnderlineThickness": "1px",
                "--TableCell-paddingY": "4px",
                "--TableCell-paddingX": "8px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "50px",
                    }}
                  >
                    Select
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "100px",
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "200px",
                    }}
                  >
                    Description
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "50px",
                    }}
                  >
                    Default
                  </th>
                </tr>
              </thead>
              <tbody>
                {presets?.map((enginePreset) => (
                  <tr
                    key={enginePreset.id}
                    onClick={() =>
                      setSelectedPresetId(
                        enginePreset.id === undefined ? null : enginePreset.id!
                      )
                    }
                    aria-checked={enginePreset.id === selectedPresetId}
                    role="checkbox"
                  >
                    <td>
                      <Radio
                        checked={enginePreset.id === selectedPresetId}
                        value={enginePreset.id}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setSelectedPresetId(parseInt(event.target.value));
                        }}
                      />
                    </td>
                    <td>{enginePreset.name}</td>
                    <td>{enginePreset.description}</td>
                    <td>{enginePreset.default && <Check />}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        )}

        <FormControl
          sx={{
            mt: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Button
              disabled={
                loading ||
                selectedPresetId === null ||
                runningInProgress ||
                runDisabled
              }
              color="primary"
              variant="solid"
              onClick={() => runPreset(selectedPresetId!)}
            >
              {runningInProgress ? "Run in progress…" : "Run selected preset"}
            </Button>
            <ButtonGroup color="primary" variant="outlined">
              <Button
                disabled={loading || selectedPresetId === null}
                onClick={() =>
                  navigateToEnginePresetsWithReplace(selectedPresetId!)
                }
              >
                Edit selected preset
              </Button>
              <Button
                disabled={
                  loading || makeDefaultLoading || selectedPresetId === null
                }
                onClick={() => makePresetDefault(selectedPresetId!)}
              >
                Make default
              </Button>
            </ButtonGroup>
            <Button
              disabled={loading}
              color="primary"
              variant="outlined"
              onClick={navigateToEnginePresetsWithCreate}
            >
              Add new preset
            </Button>
          </Box>
        </FormControl>
      </Sheet>
    </PageFrame>
  );
};
