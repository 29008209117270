import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import LinearProgress from "@mui/joy/LinearProgress";
import Chip from "@mui/joy/Chip";
import Sheet from "@mui/joy/Sheet";
import Switch from "@mui/joy/Switch";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useMemo, useState } from "react";
import { RIGHT_PLANE_WIDTH } from "../../../frameworks/joy/layouts/drawer_layout/constants";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneTitle } from "../../../components/right_pane_title";
import {
  EnginePreset,
  OrchestratorRun,
  OrchestratorStep,
} from "../../../services/api/__generated__/backend_gateway-types";
import { runStatusToEmoji } from "../run_status_to_emoji";
import { runStatusToText } from "../run_status_to_text";
dayjs.extend(utc);

export const RunsDetailsComponent = (props: {
  loading: boolean;
  navigateBack: () => void;
  run: OrchestratorRun | null;
  steps: OrchestratorStep[];
  hasLockedRuns: boolean;
  setRunName: (name: string) => Promise<void>;
  setRunDescription: (description: string) => Promise<void>;
  setRunLocked: (locked: boolean) => Promise<void>;
  presets: EnginePreset[];
}) => {
  const {
    loading,
    navigateBack,
    run,
    hasLockedRuns,
    setRunName: storeRunName,
    setRunDescription: storeRunDescription,
    setRunLocked: storeRunLocked,
    presets,
  } = props;

  const [runName, setRunName] = useState<string>("");
  const [runDescription, setRunDescription] = useState<string>("");
  const [runLocked, setRunLocked] = useState<boolean>(false);

  useEffect(() => {
    if (run) {
      setRunName(run.name || "");
      setRunDescription(run.description || "");
      setRunLocked(run.locked || false);
    }
  }, [run]);

  const runParams = useMemo(() => {
    const runParamsAll = JSON.parse(run?.runParamsJson || "{}");
    const runParamsNotNull = {
      ...runParamsAll,
    };
    Object.keys(runParamsAll).forEach((k) => {
      if (
        runParamsAll[k] === null ||
        runParamsAll[k] === undefined ||
        k === "load_demand_of_run_id" ||
        k === "default"
      ) {
        delete runParamsNotNull[k];
      }
    });
    return runParamsNotNull;
  }, [run]);

  const presetName = useMemo(() => {
    const varId = run?.runSettings.variablesId || null;
    if (varId === null) {
      return "";
    }
    const preset = presets.find((pr) => pr.id === parseInt(varId));
    if (preset) {
      return `${preset.name || preset.id} ${preset.default ? "(default)" : ""}`;
    }
    return `${varId}`;
  }, [run, presets]);

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>Run details</RightPaneTitle>
      {loading && <LinearProgress />}
      <Box
        sx={{
          py: 2,
          display: "grid",
          gap: 4,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <FormControl>
          <FormLabel>Run name</FormLabel>
          <Input
            value={runName}
            placeholder="Name"
            onChange={(event) => setRunName(event.target.value)}
            endDecorator={
              <Button onClick={() => storeRunName(runName)}>Update</Button>
            }
            disabled={run === undefined}
          />
          <FormHelperText>
            Short name that will be used as a reference
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>Run description</FormLabel>
          <Input
            value={runDescription}
            placeholder="Description"
            onChange={(event) => setRunDescription(event.target.value)}
            endDecorator={
              <Button onClick={() => storeRunDescription(runDescription)}>
                Update
              </Button>
            }
            disabled={run === undefined}
          />
        </FormControl>
        {hasLockedRuns && (
          <FormControl>
            <FormLabel>Lock demand forecast</FormLabel>
            <Sheet variant="outlined" sx={{ p: 1, borderRadius: 6 }}>
              <Typography
                component="label"
                sx={{ width: "100%" }}
                startDecorator={
                  <Switch
                    checked={runLocked}
                    onChange={(event) => {
                      setRunLocked(event.target.checked);
                      storeRunLocked(event.target.checked);
                    }}
                  />
                }
              >
                {runLocked ? "Locked" : "Not locked"}
              </Typography>
            </Sheet>
            <FormHelperText>
              New runs will be able to re-use demand forecast of this run if
              enabled
            </FormHelperText>
          </FormControl>
        )}
      </Box>

      <Sheet
        variant="outlined"
        sx={{
          "--TableCxell-height": "40px",
          "--TableHeader-height": "40px",
          minHeight: "10vh",
          maxHeight: "70vh",
          overflow: "auto",
          background: (
            theme
          ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
                  linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
                  radial-gradient(
                    farthest-side at 50% 0,
                    ${theme.palette.atomic.fullShadowFade},
                    ${theme.palette.atomic.fullShadow}
                  ),
                  radial-gradient(
                      farthest-side at 50% 100%,
                    ${theme.palette.atomic.fullShadowFade},
                    ${theme.palette.atomic.fullShadow}
                    )
                    0 100%`,
          backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "local, local, scroll, scroll",
          backgroundPosition:
            "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
          backgroundColor: (theme) =>
            `${theme.palette.atomic.highlightBackground}`,
        }}
      >
        <Table
          aria-labelledby="Run details"
          stickyHeader
          hoverRow={true}
          // stripe="odd"
          borderAxis="xBetween"
          sx={{
            maxWidth: `${RIGHT_PLANE_WIDTH - 20}px`,
            "--Table-headerUnderlineThickness": "1px",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          {/* <thead>
            <tr>
              <th
                style={{
                  width: "120px",
                }}
              >
                Workflow
              </th>
              <th>{run ? run.runSettings.workflowId : ""}</th>
            </tr>
          </thead> */}
          <tbody>
            <tr>
              <td
                style={{
                  width: "120px",
                }}
              >
                Data fetch date
              </td>
              <td>
                {run
                  ? dayjs
                      .utc(run.runSettings.inputId)
                      .local()
                      .format("MM/DD/YYYY hh:mm:ss a")
                  : ""}
              </td>
            </tr>
            <tr>
              <td>Variable set id</td>
              <td>{presetName}</td>
            </tr>
            <tr>
              <td>Started at</td>
              <td>
                {run
                  ? dayjs
                      .utc(run.createdAt)
                      .local()
                      .format("MM/DD/YYYY hh:mm:ss a")
                  : ""}
              </td>
            </tr>
            {/* <tr>
              <td>Last updated at</td>
              <td>
                {run
                  ? dayjs
                      .utc(run.updatedAt)
                      .local()
                      .format("MM/DD/YYYY hh:mm:ss a")
                  : ""}
              </td>
            </tr> */}
            <tr>
              <td>Status</td>
              <td>
                {run ? (
                  <Chip size="sm">
                    {runStatusToEmoji(run.status)} {runStatusToText(run.status)}
                  </Chip>
                ) : (
                  ""
                )}
              </td>
            </tr>
            <tr>
              <td>Initiated by</td>
              <td>{run ? run.initiatedBy : ""}</td>
            </tr>
            {hasLockedRuns && (
              <tr>
                <td>Uses locked run</td>
                <td>
                  {run?.oldLockedRunId
                    ? `${run?.oldLockedRunId}`
                    : run?.usesLockedInputId && run?.usesLockedVariablesId
                    ? `${run?.usesLockedInputId} - ${run?.usesLockedVariablesId}`
                    : "No"}
                </td>
              </tr>
            )}
            <tr>
              <td>Run ID</td>
              <td>{run ? run.oldRunId : ""}</td>
            </tr>
          </tbody>
        </Table>
      </Sheet>

      <Sheet variant="outlined" sx={{ p: 1 }}>
        <Typography level="body-lg" sx={{ fontWeight: 700 }}>
          Variables used:
        </Typography>
        {Object.keys(runParams).map((k) => (
          <FormControl key={k}>
            <FormLabel>{k}</FormLabel>
            <Input value={runParams[k]} disabled variant="solid" />
          </FormControl>
        ))}
      </Sheet>
    </RightPaneFrame>
  );
};
