import { useContext } from "react";
import { ApiContext } from "../../contexts/api/context";
import { PlanOverviewComponent } from "./component";
import { useGetMyPowerBiReportItemsQuery } from "../../services/api/__generated__/backend_gateway-types";
import { AppearanceContext } from "../../contexts/appearance/context";

export const ReportContainer = (props: {
  workspaceId: string;
  reportId: string;
  pageId: string;
}) => {
  const { apiService } = useContext(ApiContext);
  const { workspaceId, reportId, pageId } = props;

  const { data, loading, error } = useGetMyPowerBiReportItemsQuery({
    client: apiService.getClient(),
    variables: {
      workspaceId,
    },
  });
  const { modeString } = useContext(AppearanceContext);

  if (error) {
    console.error(error);
  }

  const report = data?.getMyPowerBiReportItems?.reports.find(
    (r) => r.reportId === reportId
  );
  const embedUrl = report?.embedUrl || null;
  const page = report?.pages.find((p) => p.pageId === pageId);
  const token = data?.getMyPowerBiReportItems?.embedToken || null;
  if (page?.pageName) heap.track(`${page?.pageName} visited`);

  return (
    <PlanOverviewComponent
      loading={loading}
      title={page?.pageName || ""}
      embedUrl={embedUrl}
      token={token}
      reportId={reportId}
      pageId={pageId}
      modeString={modeString}
    />
  );
};
