import { useContext, useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";

import {
  useGetMyGoodchopGrossOdlJobPlansLazyQuery,
  useGetMyGoodchopGrossOdlJobsLazyQuery,
} from "../../../services/api/__generated__/backend_gateway-types";
import { ApiContext } from "../../../contexts/api/context";
import { FileUploaderComponent } from "./file_uploader_component";
import { DownloadTableComponent } from "./download_table_component";
import { PageFrame } from "../../../components/page_frame";
import { PageTitle } from "../../../components/page_title";

import "./index.css";
import { RunSelectionComponent } from "./run_selection_component";

export const GoodchopGrossOdlGenerationComponent = (props: {
  loading: boolean;
}) => {
  const { apiService } = useContext(ApiContext);
  const { loading } = props;

  const [selectedRun, setSelectedRun] = useState<string | null>(null);
  const [runName, setRunName] = useState<string | null>(null);
  const [jobName, setJobName] = useState<string | null>(null);
  const [jobPlan, setJobPlan] = useState<
    { dagsterJobName: string; jobName: string; step: number }[] | null
  >(null);

  const JOB_PLAN_ID = "step_plan_one";

  const [
    getAllGoodchopGrossOdlJobs,
    {
      data: getAllGoodchopGrossOdlJobsData,
      loading: getAllGoodchopGrossOdlJobsLoading,
    },
  ] = useGetMyGoodchopGrossOdlJobsLazyQuery({
    client: apiService.getClient(),
  });

  const [
    getAllGoodchopGrossOdlJobPlans,
    {
      data: getMyGoodchopGrossOdlJobPlansData,
      // loading: getMyGoodchopGrossOdlJobPlansLoading,
    },
  ] = useGetMyGoodchopGrossOdlJobPlansLazyQuery({
    client: apiService.getClient(),
  });

  useEffect(() => {
    const fetchAllGoodchopGrossOdlJobsPlans = async () => {
      const getAllGoodchopGrossOdlJobsPlansResponse =
        await getAllGoodchopGrossOdlJobPlans();
      await getAllGoodchopGrossOdlJobsPlansResponse.refetch();
    };

    fetchAllGoodchopGrossOdlJobsPlans();

    const selectedJobPlan =
      getMyGoodchopGrossOdlJobPlansData?.getMyGoodchopGrossOdlJobPlans?.find(
        (item) => item.jobPlanId === JOB_PLAN_ID
      );
    setJobPlan(selectedJobPlan?.jobPlan || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMyGoodchopGrossOdlJobPlansData]);

  useEffect(() => {
    const fetchAllGoodchopGrossOdlJobs = async () => {
      const getAllGoodchopGrossOdlJobsResponse =
        await getAllGoodchopGrossOdlJobs();
      await getAllGoodchopGrossOdlJobsResponse.refetch();
    };

    fetchAllGoodchopGrossOdlJobs();
    const intervalId = setInterval(() => {
      fetchAllGoodchopGrossOdlJobs();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [getAllGoodchopGrossOdlJobs]);

  return (
    <PageFrame
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 1,
        m: 3,
        minWidth: 0,
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <PageTitle>{"Gross ODL"}</PageTitle>

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      {!loading && (
        <>
          <Box
            sx={{
              width: "100%",
              overflow: "auto",
            }}
          >
            <Sheet
              variant="outlined"
              color="neutral"
              sx={{
                p: 1,
                minWidth: "0",
                maxWidth: "100%",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "100%", padding: "2%" }}>
                <RunSelectionComponent
                  jobPlan={jobPlan}
                  setJobName={setJobName}
                  setSelectedRun={setSelectedRun}
                  setRunName={setRunName}
                />
              </Box>
              <Box sx={{ width: "100%", padding: "2%" }}>
                <FileUploaderComponent
                  selectedRun={selectedRun}
                  jobName={jobName}
                  runName={runName}
                />
              </Box>
            </Sheet>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "50%",
              overflow: "auto",
            }}
          >
            <Sheet
              variant="outlined"
              color="neutral"
              sx={{
                overflow: "auto",
                minWidth: "0",
                maxWidth: "100%",
                borderRadius: "4px",
              }}
            >
              {getAllGoodchopGrossOdlJobsLoading ? (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <DownloadTableComponent
                  data={
                    getAllGoodchopGrossOdlJobsData?.getMyGoodchopGrossOdlJobs ||
                    []
                  }
                />
              )}
            </Sheet>
          </Box>
        </>
      )}
    </PageFrame>
  );
};
