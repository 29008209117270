import { useContext, useEffect } from "react";
import { DemandDiffControlComponent } from "./control_component";
import { DiffParams, SalesMeasure } from "../diff_params";
import { RunTriggerContext } from "../../../contexts/run_trigger/context";
import { PeriodType } from "../period_type";
import { DemandFromCsv } from "../demand_from_csv";
import { ApiContext } from "../../../contexts/api/context";
import { useGetMyRunComparisonRunIdsQuery } from "../../../services/api/__generated__/backend_gateway-types";

export const DemandDiffControlContainer = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
  demand0: DemandFromCsv;
  demand1: DemandFromCsv;
  pivotObject: any;
}) => {
  const { diffParams, updateDiffParams, pivotObject } = props;
  const { runLogs, isLogsLoading } = useContext(RunTriggerContext);

  const { apiService } = useContext(ApiContext);
  const {
    data: runIdsData,
    loading: runIdsLoading,
    error: runIdsError,
  } = useGetMyRunComparisonRunIdsQuery({
    client: apiService.getClient(),
  });

  const updateParams = (params: DiffParams) => {
    updateDiffParams({
      ...params,
      salesMeasure: params.salesMeasure || SalesMeasure.QUANTITY,
    });
  };

  const updateRuns = (params: DiffParams) => {
    const runLog0 = runLogs.find(
      (rl) => params.runId0 === (rl.id === null ? "" : `${rl.id}`)
    );
    const runLog1 = runLogs.find(
      (rl) => params.runId1 === (rl.id === null ? "" : `${rl.id}`)
    );
    updateDiffParams({
      ...params,
      ...(params.runId0 !== null &&
        params.runId1 === null && {
          minDate: runLog0?.startedAt ? new Date(runLog0?.startedAt) : null,
          maxDate: runLog0?.startedAt
            ? new Date(
                new Date(runLog0.startedAt || "1970-01-01").getUTCFullYear(),
                11,
                31
              )
            : null,
        }),
      ...(params.runId0 !== null &&
        params.runId1 !== null && {
          minDate: runLog1?.startedAt ? new Date(runLog1?.startedAt) : null,
          // NOTE: we don't have the run1 data yet, and for that reason the
          // maxDate is unknown yet. Setting the maxDate to null, and
          // setting it later with a useEffect.
          maxDate: new Date(
            Math.min(
              new Date(
                runLog0?.orderSimulationEndDate || "2025-12-31"
              ).getTime(),
              new Date(
                runLog1?.orderSimulationEndDate || "2025-12-31"
              ).getTime()
            )
          ),
          heatMap: params.heatMap === null ? true : params.heatMap,
        }),
      aggregatePeriodType: PeriodType.MONTH,
      salesMeasure: params.salesMeasure || SalesMeasure.QUANTITY,
    });
  };

  useEffect(() => {
    if (
      diffParams.maxDate === null &&
      diffParams.runId0 !== null &&
      diffParams.runId1 !== null
    ) {
      const runLog0 = runLogs.find(
        (rl) => diffParams.runId0 === (rl.id === null ? "" : `${rl.id}`)
      );
      const runLog1 = runLogs.find(
        (rl) => diffParams.runId1 === (rl.id === null ? "" : `${rl.id}`)
      );
      updateDiffParams({
        ...diffParams,
        maxDate: new Date(
          Math.min(
            new Date(runLog0?.orderSimulationEndDate || "2024-06-30").getTime(),
            new Date(runLog1?.orderSimulationEndDate || "2024-06-30").getTime()
          )
        ),
      });
    }
  }, [diffParams, runLogs, updateDiffParams]);

  const excelExport = () => {
    if (pivotObject?.excelExport) {
      pivotObject.excelExport({
        fileName: `atomic-run-comparison-${diffParams.runId0}-${diffParams.runId1}.xlsx`,
      });
    }
  };

  if (runIdsError) {
    console.error(
      "DemandDiffControlContainer: useGetMyLockedDemandOutputRunIdsQuery fetch error",
      runIdsError
    );
  }

  return (
    <DemandDiffControlComponent
      diffParams={diffParams}
      updateDiffParams={updateParams}
      updateRuns={updateRuns}
      loading={isLogsLoading || runIdsLoading}
      error={null}
      runLogs={runLogs.filter(
        (rl) => rl.id && runIdsData?.getMyRunComparisonRunIds?.includes(rl.id)
      )}
      excelExport={excelExport}
    />
  );
};
