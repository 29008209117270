import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import Sheet from "@mui/joy/Sheet";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import {
  PowerBiReportPages,
  UserComment,
} from "../../services/api/__generated__/backend_gateway-types";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import "./component.css";

export const PlanOfRecordComponent = (props: {
  loading: boolean;
  messagingLoading: boolean;
  reportId: string | null;
  embedUrl: string | null;
  embedToken: string | null;
  embedTokenId: string | null;
  embedTokenExpiration: string | null;
  sales: PowerBiReportPages | null;
  production: PowerBiReportPages | null;
  logistics: PowerBiReportPages | null;
  delivery: PowerBiReportPages | null;
  salesComments: UserComment[];
  productionComments: UserComment[];
  logisticsComments: UserComment[];
  deliveryComments: UserComment[];
  addCommentToSales: (message: string) => void;
  addCommentToProduction: (message: string) => void;
  addCommentToLogistics: (message: string) => void;
  addCommentToDelivery: (message: string) => void;
  salesNewComment: string;
  setSalesNewComment: React.Dispatch<React.SetStateAction<string>>;
  productionNewComment: string;
  setProductionNewComment: React.Dispatch<React.SetStateAction<string>>;
  logisticsNewComment: string;
  setLogisticsNewComment: React.Dispatch<React.SetStateAction<string>>;
  deliveryNewComment: string;
  setDeliveryNewComment: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    loading,
    reportId,
    embedUrl,
    embedToken,

    sales,
    salesComments,
    addCommentToSales,
    production,
    productionComments,
    addCommentToProduction,
    logistics,
    logisticsComments,
    addCommentToLogistics,
    delivery,
    deliveryComments,
    addCommentToDelivery,
    salesNewComment,
    setSalesNewComment,
    productionNewComment,
    setProductionNewComment,
    logisticsNewComment,
    setLogisticsNewComment,
    deliveryNewComment,
    setDeliveryNewComment,
  } = props;

  const blocks = [
    [
      "Sales",
      sales,
      salesComments,
      addCommentToSales,
      salesNewComment,
      setSalesNewComment,
    ],
    [
      "Production",
      production,
      productionComments,
      addCommentToProduction,
      productionNewComment,
      setProductionNewComment,
    ],
    [
      "Logistics",
      logistics,
      logisticsComments,
      addCommentToLogistics,
      logisticsNewComment,
      setLogisticsNewComment,
    ],
    [
      "Delivery",
      delivery,
      deliveryComments,
      addCommentToDelivery,
      deliveryNewComment,
      setDeliveryNewComment,
    ],
  ];
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
      }}
    >
      <PageTitle sx={{ m: 4 }}>Plan of Record</PageTitle>
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 0,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <Tabs sx={{ backgroundColor: "atomic.highlightBackground" }}>
          <TabList>
            {blocks.map((block, i) => {
              const [title] = block;
              return (
                <Tab variant="plain" color="neutral" key={i}>
                  {title as string}
                </Tab>
              );
            })}
          </TabList>
          {blocks.map((block, i) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, page, comments, addComment, newComment, setNewComment] =
              block;
            return (
              <TabPanel
                value={i}
                key={i}
                sx={{
                  bgcolor: "white",
                }}
              >
                {loading && (
                  <Box sx={{ pt: 2, width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
                {!loading && page && reportId && (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto",
                      gap: 2,
                    }}
                  >
                    <Box>
                      <PowerBIEmbed
                        cssClassName="report-container-por"
                        embedConfig={{
                          type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                          id: reportId,
                          embedUrl: embedUrl || undefined,
                          accessToken: embedToken || undefined,
                          pageName: (page as PowerBiReportPages).pageId,
                          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                          settings: {
                            panes: {
                              bookmarks: {
                                visible: false,
                              },
                              fields: {
                                expanded: false,
                              },
                              filters: {
                                expanded: false,
                                visible: false,
                              },
                              pageNavigation: {
                                visible: false,
                              },
                              selection: {
                                visible: true,
                              },
                              syncSlicers: {
                                visible: true,
                              },
                              visualizations: {
                                expanded: false,
                              },
                            },
                            background: models.BackgroundType.Transparent,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </TabPanel>
            );
          })}
        </Tabs>
      </Sheet>
    </PageFrame>
  );
};
