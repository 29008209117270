import { extendTheme } from "@mui/joy/styles";

declare module "@mui/joy/styles" {
  interface Palette {
    atomic: {
      highlightBackground: string;
      highlightBackgroundFade: string;
      highlightUnderlay: string;
      fullShadow: string;
      fullShadowFade: string;

      grey1: string;
      grey2: string;
      grey3: string;
      grey4: string;
      grey5: string;
      grey6: string;
      grey7: string;
      grey8: string;
      grey9: string;
      grey10: string;
      grey11: string;

      shadow: string;
      lightShadow: string;
      header: string;
      headerShadow: string;

      atomicBluePrimary: string;
      // atomicBlue900: string;
      // atomicBlue800: string;
      // atomicBlue700: string;
      // atomicBlue600: string;
      // atomicBlue500: string;
      // atomicBlue400: string;
      // atomicBlue300: string;
      // atomicBlue200: string;
      // atomicBlue100: string;

      redPrimary: string;
      red1: string;
      red2: string;
      red3: string;
      red4: string;

      greenPrimary: string;
      green1: string;
      green2: string;
      green3: string;
      green4: string;
    };
  }
}

export const theme = extendTheme({
  cssVarPrefix: "",

  fontFamily: {
    body: "var(--fontFamily-fallback)",
    display: "var(--fontFamily-fallback)",
    code: "Source Code Pro,ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace",
    fallback:
      '"Archivo", "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  colorSchemes: {
    light: {
      palette: {
        atomic: {
          highlightBackground: "white",
          highlightBackgroundFade: "rgba(255,255,255,0)",
          highlightUnderlay: "#F7F7F7",
          fullShadow: "rgba(0, 0, 0, 0)",
          fullShadowFade: "rgba(0, 0, 0, 0.12)",

          grey1: "#F7F7F7",
          grey2: "#EEEEEE",
          grey3: "#D8D8D8",
          grey4: "#CCCCCC",
          grey5: "#BBBBBB",
          grey6: "#AAAAAA",
          grey7: "#999999",
          grey8: "#666666",
          grey9: "#474747",
          grey10: "#333333",
          grey11: "#222222",

          shadow: "rgba(0,0,0,0.6)",
          lightShadow: "rgba(0,0,0,0.2)",
          header: "rgba(241, 241, 241, 0.6)",
          headerShadow: "rgba(0, 0, 0, 0.04)",

          atomicBluePrimary: "#3877F7",
          // atomicBlue900: "#4C85f8",
          // atomicBlue800: "#6092F9",
          // atomicBlue700: "#74A0F9",
          // atomicBlue600: "#88ADFA",
          // atomicBlue500: "#9CBBFB",
          // atomicBlue400: "#AFC9FC",
          // atomicBlue300: "#C3D6FD",
          // atomicBlue200: "#D7E4FD",
          // atomicBlue100: "#EBF1FE",

          redPrimary: "#F75539",
          red4: "#FA7861",
          red3: "#FA9887",
          red2: "#FCBAAE",
          red1: "#FCDCD7",

          greenPrimary: "#BBF739",
          green4: "#CAFA61",
          green3: "#D5FA87",
          green2: "#E4FCAE",
          green1: "#F0FCD7",
        },
      },
    },
    dark: {
      palette: {
        atomic: {
          highlightBackground: "#1a1a1a",
          highlightBackgroundFade: "rgba(26,26,26,0)",
          highlightUnderlay: "#141414",
          fullShadow: "rgba(0, 0, 0, 0)",
          fullShadowFade: "rgba(0, 0, 0, 0.9)",

          grey11: "#F2F2F2",
          grey10: "#EEEEEE",
          grey9: "#CDCDCD",
          grey8: "#ABABAB",
          grey7: "#999999",
          grey6: "#777777",
          grey5: "#474747",
          grey4: "#333333",
          grey3: "#2A2A2A",
          grey2: "#1F1F1F",
          grey1: "#141414",

          shadow: "rgba(0,0,0,0.6)",
          lightShadow: "rgba(255,255,255,0.1)",
          header: "rgba(0, 0, 0, 0.6)",
          headerShadow: "rgba(0, 0, 0, 0.12)",

          atomicBluePrimary: "#3877F7",
          // atomicBlue900: "#4C85f8",
          // atomicBlue800: "#6092F9",
          // atomicBlue700: "#74A0F9",
          // atomicBlue600: "#88ADFA",
          // atomicBlue500: "#9CBBFB",
          // atomicBlue400: "#AFC9FC",
          // atomicBlue300: "#C3D6FD",
          // atomicBlue200: "#D7E4FD",
          // atomicBlue100: "#EBF1FE",

          redPrimary: "#F75539",
          red4: "rgba(247, 85, 57, 0.6)",
          red3: "rgba(247, 85, 57, 0.4)",
          red2: "rgba(247, 85, 57, 0.2)",
          red1: "rgba(247, 85, 57, 0.1)",

          greenPrimary: "#BBF739",
          green4: "rgba(187, 247, 57, 0.6)",
          green3: "rgba(187, 247, 57, 0.4)",
          green2: "rgba(187, 247, 57, 0.2)",
          green1: "rgba(187, 247, 57, 0.1)",
        },
      },
    },
  },
});
