import { useContext, useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Chip from "@mui/joy/Chip";

import { TaskStatus, DagsterStatus } from "./enums";
import {
  Tooltip,
} from "@mui/joy";
import { ApiContext } from "../../../contexts/api/context";
import { useCreateMyGoodchopGrossOdlPresignDownloadUrlMutation } from "../../../services/api/__generated__/backend_gateway-types";

interface TableData {
  runId: string;
  dagsterRunId: string;
  job: string;
  jobPlanId: string;
  jobName: string;
  step: number;
  status: string;
  error: string;
  fileName: string;
  name: string;
  dateCreated: string;
  dateUpdated: string;
}

export const DownloadTableComponent = (props: any) => {
  const { data } = props;

  const { apiService } = useContext(ApiContext);

  const [createMyGoodchopGrossOdlPresignDownloadUrl] =
    useCreateMyGoodchopGrossOdlPresignDownloadUrlMutation({
      client: apiService.getClient(),
    });

  const [tableData, setTableData] = useState<TableData[]>();

  useEffect(() => {
    const newTableData: TableData[] = Object.values(data);
    newTableData.sort((a, b) => {
      return (
        new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime()
      );
    });
    setTableData(newTableData);
  }, [data]);

  const handleClickDownloadButton = async (S3filePath: string) => {
    try {
      const presignDownloadUrlResponse =
        await createMyGoodchopGrossOdlPresignDownloadUrl({
          variables: {
            filePath: S3filePath,
          },
        });
      const presignDownloadUrlResult =
        presignDownloadUrlResponse.data
          ?.createMyGoodchopGrossOdlPresignDownloadUrl;
      const presignDownloadUrl = presignDownloadUrlResult?.presignUrl;
      if (!data || !presignDownloadUrlResult || !presignDownloadUrl) {
        console.error("No data found");
      } else {
        window.location.href = presignDownloadUrl;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const chipStatusWarning = [
    TaskStatus.STARTED,
    TaskStatus.RUNNING_QUEUE,
    TaskStatus.RUNNING_TASK,
    TaskStatus.RUNNING_VALIDATION,
    TaskStatus.RUNNING_TRANSFORM,
    DagsterStatus.QUEUED,
    DagsterStatus.NOT_STARTED,
    DagsterStatus.STARTING,
    DagsterStatus.RUNNING,
    DagsterStatus.STARTED,
  ];
  const chipStatusFailiure = [TaskStatus.FAILED, DagsterStatus.FAILURE];
  const chipStatusSuccess = [TaskStatus.SUCCESS, DagsterStatus.SUCCESS];

  return (
    <Box sx={{ width: "100%" }}>
      <Sheet
        variant="outlined"
        sx={{
          border: "none",
          "--TableCell-height": "40px",
          "--TableHeader-height": "calc(1 * var(--TableCell-height))",
          "--Table-lastColumnWidth": "144px",
          "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
          "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
          overflow: "auto",
        }}
      >
        <Table
          borderAxis="bothBetween"
          stripe="odd"
          hoverRow
          sx={{
            "& tr > *:last-child": {
              position: "sticky",
              right: 0,
              bgcolor: "var(--TableCell-headBackground)",
            },
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "5%" }}>Row</th>
              <th style={{ width: "25%" }}>Name</th>
              <th style={{ width: "30%" }}>Status</th>
              <th style={{ width: "20%" }}>Job</th>
              <th style={{ width: "20%" }}>Date</th>
              <th
                aria-label="last"
                style={{ width: "var(--Table-lastColumnWidth)" }}
              >
                Download
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((row: TableData, idx: number) => {
              const dateObj = new Date(row.dateCreated);
              const year = dateObj.getFullYear();
              const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
              const day = String(dateObj.getDate()).padStart(2, "0");
              const hour = String(dateObj.getHours()).padStart(2, "0");
              const minute = String(dateObj.getMinutes()).padStart(2, "0");
              const seconds = String(dateObj.getSeconds()).padStart(2, "0");
              const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;

              const presignedUrl = `${row.jobPlanId}/${row.runId}/${row.job}/output/output_odl_file.xlsx`;

              return (
                <tr key={idx}>
                  <td>{tableData.length - idx}</td>
                  <td style={{ wordWrap: "break-word" }}>{row.name}</td>
                  <td style={{ wordWrap: "break-word" }}>
                    {chipStatusWarning.includes(row.status as TaskStatus) && (
                      <Chip
                        variant="solid"
                        color="warning"
                        sx={{ display: "flex" }}
                      >
                        {row.status}
                      </Chip>
                    )}
                    {chipStatusFailiure.includes(row.status as TaskStatus) && (
                      <>
                        <Tooltip title={row.error}>
                          <Chip
                            variant="solid"
                            color="danger"
                            sx={{ display: "flex" }}
                          >
                            {row.status}
                          </Chip>
                        </Tooltip>
                      </>
                    )}
                    {chipStatusSuccess.includes(row.status as TaskStatus) && (
                      <Chip
                        variant="solid"
                        color="success"
                        sx={{ display: "flex" }}
                      >
                        {row.status}
                      </Chip>
                    )}
                  </td>
                  <td style={{ wordWrap: "break-word" }}>{row.jobName}</td>
                  <td>{formattedDate}</td>
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={() => handleClickDownloadButton(presignedUrl)}
                        size="sm"
                        variant="solid"
                        color="success"
                        disabled={row.status !== "SUCCESS"}
                      >
                        Download
                      </Button>
                    </Box>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
    </Box>
  );
};
