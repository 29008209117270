import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import { useEffect, useState } from "react";

import { PresignedUrls, PresignedUrlsData } from "./entities";
import { IconButton, LinearProgress, Option, Select } from "@mui/joy";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Exact,
  GetMyGoodchopTransformedDataUrlsQuery,
  InputMaybe,
  Scalars,
} from "../../../services/api/__generated__/backend_gateway-types";
import { ApolloQueryResult } from "@apollo/client";

interface DownloadTableProps {
  tableData: PresignedUrls;
  loading: boolean;
  refetchTransformedDataUrls: (
    variables?:
      | Partial<
          Exact<{
            page?: InputMaybe<Scalars["Int"]["input"]>;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetMyGoodchopTransformedDataUrlsQuery>>;
}

export const DownloadTableComponent = (props: DownloadTableProps) => {
  const { tableData, loading, refetchTransformedDataUrls } = props;

  const [page, setPage] = useState(tableData?.page ?? 0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalPages, setTotalPages] = useState(tableData?.totalPages ?? 0);
  const [presignedUrls, setPresignedUrls] = useState<PresignedUrlsData[]>(
    tableData?.data ?? []
  );
  const [pageLoading, setPageLoading] = useState(false);

  // Reverse order of array
  useEffect(() => {
    if (tableData && tableData.data) {
      // const reversedArray = tableData.data.slice().reverse();
      setPresignedUrls(tableData.data);
    }

    if (tableData && tableData.totalPages) {
      setTotalPages(tableData.totalPages);
    }
  }, [tableData]);

  const handleChangePage = async (newPage: number) => {
    setPageLoading(true);
    await refetchTransformedDataUrls({ page: newPage });
    setPage(newPage);
    setPageLoading(false);
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Sheet
        variant="outlined"
        sx={{
          "--TableCell-height": "40px",
          "--TableHeader-height": "calc(1 * var(--TableCell-height))",
          "--Table-lastColumnWidth": "144px",
          "--TableRow-stripeBackground": "rgba(0 0 0 / 0.04)",
          "--TableRow-hoverBackground": "rgba(0 0 0 / 0.08)",
          overflow: "auto",
        }}
      >
        <Table
          borderAxis="bothBetween"
          stripe="odd"
          hoverRow
          sx={{
            "& tr > *:last-child": {
              position: "sticky",
              right: 0,
              bgcolor: "var(--TableCell-headBackground)",
            },
            tableLayout: "inherit",
          }}
        >
          <thead>
            <tr>
              <th>Row</th>
              <th>Size</th>
              <th>Date</th>
              <th
                aria-label="last"
                style={{ width: "var(--Table-lastColumnWidth)" }}
              ></th>
            </tr>
          </thead>

          {pageLoading ? (
            <tr>
              <td colSpan={4} style={{ padding: 0 }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <LinearProgress sx={{ width: "100%" }} />
                </Box>
              </td>
            </tr>
          ) : (
            <tbody>
              {presignedUrls.map((row: PresignedUrlsData, idx: number) => {
                const { url, size, lastModified } = row;

                const dateObj = new Date(lastModified);
                const year = dateObj.getFullYear();
                const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                const day = String(dateObj.getDate()).padStart(2, "0");
                const hour = String(dateObj.getHours()).padStart(2, "0");
                const minute = String(dateObj.getMinutes()).padStart(2, "0");
                const seconds = String(dateObj.getSeconds()).padStart(2, "0");
                const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;

                const rowSize = size;
                const formattedRowSize =
                  rowSize > 1000000
                    ? `${(rowSize / 1000000).toFixed(2)} MB`
                    : `${(rowSize / 1000).toFixed(2)} KB`;

                return (
                  <tr key={idx}>
                    <td style={{ width: "10%" }}>{page * 25 + idx + 1}</td>
                    <td style={{ width: "25%" }}>{formattedRowSize}</td>
                    <td style={{ width: "25%" }}>{formattedDate}</td>
                    <td style={{ width: "50%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <a href={url}>
                          <Button size="sm" variant="soft" color="success">
                            Download
                          </Button>
                        </a>
                      </Box>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}

          <tfoot>
            <tr>
              <td colSpan={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <Select
                    value={page}
                    onChange={(_, value) => handleChangePage(value!)}
                  >
                    {Array.from({ length: totalPages }, (_, idx) => (
                      <Option key={idx} value={idx}>
                        Page: {idx + 1} of {totalPages}
                      </Option>
                    ))}
                  </Select>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                      size="sm"
                      color="neutral"
                      variant="outlined"
                      disabled={page === 0}
                      onClick={() => handleChangePage(page - 1)}
                      sx={{ bgcolor: "background.surface" }}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                      size="sm"
                      color="neutral"
                      variant="outlined"
                      disabled={page + 1 >= totalPages}
                      onClick={() => handleChangePage(page + 1)}
                      sx={{ bgcolor: "background.surface" }}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Box>
                </Box>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Sheet>
    </Box>
  );
};
