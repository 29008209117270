import { useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";

import {
  Customer,
  Role,
} from "../../../services/api/__generated__/backend_gateway-types";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";

export const AddCustomerComponent = ({
  navigateBack,
  addCustomer,
  loading,
}: {
  navigateBack: () => void;
  addCustomer: (
    id: string,
    name: string,
    defaultRole: string
  ) => Promise<Customer | undefined>;
  loading: boolean;
}) => {
  const [customerId, setCustomerId] = useState<string>("");
  const [customerName, setCustomerName] = useState<string>("");
  const [defaultRole, setDefaultRole] = useState<string>(Role.Admin);

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>Add New Customer</RightPaneTitle>
      <MaterialEmbed>
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>Customer ID</FormLabel>
          <Input
            value={customerId}
            onChange={(event) => setCustomerId(event.target.value)}
          />
        </FormControl>
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>Customer Name</FormLabel>
          <Input
            value={customerName}
            onChange={(event) => setCustomerName(event.target.value)}
          />
        </FormControl>
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>Default Role</FormLabel>
          <Select
            value={defaultRole}
            onChange={(event, newValue) => setDefaultRole(newValue as string)}
          >
            {Object.values(Role)
              .filter((role) => role !== Role.None)
              .map((role) => (
                <Option key={role} value={role}>
                  {role}
                </Option>
              ))}
          </Select>
        </FormControl>

        <Button
          disabled={loading}
          sx={{ mt: 4 }}
          onClick={async () => {
            const createdCustomer = await addCustomer(
              customerId,
              customerName,
              defaultRole
            );
            if (createdCustomer) {
              navigateBack();
            }
          }}
        >
          {loading ? "Creating..." : "Create Customer"}
        </Button>
      </MaterialEmbed>
    </RightPaneFrame>
  );
};
