import Papa from "papaparse";

export type IOHData = {
  sku: string;
  location: string;
  unit_cogs: number;
  quantity: number;
  total_cogs: number;
  is_reorder_sku: number;
  is_sold_sku: number;
  sales_ct: number;
};

export type RunData = {
  runId: number;
  url: string;
  date: Date;
  data: IOHData[] | null;
};

export type RunDataLoading = {
  runId: number;
  loading: boolean;
};

export class InventoryOnHandData {
  private runData: RunData[] = [];
  private setDataLoading: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;

  constructor(
    setDataLoading: React.Dispatch<
      React.SetStateAction<{
        [key: string]: boolean;
      }>
    >
  ) {
    this.setDataLoading = setDataLoading;
  }

  setAndFetchDataUrls = async (runData: RunData[]) => {
    this.runData = runData;

    this.runData.forEach((r) => {
      this.setDataLoading((state) => ({
        ...state,
        [r.runId]: true,
      }));
      Papa.parse<IOHData>(r.url, {
        download: true,
        worker: true,
        header: true,
        dynamicTyping: true,
        step: (results) => {
          const {
            sku,
            location,
            unit_cogs,
            quantity,
            total_cogs,
            is_reorder_sku,
            is_sold_sku,
            sales_ct,
          } = results.data;
          r.data = r.data || [];
          if (sku !== undefined && sku !== null) {
            r.data?.push({
              sku,
              location,
              unit_cogs:
                unit_cogs === undefined ||
                (unit_cogs as unknown as string) === "NULL"
                  ? 0
                  : unit_cogs,
              quantity,
              total_cogs:
                total_cogs === undefined ||
                (total_cogs as unknown as string) === "NULL"
                  ? 0
                  : total_cogs,
              is_reorder_sku,
              is_sold_sku,
              sales_ct:
                sales_ct === undefined ||
                (sales_ct as unknown as string) === "NULL"
                  ? 1
                  : sales_ct,
            });
          }
        },
        error(error, file) {
          console.info("error", r, error, file);
        },
        complete: () => {
          this.setDataLoading((state) => ({
            ...state,
            [r.runId]: false,
          }));
        },
      });
    });
  };

  getData = () => this.runData;
}
