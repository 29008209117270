import { useContext } from "react";
import { DiffParams } from "../diff_params";
import { PlanningForecastAccuracyControlComponent } from "./component";
import { RunTriggerContext } from "../../../../contexts/run_trigger/context";

export const PLanningForecastAccuracyControlContainer = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
}) => {
  const { diffParams } = props;
  const { isLogsLoading } = useContext(RunTriggerContext);

  return (
    <PlanningForecastAccuracyControlComponent
      diffParams={diffParams}
      updateDiffParams={(params: DiffParams) => {}}
      updateRuns={(params: DiffParams) => {}}
      loading={isLogsLoading}
      error={null}
    />
  );
};
