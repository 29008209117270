import { Fragment } from "react";
import { PowerBiReportItems } from "../../services/api/__generated__/backend_gateway-types";
import LinearProgress from "@mui/joy/LinearProgress";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import Timeline from "@mui/icons-material/Timeline";
import Typography from "@mui/joy/Typography";

export const HeaderDrawerLinksToReportsComponent = (props: {
  powerBiReportItems: PowerBiReportItems | null;
  loading: boolean;
  navigateToReport: (reportId: string, pageId: string) => void;
  currentPathName: string | null;
  selectedReportId: string | null;
  selectedPageId: string | null;
}) => {
  const {
    powerBiReportItems,
    loading,
    navigateToReport,
    currentPathName,
    selectedReportId,
    selectedPageId,
  } = props;

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        powerBiReportItems?.reports?.map((report, i) => (
          <Fragment key={i}>
            <ListItem nested>
              {/* <ListItem>{report.reportName}</ListItem> */}
              <List>
                {[...report.pages]
                  .sort((p0, p1) => (p0.order < p1.order ? -1 : 1))
                  .map((page) => (
                    <ListItemButton
                      selected={
                        ["reports_with_report"].includes(
                          currentPathName || ""
                        ) &&
                        selectedReportId === report.reportId &&
                        selectedPageId === page.pageId
                      }
                      onClick={() =>
                        navigateToReport(report.reportId, page.pageId)
                      }
                      key={`${report.reportId}-${page.pageId}`}
                    >
                      <ListItemDecorator>
                        <Timeline />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography noWrap level="body-sm">
                          {page.pageName}
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  ))}
              </List>
            </ListItem>
          </Fragment>
        ))
      )}
    </>
  );
};