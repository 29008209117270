import { Page } from "../../frameworks/joy/layouts/page";
import { PlanOfRecordContainer } from "./container";

export const PlanOfRecordPage = () => {
  return (
    <Page>
      <PlanOfRecordContainer />
    </Page>
  );
};
