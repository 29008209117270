import { useContext, useState, useEffect } from "react";
import { ApiContext } from "../../contexts/api/context";
import { useWhoAmIQuery } from "../../services/api/__generated__/backend_gateway-types";
import { RunLoginDashComponent } from "./component";
// import { gcpConfig } from "../../configs/gcp";
import { RunData, LoginData } from "../../pages/internal_dashboards/component";

export const RunLoginDashContainer = () => {
  const { apiService } = useContext(ApiContext);
  const [runData, setRunData] = useState<RunData[]>([]);
  const [loginData, setLoginData] = useState<LoginData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { loading: whoAmILoading, error: whoAmIError } = useWhoAmIQuery({
    client: apiService.getClient(),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://us-central1-mercurial-song-396018.cloudfunctions.net/atomic-internal-dash-be"
        );
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        const jsonData = await response.json();
        console.log("jsonData", jsonData);
        setRunData(jsonData.run_data || []);
        setLoginData(jsonData.login_data || []);
      } catch (err) {
        setError(`${err as Error}`);
        console.error("RunLoginDashComponent: fetch error", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Ensure this hook runs only when the URL changes

  useEffect(() => {
    console.log("whoAmILoading or whoAmIError changed", {
      whoAmILoading,
      whoAmIError,
    });
  }, [whoAmILoading, whoAmIError]);

  if (whoAmIError) {
    console.error(
      "RunLoginDashComponent: useWhoAmIQuery fetch error",
      whoAmIError
    );
  }

  return (
    <RunLoginDashComponent
      loading={loading || whoAmILoading}
      data={{ run_data: runData, login_data: loginData }} // Passing both run_data and login_data
      error={error || whoAmIError}
    />
  );
};
