import { createContext } from "react";
import { AppearanceType } from "./type";

const defaultValue: AppearanceType = {
  demoMode: false,
  setDemoMode: () => {},
  modeString: "light",
  setModeString: () => {},
};

export const AppearanceContext = createContext(defaultValue);
