import { useContext } from "react";
import { HeaderDrawerLinksToReportsComponent } from "./header_component";
import { ApiContext } from "../../contexts/api/context";
import { DrawerContext } from "../../contexts/drawer/context";
import {
  PowerBiReportItems,
  useGetMyPowerBiReportItemsQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import {
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ROUTES } from "../../routes";

export const HeaderDrawerLinksToReportsContainer = (props: {
  workspaceId: string;
}) => {
  const { workspaceId } = props;
  const { apiService } = useContext(ApiContext);
  const { closeDrawer } = useContext(DrawerContext);
  const navigate = useNavigate();
  const { reportId: selectedReportId, pageId: selectedPageId } = useParams();

  const { data, loading, error } = useGetMyPowerBiReportItemsQuery({
    client: apiService.getClient(),
    variables: {
      workspaceId,
    },
  });

  if (error) {
    console.error(error.message);
  }

  const location = useLocation();
  const match = matchRoutes(Object.values(ROUTES), location);
  let currentPathName: string | null = null;
  if (match !== null) {
    const [{ route }] = match;
    const matchedPathEntry = Object.entries(ROUTES).find(
      (i) => i[1].path === route.path
    );
    currentPathName = matchedPathEntry ? matchedPathEntry[0] : null;
  }

  const navigateToReport = (reportId: string, pageId: string) => {
    closeDrawer();
    navigate(`/reports/${workspaceId}/${reportId}/${pageId}`, {
      replace: true,
    });
  };

  return (
    <HeaderDrawerLinksToReportsComponent
      loading={loading}
      powerBiReportItems={
        (data?.getMyPowerBiReportItems || null) as PowerBiReportItems | null
      }
      navigateToReport={navigateToReport}
      currentPathName={currentPathName}
      selectedReportId={selectedReportId || null}
      selectedPageId={selectedPageId || null}
    />
  );
};
