import { createContext } from "react";
import { ApiService } from "../../services/api/service";
import { ApiType } from "./type";

const defaultValue: ApiType = {
  apiService: ApiService.getInstance(),
  selectedCustomerId: null,
  setSelectedCustomerId: () => {},
};

export const ApiContext = createContext(defaultValue);
