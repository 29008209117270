import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import {
  ExcelExport,
  GroupingBar,
  FieldList,
  IDataSet,
  Inject,
  PivotViewComponent,
  VirtualScroll,
  IDataOptions,
} from "@syncfusion/ej2-react-pivotview";
import { DiffParams, SalesMeasure } from "./diff_params";
import { DemandWithSkuTags } from "./demand_from_csv";
import "./syncfusion.css";
import FormControl from "@mui/joy/FormControl";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { ReactNode } from "react";

export const ReviewAndPublishComponent = (props: {
  control: ReactNode;
  diffParams: DiffParams;
  hasLicence: boolean;
  loading: boolean;
  dataLoading: boolean;
  demand: DemandWithSkuTags[];
  maxRevenue: number | null;
  maxQuantity: number | null;
}) => {
  const {
    control,
    diffParams,
    hasLicence,
    loading,
    dataLoading,
    demand,
    maxRevenue,
    maxQuantity,
  } = props;

  const { salesMeasure } = diffParams;
  const dataSourceSettings: IDataOptions = {
    dataSource: demand as IDataSet[],
    columns: [
      { name: "order_placed_date", caption: "Date", showRemoveIcon: false },
    ],
    filters: [
      {
        name: "model",
        caption: "Model",
        showRemoveIcon: false,
      },
      {
        name: "drive_system",
        caption: "Drive System",
        showRemoveIcon: false,
      },
      {
        name: "battery",
        caption: "Battery",
        showRemoveIcon: false,
      },
      { name: "location", caption: "Location", showRemoveIcon: false },
      { name: "channel", caption: "Channel", showRemoveIcon: false },
      { name: "sku", caption: "SKU", showRemoveIcon: false },
    ],
    formatSettings: [
      {
        format: "###",
      },
    ],
    rows: [
      {
        name: "runId",
        caption: "Run",
        showRemoveIcon: false,
        showSortIcon: false,
        showFilterIcon: false,
        allowDragAndDrop: false,
      },
      //   {
      //     name: "product_category",
      //     caption: "Product Category",
      //     showRemoveIcon: true,
      //   },
      //   {
      //     name: "product_family",
      //     caption: "Product Family",
      //     showRemoveIcon: true,
      //   },
      //   { name: "product_type", caption: "Product Type", showRemoveIcon: true },
      //   { name: "location", caption: "Location", showRemoveIcon: true },
      { name: "channel", caption: "Channel", showRemoveIcon: true },
      { name: "sku", caption: "SKU", showRemoveIcon: true },
    ],
    values: [
      {
        name: salesMeasure === SalesMeasure.QUANTITY ? "quantity" : "revenue",
        caption:
          salesMeasure === SalesMeasure.QUANTITY ? "Quantity" : "Revenue",
        showRemoveIcon: false,
      },
    ],
    showSubTotals: false,
    showGrandTotals: false,
  };

  if (!hasLicence) {
    return null;
  }

  const pivotCss = `
    #PivotView {
        opacity: ${loading || dataLoading ? "0.4" : "1.0"};
    }
  `;

  return (
    <Box
      sx={{
        width: "100%",
        pt: 2,
        m: 0,
      }}
    >
      {control}
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 1,
          m: 2,
          mt: 0,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
        }}
      >
        <LinearProgress
          sx={{ display: loading || dataLoading ? "inherit" : "none" }}
        />
        <style>{pivotCss}</style>
        <PivotViewComponent
          id="PivotViewReviewAndPublish"
          enableVirtualization={true}
          dataSourceSettings={dataSourceSettings}
          height="480px"
          showGroupingBar={true}
          showFieldList={true}
          gridSettings={{ rowHeight: 25, allowResizing: false }}
          enableValueSorting={true}
          allowExcelExport={true}
          aggregateTypes={["Sum"]}
          //   actionComplete={actionComplete}
          //   enginePopulated={onEnginePopulated}
          //   fieldDrop={fieldDrop}
          cellTemplate={(args: any) => {
            const value = parseInt(args?.cellInfo?.value);
            if (
              diffParams.heatMap &&
              args?.cellInfo &&
              args?.cellInfo?.axis === "value" &&
              args?.cellInfo?.rowHeaders.indexOf("Run Delta (1 - 2)") > -1
            ) {
              if (
                (args?.cellInfo?.actualText === "quantity" ||
                  args?.cellInfo?.actualText === "revenue") &&
                !isNaN(value) &&
                maxQuantity !== null &&
                maxRevenue !== null &&
                args?.targetCell
              ) {
                const diffRatio = Math.abs(
                  args?.cellInfo?.actualText === "quantity"
                    ? value / maxQuantity
                    : value / maxRevenue
                );
                const diffBad = value > 0;
                // args?.cellInfo?.actualText === "quantity"
                //   ? value > 0
                //   : value < 0;
                if (diffRatio < 0.0000000001) {
                  args.targetCell.style = `background-color: white`;
                } else if (diffRatio < 0.1) {
                  args.targetCell.style = `background-color: ${
                    // diffBad ? colors.green200 : colors.red200
                    diffBad ? "atomic.green1" : "atomic.red1"
                  }`;
                } else if (diffRatio < 0.2) {
                  args.targetCell.style = `background-color: ${
                    // diffBad ? colors.green400 : colors.red400
                    diffBad ? "atomic.green2" : "atomic.red2"
                  }`;
                } else if (diffRatio < 0.3) {
                  args.targetCell.style = `background-color: ${
                    // diffBad ? colors.green600 : colors.red600
                    diffBad ? "atomic.green3" : "atomic.red3"
                  }`;
                } else if (diffRatio < 0.5) {
                  args.targetCell.style = `background-color: ${
                    // diffBad ? colors.green800 : colors.red800
                    diffBad ? "atomic.green4" : "atomic.red4"
                  }`;
                } else {
                  args.targetCell.style = `background-color: ${
                    // diffBad ? colors.greenPrimary : colors.redPrimary
                    diffBad ? "atomic.greenPrimary" : "atomic.redPrimary"
                  }`;
                }
              }
            }
            if (
              args?.cellInfo &&
              args?.cellInfo?.axis === "value" &&
              !isNaN(value) &&
              args?.targetCell
            ) {
              if (args?.cellInfo?.actualText === "quantity") {
                args.targetCell.innerText = `${value.toLocaleString()}`;
              } else if (args?.cellInfo?.actualText === "revenue") {
                args.targetCell.innerText = `$ ${value.toLocaleString()}`;
              }
            }
          }}
        >
          <Inject
            services={[GroupingBar, ExcelExport, VirtualScroll, FieldList]}
          />
        </PivotViewComponent>
      </Sheet>
      <FormControl
        sx={{
          m: 2,
          pt: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Button disabled={loading} color="primary" variant="solid" size="lg">
            Publish
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};
