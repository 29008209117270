import { OrchestratorStepStatus } from "../../services/api/__generated__/backend_gateway-types";

export const stepStatusToEmoji = (
  stepStatus: OrchestratorStepStatus
): string => {
  switch (stepStatus) {
    case OrchestratorStepStatus.ToExecute:
      return "⏳";
    case OrchestratorStepStatus.ExecutionQueued:
      return "⏳";
    case OrchestratorStepStatus.ExecutionStarted:
      return "🛠️";
    case OrchestratorStepStatus.ExecutionCompleted:
      return "✅";
    case OrchestratorStepStatus.ExecutionFailed:
      return "🛑";
  }
};
