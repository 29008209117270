import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import aws4 from "aws4-tiny";
import { apiConfig } from "../../configs/api";
import { auth0Config } from "../../configs/auth0";
import { IdToken } from "@auth0/auth0-react";

export class AuthenticationService {
  private static instance: AuthenticationService;
  // private static accessToken: string;
  private static idToken: IdToken | undefined;

  private constructor() {}

  public static getInstance(): AuthenticationService {
    if (!AuthenticationService.instance) {
      AuthenticationService.instance = new AuthenticationService();
    }
    return AuthenticationService.instance;
  }

  // public static setAccessToken(accessToken: string) {
  //   AuthenticationService.accessToken = accessToken;
  // }

  // public static getAccessToken() {
  //   return AuthenticationService.accessToken;
  // }

  public static setIdToken(idToken: IdToken | undefined) {
    AuthenticationService.idToken = idToken;
  }

  public static getIdToken() {
    return AuthenticationService.idToken;
  }

  getCredentials = async () => {
    const { cognitoIdentityPoolId, domain, cognitoRegion } = auth0Config;
    if (!AuthenticationService.idToken) {
      console.info("No ID token");
      return {};
    }
    const logins = {
      [domain]: AuthenticationService.idToken.__raw!,
    };
    const credentialsFunc = fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: cognitoRegion }),
      identityPoolId: cognitoIdentityPoolId,
      logins,
    });

    const cognitoidentity = new CognitoIdentityClient({
      region: cognitoRegion,
      credentials: credentialsFunc,
    });

    try {
      const credentials = await cognitoidentity.config.credentials();
      // {
      //    identityId: 'us-west-2:d393294b-ff23-43t6-d8s5-59876321457d',
      //    accessKeyId: 'ALALA2RZ7KTS7STD3VXLM',
      //    secretAccessKey: '/AldkSdt67saAddb6vddRIrs32adQCAo99XM6',
      //    sessionToken: 'IQoJb3JpZ2luX2VjEJj//////////...', // sessionToken cut for brevity
      //    expiration: 2022-07-17T08:58:10.000Z
      //  }
      return credentials;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  awsGraphqlFetch = async (
    uri: string,
    options: { body: string; headers: any }
  ) => {
    if (!AuthenticationService.idToken) {
      console.info("awsGraphqlFetch: no idToken set yet, ignoring fetch");
      return new Response();
      // '{"data":null,"errors":[{"message":"unauthenticated"}]}'
    }
    const { host, path, graphqlPath, region } = apiConfig;

    const request = {
      host,
      method: "POST",
      path: `${path}${graphqlPath}`,
      region,
      service: "execute-api",
      // NOTE: options.body is a json string
      body: options.body,
      headers: options.headers,
    };

    const credentials = await this.getCredentials();

    const signedRequest = aws4.sign(request, {
      ...credentials,
    });
    if (signedRequest?.headers && signedRequest?.headers["Host"]) {
      delete signedRequest.headers["Host"];
    }
    if (signedRequest?.headers && signedRequest?.headers["Content-Length"]) {
      delete signedRequest.headers["Content-Length"];
    }
    return fetch(uri, signedRequest as RequestInit);
  };
}
