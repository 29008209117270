import { FC, useContext } from "react";
import { ApiContext } from "../../contexts/api/context";
import {
  Customer,
  useGetAllCustomersQuery,
  useWhoAmIQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { CustomerSelectorComponent } from "./component";
import { RunTriggerContext } from "../../contexts/run_trigger/context";

interface CustomerSelectorContainerProps {
  navigateToRoot: () => void;
}

export const CustomerSelectorContainer: FC<CustomerSelectorContainerProps> = (
  props
) => {
  const { navigateToRoot } = props;
  const { selectedCustomerId, setSelectedCustomerId, apiService } =
    useContext(ApiContext);
  const { reset } = useContext(RunTriggerContext);

  const {
    data: whoAmIData,
    loading: whoAmILoading,
    error: whoAmIError,
  } = useWhoAmIQuery({
    client: apiService.getClient(),
  });

  const {
    data: getAllCompaniesData,
    loading: getAllCompaniesLoading,
    error: getAllCompaniesError,
  } = useGetAllCustomersQuery({
    client: apiService.getClient(),
  });

  if (whoAmIError || getAllCompaniesError) {
    console.error(whoAmIError, getAllCompaniesError);
  }

  const setCustomer = (customerId: string | null) => {
    setSelectedCustomerId(customerId);
    reset();
  };

  return (
    <CustomerSelectorComponent
      navigateToRoot={navigateToRoot}
      customers={(getAllCompaniesData?.getAllCustomers || []) as Customer[]}
      selectedCustomerId={selectedCustomerId}
      setSelectedCustomerId={setCustomer}
      role={whoAmIData?.whoAmI?.role || null}
      loading={whoAmILoading || getAllCompaniesLoading}
    />
  );
};
