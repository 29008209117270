import { useContext } from "react";
import { SystemMessagesComponent } from "./component";
import { SysMessagesContext } from "../../contexts/sys_messages/context";

export const SystemMessagesContainer = () => {
  const { systemMessages, removeSystemMessage } =
    useContext(SysMessagesContext);
  return (
    <SystemMessagesComponent
      systemMessages={systemMessages}
      removeSystemMessage={removeSystemMessage}
    />
  );
};
