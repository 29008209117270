import { Page } from "../../frameworks/joy/layouts/page";
import { OperationsContainer } from "./containter";

export const OperationsPage = () => {
  return (
    <Page>
      <OperationsContainer />
    </Page>
  );
};
