import { Page } from "../../frameworks/joy/layouts/page";
import { ValidationDashboardContainer } from "./container";

export const ValidationDashboardPage = () => {
  return (
    <Page>
      <ValidationDashboardContainer />
    </Page>
  );
};
