import { useContext, useState } from "react";
import { DiffParams, SalesMeasure, Segmentation } from "./diff_params";
import { ApiContext } from "../../../contexts/api/context";
import { SkuTagsFromCsv } from "./sku_hierarchy_from_csv";
import {
  useGetMyForecastAccuracyUrlQuery,
  useGetMySkuHierarchyUrlQuery,
} from "../../../services/api/__generated__/backend_gateway-types";
import { ForecastAccuracyFromCsv } from "./forecast_accuracy_from_csv";
import { PlanningForecastAccuracyTableContainer } from "./table/container";
import { PlanningForecastAccuracyComponent } from "./component";
import { PLanningForecastAccuracyControlContainer } from "./control/container";

const RUNID0 = "44";
const RUNID1 = "43";
const MINDATE = new Date("2024-04-01");
const MAXDATE = new Date("2024-04-30");

export const PlanningForecastAccuracyContainer = () => {
  const diffParams: DiffParams = {
    actualsEngineRunId: RUNID0,
    forecastEngineRunId: RUNID1,
    minDate: MINDATE,
    maxDate: MAXDATE,
    salesMeasure: SalesMeasure.QUANTITY,
    segmentation: Segmentation.CHANNEL,
  };

  const { apiService } = useContext(ApiContext);

  const [skuTagsLoading, setSkuTagsLoading] = useState<boolean>(false);
  const [skuTags, setSkuTags] = useState<SkuTagsFromCsv>(
    new SkuTagsFromCsv(setSkuTagsLoading)
  );
  const { loading: skuTagsURLLoading, error: skuTagsError } =
    useGetMySkuHierarchyUrlQuery({
      client: apiService.getClient(),
      variables: { engineRunId: parseInt(RUNID0) },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      onCompleted(data) {
        const url = data?.getMySkuHierarchyUrl;
        skuTags.setOnCompleteCallback(setSkuTags);
        if (url) {
          skuTags.fetch(url);
        }
      },
    });

  const [forecastAccuracyLoading, setForecastAccuracyLoading] =
    useState<boolean>(false);
  const [forecastAccuracy, setForecastAccuracy] =
    useState<ForecastAccuracyFromCsv>(
      new ForecastAccuracyFromCsv(setForecastAccuracyLoading)
    );
  const {
    loading: forecastAccuracyUrlLoading,
    error: forecastAccuracyUrlError,
  } = useGetMyForecastAccuracyUrlQuery({
    client: apiService.getClient(),
    variables: {
      actualsEngineRunId: parseInt(RUNID0),
      forecastEngineRunId: parseInt(RUNID1),
      minDate: MINDATE.toISOString().split("T")[0],
      maxDate: MAXDATE.toISOString().split("T")[0],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const url = data?.getMyForecastAccuracyUrl;
      forecastAccuracy.setOnCompleteCallback(setForecastAccuracy);
      if (url) {
        forecastAccuracy.fetch(url, true);
      }
    },
  });

  if (skuTagsError) {
    console.error(
      "PlanningForecastAccuracyContainer: useGetMySkuHierarchyUrlLazyQuery fetch error",
      skuTagsError
    );
  }

  if (forecastAccuracyUrlError) {
    console.error(
      "PlanningForecastAccuracyContainer: useGetMyForecastAccuracyUrlLazyQuery fetch error",
      forecastAccuracyUrlError
    );
  }
  return (
    <PlanningForecastAccuracyComponent
      table={
        <PlanningForecastAccuracyTableContainer
          diffParams={diffParams}
          forecastAccuracy={forecastAccuracy}
          forecastAccuracyLoading={forecastAccuracyLoading}
          skuTags={skuTags}
          skuTagsLoading={skuTagsLoading}
        />
      }
      control={
        <PLanningForecastAccuracyControlContainer
          diffParams={diffParams}
          updateDiffParams={(params: DiffParams) => {}}
        />
      }
      loading={
        skuTagsURLLoading ||
        skuTagsLoading ||
        forecastAccuracyLoading ||
        forecastAccuracyUrlLoading
      }
    />
  );
};
