import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../contexts/api/context";
import {
  PowerBi,
  useGetMyPowerBisQuery,
  useGetMyPowerBiReportItemsLazyQuery,
  PowerBiReportPages,
  useGetMyUserCommentsByAnchorLazyQuery,
  useGetMyUserApprovalsByAnchorLazyQuery,
  UserComment,
  useAddMyUserCommentMutation,
  useWhoAmIQuery,
  GetMyUserCommentsByAnchorDocument,
  GetMyUserCommentsByAnchorQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { ValidationDashboardComponent } from "./component";
import { RunTriggerContext } from "../../contexts/run_trigger/context";

export const ValidationDashboardContainer = () => {
  const { apiService } = useContext(ApiContext);

  const {
    data: getMyPowerBisData,
    loading: getMyPowerBisLoading,
    error: getMyPowerBisError,
  } = useGetMyPowerBisQuery({
    client: apiService.getClient(),
  });

  const [
    getMyPowerBiReportItems,
    {
      loading: getMyPowerBiReportItemsLoading,
      error: getMyPowerBiReportItemsError,
    },
  ] = useGetMyPowerBiReportItemsLazyQuery({
    client: apiService.getClient(),
  });

  const [
    getMyUserCommentsByAnchor,
    {
      loading: getMyUserCommentsByAnchorLoading,
      error: getMyUserCommentsByAnchorError,
    },
  ] = useGetMyUserCommentsByAnchorLazyQuery({
    client: apiService.getClient(),
  });

  const [
    getMyUserApprovalsByAnchor,
    {
      loading: getMyUserApprovalsByAnchorLoading,
      error: getMyUserApprovalsByAnchorError,
    },
  ] = useGetMyUserApprovalsByAnchorLazyQuery({
    client: apiService.getClient(),
  });

  const {
    data: whoAmIData,
    loading: whoAmILoading,
    error: whoAmIError,
  } = useWhoAmIQuery({
    client: apiService.getClient(),
  });

  const { runLogs, isLogsLoading } = useContext(RunTriggerContext);

  const [reportId, setReportId] = useState<string | null>(null);
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  const [embedToken, setEmbedToken] = useState<string | null>(null);
  const [embedTokenId, setEmbedTokenId] = useState<string | null>(null);
  const [embedTokenExpiration, setEmbedTokenExpiration] = useState<
    string | null
  >(null);

  const [grossProfitTracker, setGrossProfitTracker] =
    useState<PowerBiReportPages | null>(null);
  const [grossProfitTrackerComments, setGrossProfitTrackerComments] = useState<
    UserComment[]
  >([]);
  const [grossProfitTrackerNewComment, setGrossProfitTrackerNewComment] =
    useState<string>("");

  const [checks, setChecks] = useState<PowerBiReportPages | null>(null);
  const [checksComments, setChecksComments] = useState<UserComment[]>([]);
  const [checksNewComment, setChecksNewComment] = useState<string>("");

  const [matchRates, setMatchRates] = useState<PowerBiReportPages | null>(null);
  const [matchRatesComments, setMatchRatesComments] = useState<UserComment[]>(
    []
  );
  const [matchRatesNewComment, setMatchRatesNewComment] = useState<string>("");

  const [dayOfSupplyOnHand, setDayOfSupplyOnHand] =
    useState<PowerBiReportPages | null>(null);
  const [dayOfSupplyOnHandComments, setDayOfSupplyOnHandComments] = useState<
    UserComment[]
  >([]);
  const [dayOfSupplyOnHandNewComment, setDayOfSupplyOnHandNewComment] =
    useState<string>("");

  const [routingPlan, setRoutingPlan] = useState<PowerBiReportPages | null>(
    null
  );
  const [routingPlanComments, setRoutingPlanComments] = useState<UserComment[]>(
    []
  );
  const [routingPlanNewComment, setRoutingPlanNewComment] =
    useState<string>("");

  const [deliveryForecast, setDeliveryForecast] =
    useState<PowerBiReportPages | null>(null);
  const [deliveryForecastComments, setDeliveryForecastComments] = useState<
    UserComment[]
  >([]);
  const [deliveryForecastNewComment, setDeliveryForecastNewComment] =
    useState<string>("");

  const [constraintMaxBattery, setConstraintMaxBattery] =
    useState<PowerBiReportPages | null>(null);
  const [constraintMaxBatteryComments, setConstraintMaxBatteryComments] =
    useState<UserComment[]>([]);
  const [constraintMaxBatteryNewComment, setConstraintMaxBatteryNewComment] =
    useState<string>("");

  const [constraintCaliforniaRouting, setConstraintCaliforniaRouting] =
    useState<PowerBiReportPages | null>(null);
  const [
    constraintCaliforniaRoutingComments,
    setConstraintCaliforniaRoutingComments,
  ] = useState<UserComment[]>([]);
  const [
    constraintCaliforniaRoutingNewComment,
    setConstraintCaliforniaRoutingNewComment,
  ] = useState<string>("");

  const [inventoryOnHand, setInventoryOnHand] =
    useState<PowerBiReportPages | null>(null);
  const [inventoryOnHandComments, setInventoryOnHandComments] = useState<
    UserComment[]
  >([]);
  const [inventoryOnHandNewComment, setInventoryOnHandNewComment] =
    useState<string>("");

  const [
    addMyUserComment,
    { loading: addMyUserCommentLoading, error: addMyUserCommentError },
  ] = useAddMyUserCommentMutation({
    client: apiService.getClient(),
  });

  useEffect(() => {
    const myPowerBis = getMyPowerBisData?.getMyPowerBis || ([] as PowerBi[]);
    const workspaceId =
      myPowerBis.length > 0 ? myPowerBis[0].workspaceId : null;
    if (workspaceId !== null) {
      getMyPowerBiReportItems({
        variables: { workspaceId },
        onCompleted(data) {
          if (data?.getMyPowerBiReportItems === undefined) {
            return;
          }
          setEmbedToken(data!.getMyPowerBiReportItems!.embedToken);
          setEmbedTokenId(data!.getMyPowerBiReportItems!.embedTokenId);
          setEmbedTokenExpiration(
            data!.getMyPowerBiReportItems!.embedTokenExpiration
          );
          const { reports } = data!.getMyPowerBiReportItems!;
          const { pages, reportId, embedUrl } = reports[0];
          setReportId(reportId);
          setEmbedUrl(embedUrl);
          pages.forEach((page) => {
            if (page.pageName === "Gross Profit Tracker") {
              setGrossProfitTracker(page);
            } else if (page.pageName === "Checks") {
              setChecks(page);
            } else if (page.pageName === "Match Rates") {
              setMatchRates(page);
            } else if (page.pageName === "Days of Supply - Point in Time") {
              setDayOfSupplyOnHand(page);
            } else if (page.pageName === "Routing Plan") {
              setRoutingPlan(page);
            } else if (page.pageName === "Delivery Forecast") {
              setDeliveryForecast(page);
            } else if (page.pageName === "Max Battery Capacity") {
              setConstraintMaxBattery(page);
            } else if (page.pageName === "California Weekly Routing Capacity") {
              setConstraintCaliforniaRouting(page);
            } else if (page.pageName === "Inventory On Hand - Over Time") {
              setInventoryOnHand(page);
            }
          });
        },
      });
    }
  }, [getMyPowerBisData, getMyPowerBiReportItems]);

  const lastestRun = runLogs[runLogs.length - 1];

  const updateComments = (data: GetMyUserCommentsByAnchorQuery) => {
    setGrossProfitTrackerComments(() => []);
    setChecksComments(() => []);
    setMatchRatesComments(() => []);
    setDayOfSupplyOnHandComments(() => []);
    setRoutingPlanComments(() => []);
    setDeliveryForecastComments(() => []);
    data?.getMyUserCommentsByAnchor.forEach((comment) => {
      if (
        comment.anchorRef === `validations|grossProfitTracker|${lastestRun.id}`
      ) {
        setGrossProfitTrackerComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef === `validations|matchRates|${lastestRun.id}`
      ) {
        setMatchRatesComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef === `validations|dayOfSupplyOnHand|${lastestRun.id}`
      ) {
        setDayOfSupplyOnHandComments((last) => [...last, comment]);
      } else if (comment.anchorRef === `validations|checks|${lastestRun.id}`) {
        setChecksComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef === `validations|routingPlan|${lastestRun.id}`
      ) {
        setRoutingPlanComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef === `validations|deliveryForecast|${lastestRun.id}`
      ) {
        setDeliveryForecastComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef ===
        `validations|constraintMaxBattery|${lastestRun.id}`
      ) {
        setConstraintMaxBatteryComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef ===
        `validations|constraintCaliforniaRouting|${lastestRun.id}`
      ) {
        setConstraintCaliforniaRoutingComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef === `validations|inventoryOnHand|${lastestRun.id}`
      ) {
        setInventoryOnHandComments((last) => [...last, comment]);
      }
    });
  };

  useEffect(() => {
    if (!runLogs || runLogs.length < 1) {
      return;
    }
    const lastestRun = runLogs[runLogs.length - 1];
    getMyUserCommentsByAnchor({
      variables: {
        anchorRefs: [
          `validations|grossProfitTracker|${lastestRun.id}`,
          `validations|matchRates|${lastestRun.id}`,
          `validations|dayOfSupplyOnHand|${lastestRun.id}`,
          `validations|checks|${lastestRun.id}`,
          `validations|routingPlan|${lastestRun.id}`,
          `validations|deliveryForecast|${lastestRun.id}`,
          `validations|constraintMaxBattery|${lastestRun.id}`,
          `validations|constraintCaliforniaRouting|${lastestRun.id}`,
          `validations|inventoryOnHand|${lastestRun.id}`,
        ],
      },
      onCompleted(data) {
        data?.getMyUserCommentsByAnchor.forEach((comment) => {
          if (
            comment.anchorRef ===
            `validations|grossProfitTracker|${lastestRun.id}`
          ) {
            setGrossProfitTrackerComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef === `validations|matchRates|${lastestRun.id}`
          ) {
            setMatchRatesComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef ===
            `validations|dayOfSupplyOnHand|${lastestRun.id}`
          ) {
            setMatchRatesComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef === `validations|checks|${lastestRun.id}`
          ) {
            setChecksComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef === `validations|routingPlan|${lastestRun.id}`
          ) {
            setRoutingPlanComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef ===
            `validations|deliveryForecast|${lastestRun.id}`
          ) {
            setDeliveryForecastComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef ===
            `validations|constraintMaxBattery|${lastestRun.id}`
          ) {
            setConstraintMaxBatteryComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef ===
            `validations|constraintCaliforniaRouting|${lastestRun.id}`
          ) {
            setConstraintCaliforniaRoutingComments((last) => [
              ...last,
              comment,
            ]);
          } else if (
            comment.anchorRef === `validations|inventoryOnHand|${lastestRun.id}`
          ) {
            setInventoryOnHandComments((last) => [...last, comment]);
          }
        });
      },
    });
    getMyUserApprovalsByAnchor({
      variables: {
        anchorRefs: [
          `validations|grossProfitTracker|${lastestRun.id}`,
          `validations|matchRates|${lastestRun.id}`,
          `validations|dayOfSupplyOnHand|${lastestRun.id}`,
          `validations|checks|${lastestRun.id}`,
          `validations|routingPlan|${lastestRun.id}`,
          `validations|deliveryForecast|${lastestRun.id}`,
          `validations|constraintMaxBattery|${lastestRun.id}`,
          `validations|constraintCaliforniaRouting|${lastestRun.id}`,
          `validations|inventoryOnHand|${lastestRun.id}`,
        ],
      },
    });
  }, [runLogs, getMyUserCommentsByAnchor, getMyUserApprovalsByAnchor]);

  const addComment = (
    message: string,
    anchorRef: string,
    callback: () => void
  ) => {
    addMyUserComment({
      variables: {
        newComment: {
          anchorRef,
          message,
        },
      },
      optimisticResponse: {
        addMyUserComment: {
          __typename: "UserComment",
          id: `${anchorRef}|${new Date().toISOString()}|${
            whoAmIData?.whoAmI?.id
          }`,
          anchorRef,
          userId: `${whoAmIData?.whoAmI?.id}`,
          date: new Date().toISOString(),
          message,
        },
      },
      update(cache, { data }) {
        const { getMyUserCommentsByAnchor } = cache.readQuery({
          query: GetMyUserCommentsByAnchorDocument,
          variables: {
            anchorRefs: [
              `validations|grossProfitTracker|${lastestRun.id}`,
              `validations|matchRates|${lastestRun.id}`,
              `validations|dayOfSupplyOnHand|${lastestRun.id}`,
              `validations|checks|${lastestRun.id}`,
              `validations|routingPlan|${lastestRun.id}`,
              `validations|deliveryForecast|${lastestRun.id}`,
              `validations|constraintMaxBattery|${lastestRun.id}`,
              `validations|constraintCaliforniaRouting|${lastestRun.id}`,
              `validations|inventoryOnHand|${lastestRun.id}`,
            ],
          },
        }) as { getMyUserCommentsByAnchor: UserComment[] };
        const newComment: UserComment = data?.addMyUserComment as UserComment;
        if (
          getMyUserCommentsByAnchor.map((i) => i.id).includes(newComment.id)
        ) {
          return;
        }
        const newData = {
          getMyUserCommentsByAnchor: [
            ...getMyUserCommentsByAnchor,
            { ...(data?.addMyUserComment as UserComment) },
          ],
        };
        cache.writeQuery({
          query: GetMyUserCommentsByAnchorDocument,
          variables: {
            anchorRefs: [
              `validations|grossProfitTracker|${lastestRun.id}`,
              `validations|matchRates|${lastestRun.id}`,
              `validations|dayOfSupplyOnHand|${lastestRun.id}`,
              `validations|checks|${lastestRun.id}`,
              `validations|routingPlan|${lastestRun.id}`,
              `validations|deliveryForecast|${lastestRun.id}`,
              `validations|constraintMaxBattery|${lastestRun.id}`,
              `validations|constraintCaliforniaRouting|${lastestRun.id}`,
              `validations|inventoryOnHand|${lastestRun.id}`,
            ],
          },
          data: newData,
        });
        updateComments(newData);
      },
      onCompleted: callback,
    });
  };

  const addCommentToGrossProfitTracker = (message: string) => {
    addComment(message, `validations|grossProfitTracker|${lastestRun.id}`, () =>
      setMatchRatesNewComment("")
    );
  };

  const addCommentToMatchRates = (message: string) => {
    addComment(message, `validations|matchRates|${lastestRun.id}`, () =>
      setMatchRatesNewComment("")
    );
  };

  const addCommentToDayOfSupplyOnHand = (message: string) => {
    addComment(message, `validations|dayOfSupplyOnHand|${lastestRun.id}`, () =>
      setDayOfSupplyOnHandNewComment("")
    );
  };

  const addCommentToChecks = (message: string) => {
    addComment(message, `validations|checks|${lastestRun.id}`, () =>
      setChecksNewComment("")
    );
  };

  const addCommentToRoutingPlan = (message: string) => {
    addComment(message, `validations|routingPlan|${lastestRun.id}`, () =>
      setRoutingPlanNewComment("")
    );
  };

  const addCommentToDeliveryForecast = (message: string) => {
    addComment(message, `validations|deliveryForecast|${lastestRun.id}`, () =>
      setDeliveryForecastNewComment("")
    );
  };

  const addCommentToConstraintMaxBattery = (message: string) => {
    addComment(
      message,
      `validations|constraintMaxBattery|${lastestRun.id}`,
      () => setDeliveryForecastNewComment("")
    );
  };

  const addCommentToConstraintCaliforniaRouting = (message: string) => {
    addComment(
      message,
      `validations|constraintCaliforniaRouting|${lastestRun.id}`,
      () => setDeliveryForecastNewComment("")
    );
  };

  const addCommentToInventoryOnHand = (message: string) => {
    addComment(message, `validations|inventoryOnHand|${lastestRun.id}`, () =>
      setDeliveryForecastNewComment("")
    );
  };

  if (getMyPowerBisError) {
    console.error(getMyPowerBisError.message);
  }

  if (getMyPowerBiReportItemsError) {
    console.error(getMyPowerBiReportItemsError.message);
  }

  if (getMyUserCommentsByAnchorError) {
    console.error(getMyUserCommentsByAnchorError.message);
  }

  if (getMyUserApprovalsByAnchorError) {
    console.error(getMyUserApprovalsByAnchorError.message);
  }

  if (whoAmIError) {
    console.error(whoAmIError.message);
  }

  if (addMyUserCommentError) {
    console.error(addMyUserCommentError.message);
  }

  return (
    <ValidationDashboardComponent
      loading={getMyPowerBisLoading || getMyPowerBiReportItemsLoading}
      messagingLoading={
        isLogsLoading ||
        getMyUserCommentsByAnchorLoading ||
        getMyUserApprovalsByAnchorLoading ||
        whoAmILoading ||
        addMyUserCommentLoading
      }
      reportId={reportId}
      embedUrl={embedUrl}
      embedToken={embedToken}
      embedTokenId={embedTokenId}
      embedTokenExpiration={embedTokenExpiration}
      grossProfitTracker={grossProfitTracker}
      grossProfitTrackerComments={grossProfitTrackerComments}
      addCommentToGrossProfitTracker={addCommentToGrossProfitTracker}
      grossProfitTrackerNewComment={grossProfitTrackerNewComment}
      setGrossProfitTrackerNewComment={setGrossProfitTrackerNewComment}
      checks={checks}
      checksComments={checksComments}
      addCommentToChecks={addCommentToChecks}
      checksNewComment={checksNewComment}
      setChecksNewComment={setChecksNewComment}
      matchRates={matchRates}
      matchRatesComments={matchRatesComments}
      addCommentToMatchRates={addCommentToMatchRates}
      matchRatesNewComment={matchRatesNewComment}
      setMatchRatesNewComment={setMatchRatesNewComment}
      dayOfSupplyOnHand={dayOfSupplyOnHand}
      dayOfSupplyOnHandComments={dayOfSupplyOnHandComments}
      addCommentToDayOfSupplyOnHand={addCommentToDayOfSupplyOnHand}
      dayOfSupplyOnHandNewComment={dayOfSupplyOnHandNewComment}
      setDayOfSupplyOnHandNewComment={setDayOfSupplyOnHandNewComment}
      routingPlan={routingPlan}
      routingPlanComments={routingPlanComments}
      addCommentToRoutingPlan={addCommentToRoutingPlan}
      routingPlanNewComment={routingPlanNewComment}
      setRoutingPlanNewComment={setRoutingPlanNewComment}
      deliveryForecast={deliveryForecast}
      deliveryForecastComments={deliveryForecastComments}
      addCommentToDeliveryForecast={addCommentToDeliveryForecast}
      deliveryForecastNewComment={deliveryForecastNewComment}
      setDeliveryForecastNewComment={setDeliveryForecastNewComment}
      constraintMaxBattery={constraintMaxBattery}
      constraintMaxBatteryComments={constraintMaxBatteryComments}
      addCommentToConstraintMaxBattery={addCommentToConstraintMaxBattery}
      constraintMaxBatteryNewComment={constraintMaxBatteryNewComment}
      setConstraintMaxBatteryNewComment={setConstraintMaxBatteryNewComment}
      constraintCaliforniaRouting={constraintCaliforniaRouting}
      constraintCaliforniaRoutingComments={constraintCaliforniaRoutingComments}
      addCommentToConstraintCaliforniaRouting={
        addCommentToConstraintCaliforniaRouting
      }
      constraintCaliforniaRoutingNewComment={
        constraintCaliforniaRoutingNewComment
      }
      setConstraintCaliforniaRoutingNewComment={
        setConstraintCaliforniaRoutingNewComment
      }
      inventoryOnHand={inventoryOnHand}
      inventoryOnHandComments={inventoryOnHandComments}
      addCommentToInventoryOnHand={addCommentToInventoryOnHand}
      inventoryOnHandNewComment={inventoryOnHandNewComment}
      setInventoryOnHandNewComment={setInventoryOnHandNewComment}
    />
  );
};
