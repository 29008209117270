import { Page } from "../../frameworks/joy/layouts/page";
import { PlanningContainer } from "./container";

export const PlanningPage = () => {
  return (
    <Page>
      <PlanningContainer />
    </Page>
  );
};
