import { useEffect, useState } from "react";
import { DiffParams, SalesMeasure } from "../diff_params";
import { ForecastAccuracyFromCsv } from "../forecast_accuracy_from_csv";
import { SkuDelta } from "./sku_delta";
import { ForecastAccuracyAlertsComponent } from "./alerts_component";

export const ForecastAccuracyAlertsContainer = (props: {
  diffParams: DiffParams;
  forecastAccuracy: ForecastAccuracyFromCsv;
  forecastAccuracyLoading: boolean;
}) => {
  const { diffParams, forecastAccuracy, forecastAccuracyLoading } = props;
  const [skuDeltaData, setSkuDeltaData] = useState<SkuDelta[]>([]);

  useEffect(() => {
    if (forecastAccuracyLoading) {
      return;
    }
    const newSkuDeltaData: SkuDelta[] = [];

    forecastAccuracy.getItems().forEach((fa) => {
      const {
        sku,
        actual_quantity,
        actual_revenue,
        forecast_quantity,
        forecast_revenue,
      } = fa;
      const forecastValue =
        diffParams.salesMeasure === SalesMeasure.QUANTITY
          ? forecast_quantity
          : forecast_revenue || 0;
      const actualValue =
        diffParams.salesMeasure === SalesMeasure.QUANTITY
          ? actual_quantity
          : actual_revenue || 0;
      const deltaValue = forecastValue - actualValue;

      const item = newSkuDeltaData.find((d) => d.sku === sku);
      if (item) {
        item.forecastValue += forecastValue;
        item.actualValue += actualValue;
        item.deltaValue = item.forecastValue - item.actualValue;
      } else {
        newSkuDeltaData.push({
          sku,
          forecastValue,
          actualValue,
          deltaValue,
        } as SkuDelta);
      }
    });
    newSkuDeltaData.sort((a, b) => a.deltaValue - b.deltaValue);
    setSkuDeltaData(newSkuDeltaData);
  }, [diffParams, forecastAccuracyLoading, forecastAccuracy]);

  return (
    <ForecastAccuracyAlertsComponent
      loading={forecastAccuracyLoading}
      error={null}
      skuDelta={skuDeltaData}
    />
  );
};
