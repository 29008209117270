import { SalesMeasure } from "../diff_params";
import { TableRow } from "./table_row";
import { TableStructure } from "./table_structure";
import { TBodyComponent } from "./tbody_component";

export const TBodyContainer = (props: {
  data: Record<string, TableStructure>;
  totals: TableStructure;
  loading: boolean;
  toggleLabelExpansion: (label: string) => void;
  salesMeasure: SalesMeasure;
}) => {
  const { data, totals, loading, toggleLabelExpansion, salesMeasure } = props;

  const labels = Object.keys(data) as Array<string>;
  labels.sort();
  const dataFlattened: TableRow[] = [];
  labels.forEach((label) => {
    dataFlattened.push({
      key: label,
      label,
      forecastValue: data[label].forecastValue,
      actualValue: data[label].actualValue,
      deltaValue: data[label].deltaValue,
      correctValue: data[label].correctValue,
      wmape: data[label].wmape,
      hasChildren: true,
      isExpanded: data[label].isExpanded || false,
      isTotal: false,
    });
    if (data[label].isExpanded) {
      const skus = Object.keys(data[label].children || {}) as Array<string>;
      skus.sort();
      skus.forEach((sku) => {
        dataFlattened.push({
          key: `${label}${sku}`,
          label: sku,
          forecastValue: data[label].children![sku].forecastValue,
          actualValue: data[label].children![sku].actualValue,
          deltaValue: data[label].children![sku].deltaValue,
          correctValue: data[label].children![sku].correctValue,
          wmape: data[label].children![sku].wmape,
          hasChildren: false,
          isExpanded: false,
          isTotal: false,
        });
      });
    }
  });
  dataFlattened.push({
    key: "totals",
    label: "Totals",
    forecastValue: totals.forecastValue,
    actualValue: totals.actualValue,
    deltaValue: totals.deltaValue,
    correctValue: totals.correctValue,
    wmape: totals.wmape,
    hasChildren: false,
    isExpanded: false,
    isTotal: true,
  });

  return (
    <TBodyComponent
      loading={loading}
      error={null}
      dataFlattened={dataFlattened}
      toggleLabelExpansion={toggleLabelExpansion}
      salesMeasure={salesMeasure}
    />
  );
};
