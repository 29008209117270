import { ReactNode, useEffect, useState } from "react";
import { ApiService } from "../../services/api/service";
import { ApiContext } from "./context";
import { ApiType } from "./type";

const { Provider } = ApiContext;

export const ApiProvider = (props: { children: ReactNode }): JSX.Element => {
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    localStorage.getItem("selectedCustomerId")
  );

  useEffect(() => {
    const apiService = ApiService.getInstance();
    apiService.setSelectedCompanyId(selectedCustomerId);
  }, [selectedCustomerId]);

  const value: ApiType = {
    apiService: ApiService.getInstance(),
    selectedCustomerId,
    setSelectedCustomerId,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
