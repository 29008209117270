import { StepType } from "./step_type";

export const stepIdToType = (stepId: string): StepType => {
  if (stepId.split("|")[0] === "ENGINE") {
    return StepType.ENGINE;
  }
  if (stepId.split("|")[0] === "ENGINE_TRIGGER") {
    return StepType.ENGINE_TRIGGER;
  }
  if (stepId.split("|")[0] === "TRIGGER") {
    return StepType.TRIGGER;
  }
  return StepType.PY;
};
