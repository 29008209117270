import { useParams } from "react-router-dom";
import { RunsDetailsContainer } from "./container";

export const RunsDetailsPane = () => {
  const params = useParams();
  const { inputId, workflowId, variablesId, runAttemptId } = params;
  if ([inputId, workflowId, variablesId, runAttemptId].includes(undefined)) {
    return null;
  }
  return (
    <RunsDetailsContainer
      inputId={inputId!}
      workflowId={workflowId!}
      variablesId={variablesId!}
      runAttemptId={runAttemptId!}
    />
  );
};
