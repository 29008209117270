import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import { ReactNode } from "react";

export const PlanningForecastAccuracyTableComponent = (props: {
  children: ReactNode;
}) => {
  const { children } = props;

  return (
    <Sheet
      sx={{
        "--TableCell-height": "40px",
        "--TableHeader-height": "64px",
        minHeight: "10vh",
        maxHeight: "70vh",
        overflow: "auto",
        background: (
          theme
        ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
              linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
              radial-gradient(
                farthest-side at 50% 0,
                ${theme.palette.atomic.fullShadowFade},
                ${theme.palette.atomic.fullShadow}
              ),
              radial-gradient(
                  farthest-side at 50% 100%,
                ${theme.palette.atomic.fullShadowFade},
                ${theme.palette.atomic.fullShadow}
                )
                0 100%`,
        backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "local, local, scroll, scroll",
        backgroundPosition:
          "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
        backgroundColor: (theme) =>
          `${theme.palette.atomic.highlightBackground}`,
      }}
    >
      <Table
        aria-labelledby="Run logs"
        stickyHeader
        hoverRow={true}
        // stripe="odd"
        borderAxis="xBetween"
        sx={{
          width: "600px",
          "--Table-headerUnderlineThickness": "1px",
          "--TableCell-paddingY": "4px",
          "--TableCell-paddingX": "8px",
        }}
      >
        {children}
      </Table>
    </Sheet>
  );
};
