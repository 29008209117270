import {
  PurchaseOrder,
  PurchaseOrderEdit,
  PurchaseOrderSku,
} from "../../../services/api/__generated__/backend_gateway-types";
import { PoDetailsSkuListComponent } from "./component";
import { PoSkuRow } from "./row_type";

export const PoDetailsSkuListContainer = (props: {
  id: string;
  po: PurchaseOrder | null;
  latestPe: PurchaseOrderEdit | null;
  latestPs: PurchaseOrderSku[];
}) => {
  const { latestPs } = props;
  return (
    <PoDetailsSkuListComponent
      skus={latestPs
        .map((s) => {
          const quantity =
            s.quantity !== null && s.quantity !== undefined
              ? parseFloat(s.quantity)
              : 0;
          const unit_cost =
            s.unit_cost !== null && s.unit_cost !== undefined
              ? parseFloat(s.unit_cost)
              : null;
          return {
            id: s.sku,
            sku: s.sku,
            quantity,
            unit: s.unit,
            unit_cost: s.unit_cost || null,
            // cogs is unused, calculated by the grid
            cogs: unit_cost !== null ? quantity * unit_cost! : 0,
          } as PoSkuRow;
        })
        .filter((s) => s.quantity !== 0)}
    />
  );
};
