import { SyntheticEvent, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import LinearProgress from "@mui/joy/LinearProgress";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";
import {
  MyCustomer,
  EnginePreset,
} from "../../../services/api/__generated__/backend_gateway-types";
import { JoyCompatibleDatePicker } from "../../../components/date_picker";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";

export const EditEnginePresetComponent = (props: {
  preset: EnginePreset | null;
  loading: boolean;
  navigateBack: () => void;
  replacePreset: (
    presetName: string,
    presetDescription: string,
    orderToDeliveryDaysOffset: number | null,
    weightingMethod: string | null,
    orderSimulationEndDate: string | null,
    historicalDatePatterns: boolean | null,
    weightingWindowDays: number | null,
    bindToSKUOverrides: boolean | null,
    bindToForecastUpload: boolean | null,
    modifyExistingPos: boolean | null,
    dyeYieldLoss: number | null,
    sewYieldLoss: number | null,
    cutYieldLoss: number | null,
    weeksOfSupply: number | null,
    useBufferForecast: boolean | null,
    desiredMinWeeksOnHand: number | null,
    leadTimeInWeeks: number | null,
    assumedCustomerGrowth: number | null,
    excludeForecast: boolean | null,
    excludeYieldPlan: boolean | null,
    truckOrderToPackDays: number | null,
    onHoldToAvailableDays: number | null,
    productionStartToEndDays: number | null,
    darkRoastRuntime: number | null,
    mediumRoastRuntime: number | null,
    lightRoastRuntime: number | null,
    defaultChangeoverTime: number | null,
    decafChangeoverTime: number | null,
    turnOnProductionBatching: boolean | null,
    organicMillingNextProductionRun: string | null,
    cnpNextProductionRun: string | null,
    taraNextProductionRun: string | null,
    auroraNextProductionRun: string | null,
    firmWindowLookAhead: number | null
  ) => void;
  replaceLoading: boolean;
  customer: MyCustomer | null;
}) => {
  const {
    preset,
    loading,
    navigateBack,
    replacePreset,
    replaceLoading,
    customer,
  } = props;
  const [presetName, setPresetName] = useState<string>("Preset Name");
  const [presetDescription, setPresetDescription] =
    useState<string>("Description");

  const [orderToDeliveryDaysOffset, setOrderToDeliveryDaysOffset] = useState<
    number | null
  >(null);
  const [weightingMethod, setWeightingMethod] = useState<string | null>(null);
  const [orderSimulationEndDate, setOrderSimulationEndDate] = useState<
    string | null
  >(null);
  const [historicalDatePatterns, setHistoricalDatePatterns] = useState<
    boolean | null
  >(null);
  const [weightingWindowDays, setWeightingWindowDays] = useState<number | null>(
    null
  );
  const [bindToSKUOverrides, setBindToSKUOverrides] = useState<boolean | null>(
    null
  );
  const [bindToForecastUpload, setBindToForecastUpload] = useState<
    boolean | null
  >(null);
  const [modifyExistingPos, setModifyExistingPos] = useState<boolean | null>(
    null
  );
  const [dyeYieldLoss, setDyeYieldLoss] = useState<number | null>(null);
  const [sewYieldLoss, setSewYieldLoss] = useState<number | null>(null);
  const [cutYieldLoss, setCutYieldLoss] = useState<number | null>(null);
  const [weeksOfSupply, setWeeksOfSupply] = useState<number | null>(null);
  const [useBufferForecast, setUseBufferForecast] = useState<boolean | null>(
    null
  );
  const [desiredMinWeeksOnHand, setDesiredMinWeeksOnHand] = useState<
    number | null
  >(null);
  const [leadTimeInWeeks, setLeadTimeInWeeks] = useState<number | null>(null);
  const [assumedCustomerGrowth, setAssumedCustomerGrowth] = useState<
    number | null
  >(null);
  const [excludeForecast, setExcludeForecast] = useState<boolean | null>(null);
  const [excludeYieldPlan, setExcludeYieldPlan] = useState<boolean | null>(
    null
  );
  const [truckOrderToPackDays, setTruckOrderToPackDays] = useState<
    number | null
  >(null);
  const [onHoldToAvailableDays, setOnHoldToAvailableDays] = useState<
    number | null
  >(null);
  const [productionStartToEndDays, setProductionStartToEndDays] = useState<
    number | null
  >(null);
  const [darkRoastRuntime, setDarkRoastRuntime] = useState<number | null>(60);
  const [mediumRoastRuntime, setMediumRoastRuntime] = useState<number | null>(
    60
  );
  const [lightRoastRuntime, setLightRoastRuntime] = useState<number | null>(60);
  const [defaultChangeoverTime, setDefaultChangeoverTime] = useState<
    number | null
  >(15);
  const [decafChangeoverTime, setDecafChangeoverTime] = useState<number | null>(
    35
  );
  const [turnOnProductionBatching, setTurnOnProductionBatching] = useState<
    boolean | null
  >(null);
  const [organicMillingNextProductionRun, setOrganicMillingNextProductionRun] =
    useState<string | null>("2025-12-31");
  const [cnpNextProductionRun, setCnpNextProductionRun] = useState<
    string | null
  >("2025-12-31");
  const [taraNextProductionRun, setTaraNextProductionRun] = useState<
    string | null
  >("2025-12-31");
  const [auroraNextProductionRun, setAuroraNextProductionRun] = useState<
    string | null
  >("2025-12-31");
  const [firmWindowLookAhead, setFirmWindowLookAhead] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (preset) {
      setPresetName(preset.name || "");
      setPresetDescription(preset.description || "");
      setOrderToDeliveryDaysOffset(
        preset.orderToDeliveryDaysOffset === undefined
          ? null
          : preset.orderToDeliveryDaysOffset
      );
      setWeightingMethod(
        preset.weightingMethod === undefined ? null : preset.weightingMethod
      );
      setOrderSimulationEndDate(
        preset.orderSimulationEndDate === undefined
          ? null
          : preset.orderSimulationEndDate
      );
      setHistoricalDatePatterns(
        preset.historicalDatePatterns === undefined
          ? null
          : preset.historicalDatePatterns
      );
      setWeightingWindowDays(
        preset.weightingWindowDays === undefined
          ? null
          : preset.weightingWindowDays
      );
      setBindToSKUOverrides(
        preset.bindToSKUOverrides === undefined
          ? null
          : preset.bindToSKUOverrides
      );
      setBindToForecastUpload(
        preset.bindToForecastUpload === undefined
          ? null
          : preset.bindToForecastUpload
      );
      setModifyExistingPos(
        preset.modifyExistingPos === undefined ? null : preset.modifyExistingPos
      );
      setDyeYieldLoss(
        preset.dyeYieldLoss === undefined ? null : preset.dyeYieldLoss
      );
      setSewYieldLoss(
        preset.sewYieldLoss === undefined ? null : preset.sewYieldLoss
      );
      setCutYieldLoss(
        preset.cutYieldLoss === undefined ? null : preset.cutYieldLoss
      );
      setWeeksOfSupply(
        preset.weeksOfSupply === undefined ? null : preset.weeksOfSupply
      );
      setUseBufferForecast(
        preset.useBufferForecast === undefined ? null : preset.useBufferForecast
      );
      setDesiredMinWeeksOnHand(
        preset.desiredMinWeeksOnHand === undefined
          ? null
          : preset.desiredMinWeeksOnHand
      );
      setLeadTimeInWeeks(
        preset.leadTimeInWeeks === undefined ? null : preset.leadTimeInWeeks
      );
      setAssumedCustomerGrowth(
        preset.assumedCustomerGrowth === undefined
          ? null
          : preset.assumedCustomerGrowth
      );
      setExcludeForecast(
        preset.excludeForecast === undefined ? null : preset.excludeForecast
      );
      setExcludeYieldPlan(
        preset.excludeYieldPlan === undefined ? null : preset.excludeYieldPlan
      );
      setTruckOrderToPackDays(
        preset.truckOrderToPackDays === undefined
          ? null
          : preset.truckOrderToPackDays
      );
      setOnHoldToAvailableDays(
        preset.onHoldToAvailableDays === undefined
          ? null
          : preset.onHoldToAvailableDays
      );
      setProductionStartToEndDays(
        preset.productionStartToEndDays === undefined
          ? null
          : preset.productionStartToEndDays
      );
      setDarkRoastRuntime(
        preset.darkRoastRuntime === undefined ? null : preset.darkRoastRuntime
      );
      setMediumRoastRuntime(
        preset.mediumRoastRuntime === undefined
          ? null
          : preset.mediumRoastRuntime
      );
      setLightRoastRuntime(
        preset.lightRoastRuntime === undefined ? null : preset.lightRoastRuntime
      );
      setDefaultChangeoverTime(
        preset.defaultChangeoverTime === undefined
          ? null
          : preset.defaultChangeoverTime
      );
      setDecafChangeoverTime(
        preset.decafChangeoverTime === undefined
          ? null
          : preset.decafChangeoverTime
      );
      setTurnOnProductionBatching(
        preset.turnOnProductionBatching === undefined
          ? null
          : preset.turnOnProductionBatching
      );
      setOrganicMillingNextProductionRun(
        preset.organicMillingNextProductionRun === undefined
          ? null
          : preset.organicMillingNextProductionRun
      );
      setCnpNextProductionRun(
        preset.cnpNextProductionRun === undefined
          ? null
          : preset.cnpNextProductionRun
      );
      setTaraNextProductionRun(
        preset.taraNextProductionRun === undefined
          ? null
          : preset.taraNextProductionRun
      );
      setAuroraNextProductionRun(
        preset.auroraNextProductionRun === undefined
          ? null
          : preset.auroraNextProductionRun
      );
    }
  }, [preset]);
  const inputRef = useRef<HTMLInputElement | null>(null);

  if (!customer?.features?.EnginePresets) {
    return null;
  }

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>Edit Preset</RightPaneTitle>
      <Typography level="body-sm" sx={{ pb: 2 }}>
        Edit the values of the selected preset
      </Typography>

      {(loading || replaceLoading) && <LinearProgress />}

      {preset && (
        <MaterialEmbed>
          <Box
            sx={{
              py: 2,
              display: "grid",
              gap: 4,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <FormControl>
              <FormLabel>Preset name</FormLabel>
              <Input
                value={presetName}
                onChange={(event) => setPresetName(event.target.value)}
              />
              <FormHelperText>
                Short name that will appear when triggering runs
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Preset description</FormLabel>
              <Input
                value={presetDescription}
                onChange={(event) => setPresetDescription(event.target.value)}
              />
              <FormHelperText>Leave notes here for yourself</FormHelperText>
            </FormControl>
            {/* <FormControl>
                  <FormLabel>Order to Delivery Days Offset</FormLabel>
                  <Input
                    type="number"
                    slotProps={{
                      input: {
                        ref: inputRef,
                        min: 0,
                      },
                    }}
                    value={orderToDeliveryDaysOffset || 14}
                    onChange={(event) =>
                      setOrderToDeliveryDaysOffset(parseInt(event.target.value))
                    }
                  />
                  <FormHelperText>
                    The average delivery lead time in days to be used in the
                    simulation
                  </FormHelperText>
                </FormControl> */}
            {customer?.features?.VarWeightingMethod && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  SKU Assignment
                </Typography>
                <FormControl>
                  <FormLabel>Weighting Method</FormLabel>
                  <Select
                    placeholder="Select Weighting Method"
                    name="foo"
                    required
                    defaultValue="AVERAGE"
                    value={weightingMethod}
                    onChange={(
                      event: SyntheticEvent | null,
                      newValue: string | null
                    ) => setWeightingMethod(newValue || "AVERAGE")}
                    sx={{ minWidth: 200 }}
                  >
                    <Option value="AVERAGE">Average</Option>
                    <Option value="WEIGHTED_AVERAGE">Weighted Average</Option>
                  </Select>
                </FormControl>
              </>
            )}
            {customer?.features?.VarWeightingWindowDays && (
              <FormControl>
                <FormLabel>Weighting Window</FormLabel>
                <Input
                  type="number"
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={weightingWindowDays || 60}
                  onChange={(event) =>
                    setWeightingWindowDays(parseInt(event.target.value))
                  }
                />
                <FormHelperText>
                  Drill down DC forecast to SKU using average take rates over
                  the selected window of recent sales data
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarOrderSimulationEndDate && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Order Simulation End Date
                </Typography>
                <FormControl>
                  <JoyCompatibleDatePicker
                    value={dayjs(orderSimulationEndDate)}
                    disablePast
                    maxDate={dayjs("12/31/2026")}
                    onChange={(newNalue) =>
                      setOrderSimulationEndDate(newNalue?.toString() || "")
                    }
                  />
                </FormControl>
              </>
            )}
            {(customer?.features?.VarHistoricalDatePatterns ||
              customer?.features?.VarBindToSKUOverrides ||
              customer?.features?.VarBindToForecastUpload) && (
              <Typography level="body-lg" sx={{ pb: 1 }}>
                Order Simulation Feature Selection
              </Typography>
            )}
            {customer?.features?.VarHistoricalDatePatterns && (
              <FormControl>
                <FormLabel>Historical Date Patterns</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={historicalDatePatterns || false}
                      onChange={(event) =>
                        setHistoricalDatePatterns(event.target.checked)
                      }
                    />
                  }
                >
                  {historicalDatePatterns ? "Enabled" : "Disabled"}
                </Typography>
                <FormHelperText>
                  Use historical data to assign typical sales pattern by channel
                  and day of week
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarBindToSKUOverrides && (
              <FormControl>
                <FormLabel>Bind to SKU Overrides</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={bindToSKUOverrides || false}
                      onChange={(event) =>
                        setBindToSKUOverrides(event.target.checked)
                      }
                    />
                  }
                >
                  {bindToSKUOverrides ? "Enabled" : "Disabled"}
                </Typography>
                <FormHelperText>
                  Override take rates for any SKUs/DCs manually assigned in
                  input file.
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarBindToForecastUpload && (
              <FormControl>
                <FormLabel>Bind to Forecast Upload</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={bindToForecastUpload || false}
                      onChange={(event) =>
                        setBindToForecastUpload(event.target.checked)
                      }
                    />
                  }
                >
                  {bindToForecastUpload ? "Enabled" : "Disabled"}
                </Typography>
                <FormHelperText>
                  Scale order simulation to match top down forecast in upload
                  file.
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarModifyExistingPos && (
              <FormControl>
                <FormLabel>Allow for Modifications to Existing POs</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={modifyExistingPos || false}
                      onChange={(event) =>
                        setModifyExistingPos(event.target.checked)
                      }
                    />
                  }
                >
                  {modifyExistingPos ? "Enabled" : "Disabled"}
                </Typography>
                <FormHelperText>
                  Turn on/off modifications suggestions for existing inbound
                  purchase orders.
                </FormHelperText>
              </FormControl>
            )}
            {(customer?.features?.VarDyeYieldLoss ||
              customer?.features?.VarSewYieldLoss ||
              customer?.features?.VarCutYieldLoss) && (
              <Typography level="body-lg" sx={{ pb: 1 }}>
                Yield Loss Assumptions
              </Typography>
            )}
            {customer?.features?.VarDyeYieldLoss && (
              <FormControl>
                <FormLabel>Dye Yield Loss</FormLabel>
                <Input
                  type="number"
                  endDecorator={"%"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                      max: 100,
                    },
                  }}
                  value={dyeYieldLoss || 0}
                  onChange={(event) =>
                    setDyeYieldLoss(parseInt(event.target.value))
                  }
                />
                <FormHelperText>
                  Percent of products lost in the Dye stage
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarSewYieldLoss && (
              <FormControl>
                <FormLabel>Sew Yield Loss</FormLabel>
                <Input
                  type="number"
                  endDecorator={"%"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                      max: 100,
                    },
                  }}
                  value={sewYieldLoss || 0}
                  onChange={(event) =>
                    setSewYieldLoss(parseInt(event.target.value))
                  }
                />
                <FormHelperText>
                  Percent of products lost in the Sew stage
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarCutYieldLoss && (
              <FormControl>
                <FormLabel>Cut Yield Loss</FormLabel>
                <Input
                  type="number"
                  endDecorator={"%"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                      max: 100,
                    },
                  }}
                  value={cutYieldLoss || 0}
                  onChange={(event) =>
                    setCutYieldLoss(parseInt(event.target.value))
                  }
                />
                <FormHelperText>
                  Percent of products lost in the Cut stage
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarWeeksOfSupply && (
              <FormControl>
                <FormLabel>Weeks of Supply</FormLabel>
                <Input
                  type="number"
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={weeksOfSupply || 0}
                  onChange={(event) =>
                    setWeeksOfSupply(parseInt(event.target.value))
                  }
                />
                <FormHelperText>
                  Production planned to have finished goods ready X weeks before
                  customer end date
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarUseBufferForecast && (
              <FormControl>
                <FormLabel>Use Buffer Forecast</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={useBufferForecast || false}
                      onChange={(event) =>
                        setUseBufferForecast(event.target.checked)
                      }
                    />
                  }
                >
                  {useBufferForecast ? "Enabled" : "Disabled"}
                </Typography>
                <FormHelperText>
                  Turn on/off the Buffer assumptions in the forecast
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarDesiredMinWeeksOnHand && (
              <FormControl>
                <FormLabel>Desired Min Weeks On Hand</FormLabel>
                <Input
                  type="number"
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={desiredMinWeeksOnHand || 0}
                  onChange={(event) =>
                    setDesiredMinWeeksOnHand(parseInt(event.target.value))
                  }
                />
                <FormHelperText>
                  Minimum weeks on hand to target for purchase order
                  recommendations
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarLeadTimeInWeeks && (
              <FormControl>
                <FormLabel>Lead Time in Weeks</FormLabel>
                <Input
                  type="number"
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={leadTimeInWeeks || 0}
                  onChange={(event) =>
                    setLeadTimeInWeeks(parseInt(event.target.value))
                  }
                />
                <FormHelperText>
                  Assumed weeks from purchase order placement to arrival
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarAssumedCustomerGrowth && (
              <FormControl>
                <FormLabel>Assumed Customer Growth</FormLabel>
                <Input
                  type="number"
                  endDecorator={"%"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={assumedCustomerGrowth || 0}
                  onChange={(event) =>
                    setAssumedCustomerGrowth(parseFloat(event.target.value))
                  }
                />
                <FormHelperText>
                  Assumed monthly percentage growth in customer accounts to
                  apply in forecast
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarExcludeForecast && (
              <FormControl>
                <FormLabel>Exclude Forecast</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={excludeForecast || false}
                      onChange={(event) =>
                        setExcludeForecast(event.target.checked)
                      }
                    />
                  }
                >
                  {excludeForecast ? "Enabled" : "Disabled"}
                </Typography>
                <FormHelperText>Turn on/off forecast exclusion</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarExcludeYieldPlan && (
              <FormControl>
                <FormLabel>Exclude Yield Plan</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={excludeYieldPlan || false}
                      onChange={(event) =>
                        setExcludeYieldPlan(event.target.checked)
                      }
                    />
                  }
                >
                  {excludeYieldPlan ? "Enabled" : "Disabled"}
                </Typography>
                <FormHelperText>
                  Turn on/off yield plan exclusion
                </FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarTruckOrderToPackDays && (
              <FormControl>
                <FormLabel>Truck Order to Pack Days</FormLabel>
                <Input
                  type="number"
                  endDecorator={"days"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={truckOrderToPackDays || 0}
                  onChange={(event) =>
                    setTruckOrderToPackDays(parseInt(event.target.value))
                  }
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarOnHoldToAvailableDays && (
              <FormControl>
                <FormLabel>On Hold to Available Days</FormLabel>
                <Input
                  type="number"
                  endDecorator={"days"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={onHoldToAvailableDays || 0}
                  onChange={(event) =>
                    setOnHoldToAvailableDays(parseInt(event.target.value))
                  }
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarProductionStartToEndDays && (
              <FormControl>
                <FormLabel>Production Start to End Days</FormLabel>
                <Input
                  type="number"
                  endDecorator={"days"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={productionStartToEndDays || 0}
                  onChange={(event) =>
                    setProductionStartToEndDays(parseInt(event.target.value))
                  }
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarDarkRoastRuntime && (
              <FormControl>
                <FormLabel>Dark Roast Runtime</FormLabel>
                <Input
                  type="number"
                  endDecorator={"minutes"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={darkRoastRuntime || 0}
                  onChange={(event) =>
                    setDarkRoastRuntime(parseFloat(event.target.value))
                  }
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarMediumRoastRuntime && (
              <FormControl>
                <FormLabel>Medium Roast Runtime</FormLabel>
                <Input
                  type="number"
                  endDecorator={"minutes"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={mediumRoastRuntime || 0}
                  onChange={(event) =>
                    setMediumRoastRuntime(parseFloat(event.target.value))
                  }
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarLightRoastRuntime && (
              <FormControl>
                <FormLabel>Light Roast Runtime</FormLabel>
                <Input
                  type="number"
                  endDecorator={"minutes"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={lightRoastRuntime || 0}
                  onChange={(event) =>
                    setLightRoastRuntime(parseFloat(event.target.value))
                  }
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarDefaultChangeoverTime && (
              <FormControl>
                <FormLabel>Default Changeover Time</FormLabel>
                <Input
                  type="number"
                  endDecorator={"minutes"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={defaultChangeoverTime || 0}
                  onChange={(event) =>
                    setDefaultChangeoverTime(parseFloat(event.target.value))
                  }
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarDecafChangeoverTime && (
              <FormControl>
                <FormLabel>Decaf Changeover Time</FormLabel>
                <Input
                  type="number"
                  endDecorator={"minutes"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={decafChangeoverTime || 0}
                  onChange={(event) =>
                    setDecafChangeoverTime(parseFloat(event.target.value))
                  }
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarTurnOnProductionBatching && (
              <FormControl>
                <FormLabel>Production Batching</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={turnOnProductionBatching || false}
                      onChange={(event) =>
                        setTurnOnProductionBatching(event.target.checked)
                      }
                    />
                  }
                >
                  {turnOnProductionBatching ? "Enabled" : "Disabled"}
                </Typography>
                <FormHelperText>Enables production batching.</FormHelperText>
              </FormControl>
            )}
            {customer?.features?.VarOrganicMillingNextProductionRun && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Organic Milling Next Production Run
                </Typography>
                <FormControl>
                  <JoyCompatibleDatePicker
                    value={dayjs(organicMillingNextProductionRun)}
                    disablePast
                    maxDate={dayjs("12/31/2026")}
                    onChange={(newNalue) =>
                      setOrganicMillingNextProductionRun(
                        newNalue?.toString() || ""
                      )
                    }
                  />
                </FormControl>
              </>
            )}
            {customer?.features?.VarCnpNextProductionRun && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Cnp Next Production Run
                </Typography>
                <FormControl>
                  <JoyCompatibleDatePicker
                    value={dayjs(cnpNextProductionRun)}
                    disablePast
                    maxDate={dayjs("12/31/2026")}
                    onChange={(newNalue) =>
                      setCnpNextProductionRun(newNalue?.toString() || "")
                    }
                  />
                </FormControl>
              </>
            )}
            {customer?.features?.VarTaraNextProductionRun && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Tara Next Production Run
                </Typography>
                <FormControl>
                  <JoyCompatibleDatePicker
                    value={dayjs(taraNextProductionRun)}
                    disablePast
                    maxDate={dayjs("12/31/2026")}
                    onChange={(newNalue) =>
                      setTaraNextProductionRun(newNalue?.toString() || "")
                    }
                  />
                </FormControl>
              </>
            )}
            {customer?.features?.VarAauroraNextProductionRun && (
              <>
                <Typography level="body-md" sx={{ pb: 0.5 }}>
                  Aaurora Next Production Run
                </Typography>
                <FormControl>
                  <JoyCompatibleDatePicker
                    value={dayjs(auroraNextProductionRun)}
                    disablePast
                    maxDate={dayjs("12/31/2026")}
                    onChange={(newNalue) =>
                      setAuroraNextProductionRun(newNalue?.toString() || "")
                    }
                  />
                </FormControl>
              </>
            )}
            {customer?.features?.VarFirmWindowLookAhead && (
              <FormControl>
                <FormLabel>Firm Window Look Ahead Days</FormLabel>
                <Input
                  type="number"
                  endDecorator={"days"}
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  value={firmWindowLookAhead || 0}
                  onChange={(event) =>
                    setFirmWindowLookAhead(parseInt(event.target.value))
                  }
                />
                <FormHelperText>Time period</FormHelperText>
              </FormControl>
            )}
          </Box>

          <FormControl
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() =>
                replacePreset(
                  presetName,
                  presetDescription,
                  customer?.features?.VarOrderToDeliveryDaysOffset
                    ? orderToDeliveryDaysOffset
                    : null,
                  customer?.features?.VarWeightingMethod
                    ? weightingMethod
                    : null,
                  customer?.features?.VarOrderSimulationEndDate
                    ? (orderSimulationEndDate &&
                        dayjs(orderSimulationEndDate).format("YYYY-MM-DD")) ||
                        null
                    : null,
                  customer?.features?.VarHistoricalDatePatterns
                    ? historicalDatePatterns
                    : null,
                  customer?.features?.VarWeightingWindowDays
                    ? weightingWindowDays
                    : null,
                  customer?.features?.VarBindToSKUOverrides
                    ? bindToSKUOverrides
                    : null,
                  customer?.features?.VarBindToForecastUpload
                    ? bindToForecastUpload
                    : null,
                  customer?.features?.VarModifyExistingPos
                    ? modifyExistingPos
                    : null,
                  customer?.features?.VarDyeYieldLoss ? dyeYieldLoss : null,
                  customer?.features?.VarSewYieldLoss ? sewYieldLoss : null,
                  customer?.features?.VarCutYieldLoss ? cutYieldLoss : null,
                  customer?.features?.VarWeeksOfSupply ? weeksOfSupply : null,
                  customer?.features?.VarUseBufferForecast
                    ? useBufferForecast
                    : null,
                  customer?.features?.VarDesiredMinWeeksOnHand
                    ? desiredMinWeeksOnHand
                    : null,
                  customer?.features?.VarLeadTimeInWeeks
                    ? leadTimeInWeeks
                    : null,
                  customer?.features?.VarAssumedCustomerGrowth
                    ? assumedCustomerGrowth
                    : null,
                  customer?.features?.VarExcludeForecast
                    ? excludeForecast
                    : null,
                  customer?.features?.VarExcludeYieldPlan
                    ? excludeYieldPlan
                    : null,
                  customer?.features?.VarTruckOrderToPackDays
                    ? truckOrderToPackDays
                    : null,
                  customer?.features?.VarOnHoldToAvailableDays
                    ? onHoldToAvailableDays
                    : null,
                  customer?.features?.VarProductionStartToEndDays
                    ? productionStartToEndDays
                    : null,
                  customer?.features?.VarDarkRoastRuntime
                    ? darkRoastRuntime
                    : null,
                  customer?.features?.VarMediumRoastRuntime
                    ? mediumRoastRuntime
                    : null,
                  customer?.features?.VarLightRoastRuntime
                    ? lightRoastRuntime
                    : null,
                  customer?.features?.VarDefaultChangeoverTime
                    ? defaultChangeoverTime
                    : null,
                  customer?.features?.VarDecafChangeoverTime
                    ? decafChangeoverTime
                    : null,
                  customer?.features?.VarTurnOnProductionBatching
                    ? turnOnProductionBatching
                    : null,
                  customer?.features?.VarOrganicMillingNextProductionRun
                    ? (organicMillingNextProductionRun &&
                        dayjs(organicMillingNextProductionRun).format(
                          "YYYY-MM-DD"
                        )) ||
                        null
                    : null,
                  customer?.features?.VarCnpNextProductionRun
                    ? (cnpNextProductionRun &&
                        dayjs(cnpNextProductionRun).format("YYYY-MM-DD")) ||
                        null
                    : null,
                  customer?.features?.VarTaraNextProductionRun
                    ? (taraNextProductionRun &&
                        dayjs(taraNextProductionRun).format("YYYY-MM-DD")) ||
                        null
                    : null,
                  customer?.features?.VarAauroraNextProductionRun
                    ? (auroraNextProductionRun &&
                        dayjs(auroraNextProductionRun).format("YYYY-MM-DD")) ||
                        null
                    : null,
                  customer?.features?.VarFirmWindowLookAhead
                    ? firmWindowLookAhead
                    : null
                )
              }
              disabled={loading || replaceLoading || !preset}
            >
              Update Preset
            </Button>
          </FormControl>
        </MaterialEmbed>
      )}
    </RightPaneFrame>
  );
};
