import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import { useRef } from "react";

export const GenerateChannelForecastComponent = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <Box
      sx={{
        width: "100%",
        pt: 2,
      }}
    >
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>
            <ListItemContent>
              <Typography level="title-md" sx={{ display: "block" }}>
                Store
              </Typography>
            </ListItemContent>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexFlow: "column nowrap",
                alignItems: "stretch",
                justifyContent: "center",
                minWidth: "200px",
                maxWidth: "600px",
                my: 3,
              }}
            >
              <List>
                <ListItem>
                  <Avatar color="primary">
                    <CloudUploadIcon />
                  </Avatar>
                  <ListItemContent>
                    <Typography level="title-md" sx={{ display: "block" }}>
                      Upload Store Expansion Plan
                    </Typography>
                    <Typography level="body-sm" sx={{ display: "block" }}>
                      Latest upload: 05/01/2024
                    </Typography>
                  </ListItemContent>
                </ListItem>
              </List>

              <FormControl>
                <FormLabel>
                  Gross orders per store per week (default: L4W average)
                </FormLabel>
                <Input
                  type="number"
                  defaultValue="3.7"
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                />
              </FormControl>

              <FormControl
                sx={{
                  //   m: 2,
                  pt: 2,
                }}
              >
                <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                  <Button color="primary" variant="solid" size="lg">
                    Update Store Forecast
                  </Button>
                </Box>
              </FormControl>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion disabled>
          <AccordionSummary>
            <ListItemContent>
              <Typography level="title-md" sx={{ display: "block" }}>
                B2B
              </Typography>
            </ListItemContent>
          </AccordionSummary>
          <AccordionDetails>Content</AccordionDetails>
        </Accordion>

        <Accordion disabled>
          <AccordionSummary>
            <ListItemContent>
              <Typography level="title-md" sx={{ display: "block" }}>
                Website
              </Typography>
            </ListItemContent>
          </AccordionSummary>
          <AccordionDetails>Content</AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </Box>
  );
};
