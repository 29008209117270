import { useSearchParams } from "react-router-dom";
import { Page } from "../../frameworks/joy/layouts/page";
import { LoginContainer } from "./container";

export const LoginPage = () => {
  let [searchParams] = useSearchParams();
  //   /login?invitation={invitation_ticket_id}&organization={organization_id}&organization_name={organization_name}
  const invitation = searchParams.get("invitation");
  const organization = searchParams.get("organization");
  const organizationName = searchParams.get("organization_name");
  return (
    <Page>
      <LoginContainer
        invitation={invitation}
        organizationId={organization}
        organizationName={organizationName}
      />
    </Page>
  );
};
