import LinearProgress from "@mui/joy/LinearProgress";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";
import { useEffect, useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";
import { PurchaseOrder } from "../../../services/api/__generated__/backend_gateway-types";
import { JoyCompatibleDatePicker } from "../../../components/date_picker";
import dayjs from "dayjs";

export const PoDetailsSetSnoozeComponent = (props: {
  loading: boolean;
  navigateBack: () => void;
  po: PurchaseOrder | null;
  setPoEndDate: (dateStr: Date | null) => Promise<void>;
}) => {
  const { loading, navigateBack, po, setPoEndDate } = props;
  const [endDate, setEndDate] = useState<Date | null>(null);
  useEffect(() => {
    setEndDate(po?.snooze_end_date ? new Date(po.snooze_end_date) : null);
  }, [po]);

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>Snooze PO</RightPaneTitle>

      {loading && <LinearProgress />}

      <FormControl>
        <FormLabel>Snooze end date</FormLabel>
        <MaterialEmbed>
          <JoyCompatibleDatePicker
            value={dayjs.utc(endDate)}
            disablePast={false}
            minDate={dayjs(po?.created_at)}
            maxDate={dayjs("2025-12-31")}
            onChange={(newNalue) =>
              setEndDate(newNalue?.toDate() || new Date())
            }
          />
        </MaterialEmbed>
      </FormControl>

      <Button
        onClick={() => {
          setPoEndDate(endDate);
        }}
      >
        {endDate ? "Snooze" : po?.snooze_end_date ? "Unsnooze" : "Snooze"} PO
      </Button>
    </RightPaneFrame>
  );
};
