import { deploymentConfig } from "../../configs/deployment";
import { OrchestratorStep } from "../../services/api/__generated__/backend_gateway-types";

export const stepEventLink = (
  stepEvent: OrchestratorStep,
  customerId: string | null
): string => {
  if (customerId === null) {
    return "";
  }
  const { deploymentId } = deploymentConfig;
  const { runSettings, stepId, status } = stepEvent;
  const fullStepId = encodeURIComponent(
    `{"input_id":"${runSettings.inputId}","organization_id":"${customerId}","workflow_id":"${runSettings.workflowId}","variables_id":"${runSettings.variablesId}","run_attempt_id":${runSettings.runAttemptId}}-${stepId}`
  );
  return `https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#edit-item?itemMode=2&pk=${fullStepId}&sk=${status}&route=ROUTE_ITEM_EXPLORER&table=${
    deploymentId || "dev"
  }-orchestrator-steps`;
};
