import { useContext, useMemo } from "react";
import { WorkflowsContext } from "../../contexts/workflows/context";
import { useNavigate } from "react-router-dom";
import { EngineHistoryComponent } from "./component";
import { ApiContext } from "../../contexts/api/context";
import {
  useGetMyCustomerQuery,
  useGetMyEnginePresetsQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { SysMessagesContext } from "../../contexts/sys_messages/context";
import { SysMessageLevel } from "../../contexts/sys_messages/type";

export const EngineHistoryContainer = () => {
  const {
    allRuns,
    isLoading,
    isTriggerInProgress,
    hasLockedRuns,
    lockedRun,
    setLockedRun,
  } = useContext(WorkflowsContext);
  const { addSystemMessage } = useContext(SysMessagesContext);
  const { apiService } = useContext(ApiContext);

  const { data: presetsData, loading: presetsLoading } =
    useGetMyEnginePresetsQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const { data: customerData, loading: customerLoading } =
    useGetMyCustomerQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          console.error(error.message);
          // addSystemMessage({
          //   level: SysMessageLevel.DANGER,
          //   message: error.message!,
          // });
        }
      },
    });
  const hasPresets =
    customerData?.getMyCustomer?.features?.EnginePresets || false;

  const navigate = useNavigate();
  const navigateToRunDetails = (
    inputId: string,
    workflowId: string,
    variablesId: string,
    runAttemptId: number
  ) => {
    navigate(
      `/runs/details/${inputId}/${workflowId}/${variablesId}/${runAttemptId}`,
      { replace: true }
    );
  };
  const engineRuns = useMemo(
    () =>
      allRuns.filter((r) =>
        ["the_engine_scripts", "the_engine_scripts_legacy_dbs"].includes(
          r.runSettings.workflowId
        )
      ),
    [allRuns]
  );
  const ongoingExportRun = useMemo(() => {
    const exportRuns = allRuns.filter((r) =>
      ["export_to_csv", "export_to_csv_legacy_db"].includes(
        r.runSettings.workflowId
      )
    );
    if (exportRuns.length === 0) {
      return null;
    }
    if (engineRuns.length === 0) {
      return exportRuns[0];
    }
    if (new Date(exportRuns[0].createdAt) > new Date(engineRuns[0].createdAt)) {
      return exportRuns[0];
    }
    return null;
  }, [allRuns, engineRuns]);

  return (
    <EngineHistoryComponent
      loading={
        isLoading || isTriggerInProgress || presetsLoading || customerLoading
      }
      hasLockedRuns={hasLockedRuns}
      engineRuns={engineRuns}
      ongoingExportRun={ongoingExportRun}
      lockedRun={lockedRun}
      setLockedRun={setLockedRun}
      navigateToRunDetails={navigateToRunDetails}
      hasPresets={hasPresets}
      presets={presetsData?.getMyEnginePresets || []}
    />
  );
};
