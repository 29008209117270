import Sheet from "@mui/joy/Sheet";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import TabPanel from "@mui/joy/TabPanel";
import { ReactNode } from "react";

export const PlanningComponent = (props: {
  generateChannelForecast: ReactNode;
  runOrderSimulation: ReactNode;
  reviewAndPublish: ReactNode;
  forecastAccuracy: ReactNode;
}) => {
  const loading = false;
  const {
    generateChannelForecast,
    runOrderSimulation,
    reviewAndPublish,
    forecastAccuracy,
  } = props;
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
      }}
    >
      <PageTitle sx={{ m: 4 }}>Planning</PageTitle>

      {loading && (
        <Box sx={{ pt: 2, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 0,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <Tabs sx={{ backgroundColor: "atomic.highlightBackground" }}>
          <TabList>
            <Tab variant="plain" color="neutral">
              Generate Channel Forecast
            </Tab>
            <Tab variant="plain" color="neutral">
              Run Order Simulation
            </Tab>
            <Tab variant="plain" color="neutral">
              Review and Publish
            </Tab>
            <Tab variant="plain" color="neutral">
              Forecast Accuracy
            </Tab>
          </TabList>

          <TabPanel
            value={0}
            sx={{
              bgcolor: "white",
            }}
          >
            {generateChannelForecast}
          </TabPanel>

          <TabPanel
            value={1}
            sx={{
              bgcolor: "white",
            }}
          >
            {runOrderSimulation}
          </TabPanel>

          <TabPanel
            value={2}
            sx={{
              bgcolor: "white",
            }}
          >
            {reviewAndPublish}
          </TabPanel>

          <TabPanel
            value={3}
            sx={{
              bgcolor: "white",
            }}
          >
            {forecastAccuracy}
          </TabPanel>
        </Tabs>
      </Sheet>
    </PageFrame>
  );
};
