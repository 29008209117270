import { Page } from "../../frameworks/joy/layouts/page";
import { InventoryOnHandContainer } from "./container";

export const InventoryAtHandPage = () => {
  return (
    <Page>
      <InventoryOnHandContainer />
    </Page>
  );
};
