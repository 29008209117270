import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/icons-material/Menu";

export const HeaderComponent = (props: { handleDrawerToggle: () => void }) => {
  const { handleDrawerToggle } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1.5,
        zIndex: 1200,
      }}
    >
      <IconButton
        variant="plain"
        size="sm"
        onClick={() => handleDrawerToggle()}
        sx={{
          display: {
            xs: "initial",
            sm: "initial",
            md: "initial",
            lg: "none",
          },
        }}
      >
        <Menu />
      </IconButton>
    </Box>
  );
};
