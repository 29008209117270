import { useContext, useEffect, useMemo, useState } from "react";
import { InventoryOnHandForecastTotalCogsComponent } from "./component";
import { RunTriggerContext } from "../../../contexts/run_trigger/context";
import { InventoryOnHandData } from "../inventory_on_hand_data";
import { TotalCogs } from "./total_cogs";
import { SkuTotalCogs } from "./sku_total_cogs";
import { InventoryOnHandForecastData } from "./inventory_on_hand_forecast_data";
import { ApiContext } from "../../../contexts/api/context";
import { useGetMyInventoryOnHandForecastUrlsQuery } from "../../../services/api/__generated__/backend_gateway-types";

export const InventoryOnHandForecastTotalCogsContainer = (props: {
  inventoryOnHandData: InventoryOnHandData;
  inventoryOnHandDataLoading: boolean;
}) => {
  const { inventoryOnHandData, inventoryOnHandDataLoading } = props;
  const { runLogs, isLogsLoading } = useContext(RunTriggerContext);
  const { apiService } = useContext(ApiContext);
  const [totalCogs, setTotalCogs] = useState<TotalCogs[]>([]);
  const [skuTotalCogs, setSkuTotalCogs] = useState<SkuTotalCogs[]>([]);

  const [dataLoading, setDataLoading] = useState<{ [key: string]: boolean }>(
    {}
  );

  const inventoryOnHandForecastData = useMemo(
    () => new InventoryOnHandForecastData(setDataLoading),
    []
  );

  const {
    data: inventoryOnHandForecastUrls,
    loading: inventoryOnHandForecastUrlsLoading,
    error: inventoryOnHandForecastUrlsError,
  } = useGetMyInventoryOnHandForecastUrlsQuery({
    client: apiService.getClient(),
  });

  const runIdsWithForecastUrls: Record<number, string> = useMemo(() => {
    const o: Record<number, string> = {};
    (
      inventoryOnHandForecastUrls?.getMyInventoryOnHandForecastUrls || []
    ).forEach((d) => {
      o[d.id] = d.url;
    });
    return o;
  }, [inventoryOnHandForecastUrls]);

  useEffect(() => {
    if (inventoryOnHandDataLoading === false && isLogsLoading === false) {
      const newTotalCogs: TotalCogs[] = [];
      const newSkuTotalCogs: SkuTotalCogs[] = [];
      inventoryOnHandData.getData().forEach((runData) => {
        const totalCogs = (runData.data?.map((d) => d.total_cogs) || []).reduce(
          (s, i) => s + i,
          0
        );
        newTotalCogs.push({
          date: runData.date,
          totalCogs,
        });
        runData.data?.forEach((d) => {
          newSkuTotalCogs.push({
            date: runData.date,
            totalCogs: d.total_cogs,
            sku: d.sku,
            location: d.location,
            unitCogs: d.unit_cogs,
            quantityOnHand: d.quantity,
          });
        });
      });
      setTotalCogs(() => newTotalCogs);
      setSkuTotalCogs(() => newSkuTotalCogs);
    }
  }, [inventoryOnHandDataLoading, isLogsLoading, inventoryOnHandData]);

  const downloadSummaryCsvBlob = () => {
    const csv = [
      "date,total_cogs",
      ...totalCogs
        .filter((stc) => stc.date !== undefined)
        .map((stc) =>
          [stc.date?.toISOString().split("T")[0], `${stc?.totalCogs}`].join(",")
        ),
    ].join("\r\n");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "total_cogs_summary.csv");
    pom.click();
    pom.remove();
  };

  const downloadDetailedCsvBlob = () => {
    const csv = [
      "date,sku,location,total_cogs,unit_cogs,quantity_on_hand",
      ...skuTotalCogs
        .filter((stc) => stc.date !== undefined && stc.sku !== undefined)
        .map((stc) =>
          [
            stc.date?.toISOString().split("T")[0],
            '"' + `${stc.sku}`.replaceAll('"', '""') + '"',
            '"' + `${stc.location}`.replaceAll('"', '""') + '"',
            `${stc.totalCogs}`,
            `${stc.unitCogs}`,
            `${stc.quantityOnHand}`,
          ].join(",")
        ),
    ].join("\r\n");
    const blob = new Blob([csv], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "total_cogs_detailed.csv");
    pom.click();
    pom.remove();
  };

  // const setInventoryOnHandForecast = (runIds: number[]) => {
  //   inventoryOnHandForecastData.setRunIdsAndUrls(
  //     runIds,
  //     runIdsWithForecastUrls
  //   );
  // };

  const { setRunIdsAndUrls } = inventoryOnHandForecastData;

  useEffect(() => {
    if (runLogs.length > 0) {
      let maxId: number | null = null;
      let i: number = 0;
      while (i < runLogs.length) {
        if (
          runLogs[i] !== undefined &&
          runLogs[i].id !== undefined &&
          runLogs[i].id !== null &&
          runIdsWithForecastUrls[runLogs[i].id!] !== undefined
        ) {
          if (maxId === null) {
            maxId = runLogs[i].id!;
          } else if (maxId < runLogs[i].id!) {
            maxId = runLogs[i].id!;
          }
        }
        i += 1;
      }
      if (maxId !== null) {
        setRunIdsAndUrls([maxId], runIdsWithForecastUrls);
      }
    }
  }, [runIdsWithForecastUrls, setRunIdsAndUrls, runLogs]);

  if (inventoryOnHandForecastUrlsError) {
    console.error(inventoryOnHandForecastUrlsError);
  }

  return (
    <InventoryOnHandForecastTotalCogsComponent
      totalCogs={totalCogs}
      loading={
        inventoryOnHandDataLoading ||
        inventoryOnHandForecastUrlsLoading ||
        Object.values(dataLoading).includes(true)
      }
      downloadSummaryCsvBlob={downloadSummaryCsvBlob}
      downloadDetailedCsvBlob={downloadDetailedCsvBlob}
      // availableRunIds={(
      //   inventoryOnHandForecastUrls?.getMyInventoryOnHandForecastUrls || []
      // ).map((d) => d.id)}
      runLogs={runLogs}
      // setInventoryOnHandForecast={setInventoryOnHandForecast}
      forecastedTotalCogs={inventoryOnHandForecastData.getData()}
    />
  );
};
