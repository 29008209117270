import Papa from "papaparse";

export type SkuTag = {
  sku: string;
  tag: string;
  key?: string;
  value: string;
};

export class SkuTagsFromCsv {
  private skuTags: SkuTag[] = [];
  private setLoading: (v: boolean) => void;

  constructor(setLoading: (v: boolean) => void) {
    this.setLoading = setLoading;
  }

  async fetch(csvUrl: string) {
    this.setLoading(true);
    Papa.parse<SkuTag>(csvUrl, {
      download: true,
      worker: true,
      header: true,
      dynamicTyping: true,
      step: (results) => {
        const { sku, tag, key, value } = results.data;
        this.skuTags.push({
          sku,
          tag: tag || key || "",
          value,
        });
      },
      complete: () => {
        this.setLoading(false);
      },
    });
  }

  toDict(): Record<string, Record<string, string>> {
    const skuTagDict: Record<string, Record<string, string>> = {};
    this.skuTags.forEach((skuTag) => {
      const { sku, tag, value } = skuTag;
      skuTagDict[sku] = skuTagDict[sku] || {};
      skuTagDict[sku][tag] =
        value === null || value === undefined || value === "undefined"
          ? "-"
          : value;
    });
    return skuTagDict;
  }
}
