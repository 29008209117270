import { useContext, useEffect } from "react";
import { ForecastAccuracyControlComponent } from "./control_component";
import { DiffParams, SalesMeasure, Segmentation } from "../diff_params";
import { RunTriggerContext } from "../../../contexts/run_trigger/context";
import { ApiContext } from "../../../contexts/api/context";
import {
  useGetMyCustomerQuery,
  useGetMyRunComparisonRunIdsQuery,
} from "../../../services/api/__generated__/backend_gateway-types";

export const ForecastAccuracyControlContainer = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
}) => {
  const { diffParams, updateDiffParams } = props;
  const { runLogs, isLogsLoading } = useContext(RunTriggerContext);

  const { apiService } = useContext(ApiContext);
  const {
    data: runIdsData,
    loading: runIdsLoading,
    error: runIdsError,
  } = useGetMyRunComparisonRunIdsQuery({
    client: apiService.getClient(),
  });

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });

  const maxId: number | null = runIdsData?.getMyRunComparisonRunIds
    ? Math.max(...runIdsData.getMyRunComparisonRunIds)
    : null;
  const actualsRun = maxId
    ? runLogs.find((rl) => rl.id === maxId) || null
    : null;

  const updateParams = (params: DiffParams) => {
    updateDiffParams({
      ...params,
      salesMeasure: params.salesMeasure || SalesMeasure.QUANTITY,
    });
  };

  const updateRuns = (params: DiffParams) => {
    const nextForecastRun =
      runLogs.find(
        (rl) =>
          params.forecastEngineRunId === (rl.id === null ? "" : `${rl.id}`)
      ) || null;
    updateDiffParams({
      ...params,
      ...((nextForecastRun?.startedAt || null) !== null &&
        (actualsRun?.startedAt || null) !== null && {
          minDate: new Date(nextForecastRun!.startedAt!),
          maxDate: new Date(actualsRun!.startedAt!),
        }),
      salesMeasure: params.salesMeasure || SalesMeasure.QUANTITY,
      segmentation: params.segmentation || Segmentation.PRODUCT_TYPE,
    });
  };

  useEffect(() => {
    if (diffParams.actualsEngineRunId === null && maxId !== null) {
      updateDiffParams({
        ...diffParams,
        actualsEngineRunId: `${maxId}`,
      });
    }
  }, [diffParams, maxId, updateDiffParams]);

  if (runIdsError) {
    console.error(
      "ForecastAccuracyControlContainer: useGetMyLockedDemandOutputRunIdsQuery fetch error",
      runIdsError
    );
  }

  if (customerError) {
    console.error(
      "ForecastAccuracyControlContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }

  return (
    <ForecastAccuracyControlComponent
      diffParams={diffParams}
      updateDiffParams={updateParams}
      updateRuns={updateRuns}
      loading={isLogsLoading || runIdsLoading || customerLoading}
      error={null}
      runLogs={runLogs.filter(
        (rl) =>
          rl.id &&
          runIdsData?.getMyRunComparisonRunIds?.includes(rl.id) &&
          rl.id !== maxId
      )}
      actualsRun={actualsRun}
      hasProductCategory={
        customerData?.getMyCustomer?.features?.SkuHierarchyProductCategory ||
        false
      }
      hasProductType={
        customerData?.getMyCustomer?.features?.SkuHierarchyProductType || false
      }
      hasProductFamily={
        customerData?.getMyCustomer?.features?.SkuHierarchyProductFamily ||
        false
      }
      hasProductTitle={
        customerData?.getMyCustomer?.features?.SkuHierarchyProductTitle || false
      }
    />
  );
};
