import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
  NormalizedCacheObject,
} from "@apollo/client";
import { apiConfig } from "../../configs/api";
import { AuthenticationService } from "./authentication";

export const getNewApiClient = (
  authenticationService: AuthenticationService = AuthenticationService.getInstance()
): ApolloClient<NormalizedCacheObject> => {
  // Best recreated a new one at every authentication event
  // to clear the in memory cache. The service takes care of that.

  const { apiUrl, graphqlPath } = apiConfig;
  const uri = `${apiUrl}${graphqlPath}`;

  const httpLink = new HttpLink({
    uri: uri,
    fetch: authenticationService.awsGraphqlFetch,
  });

  const rootCorporationSelectorMiddleware = new ApolloLink(
    (operation, forward) => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          // NOTE: the X-Atomic-CustomerId and X-Atomic-Auth0-IdToken headers are
          // specific to this project, are set in backend_gateway/src/handlers/lambda
          "X-Atomic-CustomerId":
            localStorage.getItem("selectedCustomerId") || "",
          "X-Atomic-Auth0-IdToken":
            AuthenticationService.getIdToken()?.__raw || "",
        },
      }));

      return forward(operation);
    }
  );

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: concat(rootCorporationSelectorMiddleware, httpLink),
    cache: new InMemoryCache(),
  });
  return client;
};
