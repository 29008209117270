import { FC } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { FooterComponent } from "./component";
import { ROUTES } from "../../../../../routes";

export const FooterContainer: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const match = matchRoutes(Object.values(ROUTES), location);
  let currentPathName: string | null = null;
  if (match !== null) {
    const [{ route }] = match;
    const matchedPathEntry = Object.entries(ROUTES).find(
      (i) => i[1].path === route.path
    );
    currentPathName = matchedPathEntry ? matchedPathEntry[0] : null;
  }

  const navigateToReports = () => {
    navigate("/reports", { replace: true });
  };

  const navigateToRunHistory = () => {
    navigate("/run_history", { replace: true });
  };

  const navigateToProfile = () => {
    navigate("/profile", { replace: true });
  };

  return (
    <FooterComponent
      navigateToReports={navigateToReports}
      navigateToRunHistory={navigateToRunHistory}
      navigateToProfile={navigateToProfile}
      currentPathName={currentPathName}
    />
  );
};
