import { createContext } from "react";
import { PoBoxSettings, PosType, StatusFilter } from "./type";

const defaultValue: PosType = {
  loading: false,
  hasPoInbox: false,
  poBoxSettings: {
    includeStatus: [
      StatusFilter.ATOMIC_SUGGESTION,
      StatusFilter.CUSTOMER_OWNED,
    ],
    includeSnoozed: false,
  } as PoBoxSettings,
  setPoBoxSettings: () => {},
  pos: [],
  refetchPos: () => {},
  savePuchaseOrder: async () => {},
  savePurchaseOrderSnoozeAndStatus: async () => {},
  saving: false,
};
export const PosContext = createContext(defaultValue);
