import { useNavigate } from "react-router-dom";
import { FeatureFlagsComponent } from "./component";
import { useContext, useState } from "react";
import { ApiContext } from "../../../contexts/api/context";
import {
  GetCustomerDocument,
  useCreateCustomerEmailDomainMutation,
  useCreateOrReplaceFeatureFlagsMutation,
  useCreatePowerBiMutation,
  useDeleteCustomerEmailDomainMutation,
  useGetCustomerQuery,
  useReplacePowerBiMutation,
} from "../../../services/api/__generated__/backend_gateway-types";
import { FeatureList } from "./features_type";

export const FeatureFlagsContainer = (props: { customerId: string }) => {
  const { customerId } = props;
  const { apiService } = useContext(ApiContext);

  const [errors, setErrors] = useState<string[]>([]);

  const { data: getCustomerData, loading: getCustomerLoading } =
    useGetCustomerQuery({
      client: apiService.getClient(),
      variables: {
        customerId,
      },
      onError: (data) => {
        if (data.message) {
          setErrors((e) => [...e, data.message]);
        }
      },
    });

  const [
    createOrReplaceFeatureFlags,
    { loading: createOrReplaceFeatureFlagsLoading },
  ] = useCreateOrReplaceFeatureFlagsMutation({
    client: apiService.getClient(),
  });

  const setFeatures = async (features: FeatureList): Promise<void> => {
    const newFeatures = {
      ...features,
    };
    createOrReplaceFeatureFlags({
      variables: {
        featureFlags: {
          customerId,
          ...newFeatures,
        },
      },
      onError: (data) => {
        if (data.message) {
          setErrors((e) => [...e, data.message]);
        }
      },
      optimisticResponse: {
        createOrReplaceFeatureFlags: {
          __typename: "Features",
          ...newFeatures,
        },
      },
      update(cache, { data }) {
        cache.updateQuery(
          {
            query: GetCustomerDocument,
            variables: {
              customerId,
            },
          },
          (getCustomerData) => ({
            getCustomerData: {
              ...getCustomerData?.getCustomer,
              features: {
                ...data?.createOrReplaceFeatureFlags,
              },
            },
          })
        );
      },
      refetchQueries: [
        {
          query: GetCustomerDocument,
          variables: { customerId },
        },
      ],
    });
  };

  const [
    createCustomerEmailDomain,
    { loading: createCustomerEmailDomainLoading },
  ] = useCreateCustomerEmailDomainMutation({
    client: apiService.getClient(),
  });

  const createEmailDomain = (emailDomain: string) => {
    createCustomerEmailDomain({
      variables: {
        customerId,
        emailDomain,
      },
      optimisticResponse: {
        createCustomerEmailDomain: {
          __typename: "Customer",
          id: customerId,
          emailDomains: [
            ...(getCustomerData?.getCustomer?.emailDomains || []),
            emailDomain,
          ],
        },
      },
      update(cache, { data }) {
        cache.updateQuery(
          {
            query: GetCustomerDocument,
            variables: {
              customerId,
            },
          },
          (getCustomerData) => ({
            getCustomerData: {
              ...getCustomerData?.getCustomer,
              ...data?.createCustomerEmailDomain,
            },
          })
        );
      },
      onError: (data) => {
        if (data.message) {
          setErrors((e) => [...e, data.message]);
        }
      },
    });
  };

  const [
    deleteCustomerEmailDomain,
    { loading: deleteCustomerEmailDomainLoading },
  ] = useDeleteCustomerEmailDomainMutation({
    client: apiService.getClient(),
  });

  const deleteEmailDomain = (emailDomain: string) => {
    deleteCustomerEmailDomain({
      variables: {
        customerId,
        emailDomain,
      },
      optimisticResponse: {
        deleteCustomerEmailDomain: {
          __typename: "Customer",
          id: customerId,
          emailDomains: [
            ...(getCustomerData?.getCustomer?.emailDomains || []).filter(
              (e) => e !== emailDomain
            ),
          ],
        },
      },
      update(cache, { data }) {
        cache.updateQuery(
          {
            query: GetCustomerDocument,
            variables: {
              customerId,
            },
          },
          (getCustomerData) => {
            console.info("getCustomerData", getCustomerData, data);
            return {
              getCustomerData: {
                ...getCustomerData?.getCustomer,
                ...data?.deleteCustomerEmailDomain,
              },
            };
          }
        );
      },
      onError: (data) => {
        if (data.message) {
          setErrors((e) => [...e, data.message]);
        }
      },
    });
  };

  const [createPowerBi, { loading: createPowerBiLoading }] =
    useCreatePowerBiMutation({
      client: apiService.getClient(),
    });

  const createPowerbi = (
    name: string,
    workspaceId: string,
    datasetId: string,
    enabled: boolean
  ) => {
    createPowerBi({
      variables: {
        powerBi: {
          customerId,
          name,
          workspaceId,
          datasetId,
          enabled,
        },
      },
      update(cache, { data }) {
        cache.updateQuery(
          {
            query: GetCustomerDocument,
            variables: {
              customerId,
            },
          },
          (getCustomerData) => ({
            getCustomerData: {
              ...getCustomerData?.getCustomer,
              ...data?.createPowerBi,
            },
          })
        );
      },
      onError: (data) => {
        if (data.message) {
          setErrors((e) => [...e, data.message]);
        }
      },
    });
  };

  const [replacePowerBi, { loading: replacePowerBiLoading }] =
    useReplacePowerBiMutation({
      client: apiService.getClient(),
    });

  const replacePowerbi = (
    id: string,
    name: string,
    workspaceId: string,
    datasetId: string,
    enabled: boolean
  ) => {
    replacePowerBi({
      variables: {
        powerBi: {
          customerId,
          id,
          name,
          workspaceId,
          datasetId,
          enabled,
        },
      },
      update(cache, { data }) {
        cache.updateQuery(
          {
            query: GetCustomerDocument,
            variables: {
              customerId,
            },
          },
          (getCustomerData) => ({
            getCustomerData: {
              ...getCustomerData?.getCustomer,
              ...data?.replacePowerBi,
            },
          })
        );
      },
      onError: (data) => {
        if (data.message) {
          setErrors((e) => [...e, data.message]);
        }
      },
    });
  };

  const clearError = (i: number) => {
    const newErrors = [...errors];
    newErrors.splice(i, 1);
    setErrors(newErrors);
  };

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate("/customers", { replace: true });
  };

  return (
    <FeatureFlagsComponent
      loading={
        getCustomerLoading ||
        createOrReplaceFeatureFlagsLoading ||
        createCustomerEmailDomainLoading ||
        deleteCustomerEmailDomainLoading ||
        createPowerBiLoading ||
        replacePowerBiLoading
      }
      errors={errors}
      clearError={clearError}
      customer={getCustomerData?.getCustomer || null}
      navigateBack={navigateBack}
      setFeatures={setFeatures}
      createEmailDomain={createEmailDomain}
      deleteEmailDomain={deleteEmailDomain}
      createPowerbi={createPowerbi}
      replacePowerbi={replacePowerbi}
    />
  );
};
