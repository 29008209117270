import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RunTriggerContext } from "../../contexts/run_trigger/context";
import { DrawerContext } from "../../contexts/drawer/context";
import { RunHistoryComponent } from "./component";
import { ApiContext } from "../../contexts/api/context";
import { useGetMyCustomerQuery } from "../../services/api/__generated__/backend_gateway-types";

export const RunHistoryContainer = () => {
  const { isRunning, runLogs, isLogsLoading, lockedRun, setLockedRun } =
    useContext(RunTriggerContext);
  const { closeDrawer } = useContext(DrawerContext);
  const navigate = useNavigate();

  const navigateToRunHistoryWithEditRun = (id: number) => {
    closeDrawer();
    navigate(`/run_history/edit/${id}`, { replace: true });
  };

  const { apiService } = useContext(ApiContext);

  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });

  if (customerError) {
    console.error(customerError.message);
  }

  return (
    <RunHistoryComponent
      runlogs={runLogs}
      runlogLoading={isLogsLoading || customerLoading}
      runningInProgress={isRunning}
      navigateToRunHistoryWithEditRun={navigateToRunHistoryWithEditRun}
      lockedRun={lockedRun}
      setLockedRun={setLockedRun}
      hasLockedRuns={
        customerData?.getMyCustomer?.features?.EngineLoadLockedDemandForecast ||
        false
      }
    />
  );
};
