import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";

export const StepId = (props: { stepId: string }) => {
  const { stepId } = props;
  try {
    if (stepId.split("|")[0] === "ENGINE") {
      const filename = stepId.split("|")[2];
      const sqlname = filename.split("/").slice(-1)[0];
      const number = sqlname.split("_")[0];
      const shortfilename = `${filename.split(number)[0]}${number}...`;
      return (
        <Typography level="body-sm">
          <Typography variant="soft" color="primary">
            SQL
          </Typography>{" "}
          <Tooltip title={filename} variant="outlined">
            <Typography variant="plain">{shortfilename}</Typography>
          </Tooltip>
        </Typography>
      );
    }
    if (stepId.split("|")[0] === "ENGINE_TRIGGER") {
      const phase = stepId.split("|")[1];
      return (
        <Typography level="body-sm">
          <Typography variant="soft" color="primary">
            ENGINE
          </Typography>{" "}
          {phase}
        </Typography>
      );
    }
    if (stepId.split("|")[0] === "TRIGGER") {
      const phase = stepId.split("|")[1];
      return (
        <Typography level="body-sm">
          <Typography variant="soft" color="primary">
            SERVICE
          </Typography>{" "}
          {phase}
        </Typography>
      );
    }
    return (
      <Typography level="body-sm">
        <Typography variant="soft" color="primary">
          PY
        </Typography>{" "}
        {stepId}
      </Typography>
    );
  } catch (e) {
    return (
      <Typography level="body-sm">
        <Typography variant="soft" color="primary">
          ?
        </Typography>{" "}
        {stepId}
      </Typography>
    );
  }
};
