import { Page } from "../../../frameworks/joy/layouts/page";
import { GoodchopOdlToShipStationContainer } from "./container";

export const GoodchopOdlToShipStationPage = () => {
  return (
    <Page>
      <GoodchopOdlToShipStationContainer />
    </Page>
  );
};
