import Papa from "papaparse";

export type SkuTag = {
  sku: string;
  tag: string;
  key?: string;
  value: string;
};

export class SkuTagsFromCsv {
  private skuTags: SkuTag[] = [];
  private setLoading: (v: boolean) => void;
  private callback:
    | React.Dispatch<React.SetStateAction<SkuTagsFromCsv>>
    | undefined;

  constructor(setLoading: (v: boolean) => void) {
    this.setLoading = setLoading;
  }

  setOnCompleteCallback = (
    callback: React.Dispatch<React.SetStateAction<SkuTagsFromCsv>>
  ) => {
    this.callback = callback;
  };

  fetch = async (csvUrl: string) => {
    this.setLoading(true);
    Papa.parse<SkuTag>(csvUrl, {
      download: true,
      worker: true,
      header: true,
      dynamicTyping: true,
      step: (results) => {
        const { sku, tag, key, value } = results.data;
        this.skuTags.push({
          sku,
          tag: tag || key || "",
          value,
        });
      },
      complete: () => {
        this.callback && this.callback(Object.create(this));
        this.setLoading(false);
      },
    });
  };

  toDict(): Record<string, Record<string, string>> {
    const skuTagDict: Record<string, Record<string, string>> = {};
    this.skuTags.forEach((skuTag) => {
      const { sku, tag, value } = skuTag;
      if (value === null || value === undefined || value === "undefined") {
        return;
      }
      skuTagDict[sku] = skuTagDict[sku] || {};
      skuTagDict[sku][tag] = value;
    });
    return skuTagDict;
  }
}
