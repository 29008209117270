import { ReactNode } from "react";
import Box from "@mui/joy/Box";

export const RightPaneFrame = (props: { children?: ReactNode; sx?: any }) => {
  return (
    <Box
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "stretch",
          gap: 1.5,
          m: 4,
        },
      ]}
      {...props}
    />
  );
};
