import { ReactNode } from "react";
import Typography from "@mui/joy/Typography";

export const PageTitle = (props: { children?: ReactNode; sx?: any }) => {
  const { sx, ...rest } = props;
  return (
    <Typography
      sx={[
        {
          fontSize: {
            xs: "22px",
            sm: "30px",
          },
          fontWeight: 800,
          // color: colors.grey10,
          color: "atomic.grey10",
          textTransform: "uppercase",
          letterSpacing: ".4rem",
        },
        {
          ...(sx || {}),
        },
      ]}
      {...rest}
    />
  );
};
