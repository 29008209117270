import { useContext, useEffect, useRef, useState } from "react";
import Uppy, { UppyFile } from "@uppy/core";
import AwsS3, { AwsS3UploadParameters } from "@uppy/aws-s3";
import { Dashboard } from "@uppy/react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";

import {
  useCreateMyGoodchopGrossOdlJobMutation,
  useCreateMyGoodchopGrossOdlPresignUploadUrlMutation,
  useGetMyGoodchopGrossOdlSingleJobLazyQuery,
  useStartMyGoodchopGrossOdlJobMutation,
  useStartMyGoodchopGrossOdlUploadValidationMutation,
} from "../../../services/api/__generated__/backend_gateway-types";
import { ApiContext } from "../../../contexts/api/context";
import { TaskStatus } from "./enums";
import { AppearanceContext } from "../../../contexts/appearance/context";

interface FileUploaderComponentProps {
  selectedRun: string | null;
  runName: string | null;
  jobName: string | null;
}

const uppyTheme = {
  light: "light",
  dark: "dark",
  system: "auto",
};

export const FileUploaderComponent = (props: FileUploaderComponentProps) => {
  const { selectedRun, runName, jobName } = props;
  const { modeString } = useContext(AppearanceContext);
  const { apiService } = useContext(ApiContext);

  const [createMyGoodchopGrossOdlJob] = useCreateMyGoodchopGrossOdlJobMutation({
    client: apiService.getClient(),
  });

  const [startMyGoodchopGrossOdlUploadValidation] =
    useStartMyGoodchopGrossOdlUploadValidationMutation({
      client: apiService.getClient(),
    });

  const [startMyGoodchopGrossOdlJob] = useStartMyGoodchopGrossOdlJobMutation({
    client: apiService.getClient(),
  });

  const [createMyGoodchopGrossOdlPresignUploadUrl] =
    useCreateMyGoodchopGrossOdlPresignUploadUrlMutation({
      client: apiService.getClient(),
    });

  const [getMyGoodchopGrossOdlSingleJob] =
    useGetMyGoodchopGrossOdlSingleJobLazyQuery({
      client: apiService.getClient(),
    });

  // TODO; this is passed as a prop and determined based on step of previous run
  const selectedJobStep = "1";

  const [errorMessage, setErrorMessage] = useState<String>("");
  const [jobConfig, setJobConfig] = useState<{
    jobPlanId: string;
    job: string;
    jobName: string;
    step: string;
    runId: string;
    fileName: string;
    name: string;
  }>({
    jobPlanId: "",
    job: "",
    jobName: "",
    step: selectedJobStep,
    runId: "",
    fileName: "",
    name: "",
  });

  const jobConfigRef = useRef(jobConfig);

  const [uppy, setUppy] = useState<Uppy<
    Record<string, unknown>,
    Record<string, unknown>
  > | null>(null);

  useEffect(() => {
    jobConfigRef.current = jobConfig;
  }, [jobConfig]);

  useEffect(() => {
    const uppyInstance = new Uppy({
      meta: { type: "avatar" },
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [".xlsx"],
      },
      onBeforeFileAdded: (currentFile, file) => {
        setJobConfig((prevConfig) => ({
          ...prevConfig,
          fileName: currentFile.name,
        }));

        const fileName = `input_odl_file.xlsx`;
        const modifiedFile = {
          ...currentFile,
          name: fileName,
        };
        return modifiedFile;
      },
    }).use(AwsS3, {
      async getUploadParameters(
        file: UppyFile
      ): Promise<AwsS3UploadParameters> {
        // Make a request to your server to get the signed URL
        try {
          if (file?.name === null && file?.type === null) {
            throw Error("Uploaded file missing `name` or `type` parameters");
          }

          if (selectedRun === null) {
            throw Error("Job must be selected in dropdown to upload file");
          }
          // TODO: could imagine multiple plans via a dropdown at somepoint
          const selectedJobPlanId = "step_plan_one";

          const selectedJobStep = 1;

          const fileName = file.name;

          const bodyUpload = {
            fileName: fileName,
            contentType: file.type as string,
            jobPlanId: selectedJobPlanId,
            job: selectedRun,
          };
          const presignUploadResponse =
            await createMyGoodchopGrossOdlPresignUploadUrl({
              variables: bodyUpload,
            });
          const presignUploadResult =
            presignUploadResponse.data
              ?.createMyGoodchopGrossOdlPresignUploadUrl;
          const runId = presignUploadResult?.runId;
          const url = presignUploadResult?.presignUrl;

          if (!url || !runId) {
            throw Error("Error: failed to create presigned urls in S3");
          }

          setJobConfig((prevConfig) => ({
            ...prevConfig,
            jobName: jobName ?? "",
            jobPlanId: selectedJobPlanId,
            job: selectedRun, // Assign an empty string if selectedJob is undefined
            step: String(selectedJobStep),
            runId,
            fileName,
            name: runName || "",
          }));

          return {
            method: "PUT",
            url: url,
            fields: {},
            headers: {
              "Content-Type": file.type || "",
            },
          };
        } catch (error) {
          uppyInstance.emit("upload-error", String(error), String(error));
          throw Error(String(error));
        }
      },
    });

    uppyInstance
      .on("file-added", (file) => {
        uppyInstance.setOptions({
          locale: {
            strings: {
              uploading: "Uploading",
            },
          },
        });
        setErrorMessage("");
      })
      .on("upload-progress", (file, progress) => {
        if (file) {
          const adjustedProgress =
            (progress.bytesUploaded / progress.bytesTotal) * 0.5;
          uppyInstance.setFileState(file.id, {
            progress: {
              ...file.progress,
              bytesUploaded: adjustedProgress * file.size,
              percentage: adjustedProgress * 100,
              uploadComplete: false,
            },
          });
        }
      });

    uppyInstance.on("upload-success", async (file, response) => {
      try {
        if (file && file.name && file.type) {
          uppyInstance.setFileState(file.id, {
            progress: { ...file.progress, percentage: 50 },
          });

          const createJobBody = {
            ...jobConfigRef.current,
          };
          const createJobResponse = await createMyGoodchopGrossOdlJob({
            variables: createJobBody,
          });
          const createJobResult =
            createJobResponse.data?.createMyGoodchopGrossOdlJob;

          // if (!createJobResult || !taskId) {
          if (!createJobResult) {
            const error =
              "Failed to connect to backend and start data computation";
            throw Error(error);
          }

          uppyInstance.setOptions({
            locale: {
              strings: {
                uploading: "Beginning upload data validation",
              },
            },
          });

          const runId = jobConfigRef.current?.runId;

          const startUploadValidationBody = {
            runId: runId,
            jobPlanId: jobConfigRef.current.jobPlanId,
            job: jobConfigRef.current.job,
            fileName: jobConfigRef.current.fileName,
          };
          await startMyGoodchopGrossOdlUploadValidation({
            variables: startUploadValidationBody,
          });

          const intervalId = setInterval(async () => {
            let counter = 0; // wait 5 (5s) iterations of the interval time because their could be a delay in getting the task

            const taskStatusResponse = await getMyGoodchopGrossOdlSingleJob({
              variables: {
                runId: runId,
              },
            });
            const taskStatusResult = await taskStatusResponse.refetch();
            const taskStatusData =
              taskStatusResult?.data?.getMyGoodchopGrossOdlSingleJob;

            if (taskStatusData) {
              const taskStatus = taskStatusData.status;
              if (taskStatus === TaskStatus.SUCCESS) {
                uppyInstance.setOptions({
                  locale: undefined, // Setting it to an empty object
                });

                const startJobBody = {
                  runId: runId,
                  jobPlanId: jobConfigRef.current.jobPlanId,
                  job: jobConfigRef.current.job,
                };
                await startMyGoodchopGrossOdlJob({
                  variables: startJobBody,
                });

                uppyInstance.setFileState(file.id, {
                  progress: {
                    ...file.progress,
                    percentage: 100,
                    uploadComplete: true,
                  },
                });
                clearInterval(intervalId);
              } else if (taskStatus === TaskStatus.RUNNING_VALIDATION) {
                uppyInstance.setOptions({
                  locale: {
                    strings: {
                      uploading: "Performing data validation",
                    },
                  },
                });

                uppyInstance.setFileState(file.id, {
                  progress: { ...file.progress, percentage: 50 },
                });
              } else if (taskStatus === TaskStatus.RUNNING_TRANSFORM) {
                uppyInstance.setOptions({
                  locale: {
                    strings: {
                      uploading: "Performing data transform",
                    },
                  },
                });
                uppyInstance.setFileState(file.id, {
                  progress: { ...file.progress, percentage: 75 },
                });
              } else if (taskStatus === TaskStatus.RUNNING_QUEUE) {
                uppyInstance.setOptions({
                  locale: {
                    strings: {
                      uploading: "Queuing data computations",
                    },
                  },
                });
                uppyInstance.setFileState(file.id, {
                  progress: { ...file.progress, percentage: 75 },
                });
              } else if (taskStatus === TaskStatus.RUNNING_TASK) {
                uppyInstance.setOptions({
                  locale: {
                    strings: {
                      uploading: "Starting data computations",
                    },
                  },
                });
                uppyInstance.setFileState(file.id, {
                  progress: { ...file.progress, percentage: 75 },
                });
              } else if (taskStatus === TaskStatus.STARTED) {
                uppyInstance.setOptions({
                  locale: {
                    strings: {
                      uploading: "Beginning data computations",
                    },
                  },
                });
              } else if (taskStatus === TaskStatus.FAILED) {
                const error = taskStatusData.error;
                const formattedError = `Error: ${error}`;
                uppyInstance.emit(
                  "upload-error",
                  formattedError,
                  formattedError
                );
                clearInterval(intervalId);
              }
            } else {
              // retry logic
              counter += 1;
              if (counter >= 5) {
                const error = "Error: failed to fetch task from backend";
                uppyInstance.emit("upload-error", error, error);
                clearInterval(intervalId);
              }
            }
          }, 10000);
        }
      } catch (error) {
        const wrappedError = new Error(String(error));
        uppyInstance.emit(
          "upload-error",
          wrappedError.message,
          wrappedError.stack
        );
      }
    });

    uppyInstance.on("upload-error", (file, error, response) => {
      uppyInstance.setOptions({
        locale: undefined,
      });
      setErrorMessage(String(error));
    });

    setUppy(uppyInstance);
    return () => uppyInstance.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRun, runName, jobName]);

  return (
    <>
      {uppy && (
        <Dashboard
          uppy={uppy}
          theme={
            (uppyTheme[modeString as keyof typeof uppyTheme] as
              | "light"
              | "dark"
              | "auto") || "auto"
          }
          plugins={[]}
          proudlyDisplayPoweredByUppy={false}
          height={"25vh"}
          width={"100%"}
          hideRetryButton={true}
        />
      )}

      {errorMessage && (
        <Box
          sx={{
            width: "100%",
            overflow: "visible",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              p: 2,
              my: 2,
              overflow: "auto",
              minWidth: "0",
              maxWidth: "100%",
              borderRadius: "4px",
            }}
          >
            <b style={{ padding: "5px 0px" }}>{errorMessage}</b>
          </Sheet>
        </Box>
      )}
    </>
  );
};
