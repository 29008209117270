import { useContext, useEffect, useState } from "react";
import { registerLicense, enableRipple } from "@syncfusion/ej2-base";
import { ReviewAndPublishComponent } from "./component";
import { DiffParams, SalesMeasure } from "./diff_params";
import { PeriodType } from "./period_type";
import {
  useGetMyCustomerQuery,
  useGetMyDemandMonthlyDiffUrlQuery,
  useGetMyDemandMonthlyUrlQuery,
  useGetMySkuHierarchyUrlQuery,
} from "../../../services/api/__generated__/backend_gateway-types";
import { ApiContext } from "../../../contexts/api/context";
import { DemandFromCsv } from "./demand_from_csv";
import { SkuTagsFromCsv } from "./sku_hierarchy_from_csv";
import { PlanningReviewControlContainer } from "./control/container";

const RUNID0 = "44";
const RUNID1 = "43";
const MINDATE = new Date("2024-04-01");
const MAXDATE = new Date("2024-12-31");
const diffParams: DiffParams = {
  runId0: RUNID0,
  runId1: RUNID1,
  minDate: MINDATE,
  maxDate: MAXDATE,
  aggregatePeriodType: PeriodType.MONTH,
  salesMeasure: SalesMeasure.QUANTITY,
  heatMap: true,
};

export const ReviewAndPublishContainer = (props: {}) => {
  const [hasLicence, setHasLicense] = useState<boolean>(false);
  const { apiService } = useContext(ApiContext);
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });
  const ej2License = customerData?.getMyCustomer?.ej2License || null;

  useEffect(() => {
    if (ej2License !== null) {
      enableRipple(false);
      registerLicense(ej2License);
      setHasLicense(() => true);
    }
  }, [ej2License]);

  const [monthlyDemand0Loading, setMonthlyDemand0Loading] =
    useState<boolean>(false);
  const [monthlyDemand0] = useState<DemandFromCsv>(
    new DemandFromCsv(setMonthlyDemand0Loading, `Run 1`)
  );
  const { loading: demandMonthly0URLLoading, error: demandMonthly0Error } =
    useGetMyDemandMonthlyUrlQuery({
      client: apiService.getClient(),
      variables: { engineRunId: parseInt(RUNID0) },
      onCompleted: async (data) => {
        const url = data?.getMyDemandMonthlyUrl;
        if (url) {
          await monthlyDemand0.fetch(url);
        }
      },
    });

  const [monthlyDemand1Loading, setMonthlyDemand1Loading] =
    useState<boolean>(false);
  const [monthlyDemand1] = useState<DemandFromCsv>(
    new DemandFromCsv(setMonthlyDemand1Loading, `Run 2`)
  );
  const { loading: demandMonthly1URLLoading, error: demandMonthly1Error } =
    useGetMyDemandMonthlyUrlQuery({
      client: apiService.getClient(),
      variables: { engineRunId: parseInt(RUNID1) },
      onCompleted: async (data) => {
        const url = data?.getMyDemandMonthlyUrl;
        if (url) {
          await monthlyDemand1.fetch(url);
        }
      },
    });

  const [monthlyDemandDiffLoading, setMonthlyDemandDiffLoading] =
    useState<boolean>(false);
  const [monthlyDemandDiff] = useState<DemandFromCsv>(
    new DemandFromCsv(setMonthlyDemandDiffLoading, `Run Delta (1 - 2)`)
  );
  const {
    loading: demandMonthlyDiffURLLoading,
    error: demandMonthlyDiffError,
  } = useGetMyDemandMonthlyDiffUrlQuery({
    client: apiService.getClient(),
    variables: {
      engineRunId0: parseInt(RUNID0),
      engineRunId1: parseInt(RUNID1),
    },
    onCompleted: async (data) => {
      const url = data?.getMyDemandMonthlyDiffUrl;
      if (url) {
        await monthlyDemandDiff.fetch(url);
      }
    },
  });

  const [skuTagsLoading, setSkuTagsLoading] = useState<boolean>(false);
  const [skuTags] = useState<SkuTagsFromCsv>(
    new SkuTagsFromCsv(setSkuTagsLoading)
  );
  const { loading: skuTagsURLLoading, error: skuTagsError } =
    useGetMySkuHierarchyUrlQuery({
      client: apiService.getClient(),
      variables: { engineRunId: parseInt(RUNID0) },
      onCompleted(data) {
        const url = data?.getMySkuHierarchyUrl;
        if (url) {
          skuTags.fetch(url);
        }
      },
    });

  useEffect(() => {
    if (!skuTagsLoading && !monthlyDemand0Loading) {
      setTimeout(() => {
        monthlyDemand0.addSkuTags(skuTags.toDict());
      }, 0);
    }
  }, [skuTagsLoading, skuTags, monthlyDemand0Loading, monthlyDemand0]);

  useEffect(() => {
    if (!skuTagsLoading && !monthlyDemand1Loading) {
      monthlyDemand1.addSkuTags(skuTags.toDict());
    }
  }, [skuTagsLoading, skuTags, monthlyDemand1Loading, monthlyDemand1]);

  useEffect(() => {
    if (!skuTagsLoading && !monthlyDemandDiffLoading) {
      monthlyDemandDiff.addSkuTags(skuTags.toDict());
    }
  }, [skuTagsLoading, skuTags, monthlyDemandDiffLoading, monthlyDemandDiff]);

  if (customerError) {
    console.error(
      "ReviewAndPublishContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }

  if (demandMonthly0Error) {
    console.error(
      "ReviewAndPublishContainer: useGetMyDemandMonthlyUrlQuery fetch error",
      demandMonthly0Error
    );
  }
  if (demandMonthly1Error) {
    console.error(
      "ReviewAndPublishContainer: useGetMyDemandMonthlyUrlQuery fetch error",
      demandMonthly1Error
    );
  }
  if (demandMonthlyDiffError) {
    console.error(
      "ReviewAndPublishContainer: useGetMyDemandMonthlyDiffUrlQuery fetch error",
      demandMonthly1Error
    );
  }
  if (skuTagsError) {
    console.error(
      "ReviewAndPublishContainer: useGetMySkuHierarchyUrlLazyQuery fetch error",
      skuTagsError
    );
  }

  return (
    <ReviewAndPublishComponent
      control={
        <PlanningReviewControlContainer
          diffParams={diffParams}
          updateDiffParams={(params: DiffParams) => {}}
        />
      }
      diffParams={diffParams}
      hasLicence={hasLicence}
      loading={customerLoading}
      dataLoading={
        monthlyDemand0Loading ||
        demandMonthly0URLLoading ||
        monthlyDemand1Loading ||
        demandMonthly1URLLoading ||
        monthlyDemandDiffLoading ||
        demandMonthlyDiffURLLoading ||
        skuTagsLoading ||
        skuTagsURLLoading
      }
      demand={[
        ...monthlyDemand0.getDemand(
          MINDATE,
          MAXDATE,
          diffParams.aggregatePeriodType
        ),
        ...monthlyDemand1.getDemand(
          MINDATE,
          MAXDATE,
          diffParams.aggregatePeriodType
        ),
        ...monthlyDemandDiff.getDemand(
          MINDATE,
          MAXDATE,
          diffParams.aggregatePeriodType
        ),
      ]}
      maxRevenue={monthlyDemand0.getMinMax().maxRevenue}
      maxQuantity={monthlyDemand0.getMinMax().maxQuantity}
    />
  );
};
