import { useParams } from "react-router-dom";
import { FeatureFlagsContainer } from "./container";

export const FeatureFlagsPane = () => {
  const params = useParams();
  const { customerId } = params;
  if (customerId === undefined) {
    return null;
  }
  return <FeatureFlagsContainer customerId={customerId} />;
};
