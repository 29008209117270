import { deploymentConfig } from "../../configs/deployment";
import { OrchestratorRunSettings } from "../../services/api/__generated__/backend_gateway-types";

export const runLink = (
  runSettings: OrchestratorRunSettings,
  customerId: string
): string => {
  const { deploymentId } = deploymentConfig;
  const runId = encodeURIComponent(
    `{"input_id":"${runSettings.inputId}","organization_id":"${customerId}","workflow_id":"${runSettings.workflowId}","variables_id":"${runSettings.variablesId}","run_attempt_id":${runSettings.runAttemptId}}`
  );
  return `https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#edit-item?itemMode=2&pk=${runId}&route=ROUTE_ITEM_EXPLORER&sk=&table=${
    deploymentId || "dev"
  }-orchestrator-runs`;
};
