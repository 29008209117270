export const ROUTES = {
  root: { path: "/" },
  reports: { path: "/reports" },
  reports_with_report: { path: "/reports/:workspaceId/:reportId/:pageId" },
  run_history: { path: "/run_history" },
  run_history_with_edit: { path: "/run_history/edit/:runId" },
  runs: { path: "/runs" },
  runs_with_details: {
    path: "/runs/details/:inputId/:workflowId/:variablesId/:runAttemptId",
  },
  run_comparison: { path: "/run_comparison" },
  forecast_accuracy: { path: "/forecast_accuracy" },
  inventory_on_hand: { path: "/inventory_on_hand" },
  engine_presets: { path: "/engine_presets" },
  engine_presets_with_create: { path: "/engine_presets/new" },
  engine_presets_with_replace: { path: "/engine_presets/edit/:presetId" },
  engine_presets_with_run: { path: "/engine_presets/run/:presetId" },
  run_statuses: { path: "/run_statuses" },
  run_statuses_with_details: {
    path: "/run_statuses/run_details/:inputId/:workflowId/:variablesId/:runAttemptId",
  },
  customers: { path: "/customers" },
  customers_with_create: { path: "/customers/new" },
  customers_with_details: { path: "/customers/:customerId" },
  all_runs: { path: "/all_runs" },
  profile: { path: "/profile" },
  odl_to_shipstation: { path: "/odl_to_shipstation" },
  plan_of_record: { path: "/plan_of_record" },
  validation_dashboard: { path: "/validation_dashboard" },
  planning: { path: "/planning" },
  operations: { path: "/operations" },
  po_box: { path: "/pos" },
  po_details: { path: "/po/:id" },
  po_details_with_edit_location: { path: "/po/:id/edit_location/:location" },
  po_details_with_edit_vendor: { path: "/po/:id/edit_vendor/:vendor" },
  po_details_with_edit_logo: { path: "/po/:id/edit_logo" },
  po_details_with_snooze: { path: "/po/:id/snooze" },
};
