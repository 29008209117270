import { useEffect } from "react";
import { auth0Config } from "../../configs/auth0";

export const LoginContainer = (props: {
  invitation: string | null;
  organizationId: string | null;
  organizationName: string | null;
}) => {
  const { invitation, organizationId } = props;
  useEffect(() => {
    window.location.replace(
      `https://${auth0Config.domain}/authorize?response_type=code&client_id=${auth0Config.clientId}&organization=${organizationId}&invitation=${invitation}&redirect_uri=${window.location.origin}/login/callback`
    );
  }, [invitation, organizationId]);
  return <></>;
};
