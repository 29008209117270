import Papa from "papaparse";
import { PeriodType } from "./period_type";

export type Demand = {
  id: string;
  sku: string;
  location: string;
  channel: string | null;
  order_placed_date: string;
  quantity: number; // int
  revenue: number | null; // Decimal | None
  runId: string;
};

export type DemandWithSkuTags = {
  model?: string;
  drive_system?: string;
  battery?: string;
} & Demand;

export class DemandFromCsv {
  private demands: DemandWithSkuTags[];
  private setLoading: (v: boolean) => void;
  private runId: string;
  private maxRevenue: number | null = null;
  private maxQuantity: number | null = null;

  constructor(setLoading: (v: boolean) => void, runId: string) {
    this.demands = [];
    this.setLoading = setLoading;
    this.runId = runId;
  }

  resetDemand() {
    this.demands = [];
    this.maxRevenue = null;
    this.maxQuantity = null;
    this.runId = "";
  }

  setRunId(runId: string) {
    this.runId = runId;
  }

  getMinMax() {
    return {
      maxRevenue: this.maxRevenue,
      maxQuantity: this.maxQuantity,
    };
  }

  async fetch(csvUrl: string) {
    this.setLoading(true);
    this.demands = [];
    Papa.parse<Demand>(csvUrl, {
      download: true,
      worker: true,
      header: true,
      dynamicTyping: true,
      step: (results) => {
        const { sku, location, channel, order_placed_date, quantity, revenue } =
          results.data;
        this.demands.push({
          id: `${sku}${location}${channel}${order_placed_date}`,
          sku,
          location: location || "",
          channel: channel || null,
          order_placed_date: order_placed_date,
          quantity:
            quantity === null || quantity === undefined
              ? 0
              : parseInt(`${quantity}`),
          revenue:
            revenue === null || revenue === undefined
              ? null
              : parseFloat(`${revenue}`),
          runId: this.runId,
        });
      },
      complete: () => {
        this.maxRevenue = Math.max(
          ...(this.demands
            .map((d) => d.revenue)
            .filter((d) => d !== null && d !== undefined) as number[])
        );
        this.maxQuantity = Math.max(
          ...(this.demands
            .map((d) => d.quantity)
            .filter((d) => d !== null && d !== undefined) as number[])
        );
        this.setLoading(false);
      },
    });
  }

  addSkuTags(skuTagDict: Record<string, Record<string, string>>) {
    this.demands.forEach((demand) => {
      const { sku } = demand;
      const tags = skuTagDict[sku] || {};
      Object.keys(tags).forEach((key) => {
        if (tags[key] !== null && tags[key] !== undefined) {
          switch (key) {
            case "model":
              demand.model = tags[key];
              break;
            case "drive_system":
              demand.drive_system = tags[key];
              break;
            case "battery":
              demand.battery = tags[key];
              break;
          }
        }
      });
    });
  }

  getDemand(
    minDate: Date | null,
    maxDate: Date | null,
    aggregatePeriodType: PeriodType | null
  ) {
    const modMinDate =
      minDate &&
      (aggregatePeriodType
        ? aggregatePeriodType === PeriodType.MONTH
          ? new Date(
              Date.UTC(minDate.getUTCFullYear(), minDate.getUTCMonth(), 1)
            )
          : new Date(
              Date.UTC(
                minDate.getUTCFullYear(),
                minDate.getUTCMonth(),
                minDate.getUTCDate() -
                  (minDate.getUTCDay() === 0 ? 7 : minDate.getUTCDay()) +
                  1
              )
            )
        : minDate);
    const modMaxDate = maxDate;
    return this.demands
      .filter((demand) => {
        const demand_date = new Date(`${demand.order_placed_date}Z`);
        if (modMinDate) {
          if (modMaxDate) {
            return demand_date >= modMinDate && demand_date <= modMaxDate;
          }
          return demand_date >= modMinDate;
        }
        if (modMaxDate) {
          return demand_date <= modMaxDate;
        }
        return true;
      })
      .filter(
        (demand) =>
          demand.location !== "" &&
          demand.channel !== "" &&
          demand.channel !== undefined &&
          demand.channel !== null
      );
  }
}
