import LinearProgress from "@mui/joy/LinearProgress";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";
import { useEffect, useState } from "react";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import { Location } from "../../../services/api/__generated__/backend_gateway-types";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Typography from "@mui/joy/Typography";
import Textarea from "@mui/joy/Textarea";

export const PoDetailsEditLocationComponent = (props: {
  loading: boolean;
  navigateBack: () => void;
  location: Location | null;
  setLocation: (address: string, email: string) => Promise<void>;
}) => {
  const { loading, navigateBack, location, setLocation } = props;
  const [address, setAddress] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  useEffect(() => {
    setAddress(location?.address || "");
    setEmail(location?.email || "");
  }, [location]);

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>Edit Location</RightPaneTitle>

      {loading && <LinearProgress />}

      <Typography level="h4">{location?.id || ""}</Typography>

      <FormControl>
        <FormLabel>Address</FormLabel>
        <Textarea
          minRows={3}
          value={address}
          onChange={(event) => {
            setAddress(event.target.value);
          }}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </FormControl>

      <Button
        disabled={address === ""}
        onClick={() => {
          if (address !== "") {
            setLocation(address, email);
          }
        }}
      >
        Store location
      </Button>
    </RightPaneFrame>
  );
};
