import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../../contexts/api/context";
import { EditEnginePresetComponent } from "./component";
import {
  EnginePreset,
  useGetMyEnginePresetsQuery,
  useReplaceMyEnginePresetMutation,
  useGetMyCustomerQuery,
} from "../../../services/api/__generated__/backend_gateway-types";

export const EditEnginePresetContainer = (props: { presetId: string }) => {
  const { presetId } = props;
  const { apiService } = useContext(ApiContext);
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });
  const {
    data: presetData,
    loading: presetsLoading,
    error: presetsError,
  } = useGetMyEnginePresetsQuery({
    client: apiService.getClient(),
  });
  const [
    replaceMyEnginePreset,
    { loading: replaceLoading, error: replaceError },
  ] = useReplaceMyEnginePresetMutation({
    client: apiService.getClient(),
  });
  const navigate = useNavigate();

  const navigateToEnginePresets = () => {
    navigate("/engine_presets", { replace: true });
  };

  const preset: EnginePreset | null =
    presetData?.getMyEnginePresets?.find((p) => p.id === parseInt(presetId)) ||
    null;

  const replacePreset = async (
    presetName: string,
    presetDescription: string,
    orderToDeliveryDaysOffset: number | null,
    weightingMethod: string | null,
    orderSimulationEndDate: string | null,
    historicalDatePatterns: boolean | null,
    weightingWindowDays: number | null,
    bindToSKUOverrides: boolean | null,
    bindToForecastUpload: boolean | null,
    modifyExistingPos: boolean | null,
    dyeYieldLoss: number | null,
    sewYieldLoss: number | null,
    cutYieldLoss: number | null,
    weeksOfSupply: number | null,
    useBufferForecast: boolean | null,
    desiredMinWeeksOnHand: number | null,
    leadTimeInWeeks: number | null,
    assumedCustomerGrowth: number | null,
    excludeForecast: boolean | null,
    excludeYieldPlan: boolean | null,
    truckOrderToPackDays: number | null,
    onHoldToAvailableDays: number | null,
    productionStartToEndDays: number | null,
    darkRoastRuntime: number | null,
    mediumRoastRuntime: number | null,
    lightRoastRuntime: number | null,
    defaultChangeoverTime: number | null,
    decafChangeoverTime: number | null,
    turnOnProductionBatching: boolean | null,
    organicMillingNextProductionRun: string | null,
    cnpNextProductionRun: string | null,
    taraNextProductionRun: string | null,
    auroraNextProductionRun: string | null,
    firmWindowLookAhead: number | null
  ) => {
    await replaceMyEnginePreset({
      variables: {
        enginePreset: {
          id: parseInt(presetId),
          name: presetName,
          description: presetDescription,
          ...(orderToDeliveryDaysOffset !== null && {
            orderToDeliveryDaysOffset,
          }),
          ...(weightingMethod !== null && { weightingMethod }),
          ...(orderSimulationEndDate !== null && { orderSimulationEndDate }),
          ...(historicalDatePatterns !== null && { historicalDatePatterns }),
          ...(weightingWindowDays !== null && { weightingWindowDays }),
          ...(bindToSKUOverrides !== null && { bindToSKUOverrides }),
          ...(bindToForecastUpload !== null && { bindToForecastUpload }),
          ...(modifyExistingPos !== null && { modifyExistingPos }),
          ...(dyeYieldLoss !== null && { dyeYieldLoss }),
          ...(sewYieldLoss !== null && { sewYieldLoss }),
          ...(cutYieldLoss !== null && { cutYieldLoss }),
          ...(weeksOfSupply !== null && { weeksOfSupply }),
          ...(useBufferForecast !== null && { useBufferForecast }),
          ...(desiredMinWeeksOnHand !== null && { desiredMinWeeksOnHand }),
          ...(leadTimeInWeeks !== null && { leadTimeInWeeks }),
          ...(assumedCustomerGrowth !== null && { assumedCustomerGrowth }),
          ...(excludeForecast !== null && { excludeForecast }),
          ...(excludeYieldPlan !== null && { excludeYieldPlan }),
          ...(truckOrderToPackDays !== null && { truckOrderToPackDays }),
          ...(onHoldToAvailableDays !== null && { onHoldToAvailableDays }),
          ...(productionStartToEndDays !== null && {
            productionStartToEndDays,
          }),
          ...(darkRoastRuntime !== null && { darkRoastRuntime }),
          ...(mediumRoastRuntime !== null && { mediumRoastRuntime }),
          ...(lightRoastRuntime !== null && { lightRoastRuntime }),
          ...(defaultChangeoverTime !== null && { defaultChangeoverTime }),
          ...(decafChangeoverTime !== null && { decafChangeoverTime }),
          ...(turnOnProductionBatching !== null && {
            turnOnProductionBatching,
          }),
          ...(organicMillingNextProductionRun !== null && {
            organicMillingNextProductionRun,
          }),
          ...(cnpNextProductionRun !== null && { cnpNextProductionRun }),
          ...(taraNextProductionRun !== null && { taraNextProductionRun }),
          ...(auroraNextProductionRun !== null && { auroraNextProductionRun }),
          ...(firmWindowLookAhead !== null && { firmWindowLookAhead }),
          default: preset?.default || false,
          customerId: "",
        },
      },
    });
    await apiService.getClient().refetchQueries({
      include: ["GetMyEnginePresets"],
    });
  };

  if (customerError) {
    console.error(
      "EditEnginePresetContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }

  if (presetsError) {
    console.error(
      "EditEnginePresetContainer: useGetMyEnginePresetsQuery fetch error",
      presetsError
    );
  }

  if (replaceError) {
    console.error(
      "EditEnginePresetContainer: useReplaceMyEnginePresetMutation fetch error",
      replaceError
    );
  }

  return (
    <EditEnginePresetComponent
      preset={preset}
      loading={presetsLoading || customerLoading}
      navigateBack={navigateToEnginePresets}
      replacePreset={replacePreset}
      replaceLoading={replaceLoading}
      customer={customerData?.getMyCustomer || null}
    />
  );
};
