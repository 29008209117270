import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import List from "@mui/joy/List";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import React, { useMemo } from "react";
import { RIGHT_PLANE_WIDTH } from "../../../frameworks/joy/layouts/drawer_layout/constants";
import {
  OrchestratorRun,
  OrchestratorStep,
  OrchestratorStepStatus,
} from "../../../services/api/__generated__/backend_gateway-types";
import { StepId } from "../step_id";
import {
  stepAzureAsyncLogLink,
  stepDirectLogLink,
  stepEngineLogLink,
  stepExportImportLogLink,
  stepPoLogLink,
} from "../step_log_link";
import { stepStatusToEmoji } from "../step_status_to_emoji";
import { stepStatusToText } from "../step_status_to_text";
import { stepIdToType } from "./step_id_to_type";
import { StepType } from "./step_type";
import CircularProgress from "@mui/joy/CircularProgress";
dayjs.extend(duration);
dayjs.extend(utc);

type StrOrStrList = string | StrOrStrList[];

const flatten = (vl: StrOrStrList[]): string[] => {
  const acc: string[] = [];
  for (const v of vl) {
    if (typeof v === "string") {
      acc.push(v);
    } else {
      // acc = [...acc, ...flatten(v)];
      acc.push(...flatten(v));
    }
  }
  return acc;
};

type Step = {
  createdAt: string | null;
  durationTimeMS: number | null;
  status: OrchestratorStepStatus;
  stepId: string;
};

const findLastStep = (
  stepId: string,
  reverseSortedSteps: OrchestratorStep[],
  sortedSteps: OrchestratorStep[]
): Step | undefined => {
  const lastStep = reverseSortedSteps.find((s) => s.stepId === stepId);
  if (!lastStep) {
    return {
      createdAt: null,
      durationTimeMS: null,
      status: OrchestratorStepStatus.ToExecute,
      stepId,
    };
  }
  const s: Step = {
    createdAt: `${dayjs
      .utc(lastStep.createdAt)
      .local()
      .format("MM/DD/YYYY hh:mm:ss a")}`,
    status: lastStep.status,
    stepId,
    durationTimeMS: null,
  };
  if (lastStep.status === OrchestratorStepStatus.ExecutionCompleted) {
    const startStep = sortedSteps.find(
      (s) =>
        s.stepId === stepId &&
        s.status === OrchestratorStepStatus.ExecutionStarted
    );
    if (startStep !== undefined) {
      s.durationTimeMS = dayjs(lastStep.createdAt).diff(
        startStep.createdAt,
        "ms"
      );
      s.createdAt = `${dayjs
        .utc(startStep.createdAt)
        .local()
        .format("MM/DD/YYYY hh:mm:ss a")}`;
    }
  }
  return s;
};

export const StepOverviewComponent = (props: {
  run: OrchestratorRun;
  steps: OrchestratorStep[];
  rerunStep: (run: OrchestratorRun, stepId: string) => Promise<void>;
  rerunStepId: string | null;
}) => {
  const { run, steps, rerunStep, rerunStepId } = props;
  const stepIdsStrOrStrList: StrOrStrList[] = JSON.parse(run.stepIds || "[]");
  const stepIds = useMemo(
    () => flatten(stepIdsStrOrStrList),
    [stepIdsStrOrStrList]
  );
  const reverseSortedSteps = useMemo(
    () =>
      [...steps].sort((a: OrchestratorStep, b: OrchestratorStep) =>
        dayjs(a.createdAt).diff(b.createdAt) < 0 ? 1 : -1
      ),
    [steps]
  );
  const sortedSteps = useMemo(
    () => [...reverseSortedSteps].reverse(),
    [reverseSortedSteps]
  );
  return (
    <>
      <Typography level="body-lg" sx={{ pt: 1, fontWeight: 700 }}>
        Step Overview (ROOT only)
      </Typography>
      <Sheet
        variant="outlined"
        sx={{
          p: 1,
          maxWidth: `${RIGHT_PLANE_WIDTH - 20}px`,
        }}
      >
        <List
          aria-labelledby="step-list"
          sx={{ "--ListItemDecorator-size": "56px" }}
        >
          {stepIds.map((stepId, i) => {
            const step = findLastStep(stepId, reverseSortedSteps, sortedSteps);
            if (!step) {
              return null;
            }

            return (
              <React.Fragment key={i}>
                <ListItem
                  endAction={
                    <Chip size="sm">
                      {stepStatusToEmoji(step.status)}{" "}
                      {stepStatusToText(step.status)}
                    </Chip>
                  }
                >
                  <ListItemContent>
                    <StepId stepId={step.stepId} />
                    <Typography level="body-xs" noWrap>
                      {step.status !== OrchestratorStepStatus.ExecutionQueued &&
                        step.createdAt && (
                          <>
                            {stepIdToType(step.stepId) === StepType.PY && (
                              <>
                                <Link
                                  href={stepDirectLogLink(
                                    step.createdAt,
                                    step.durationTimeMS
                                  )}
                                >
                                  Logs
                                </Link>
                              </>
                            )}
                            {stepIdToType(step.stepId) ===
                              StepType.ENGINE_TRIGGER && (
                              <>
                                <Link
                                  href={stepEngineLogLink(
                                    step.createdAt,
                                    step.durationTimeMS
                                  )}
                                >
                                  Logs
                                </Link>
                              </>
                            )}
                            {stepIdToType(step.stepId) === StepType.TRIGGER &&
                              [
                                "GDRIVE_TO_INPUT_CSV",
                                "INPUT_CSV_TO_SQL",
                              ].includes(step.stepId.split("|")[1]) && (
                                <>
                                  <Link
                                    href={stepExportImportLogLink(
                                      step.createdAt,
                                      step.durationTimeMS
                                    )}
                                  >
                                    Logs
                                  </Link>
                                </>
                              )}
                            {stepIdToType(step.stepId) === StepType.TRIGGER &&
                              [
                                "DELETE_DB",
                                "CREATE_DB",
                                "SQL_TO_TABLES",
                                "SYNC_POWERBI",
                              ].includes(step.stepId.split("|")[1]) && (
                                <>
                                  <Link
                                    href={stepAzureAsyncLogLink(
                                      step.createdAt,
                                      step.durationTimeMS
                                    )}
                                  >
                                    Logs
                                  </Link>
                                </>
                              )}
                            {stepIdToType(step.stepId) === StepType.TRIGGER &&
                              ["POS_TO_SQL"].includes(
                                step.stepId.split("|")[1]
                              ) && (
                                <>
                                  <Link
                                    href={stepPoLogLink(
                                      step.createdAt,
                                      step.durationTimeMS
                                    )}
                                  >
                                    Logs
                                  </Link>
                                </>
                              )}

                            {stepIdToType(step.stepId) === StepType.ENGINE && (
                              <>
                                <Link
                                  href={stepAzureAsyncLogLink(
                                    step.createdAt,
                                    step.durationTimeMS
                                  )}
                                >
                                  Logs
                                </Link>
                              </>
                            )}
                          </>
                        )}
                    </Typography>
                    <Typography level="body-xs" noWrap>
                      {step.createdAt
                        ? `Started at  ${step.createdAt}`
                        : "Not started yet"}
                    </Typography>
                    <Typography level="body-xs" noWrap>
                      {step.durationTimeMS
                        ? `Duration: ${dayjs
                            .duration(step.durationTimeMS)
                            .format("mm:ss.SSS")}`
                        : ""}
                    </Typography>
                    {step.status !== OrchestratorStepStatus.ToExecute &&
                      step.status !==
                        OrchestratorStepStatus.ExecutionCompleted && (
                        <Typography level="body-xs" noWrap color="primary">
                          <Link
                            onClick={() => rerunStep(run, step.stepId)}
                            disabled={rerunStepId === step.stepId}
                          >
                            Restart step
                          </Link>
                          {rerunStepId === step.stepId && (
                            <CircularProgress size="sm" variant="plain" />
                          )}
                        </Typography>
                      )}
                  </ListItemContent>
                </ListItem>
                {i < stepIds.length - 1 && <ListDivider />}
              </React.Fragment>
            );
          })}
        </List>
      </Sheet>
    </>
  );
};
