import { OrchestratorRunStatus } from "../../services/api/__generated__/backend_gateway-types";

export const runStatusToText = (runStatus: OrchestratorRunStatus): string => {
  switch (runStatus) {
    case OrchestratorRunStatus.ToRun:
      return "To Run";
    case OrchestratorRunStatus.RunInProgress:
      return "In Progress";
    case OrchestratorRunStatus.RunCompleted:
      return "Completed";
    case OrchestratorRunStatus.RunFailed:
      return "Failed";
    case OrchestratorRunStatus.CancelInProgress:
      return "Cancelling";
    case OrchestratorRunStatus.CancelCompleted:
      return "Cancelled";
    case OrchestratorRunStatus.CancelFailed:
      return "Cancelling Failed";
  }
};
