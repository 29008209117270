import { useEffect, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { auth0Config } from "./configs/auth0";
import { LoginPage } from "./pages/login";
import { LoginCallbackPage } from "./pages/login_callback";
import { AuthenticationService } from "./services/api/authentication";
import { ApiProvider } from "./contexts/api/provider";
import { ThemedLayout } from "./frameworks/joy/themed_layout";
import { WelcomePage } from "./pages/welcome";
import { WhoAmIPage } from "./pages/who_am_i";
import { ReportsPage } from "./pages/reports";
import { ReportPage } from "./pages/report";
import { RunComparisonPage } from "./pages/run_comparison";
import { RunHistoryPage } from "./pages/run_history";
import { EditRunPane } from "./pages/run_history/edit_run_pane";
import { PresetsPage } from "./pages/engine_presets";
import { EditEnginePresetPane } from "./pages/engine_presets/edit_engine_preset_pane";
import { AddEnginePresetPane } from "./pages/engine_presets/add_engine_preset_pane";
import { RunEnginePresetPane } from "./pages/engine_presets/run_engine_preset_pane";
import { GoodchopOdlToShipStationPage } from "./pages/goodchop/goodchop_odl_to_shipstation";
import { ForecastAccuracyPage } from "./pages/forecast_accuracy";
import { ValidationDashboardPage } from "./pages/validation_dashboard";
import { PlanOfRecordPage } from "./pages/plan_of_record";
import { PlanningPage } from "./pages/planning";
import { OperationsPage } from "./pages/operations";
import { InventoryAtHandPage } from "./pages/inventory_on_hand";
import { InventoryAtHandForecastPage } from "./pages/inventory_on_hand_forecast";
import { GoodchopGrossOdlGenerationPage } from "./pages/goodchop/goodchop_gross_odl_generation";
import { useWhoAmIQuery } from "./services/api/__generated__/backend_gateway-types";
import { useContext } from "react";
import { ApiContext } from "./contexts/api/context";
import { RunStatusesPage } from "./pages/run_statuses";
import { RunDetailsPane } from "./pages/run_statuses/run_details_pane";
import { RunLoginDashPage } from "./pages/internal_dashboards";
import { CustomersPage } from "./pages/customers";
import { FeatureFlagsPane } from "./pages/customers/feature_flags";
import { AddCustomerPane } from "./pages/customers/add_customer_pane";
import { AllRunsPage } from "./pages/all_runs";
import { SysMessagesProvider } from "./contexts/sys_messages/provider";
import { PoBoxPage } from "./pages/po_box";
import { PoDetailsPage } from "./pages/po_details";
import { PoDetailsEditLogoPane } from "./pages/po_details/edit_logo_pane";
import { PoDetailsEditLocationPane } from "./pages/po_details/edit_location_pane";
import { PoDetailsEditVendorPane } from "./pages/po_details/edit_vendor_pane";
import { PoDetailsSetSnoozePane } from "./pages/po_details/set_snooze_pane";
import { PosProvider } from "./contexts/pos/provider";
import { WorkflowsProvider } from "./contexts/workflows/provider";
import { EngineHistoryPage } from "./pages/engine_history";
import { RunsDetailsPane } from "./pages/engine_history/details_pane";
import { SystemMessagesContainer } from "./components/system_messages/container";

const Unauthorized = () => {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);
  return <></>;
};

const AuthCheck = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    getIdTokenClaims,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const [hasIdToken, setHasIdToken] = useState<boolean>(false);
  const { apiService /*selectedCustomerId*/ } = useContext(ApiContext);

  const {
    data: whoAmIData,
    //loading: whoAmILoading,
    //error: whoAmIError,
  } = useWhoAmIQuery({
    client: apiService.getClient(),
  });

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    const getToken = async () => {
      // console.info("getToken fired");
      try {
        /* const accessToken = */ await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        AuthenticationService.setIdToken(idToken);
        if (!idToken) {
          console.info("Got an empty token, retrying to fetch tokens in 1s");
          timeoutId = setTimeout(getToken, 1000);
        }
        setHasIdToken(!!idToken);
      } catch (e) {
        const { message }: { message: string | undefined } = e as any;
        if (message?.indexOf("Missing Refresh Token") !== 0) {
          console.error(e);
          // Probably the user is still logged in with a pre-refreshtoken auth method
          logout();
          window.location.reload();
        }
        console.info("Retrying to fetch tokens in 1s");
        timeoutId && clearTimeout(timeoutId);
        timeoutId = setTimeout(getToken, 1000);
      }
    };
    getToken();
    return () => {
      // console.info("clearing timeout");
      timeoutId && clearTimeout(timeoutId);
    };
  }, [getAccessTokenSilently, getIdTokenClaims, logout]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      // Access and ID tokens do expire. Calling getAccessTokenSilently
      // uses the refresh token to get new access and id tokens.
      // The most robust way would be to check the token expiry at every
      // backend request, and refresh the tokens if needed. That approach
      // requires more complexity in the code, as getAccessTokenSilently
      // and getIdTokenClaims are only available as a React hook, so we
      // either have to create a context object for that, or need to use
      // js (non-react) sdk directly.
      // As a workaround checking the expiry every 10s, and if it's about
      // to expire then refreshing it.
      const expDate = new Date(
        (AuthenticationService.getIdToken()?.exp || 0) * 1000
      );
      const inTenSecs = new Date();
      inTenSecs.setTime(inTenSecs.getTime() + 10 * 1000); // ms
      if (AuthenticationService.getIdToken() && expDate < inTenSecs) {
        console.info("Tokens expired, refreshing them.");
        try {
          /* const accessToken = */ await getAccessTokenSilently({
            // Withoutt this it takes the expired token from the cache and
            // keeps using it :face_palm:
            cacheMode: "off",
          });
          const idToken = await getIdTokenClaims();
          AuthenticationService.setIdToken(idToken);
          if (!idToken) {
            console.info("Got an empty token, reloading the page");
            logout();
            window.location.reload();
          }
          setHasIdToken(!!idToken);
        } catch (e) {
          const { message }: { message: string | undefined } = e as any;
          if (message?.indexOf("Missing Refresh Token") !== 0) {
            console.error(e);
            // Probably the user is still logged in with a pre-refreshtoken auth method
            logout();
            window.location.reload();
          }
        }
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [getAccessTokenSilently, getIdTokenClaims, logout]);

  useEffect(() => {
    if (isAuthenticated && whoAmIData?.whoAmI?.email) {
      heap.identify(whoAmIData.whoAmI.email);
    }
  }, [isAuthenticated, whoAmIData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (!isAuthenticated) {
    // AuthenticationService.setIdToken(undefined);
    // loginWithRedirect();
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login">
            <Route index element={<LoginPage />} />
            <Route path="callback" element={<LoginCallbackPage />} />
          </Route>
          <Route path="*" element={<Unauthorized />} />
        </Routes>
      </BrowserRouter>
    );
  } else if (!hasIdToken) {
    return <div>Authenticating...</div>;
  }

  return (
    <ApiProvider>
      <WorkflowsProvider>
        <SysMessagesProvider>
          <PosProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<ThemedLayout />}>
                  <Route index element={<WelcomePage />} />
                  <Route path="/reports">
                    <Route index element={<ReportsPage />} />
                    <Route
                      path=":workspaceId/:reportId/:pageId"
                      element={<ReportPage />}
                    />
                  </Route>
                  <Route
                    path="/run_comparison"
                    element={<RunComparisonPage />}
                  />
                  <Route
                    path="/plan_of_record"
                    element={<PlanOfRecordPage />}
                  />
                  <Route
                    path="/validation_dashboard"
                    element={<ValidationDashboardPage />}
                  />
                  <Route path="/planning" element={<PlanningPage />} />
                  <Route path="/operations" element={<OperationsPage />} />

                  <Route
                    path="/forecast_accuracy"
                    element={<ForecastAccuracyPage />}
                  />
                  <Route
                    path="/inventory_on_hand"
                    element={<InventoryAtHandPage />}
                  />
                  <Route
                    path="/inventory_on_hand_forecast"
                    element={<InventoryAtHandForecastPage />}
                  />

                  <Route path="/run_history" element={<RunHistoryPage />}>
                    <Route path="edit/:id" element={<EditRunPane />} />
                  </Route>
                  <Route path="/runs" element={<EngineHistoryPage />}>
                    <Route
                      path="details/:inputId/:workflowId/:variablesId/:runAttemptId"
                      element={<RunsDetailsPane />}
                    />
                  </Route>

                  <Route path="/engine_presets" element={<PresetsPage />}>
                    <Route path="new" element={<AddEnginePresetPane />} />
                    <Route path="edit/:id" element={<EditEnginePresetPane />} />
                    <Route path="run/:id" element={<RunEnginePresetPane />} />
                  </Route>

                  <Route path="/run_statuses" element={<RunStatusesPage />}>
                    <Route
                      path="run_details/:inputId/:workflowId/:variablesId/:runAttemptId"
                      element={<RunDetailsPane />}
                    />
                  </Route>

                  <Route path="/customers" element={<CustomersPage />}>
                    <Route path=":customerId" element={<FeatureFlagsPane />} />
                    <Route path="new" element={<AddCustomerPane />} />
                  </Route>

                  <Route path="/all_runs" element={<AllRunsPage />} />

                  <Route path="/profile" element={<WhoAmIPage />} />

                  <Route path="/login">
                    <Route index element={<LoginPage />} />
                    <Route path="callback" element={<LoginCallbackPage />} />
                  </Route>

                  {/* Goodchop Specific Routes */}
                  <Route
                    path="/odl_to_shipstation"
                    element={<GoodchopOdlToShipStationPage />}
                  />
                  <Route
                    path="/gross_odl_generation"
                    element={<GoodchopGrossOdlGenerationPage />}
                  />

                  <Route path="/runs-logins" element={<RunLoginDashPage />} />

                  <Route path="/pos" element={<PoBoxPage />} />
                  <Route path="/po/:id" element={<PoDetailsPage />}>
                    {/* <Route index element={<PoDetailsEditLogoPane />} /> */}
                    <Route
                      path="edit_logo"
                      element={<PoDetailsEditLogoPane />}
                    />
                    <Route
                      path="edit_location/:location"
                      element={<PoDetailsEditLocationPane />}
                    />
                    <Route
                      path="edit_vendor/:vendor"
                      element={<PoDetailsEditVendorPane />}
                    />
                    <Route path="snooze" element={<PoDetailsSetSnoozePane />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </PosProvider>
          <SystemMessagesContainer />
        </SysMessagesProvider>
      </WorkflowsProvider>
    </ApiProvider>
  );
};

const App = () => {
  const { domain, clientId } = auth0Config;
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      useRefreshTokens={true}
      // This is required for refresh tokens
      // to continue to work after the page
      // is reloaded.
      cacheLocation="localstorage"
    >
      <AuthCheck />
    </Auth0Provider>
  );
};

export default App;
