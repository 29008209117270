import Grid from "@mui/joy/Grid";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";
import { StockOutRate } from "./stock_out_rate";
import FormControl from "@mui/joy/FormControl";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import { ChartsGrid } from "@mui/x-charts/ChartsGrid";

export const InventoryOnHandForecastStockOutRateComponent = (props: {
  stockOutRate: StockOutRate[];
  loading: boolean;
  downloadSummaryCsvBlob: () => void;
  downloadDetailedCsvBlob: () => void;
}) => {
  const {
    stockOutRate,
    loading,
    downloadSummaryCsvBlob,
    downloadDetailedCsvBlob,
  } = props;
  const percentFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format;
  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      <Grid xl={12} lg={12}>
        {stockOutRate.length > 0 && (
          <MaterialEmbed>
            <ResponsiveChartContainer
              xAxis={[
                {
                  data: stockOutRate.map((sor) => sor.date),
                  scaleType: "time",
                  valueFormatter: (date) =>
                    new Date(
                      date.getTime() + date.getTimezoneOffset() * 60000
                    ).toLocaleDateString(),
                  id: "x-axis-id",
                },
              ]}
              yAxis={[
                {
                  scaleType: "linear",
                  // min: 0,
                  // max: 1,
                  label: "In-stock rate %",
                  valueFormatter: (y) => `${y * 100}`,
                  id: "y-axis-id",
                },
              ]}
              series={[
                {
                  type: "line",
                  data: stockOutRate.map((sor) => sor.inStockRate),
                  showMark: true,
                  valueFormatter: (v) =>
                    v === null ? "" : percentFormatter(v),
                },
              ]}
              height={300}
              margin={{ left: 50 }}
              sx={{
                [`& .${axisClasses.left} .${axisClasses.label}`]: {
                  transform: "translateX(-10px)",
                },
              }}
            >
              <ChartsGrid vertical horizontal />
              <LinePlot />
              <ChartsXAxis axisId="x-axis-id" />
              <ChartsYAxis axisId="y-axis-id" />
              <MarkPlot />
              <ChartsTooltip />
            </ResponsiveChartContainer>
          </MaterialEmbed>
        )}
      </Grid>
      <Grid xl={12} lg={12}>
        {stockOutRate.length > 0 && (
          <FormControl
            sx={{
              m: 2,
              pt: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              <Button
                disabled={loading}
                color="primary"
                variant="solid"
                size="lg"
                onClick={downloadSummaryCsvBlob}
              >
                Export: Summary Data
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="solid"
                size="lg"
                onClick={downloadDetailedCsvBlob}
              >
                Export: Detailed Data
              </Button>
            </Box>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};
