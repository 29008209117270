import { FC } from "react";
import { CustomerSelectorContainer } from "./container";

interface CustomerSelectorProps {
  navigateToRoot: () => void;
}

export const CustomerSelector: FC<CustomerSelectorProps> = (props) => {
  const { navigateToRoot } = props;
  return <CustomerSelectorContainer navigateToRoot={navigateToRoot} />;
};
