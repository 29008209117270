import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../contexts/api/context";
import {
  PowerBi,
  useGetMyPowerBisQuery,
  useGetMyPowerBiReportItemsLazyQuery,
  PowerBiReportPages,
  useGetMyUserCommentsByAnchorLazyQuery,
  useGetMyUserApprovalsByAnchorLazyQuery,
  UserComment,
  useAddMyUserCommentMutation,
  useWhoAmIQuery,
  GetMyUserCommentsByAnchorDocument,
  GetMyUserCommentsByAnchorQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { PlanOfRecordComponent } from "./component";
import { RunTriggerContext } from "../../contexts/run_trigger/context";

export const PlanOfRecordContainer = () => {
  const { apiService } = useContext(ApiContext);

  const {
    data: getMyPowerBisData,
    loading: getMyPowerBisLoading,
    error: getMyPowerBisError,
  } = useGetMyPowerBisQuery({
    client: apiService.getClient(),
  });

  const [
    getMyPowerBiReportItems,
    {
      loading: getMyPowerBiReportItemsLoading,
      error: getMyPowerBiReportItemsError,
    },
  ] = useGetMyPowerBiReportItemsLazyQuery({
    client: apiService.getClient(),
  });

  const [
    getMyUserCommentsByAnchor,
    {
      loading: getMyUserCommentsByAnchorLoading,
      error: getMyUserCommentsByAnchorError,
    },
  ] = useGetMyUserCommentsByAnchorLazyQuery({
    client: apiService.getClient(),
  });

  const [
    getMyUserApprovalsByAnchor,
    {
      loading: getMyUserApprovalsByAnchorLoading,
      error: getMyUserApprovalsByAnchorError,
    },
  ] = useGetMyUserApprovalsByAnchorLazyQuery({
    client: apiService.getClient(),
  });

  const {
    data: whoAmIData,
    loading: whoAmILoading,
    error: whoAmIError,
  } = useWhoAmIQuery({
    client: apiService.getClient(),
  });

  const { runLogs, isLogsLoading } = useContext(RunTriggerContext);

  const [reportId, setReportId] = useState<string | null>(null);
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  const [embedToken, setEmbedToken] = useState<string | null>(null);
  const [embedTokenId, setEmbedTokenId] = useState<string | null>(null);
  const [embedTokenExpiration, setEmbedTokenExpiration] = useState<
    string | null
  >(null);
  const [sales, setSales] = useState<PowerBiReportPages | null>(null);
  const [production, setProduction] = useState<PowerBiReportPages | null>(null);
  const [logistics, setLogistics] = useState<PowerBiReportPages | null>(null);
  const [delivery, setDelivery] = useState<PowerBiReportPages | null>(null);

  const [salesComments, setSalesComments] = useState<UserComment[]>([]);
  const [productionComments, setProductionComments] = useState<UserComment[]>(
    []
  );
  const [logisticsComments, setLogisticsComments] = useState<UserComment[]>([]);
  const [deliveryComments, setDeliveryComments] = useState<UserComment[]>([]);

  const [salesNewComment, setSalesNewComment] = useState<string>("");
  const [productionNewComment, setProductionNewComment] = useState<string>("");
  const [logisticsNewComment, setLogisticsNewComment] = useState<string>("");
  const [deliveryNewComment, setDeliveryNewComment] = useState<string>("");

  const [
    addMyUserComment,
    { loading: addMyUserCommentLoading, error: addMyUserCommentError },
  ] = useAddMyUserCommentMutation({
    client: apiService.getClient(),
  });

  useEffect(() => {
    const myPowerBis = getMyPowerBisData?.getMyPowerBis || ([] as PowerBi[]);
    const workspaceId =
      myPowerBis.length > 0 ? myPowerBis[0].workspaceId : null;
    if (workspaceId !== null) {
      getMyPowerBiReportItems({
        variables: { workspaceId },
        onCompleted(data) {
          if (data?.getMyPowerBiReportItems === undefined) {
            return;
          }
          setEmbedToken(data!.getMyPowerBiReportItems!.embedToken);
          setEmbedTokenId(data!.getMyPowerBiReportItems!.embedTokenId);
          setEmbedTokenExpiration(
            data!.getMyPowerBiReportItems!.embedTokenExpiration
          );
          const { reports } = data!.getMyPowerBiReportItems!;
          const { pages, reportId, embedUrl } = reports[0];
          setReportId(reportId);
          setEmbedUrl(embedUrl);
          pages.forEach((page) => {
            if (page.pageName === "POR: Sales - Pivot") {
              setSales(page);
            } else if (page.pageName === "POR: Production - Pivot") {
              setProduction(page);
            } else if (page.pageName === "POR: Logistics - Pivot") {
              setLogistics(page);
            } else if (page.pageName === "POR: Delivery - Pivot") {
              setDelivery(page);
            }
          });
        },
      });
    }
  }, [getMyPowerBisData, getMyPowerBiReportItems]);

  const lastestRun = runLogs[runLogs.length - 1];

  const updateComments = (data: GetMyUserCommentsByAnchorQuery) => {
    setSalesComments(() => []);
    setProductionComments(() => []);
    setLogisticsComments(() => []);
    setDeliveryComments(() => []);
    data?.getMyUserCommentsByAnchor.forEach((comment) => {
      if (comment.anchorRef === `planOfRecord|sales|${lastestRun.id}`) {
        setSalesComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef === `planOfRecord|production|${lastestRun.id}`
      ) {
        setProductionComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef === `planOfRecord|logistics|${lastestRun.id}`
      ) {
        setLogisticsComments((last) => [...last, comment]);
      } else if (
        comment.anchorRef === `planOfRecord|delivery|${lastestRun.id}`
      ) {
        setDeliveryComments((last) => [...last, comment]);
      }
    });
  };

  useEffect(() => {
    if (!runLogs || runLogs.length < 1) {
      return;
    }
    const lastestRun = runLogs[runLogs.length - 1];
    getMyUserCommentsByAnchor({
      variables: {
        anchorRefs: [
          `planOfRecord|sales|${lastestRun.id}`,
          `planOfRecord|production|${lastestRun.id}`,
          `planOfRecord|logistics|${lastestRun.id}`,
          `planOfRecord|delivery|${lastestRun.id}`,
        ],
      },
      onCompleted(data) {
        data?.getMyUserCommentsByAnchor.forEach((comment) => {
          if (comment.anchorRef === `planOfRecord|sales|${lastestRun.id}`) {
            setSalesComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef === `planOfRecord|production|${lastestRun.id}`
          ) {
            setProductionComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef === `planOfRecord|logistics|${lastestRun.id}`
          ) {
            setLogisticsComments((last) => [...last, comment]);
          } else if (
            comment.anchorRef === `planOfRecord|delivery|${lastestRun.id}`
          ) {
            setDeliveryComments((last) => [...last, comment]);
          }
        });
      },
    });
    getMyUserApprovalsByAnchor({
      variables: {
        anchorRefs: [
          `planOfRecord|sales|${lastestRun.id}`,
          `planOfRecord|production|${lastestRun.id}`,
          `planOfRecord|logistics|${lastestRun.id}`,
          `planOfRecord|delivery|${lastestRun.id}`,
        ],
      },
    });
  }, [runLogs, getMyUserCommentsByAnchor, getMyUserApprovalsByAnchor]);

  const addComment = (
    message: string,
    anchorRef: string,
    callback: () => void
  ) => {
    addMyUserComment({
      variables: {
        newComment: {
          anchorRef,
          message,
        },
      },
      optimisticResponse: {
        addMyUserComment: {
          __typename: "UserComment",
          id: `${anchorRef}|${new Date().toISOString()}|${
            whoAmIData?.whoAmI?.id
          }`,
          anchorRef,
          userId: `${whoAmIData?.whoAmI?.id}`,
          date: new Date().toISOString(),
          message,
        },
      },
      update(cache, { data }) {
        const { getMyUserCommentsByAnchor } = cache.readQuery({
          query: GetMyUserCommentsByAnchorDocument,
          variables: {
            anchorRefs: [
              `planOfRecord|sales|${lastestRun.id}`,
              `planOfRecord|production|${lastestRun.id}`,
              `planOfRecord|logistics|${lastestRun.id}`,
              `planOfRecord|delivery|${lastestRun.id}`,
            ],
          },
        }) as { getMyUserCommentsByAnchor: UserComment[] };
        const newComment: UserComment = data?.addMyUserComment as UserComment;
        if (
          getMyUserCommentsByAnchor.map((i) => i.id).includes(newComment.id)
        ) {
          return;
        }
        const newData = {
          getMyUserCommentsByAnchor: [
            ...getMyUserCommentsByAnchor,
            { ...(data?.addMyUserComment as UserComment) },
          ],
        };
        cache.writeQuery({
          query: GetMyUserCommentsByAnchorDocument,
          variables: {
            anchorRefs: [
              `planOfRecord|sales|${lastestRun.id}`,
              `planOfRecord|production|${lastestRun.id}`,
              `planOfRecord|logistics|${lastestRun.id}`,
              `planOfRecord|delivery|${lastestRun.id}`,
            ],
          },
          data: newData,
        });
        updateComments(newData);
      },
      onCompleted: callback,
    });
  };

  const addCommentToSales = (message: string) => {
    addComment(message, `planOfRecord|sales|${lastestRun.id}`, () =>
      setSalesNewComment("")
    );
  };

  const addCommentToProduction = (message: string) => {
    addComment(message, `planOfRecord|production|${lastestRun.id}`, () =>
      setProductionNewComment("")
    );
  };

  const addCommentToLogistics = (message: string) => {
    addComment(message, `planOfRecord|logistics|${lastestRun.id}`, () =>
      setLogisticsNewComment("")
    );
  };

  const addCommentToDelivery = (message: string) => {
    addComment(message, `planOfRecord|delivery|${lastestRun.id}`, () =>
      setDeliveryNewComment("")
    );
  };

  if (getMyPowerBisError) {
    console.error(getMyPowerBisError.message);
  }

  if (getMyPowerBiReportItemsError) {
    console.error(getMyPowerBiReportItemsError.message);
  }

  if (getMyUserCommentsByAnchorError) {
    console.error(getMyUserCommentsByAnchorError.message);
  }

  if (getMyUserApprovalsByAnchorError) {
    console.error(getMyUserApprovalsByAnchorError.message);
  }

  if (whoAmIError) {
    console.error(whoAmIError.message);
  }

  if (addMyUserCommentError) {
    console.error(addMyUserCommentError.message);
  }

  return (
    <PlanOfRecordComponent
      loading={getMyPowerBisLoading || getMyPowerBiReportItemsLoading}
      messagingLoading={
        isLogsLoading ||
        getMyUserCommentsByAnchorLoading ||
        getMyUserApprovalsByAnchorLoading ||
        whoAmILoading ||
        addMyUserCommentLoading
      }
      reportId={reportId}
      embedUrl={embedUrl}
      embedToken={embedToken}
      embedTokenId={embedTokenId}
      embedTokenExpiration={embedTokenExpiration}
      sales={sales}
      production={production}
      logistics={logistics}
      delivery={delivery}
      salesComments={salesComments}
      productionComments={productionComments}
      logisticsComments={logisticsComments}
      deliveryComments={deliveryComments}
      addCommentToSales={addCommentToSales}
      addCommentToProduction={addCommentToProduction}
      addCommentToLogistics={addCommentToLogistics}
      addCommentToDelivery={addCommentToDelivery}
      salesNewComment={salesNewComment}
      setSalesNewComment={setSalesNewComment}
      productionNewComment={productionNewComment}
      setProductionNewComment={setProductionNewComment}
      logisticsNewComment={logisticsNewComment}
      setLogisticsNewComment={setLogisticsNewComment}
      deliveryNewComment={deliveryNewComment}
      setDeliveryNewComment={setDeliveryNewComment}
    />
  );
};
