import { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import { RunLog } from "../../../services/api/__generated__/backend_gateway-types";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneTitle } from "../../../components/right_pane_title";
import dayjs from "dayjs";
import Switch from "@mui/joy/Switch";
import Sheet from "@mui/joy/Sheet";

export const EditRunComponent = (props: {
  runLog: RunLog | null;
  loading: boolean;
  navigateBack: () => void;
  updateRun: (name: string, description: string) => void;
  updateLoading: boolean;
}) => {
  const { runLog, loading, navigateBack, updateRun, updateLoading } = props;
  const [runName, setRunName] = useState<string>("Run Name");
  const [runDescription, setRunDescription] = useState<string>("Description");

  useEffect(() => {
    if (runLog) {
      setRunName(runLog.name || "");
      setRunDescription(runLog.description || "");
    }
  }, [runLog]);

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>Edit Run</RightPaneTitle>
      <Typography level="body-sm" sx={{ pb: 2 }}>
        Edit the name and description of the run
      </Typography>
      {(loading || updateLoading) && <LinearProgress />}
      {runLog && (
        <Box
          sx={{
            py: 2,
            display: "grid",
            gap: 4,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <FormControl>
            <FormLabel>Run name</FormLabel>
            <Input
              value={runName}
              onChange={(event) => setRunName(event.target.value)}
            />
            <FormHelperText>
              Short name that will appear when triggering runs
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>Run description</FormLabel>
            <Input
              value={runDescription}
              onChange={(event) => setRunDescription(event.target.value)}
            />
            <FormHelperText>Leave notes here for yourself</FormHelperText>
          </FormControl>

          <FormControl
            sx={{
              mt: 2,
            }}
          >
            <Button
              onClick={() => updateRun(runName, runDescription)}
              disabled={loading || updateLoading || !runLog}
            >
              Update name and description
            </Button>
          </FormControl>

          <Sheet variant="outlined" sx={{ p: 1 }}>
            <Typography level="body-lg" sx={{ pb: 1, fontWeight: 700 }}>
              Details of the run (read-only)
            </Typography>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input value={runLog.email || ""} disabled variant="solid" />
            </FormControl>
            <FormControl>
              <FormLabel>Started at</FormLabel>
              <Input
                value={dayjs(runLog.startedAt).format("MM/DD/YYYY hh:mm:ss a")}
                variant="solid"
                disabled
              />
            </FormControl>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Input value={runLog.status || ""} disabled variant="solid" />
            </FormControl>
            {runLog.runError && (
              <FormControl>
                <FormLabel>Error</FormLabel>
                <Input value={runLog.runError || ""} disabled variant="solid" />
              </FormControl>
            )}
            {(runLog.weightingMethod !== null ||
              runLog.weightingWindowDays !== null) && (
              <Typography
                level="body-lg"
                sx={{ pt: 2, pb: 1, fontWeight: 700 }}
              >
                Order Simulation Settings
              </Typography>
            )}
            {(runLog.weightingMethod !== null ||
              runLog.weightingWindowDays !== null) && (
              <Typography
                level="body-md"
                sx={{ pt: 1, pb: 0.5, fontWeight: 700 }}
              >
                SKU Assignment
              </Typography>
            )}
            {runLog.weightingMethod !== null && (
              <FormControl>
                <FormLabel>Weighting Method</FormLabel>
                <Input
                  value={runLog.weightingMethod || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.weightingWindowDays !== null && (
              <FormControl>
                <FormLabel>Weighting Window</FormLabel>
                <Input
                  value={runLog.weightingWindowDays || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.orderSimulationEndDate !== null && (
              <Typography
                level="body-md"
                sx={{ pt: 1, pb: 0.5, fontWeight: 700 }}
              >
                Order Simulation End Date
              </Typography>
            )}
            {runLog.orderSimulationEndDate !== null && (
              <FormControl>
                <Input
                  value={dayjs(runLog.orderSimulationEndDate).format(
                    "MM/DD/YYYY hh:mm:ss a"
                  )}
                  variant="solid"
                  disabled
                />
              </FormControl>
            )}
            {(runLog.historicalDatePatterns !== null ||
              runLog.bindToSKUOverrides !== null ||
              runLog.bindToForecastUpload !== null) && (
              <Typography
                level="body-lg"
                sx={{ pt: 2, pb: 1, fontWeight: 700 }}
              >
                Order Simulation Feature Selection
              </Typography>
            )}
            {runLog.historicalDatePatterns !== null && (
              <FormControl>
                <FormLabel>Historical Date Patterns</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={runLog.historicalDatePatterns || false}
                      disabled
                    />
                  }
                >
                  {runLog.historicalDatePatterns ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
            {runLog.bindToSKUOverrides !== null && (
              <FormControl>
                <FormLabel>Bind to SKU Overrides</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      disabled={true}
                      checked={runLog.bindToSKUOverrides || false}
                    />
                  }
                >
                  {runLog.bindToSKUOverrides ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
            {runLog.bindToForecastUpload !== null && (
              <FormControl>
                <FormLabel>Bind to Forecast Upload</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      disabled={true}
                      checked={runLog.bindToForecastUpload || false}
                    />
                  }
                >
                  {runLog.bindToForecastUpload ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
            {(runLog.dyeYieldLoss ||
              runLog.sewYieldLoss ||
              runLog.cutYieldLoss) && (
              <Typography level="body-lg" sx={{ pb: 1 }}>
                Yield Loss Assumptions
              </Typography>
            )}
            {runLog.dyeYieldLoss !== null && (
              <FormControl>
                <FormLabel>Dye Yield Loss</FormLabel>
                <Input
                  value={runLog.dyeYieldLoss || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.sewYieldLoss !== null && (
              <FormControl>
                <FormLabel>Sew Yield Loss</FormLabel>
                <Input
                  value={runLog.sewYieldLoss || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.cutYieldLoss !== null && (
              <FormControl>
                <FormLabel>Cut Yield Loss</FormLabel>
                <Input
                  value={runLog.cutYieldLoss || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.weeksOfSupply !== null && (
              <FormControl>
                <FormLabel>Weeks of Supply</FormLabel>
                <Input
                  value={runLog.weeksOfSupply || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.useBufferForecast !== null && (
              <FormControl>
                <FormLabel>useBufferForecast</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      disabled={true}
                      checked={runLog.useBufferForecast || false}
                    />
                  }
                >
                  {runLog.useBufferForecast ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
            {runLog.desiredMinWeeksOnHand !== null && (
              <FormControl>
                <FormLabel>Desired Min Weeks On Hand</FormLabel>
                <Input
                  value={runLog.desiredMinWeeksOnHand || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.leadTimeInWeeks !== null && (
              <FormControl>
                <FormLabel>Lead Time in Weeks</FormLabel>
                <Input
                  value={runLog.leadTimeInWeeks || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.assumedCustomerGrowth !== null && (
              <FormControl>
                <FormLabel>Assumed Customer Growth</FormLabel>
                <Input
                  value={runLog.assumedCustomerGrowth || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.excludeForecast !== null && (
              <FormControl>
                <FormLabel>Exclude Forecast</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      disabled={true}
                      checked={runLog.excludeForecast || false}
                    />
                  }
                >
                  {runLog.excludeForecast ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
            {runLog.excludeYieldPlan !== null && (
              <FormControl>
                <FormLabel>Exclude Yield Plan</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      disabled={true}
                      checked={runLog.excludeYieldPlan || false}
                    />
                  }
                >
                  {runLog.excludeYieldPlan ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}{" "}
            {runLog.truckOrderToPackDays !== null && (
              <FormControl>
                <FormLabel>Truck Order to Pack Days</FormLabel>
                <Input
                  value={runLog.truckOrderToPackDays || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.onHoldToAvailableDays !== null && (
              <FormControl>
                <FormLabel>On Hold to Available Days</FormLabel>
                <Input
                  value={runLog.onHoldToAvailableDays || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.productionStartToEndDays !== null && (
              <FormControl>
                <FormLabel>Production Start to End Days</FormLabel>
                <Input
                  value={runLog.productionStartToEndDays || ""}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.darkRoastRuntime !== null && (
              <FormControl>
                <FormLabel>Dark Roast Runtime</FormLabel>
                <Input
                  value={runLog.darkRoastRuntime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.mediumRoastRuntime !== null && (
              <FormControl>
                <FormLabel>Medium Roast Runtime</FormLabel>
                <Input
                  value={runLog.mediumRoastRuntime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.lightRoastRuntime !== null && (
              <FormControl>
                <FormLabel>Light Roast Runtime</FormLabel>
                <Input
                  value={runLog.lightRoastRuntime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.defaultChangeoverTime !== null && (
              <FormControl>
                <FormLabel>Default Changeover Time</FormLabel>
                <Input
                  value={runLog.defaultChangeoverTime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.decafChangeoverTime !== null && (
              <FormControl>
                <FormLabel>Decaf Changeover Time</FormLabel>
                <Input
                  value={runLog.decafChangeoverTime || ""}
                  endDecorator={"minutes"}
                  disabled
                  variant="solid"
                />
              </FormControl>
            )}
            {runLog.historicalDatePatterns !== null && (
              <FormControl>
                <FormLabel>Turn On Production Batching</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={runLog.turnOnProductionBatching || false}
                      disabled
                    />
                  }
                >
                  {runLog.turnOnProductionBatching ? "Enabled" : "Disabled"}
                </Typography>
              </FormControl>
            )}
          </Sheet>
        </Box>
      )}
    </RightPaneFrame>
  );
};
