export enum TaskStatus {
  STARTED = "UPLOAD_STARTED",
  RUNNING_QUEUE = "UPLOAD_RUNNING_QUEUE",
  RUNNING_TASK = "UPLOAD_RUNNING_TASK",
  RUNNING_VALIDATION = "UPLOAD_RUNNING_VALIDATION",
  RUNNING_TRANSFORM = "UPLOAD_RUNNING_TRANSFORM",
  FAILED = "UPLOAD_FAILED",
  SUCCESS = "UPLOAD_SUCCESS",
}

export enum DagsterStatus {
  STARTED = "STARTED",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  QUEUED = "QUEUED",
  NOT_STARTED = "NOT_STARTED",
  STARTING = "STARTING",
}

export enum UploadRuns {
  NEW_RUN = "NEW_RUN"
}