import { useSearchParams } from "react-router-dom";
import { Page } from "../../frameworks/joy/layouts/page";
import { PoBoxContainer } from "./container";
import { StatusFilter } from "./status_filter";

export const PoBoxPage = () => {
  const [searchParams] = useSearchParams();
  const include_status = searchParams.get("include_status");
  const snoozed = searchParams.get("snoozed");
  const includeStatus: StatusFilter[] =
    include_status
      ?.split(",")
      .map((i) => StatusFilter[i as keyof typeof StatusFilter])
      .filter((i) => i !== undefined) || [];
  return (
    <Page>
      <PoBoxContainer
        includeStatus={includeStatus}
        snoozed={snoozed === "true"}
      />
    </Page>
  );
};
