import { useContext, useMemo } from "react";
import {
  GetMyVendorsDocument,
  useGetMyVendorsQuery,
  useSetMyVendorMutation,
} from "../../../services/api/__generated__/backend_gateway-types";
import { PoDetailsEditVendorComponent } from "./component";
import { ApiContext } from "../../../contexts/api/context";
import { SysMessagesContext } from "../../../contexts/sys_messages/context";
import { SysMessageLevel } from "../../../contexts/sys_messages/type";
import { useLocation, useNavigate } from "react-router-dom";

export const PoDetailsEditVendorContainer = (props: { id: string }) => {
  const { id } = props;
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);

  const { data: vendorsData, loading: vendorsLoading } = useGetMyVendorsQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const vendor = useMemo(
    () => vendorsData?.getMyVendors?.find((v) => v.id === id) || null,
    [vendorsData, id]
  );

  const [setVendor, { loading: setVendorLoading }] = useSetMyVendorMutation({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
    refetchQueries: [
      {
        query: GetMyVendorsDocument,
      },
    ],
  });

  const setVendorAddress = async (address: string, email: string) => {
    await setVendor({
      variables: {
        vendor: {
          id,
          address,
          address_override: address,
          email,
        },
      },
    });
  };

  const navigate = useNavigate();
  const loc = useLocation();
  const navigateBack = () => {
    const poPathFragments = loc.pathname.split("/");
    if (poPathFragments.length >= 3) {
      const poPath = `/${poPathFragments[1]}/${poPathFragments[2]}`;
      navigate(`${poPath}${loc.search ? "?" + loc.search : ""}`, {
        replace: true,
      });
    }
  };

  return (
    <PoDetailsEditVendorComponent
      loading={vendorsLoading || setVendorLoading}
      navigateBack={navigateBack}
      vendor={vendor}
      setVendor={setVendorAddress}
    />
  );
};
