import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import "./component.css";

export const PlanOverviewComponent = (props: {
  loading: boolean;
  title: string | null;
  embedUrl: string | null;
  token: string | null;
  reportId: string | undefined;
  pageId: string | undefined;
  modeString: string;
}) => {
  const { loading, title, embedUrl, token, reportId, pageId, modeString } =
    props;

  return (
    <PageFrame>
      <PageTitle>{title}</PageTitle>

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <PowerBIEmbed
        embedConfig={{
          type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
          id: reportId,
          embedUrl: embedUrl || undefined,
          accessToken: token || undefined,
          pageName: pageId,
          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
          settings: {
            panes: {
              bookmarks: {
                visible: false,
              },
              fields: {
                expanded: false,
              },
              filters: {
                expanded: false,
                visible: false,
              },
              pageNavigation: {
                visible: false,
              },
              selection: {
                visible: true,
              },
              syncSlicers: {
                visible: true,
              },
              visualizations: {
                expanded: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        }}
        cssClassName={
          modeString === "dark"
            ? "report-container-dark"
            : "report-container-light"
        }
      />
    </PageFrame>
  );
};
