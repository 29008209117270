export enum SalesMeasure {
  QUANTITY = "QUANTITY",
  REVENUE = "REVENUE",
}

export enum Segmentation {
  LOCATION = "LOCATION",
  CHANNEL = "CHANNEL",
  PRODUCT_TYPE = "PRODUCT_TYPE",
  PRODUCT_FAMILY = "PRODUCT_FAMILY",
  PRODUCT_TITLE = "PRODUCT_TITLE",
}

export type DiffParams = {
  actualsEngineRunId: string | null;
  forecastEngineRunId: string | null;
  minDate: Date | null;
  maxDate: Date | null;
  salesMeasure: SalesMeasure | null;
  segmentation: Segmentation | null;
};

export type Query = {
  actualsEngineRunId?: string | undefined;
  forecastEngineRunId?: string | undefined;
  minDate?: string | undefined;
  maxDate?: string | undefined;
  aggregatePeriodType?: string | undefined;
  salesMeasure?: string | undefined;
  segmentation?: string | undefined;
};

export const diffParamsToStrings = (diffParams: DiffParams) => {
  const {
    actualsEngineRunId,
    forecastEngineRunId,
    minDate,
    maxDate,
    salesMeasure,
    segmentation,
  } = diffParams;
  return {
    ...(actualsEngineRunId !== null && { actualsEngineRunId }),
    ...(forecastEngineRunId !== null && { forecastEngineRunId }),
    ...(minDate && { minDate: minDate.toISOString().split("T")[0] }),
    ...(maxDate && { maxDate: maxDate.toISOString().split("T")[0] }),
    ...(salesMeasure && {
      salesMeasure: salesMeasure.valueOf(),
    }),
    ...(segmentation && {
      segmentation: segmentation.valueOf(),
    }),
  };
};

const urlSearchParamsToQuery = (query: URLSearchParams): Query => {
  return {
    actualsEngineRunId: query.get("actualsEngineRunId") || undefined,
    forecastEngineRunId: query.get("forecastEngineRunId") || undefined,
    minDate: query.get("minDate") || undefined,
    maxDate: query.get("maxDate") || undefined,
    salesMeasure: query.get("salesMeasure") || undefined,
    segmentation: query.get("segmentation") || undefined,
  } as Query;
};

export const queryStringTodiffParams = (query: URLSearchParams) => {
  const {
    actualsEngineRunId,
    forecastEngineRunId,
    minDate,
    maxDate,
    salesMeasure,
    segmentation,
  } = urlSearchParamsToQuery(query);
  let minDateParsed = null;
  if (minDate !== undefined) {
    try {
      minDateParsed = new Date(minDate);
    } catch (err) {}
  }
  let maxDateParsed = null;
  if (maxDate !== undefined) {
    try {
      maxDateParsed = new Date(maxDate);
    } catch (err) {}
  }

  let salesMeasureParsed: SalesMeasure | null = null;
  if (salesMeasure !== undefined) {
    try {
      salesMeasureParsed = SalesMeasure[salesMeasure as SalesMeasure] || null;
    } catch (err) {}
  }

  let segmentationParsed: Segmentation | null = null;
  if (segmentation !== undefined) {
    segmentationParsed = Segmentation[segmentation as Segmentation] || null;
  }

  const diffParams: DiffParams = {
    actualsEngineRunId: actualsEngineRunId || null,
    forecastEngineRunId: forecastEngineRunId || null,
    minDate: minDateParsed,
    maxDate: maxDateParsed,
    salesMeasure: salesMeasureParsed,
    segmentation: segmentationParsed,
  };
  return diffParams;
};
