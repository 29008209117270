import { createContext } from "react";
import { DRAWER_WIDTH } from "./constants";
import { DrawerType } from "./type";

const defaultValue: DrawerType = {
  drawerOpen: false,
  drawerWidth: DRAWER_WIDTH,
  handleDrawerToggle: () => {},
  closeDrawer: () => {},
};

export const DrawerContext = createContext(defaultValue);
