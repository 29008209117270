import { useContext } from "react";
import { ApiContext } from "../../contexts/api/context";
import {
  PowerBi,
  useGetMyPowerBisQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { ReportsComponent } from "./component";

export const ReportsContainer = () => {
  const { apiService } = useContext(ApiContext);

  const { data, loading, error } = useGetMyPowerBisQuery({
    client: apiService.getClient(),
  });

  if (error) {
    console.error(error.message);
  }
  return (
    <ReportsComponent
      loading={loading}
      powerBis={(data?.getMyPowerBis || []) as PowerBi[]}
    />
  );
};
