import dayjs from "dayjs";
import Check from "@mui/icons-material/Check";
import LinearProgress from "@mui/joy/LinearProgress";
import Radio from "@mui/joy/Radio";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import { RunLog } from "../../services/api/__generated__/backend_gateway-types";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import { ChangeEvent, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { stateMap } from "./state_map";

export const RunHistoryComponent = (props: {
  runlogs: RunLog[];
  runlogLoading: boolean;
  runningInProgress: boolean;
  navigateToRunHistoryWithEditRun: (id: number) => void;
  lockedRun: number | null;
  setLockedRun: (runId: number) => void;
  hasLockedRuns: boolean;
}) => {
  const {
    runlogs,
    runlogLoading,
    navigateToRunHistoryWithEditRun,
    lockedRun,
    setLockedRun,
    hasLockedRuns,
  } = props;
  const [selectedRunId, setSelectedRunId] = useState<number | null>(null);

  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
        backgroundColor: "atomic.highlightUnderlay",
      }}
    >
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 4,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <PageTitle>Run history</PageTitle>

        {runlogLoading && <LinearProgress />}

        {!runlogLoading && (
          <Sheet
            sx={{
              "--TableCxell-height": "40px",
              "--TableHeader-height": "64px",
              minHeight: "10vh",
              maxHeight: "70vh",
              overflow: "auto",
              background: (
                theme
              ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
                    linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
                    radial-gradient(
                      farthest-side at 50% 0,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                    ),
                    radial-gradient(
                        farthest-side at 50% 100%,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                      )
                      0 100%`,
              backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "local, local, scroll, scroll",
              backgroundPosition:
                "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
              backgroundColor: (theme) =>
                `${theme.palette.atomic.highlightBackground}`,
            }}
          >
            <Table
              aria-labelledby="Run logs"
              stickyHeader
              hoverRow={true}
              // stripe="odd"
              borderAxis="xBetween"
              sx={{
                width: "600px",
                "--Table-headerUnderlineThickness": "1px",
                "--TableCell-paddingY": "4px",
                "--TableCell-paddingX": "8px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "50px",
                    }}
                  >
                    Select
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "100px",
                    }}
                  >
                    Run ID
                  </th>
                  {hasLockedRuns && (
                    <th
                      style={{
                        padding: "12px 6px",
                        width: "100px",
                      }}
                    >
                      Locked
                    </th>
                  )}
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "170px",
                    }}
                  >
                    Started At
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "140px",
                    }}
                  >
                    State
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "220px",
                    }}
                  >
                    Email
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "110px",
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "220px",
                    }}
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {runlogs?.map((runlog) => (
                  <tr
                    key={runlog.id}
                    onClick={() =>
                      setSelectedRunId(
                        runlog.id === undefined ? null : runlog.id!
                      )
                    }
                    aria-checked={runlog.id === selectedRunId}
                    role="checkbox"
                  >
                    <td>
                      <Radio
                        checked={runlog.id === selectedRunId}
                        value={runlog.id}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setSelectedRunId(parseInt(event.target.value));
                        }}
                      />
                    </td>
                    <td>{runlog.id}</td>
                    {hasLockedRuns && (
                      <td>{runlog.id === lockedRun && <Check />}</td>
                    )}
                    <td>
                      {dayjs(runlog.startedAt).format("MM/DD/YYYY hh:mm:ss a")}
                    </td>
                    <td>{stateMap[runlog.status || "DRAFT"] || "Unknown"}</td>
                    <td>{runlog.email}</td>
                    <td>{runlog.name}</td>
                    <td>{runlog.description}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        )}
        <FormControl
          sx={{
            mt: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            <Button
              disabled={runlogLoading || selectedRunId === null}
              color="primary"
              variant="solid"
              onClick={() => navigateToRunHistoryWithEditRun(selectedRunId!)}
            >
              Edit selected run
            </Button>
            {hasLockedRuns && (
              <Button
                disabled={runlogLoading || selectedRunId === null}
                onClick={() => setLockedRun(selectedRunId!)}
              >
                Set as locked
              </Button>
            )}
          </Box>
        </FormControl>
      </Sheet>
    </PageFrame>
  );
};
