import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  DiffParams,
  diffParamsToStrings,
  queryStringTodiffParams,
} from "./diff_params";
import { RunComparisonContainer } from "./container";
import { Page } from "../../frameworks/joy/layouts/page";

export const RunComparisonPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [diffParams, setDiffParams] = useState<DiffParams>({
    runId0: null,
    runId1: null,
    minDate: null,
    maxDate: null,
    aggregatePeriodType: null,
    salesMeasure: null,
    heatMap: null,
  });

  useEffect(() => {
    setDiffParams(queryStringTodiffParams(searchParams));
  }, [searchParams, setDiffParams]);

  const updateDiffParams = (params: DiffParams) => {
    setSearchParams(diffParamsToStrings(params), { replace: true });
  };

  return (
    <Page>
      <RunComparisonContainer
        diffParams={diffParams}
        updateDiffParams={updateDiffParams}
      />
    </Page>
  );
};
