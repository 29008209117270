import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiContext } from "../../contexts/api/context";
import {
  User,
  useGetMyCustomerQuery,
  useWhoAmIQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { WelcomeComponent } from "./component";
import { RunTriggerContext } from "../../contexts/run_trigger/context";

export const WelcomeContainer = () => {
  const { apiService, selectedCustomerId } = useContext(ApiContext);
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const { data, loading, error } = useWhoAmIQuery({
    client: apiService.getClient(),
  });
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });
  const { isDisabled, isRunning, lastStartedAt, addNewRun } =
    useContext(RunTriggerContext);

  if (error) {
    console.error("WelcomeContainer: useWhoAmIQuery fetch error", error);
  }

  if (customerError) {
    console.error(
      "WelcomeContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }
  const navigateToRunHistory = () => {
    navigate("/run_history", { replace: true });
  };

  const navigateToEnginePresets = () => {
    navigate("/engine_presets", { replace: true });
  };

  const navigateToReports = () => {
    navigate("/reports", { replace: true });
  };

  const navigateToPlanOfRecord = () => {
    navigate("/plan_of_record", { replace: true });
  };

  const navigateToValidationDashboard = () => {
    navigate("/validation_dashboard", { replace: true });
  };

  const navigateToPlanning = () => {
    navigate("/planning", { replace: true });
  };

  const navigateToOperations = () => {
    navigate("/operations", { replace: true });
  };

  const role = data?.whoAmI?.role || null;
  const customerId =
    (role === "ROOT" ? selectedCustomerId : data?.whoAmI?.customerId) || null;

  if (data?.whoAmI?.email) heap.identify(data?.whoAmI.email);

  return (
    <WelcomeComponent
      loading={loading || customerLoading}
      user={data?.whoAmI as User}
      customerId={customerId}
      signOut={logout}
      navigateToReports={navigateToReports}
      navigateToRunHistory={navigateToRunHistory}
      navigateToEnginePresets={navigateToEnginePresets}
      runningInProgress={isRunning}
      latestStartAt={lastStartedAt}
      runDisabled={isDisabled}
      workflowTrigger={addNewRun}
      hasPlanningDemo={
        customerData?.getMyCustomer?.features?.ValidationDashboard || false
      }
      navigateToPlanOfRecord={navigateToPlanOfRecord}
      navigateToValidationDashboard={navigateToValidationDashboard}
      navigateToPlanning={navigateToPlanning}
      navigateToOperations={navigateToOperations}
    />
  );
};
