import { useState } from "react";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import Snackbar from "@mui/joy/Snackbar";
import Table from "@mui/joy/Table";

import { Customer } from "../../services/api/__generated__/backend_gateway-types";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";

export const CustomersComponent = (props: {
  loading: boolean;
  errors: string[];
  clearError: (i: number) => void;
  navigateToCustomerDetails: (customerId: string) => void;
  navigateToCreateNewCustomer: () => void;
  customers: Customer[];
}) => {
  const {
    loading,
    errors,
    clearError,
    navigateToCustomerDetails,
    navigateToCreateNewCustomer,
    customers,
  } = props;
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null
  );
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
        backgroundColor: "atomic.highlightUnderlay",
      }}
    >
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 4,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <PageTitle>Customers</PageTitle>
        {loading && <LinearProgress />}

        {errors.map((error, i) => (
          <Snackbar
            variant="soft"
            color="danger"
            open={true}
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }
              clearError(i);
            }}
          >
            {error}
          </Snackbar>
        ))}

        <Sheet
          sx={{
            "--TableCxell-height": "40px",
            "--TableHeader-height": "64px",
            minHeight: "10vh",
            maxHeight: "70vh",
            overflow: "auto",
            background: (
              theme
            ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
                  linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
                  radial-gradient(
                    farthest-side at 50% 0,
                    ${theme.palette.atomic.fullShadowFade},
                    ${theme.palette.atomic.fullShadow}
                  ),
                  radial-gradient(
                      farthest-side at 50% 100%,
                    ${theme.palette.atomic.fullShadowFade},
                    ${theme.palette.atomic.fullShadow}
                    )
                    0 100%`,
            backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local, local, scroll, scroll",
            backgroundPosition:
              "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
            backgroundColor: (theme) =>
              `${theme.palette.atomic.highlightBackground}`,
          }}
        >
          <Table
            aria-labelledby="Runs"
            stickyHeader
            hoverRow={true}
            borderAxis="xBetween"
            sx={{
              maxWidth: "1200px",
              "--Table-headerUnderlineThickness": "1px",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    padding: "12px 6px",
                    width: "150px",
                  }}
                >
                  id
                </th>
                <th
                  style={{
                    padding: "12px 6px",
                    width: "250px",
                  }}
                >
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                customers.map((customer) => (
                  <tr
                    key={customer.id}
                    onClick={() => {
                      navigateToCustomerDetails(customer.id);
                      setSelectedCustomerId(customer.id);
                    }}
                    aria-checked={customer.id === selectedCustomerId}
                    role="checkbox"
                    style={
                      customer.id === selectedCustomerId
                        ? ({
                            "--TableCell-dataBackground": "rgba(0,0,0,0.2)",
                            "--TableCell-headBackground": "rgba(0,0,0,0.2)",
                          } as React.CSSProperties)
                        : {}
                    }
                  >
                    <td>{customer.id}</td>
                    <td>{customer.name}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Sheet>
        <FormControl
          sx={{
            mt: 2,
          }}
        >
          <Button
            sx={{
              maxWidth: "150px",
            }}
            disabled={loading}
            onClick={navigateToCreateNewCustomer}
          >
            Add customer
          </Button>
        </FormControl>
      </Sheet>
    </PageFrame>
  );
};
