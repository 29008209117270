import { useContext, useMemo, useState } from "react";
import { InventoryOnHandComponent } from "./component";
import { InventoryOnHandTotalCogsContainer } from "./total_cogs/container";
import { InventoryOnHandTotalBeanCogsContainer } from "./total_bean_cogs/container";
import { ApiContext } from "../../contexts/api/context";
import {
  useGetMyCustomerQuery,
  useGetMyInventoryOnHandUrlsQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { InventoryOnHandData, RunData } from "./inventory_on_hand_data";
import { InventoryOnHandStockOutRateContainer } from "./stock_out_rate/container";

export const InventoryOnHandContainer = () => {
  const { apiService } = useContext(ApiContext);

  const [dataLoading, setDataLoading] = useState<{ [key: string]: boolean }>(
    {}
  );

  const inventoryOnHandData = useMemo(
    () => new InventoryOnHandData(setDataLoading),
    []
  );

  const {
    loading: inventoryOnHandUrlsLoading,
    error: inventoryOnHandUrlsError,
  } = useGetMyInventoryOnHandUrlsQuery({
    client: apiService.getClient(),
    onCompleted(data) {
      if (data.getMyInventoryOnHandUrls) {
        inventoryOnHandData.setAndFetchDataUrls(
          data.getMyInventoryOnHandUrls.map((i) => {
            return {
              runId: i.id,
              url: i.url,
              date: new Date(i.date),
              data: null,
            } as RunData;
          })
        );
      }
    },
  });


  // InventoryOnHandInStockRate
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetMyCustomerQuery({
    client: apiService.getClient(),
  });

  if (inventoryOnHandUrlsError) {
    console.error(
      "InventoryOnHandContainer: useGetMyInventoryOnHandUrlsQuery fetch error",
      inventoryOnHandUrlsError
    );
  }
  if (customerError) {
    console.error(
      "InventoryOnHandContainer: useGetMyCustomerQuery fetch error",
      customerError
    );
  }

  return (
    <InventoryOnHandComponent
      totalCogs={
        <InventoryOnHandTotalCogsContainer
          inventoryOnHandData={inventoryOnHandData}
          inventoryOnHandDataLoading={Object.values(dataLoading).includes(true)}
        />
      }
      totalBeanCogs={
        <InventoryOnHandTotalBeanCogsContainer/>
      }
      stockOutRate={
        <InventoryOnHandStockOutRateContainer
          inventoryOnHandData={inventoryOnHandData}
          inventoryOnHandDataLoading={Object.values(dataLoading).includes(true)}
        />
      }
      loading={
        inventoryOnHandUrlsLoading ||
        customerLoading ||
        Object.values(dataLoading).includes(true)
      }
      hasInventoryOnHandInStockRate={
        customerData?.getMyCustomer?.features?.InventoryOnHandInStockRate ||
        false
      }
      hasInventoryOnHandBeans={
        customerData?.getMyCustomer?.features?.InventoryOnHandBeans ||
        false
      }
    />
  );
};
