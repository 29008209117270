import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { getNewApiClient } from "./client";
import {
  User,
  WhoAmIDocument,
  WhoAmIQuery,
} from "./__generated__/backend_gateway-types";

export class ApiService {
  private static instance: ApiService;
  private client: ApolloClient<NormalizedCacheObject>;
  private selectedCustomerId: string | null;
  private user: User | null;

  private constructor(client: ApolloClient<NormalizedCacheObject>) {
    this.selectedCustomerId = localStorage.getItem("selectedCustomerId");
    this.client = client;
    this.user = null;
  }

  public static getInstance(reset: boolean = false): ApiService {
    if (!ApiService.instance || reset) {
      ApiService.instance = new ApiService(getNewApiClient());
    }
    return ApiService.instance;
  }

  getClient(): ApolloClient<NormalizedCacheObject> {
    return this.client;
  }

  async reset(): Promise<void> {
    this.client.resetStore();
    await ApiService.getInstance(true);
  }

  async setWhoAmI(): Promise<void> {
    const result = await this.client.query({
      query: WhoAmIDocument,
    });
    const { error } = result;
    if (error) {
      console.error(error);
      return;
    }
    const { data }: { data: WhoAmIQuery } = result;
    if (data) {
      this.user = data.whoAmI || null;
    }
  }

  setSelectedCompanyId = (selectedCustomerId: string | null): void => {
    if (selectedCustomerId && this.selectedCustomerId !== selectedCustomerId) {
      this.selectedCustomerId = selectedCustomerId;
      localStorage.setItem("selectedCustomerId", selectedCustomerId);
      this.client.resetStore();
    } else if (selectedCustomerId === null) {
      localStorage.removeItem("selectedCustomerId");
      this.client.resetStore();
    }
  };

  async whoAmI(): Promise<User | null> {
    if (this.user === null) {
      await this.setWhoAmI();
    }
    return this.user;
  }
}
