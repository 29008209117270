import { Page } from "../../frameworks/joy/layouts/page";
import { WelcomeContainer } from "./container";

export const WelcomePage = () => {
  return (
    <Page>
      <WelcomeContainer />
    </Page>
  );
};
