import { useParams } from "react-router-dom";
import { Page } from "../../frameworks/joy/layouts/page";
import { ReportContainer } from "./container";

export const ReportPage = () => {
  const params = useParams();
  const { workspaceId, reportId, pageId } = params;
  return (
    <Page>
      {workspaceId && reportId && pageId ? (
        <ReportContainer
          workspaceId={workspaceId}
          reportId={reportId}
          pageId={pageId}
        />
      ) : (
        <></>
      )}
    </Page>
  );
};
