import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import ListItem from "@mui/joy/ListItem";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useMemo } from "react";
dayjs.extend(utc);

export const RunTriggerComponent = (props: {
  role: string | null;
  customerId: string | null;
  runningInProgress: boolean;
  runDisabled: boolean;
  latestStartAt: string | null;
  latestExportedAt: string | null;
  loading: boolean;
  workflowTrigger: () => void;
  hasEnginePresets: boolean;
  navitageToEnginePresets: () => void;
}) => {
  const {
    role,
    customerId,
    runningInProgress,
    runDisabled,
    latestStartAt,
    latestExportedAt,
    loading,
    workflowTrigger,
    hasEnginePresets,
    navitageToEnginePresets,
  } = props;

  const startedAt: string | null = useMemo(() => {
    if (latestStartAt && latestExportedAt) {
      if (new Date(latestStartAt) > new Date(latestExportedAt)) {
        return latestStartAt;
      }
      return latestExportedAt;
    }
    if (latestStartAt) {
      return latestStartAt;
    }
    if (latestExportedAt) {
      return latestExportedAt;
    }
    return null;
  }, [latestStartAt, latestExportedAt]);

  if (
    customerId === null ||
    role === null ||
    !["ROOT", "ADMIN"].includes(role!)
  ) {
    return <Box sx={{ height: "87px" }} />;
  }

  return (
    <>
      <ListItem>
        {loading && <Typography level="body-xs"> </Typography>}
        {!loading && (
          <Typography level="body-xs">
            {runningInProgress ? "Started on" : "Last run:"}{" "}
            {startedAt
              ? dayjs.utc(startedAt).local().format("MM/DD/YY hh:mm:ss a")
              : "-"}
          </Typography>
        )}
      </ListItem>
      <ListItem sx={{ pb: 2 }}>
        <Button
          onClick={hasEnginePresets ? navitageToEnginePresets : workflowTrigger}
          disabled={loading || runDisabled}
          loading={runningInProgress}
          loadingPosition="end"
          fullWidth
        >
          {!runningInProgress ? "Run Atomic" : "Run in progress…"}
        </Button>
      </ListItem>
    </>
  );
};
