import { useContext } from "react";
import { ApiContext } from "../../contexts/api/context";
import {
  PowerBiReportItems,
  useGetMyPowerBiReportItemsQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { useNavigate } from "react-router-dom";
import { WorkspaceReportsComponent } from "./workspace_component";

export const WorkspaceReportsContainer = (props: { workspaceId: string }) => {
  const { workspaceId } = props;
  const { apiService } = useContext(ApiContext);
  const navigate = useNavigate();
  const { data, loading, error } = useGetMyPowerBiReportItemsQuery({
    client: apiService.getClient(),
    variables: {
      workspaceId,
    },
  });

  if (error) {
    console.error(error.message);
  }
  const navigateToReport = (reportId: string, pageId: string) => {
    navigate(`/reports/${workspaceId}/${reportId}/${pageId}`, {
      replace: true,
    });
  };
  return (
    <WorkspaceReportsComponent
      loading={loading}
      powerBiReportItems={
        (data?.getMyPowerBiReportItems || null) as PowerBiReportItems | null
      }
      navigateToReport={navigateToReport}
    />
  );
};
