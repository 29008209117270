import { PlanningComponent } from "./component";
import { PlanningForecastAccuracyContainer } from "./forecast_accuracy/container";
import { GenerateChannelForecastContainer } from "./generate_channel_forecast/container";
import { ReviewAndPublishContainer } from "./review_and_publish/container";
import { RunOrderSimulationContainer } from "./run_order_simulation/container";

export const PlanningContainer = () => {
  return (
    <PlanningComponent
      generateChannelForecast={<GenerateChannelForecastContainer />}
      runOrderSimulation={<RunOrderSimulationContainer />}
      reviewAndPublish={<ReviewAndPublishContainer />}
      forecastAccuracy={<PlanningForecastAccuracyContainer />}
    />
  );
};
