import Papa from "papaparse";

export type ForecastAccuracyItem = {
  sku: string;
  location: string | null;
  channel: string | null;
  actual_quantity: number; // int
  actual_revenue: number | null; // Decimal | None
  forecast_quantity: number; // int
  forecast_revenue: number | null; // Decimal | None
};

export type ForecastAccuracyItemWithSkuTags = {
  product_type?: string;
  product_family?: string;
  product_title?: string;
} & ForecastAccuracyItem;

export class ForecastAccuracyFromCsv {
  private items: ForecastAccuracyItemWithSkuTags[] = [];
  private setLoading: (v: boolean) => void;
  private lastCsvUrl: string | null = null;
  private callback:
    | React.Dispatch<React.SetStateAction<ForecastAccuracyFromCsv>>
    | undefined;

  constructor(setLoading: (v: boolean) => void) {
    this.setLoading = setLoading;
    this.callback = undefined;
  }

  setOnCompleteCallback = (
    callback: React.Dispatch<React.SetStateAction<ForecastAccuracyFromCsv>>
  ) => {
    this.callback = callback;
  };

  fetch = async (csvUrl: string, forceReload: boolean) => {
    if (this.lastCsvUrl === csvUrl && !forceReload) {
      this.setLoading(false);
      return;
    }
    this.lastCsvUrl = csvUrl;
    this.setLoading(true);
    this.items = [];
    Papa.parse<ForecastAccuracyItem>(csvUrl, {
      download: true,
      worker: true,
      header: true,
      dynamicTyping: true,
      step: (results) => {
        const {
          sku,
          location,
          channel,
          actual_quantity,
          actual_revenue,
          forecast_quantity,
          forecast_revenue,
        } = results.data;
        if (
          isNaN(actual_quantity) ||
          isNaN(forecast_quantity) ||
          (actual_revenue !== null &&
            (actual_revenue as unknown as string) !== "NULL" &&
            isNaN(actual_revenue)) ||
          (forecast_revenue !== null &&
            (forecast_revenue as unknown as string) !== "NULL" &&
            isNaN(forecast_revenue))
        ) {
          return;
        }
        this.items.push({
          sku:
            sku === undefined ||
            sku === "undefined" ||
            sku === null ||
            sku === "NULL"
              ? "Unknown"
              : sku,
          location:
            location === undefined ||
            location === "undefined" ||
            location === null ||
            location === "NULL"
              ? "Unknown"
              : location,
          channel:
            channel === undefined ||
            channel === "undefined" ||
            channel === null ||
            channel === "NULL"
              ? "Unknown"
              : channel,
          actual_quantity,
          actual_revenue:
            actual_revenue === undefined ||
            (actual_revenue as unknown as string) === "undefined" ||
            actual_revenue === null ||
            (actual_revenue as unknown as string) === "NULL"
              ? null
              : actual_revenue,
          forecast_quantity,
          forecast_revenue:
            forecast_revenue === undefined ||
            (forecast_revenue as unknown as string) === "undefined" ||
            forecast_revenue === null ||
            (forecast_revenue as unknown as string) === "NULL"
              ? null
              : forecast_revenue,
        });
      },
      complete: () => {
        this.callback && this.callback(Object.create(this));
        this.setLoading(false);
      },
    });
  };

  getItems() {
    return this.items;
  }
}
