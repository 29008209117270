import React, { ReactNode } from "react";
import { CssVarsProvider } from "@mui/joy/styles/CssVarsProvider";
import Layout from "./layouts/drawer_layout";
import { theme } from "./theme";
import { AppearanceProvider } from "../../contexts/appearance/provider";

export const ThemedLayout = (props: { children?: ReactNode }): JSX.Element => {
  const { children } = props;
  return (
    <CssVarsProvider theme={theme}>
      <AppearanceProvider>
        <Layout>{children}</Layout>
      </AppearanceProvider>
    </CssVarsProvider>
  );
};
