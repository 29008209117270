import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
// import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import Stack from "@mui/joy/Stack";
import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import ChipDelete from "@mui/joy/ChipDelete";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Sheet from "@mui/joy/Sheet";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import React, { SyntheticEvent, useContext, useState } from "react";
import {
  PurchaseOrder,
  PurchaseOrderStatus,
} from "../../services/api/__generated__/backend_gateway-types";
import { PageFrame } from "../../components/page_frame";
import { PoEditContext } from "./po_edit_context/context";

const StatusIcons = (props: {
  po: PurchaseOrder | null;
  setStatus: (status: PurchaseOrderStatus) => Promise<void>;
  setSoozeEndDate: (soozeEndDate: Date | null) => Promise<void>;
  disableStatusEdit: boolean;
  navigateToSnooze: () => void;
}) => {
  const {
    po,
    setStatus,
    setSoozeEndDate,
    disableStatusEdit,
    navigateToSnooze,
  } = props;
  if (po?.snooze_end_date && new Date(po!.snooze_end_date) > new Date()) {
    return (
      <Chip
        color="primary"
        variant="outlined"
        size="lg"
        startDecorator={<WatchLaterIcon />}
        endDecorator={
          <ChipDelete
            disabled={disableStatusEdit}
            onDelete={() => setSoozeEndDate(null)}
          />
        }
      >
        Snoozed until {dayjs(po.snooze_end_date).format("L")}
      </Chip>
    );
  }
  return (
    <>
      <Typography>Order status:</Typography>
      <Tooltip title="Sent to Vendor" variant="outlined">
        {po?.status === "CUSTOMER_OWNED" ? (
          <IconButton
            variant="soft"
            color="primary"
            disabled={disableStatusEdit}
          >
            <LocalShippingIcon
              onClick={() => setStatus(PurchaseOrderStatus.AtomicSuggestion)}
            />
          </IconButton>
        ) : (
          <IconButton disabled={disableStatusEdit}>
            <LocalShippingOutlinedIcon
              onClick={() => setStatus(PurchaseOrderStatus.CustomerOwned)}
            />
          </IconButton>
        )}
      </Tooltip>
      <Tooltip title="Arrived to Warehouse" variant="outlined">
        {po?.status === "COMPLETED" ? (
          <IconButton
            variant="soft"
            color="success"
            disabled={disableStatusEdit}
          >
            <AssignmentTurnedInIcon
              onClick={() => setStatus(PurchaseOrderStatus.AtomicSuggestion)}
            />
          </IconButton>
        ) : (
          <IconButton disabled={disableStatusEdit}>
            <AssignmentTurnedInOutlinedIcon
              onClick={() => setStatus(PurchaseOrderStatus.Completed)}
            />
          </IconButton>
        )}
      </Tooltip>
      <Tooltip title="Ignored" variant="outlined">
        {po?.status === "CANCELED" ? (
          <IconButton
            variant="soft"
            color="danger"
            disabled={disableStatusEdit}
          >
            <DeleteIcon
              onClick={() => setStatus(PurchaseOrderStatus.AtomicSuggestion)}
            />
          </IconButton>
        ) : (
          <IconButton disabled={disableStatusEdit}>
            <DeleteOutlineOutlinedIcon
              onClick={() => setStatus(PurchaseOrderStatus.Canceled)}
            />
          </IconButton>
        )}
      </Tooltip>
      <Divider orientation="vertical" />
      <Tooltip title="Snooze" variant="outlined">
        <Button
          variant="plain"
          color="neutral"
          onClick={() => navigateToSnooze()}
          startDecorator={<AccessTimeOutlinedIcon />}
        >
          Snooze
        </Button>
      </Tooltip>
    </>
  );
};

export const PoDetailsComponent = (props: {
  id: string;
  po: PurchaseOrder | null;
  loading: boolean;
  controlPanel: React.ReactNode;
  skuList: React.ReactNode;
  supplyWalk: React.ReactNode;
  navigateToPoBox: () => void;
  setStatus: (status: PurchaseOrderStatus) => Promise<void>;
  setSoozeEndDate: (soozeEndDate: Date | null) => Promise<void>;
  disableStatusEdit: boolean;
  downloadWorks: boolean;
  downloadPdf: () => Promise<void>;
  navigateToSnooze: () => void;
  navigateToNextPo: () => void;
  hasNextPo: boolean;
}) => {
  const {
    loading,
    po,
    controlPanel,
    skuList,
    // supplyWalk,
    navigateToPoBox,
    setStatus,
    setSoozeEndDate,
    disableStatusEdit,
    downloadWorks,
    downloadPdf,
    navigateToSnooze,
    navigateToNextPo,
    hasNextPo,
  } = props;
  const [newStatus, setNewStatus] = useState<PurchaseOrderStatus>(
    PurchaseOrderStatus.CustomerOwned
  );
  const { savePuchaseOrder, loading: poLoading } = useContext(PoEditContext);
  return (
    <>
      <PageFrame
        sx={{
          alignItems: "stretch",
          alignContent: "flex-start",
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "auto auto",
          m: 4,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box sx={{ pr: 4 }}>
              <Tooltip title="Back to PO Box" variant="outlined">
                <Button
                  startDecorator={<KeyboardArrowLeftIcon />}
                  variant="plain"
                  onClick={() => navigateToPoBox()}
                >
                  Back
                </Button>
              </Tooltip>
            </Box>
            <StatusIcons
              po={po}
              setStatus={setStatus}
              setSoozeEndDate={setSoozeEndDate}
              disableStatusEdit={disableStatusEdit}
              navigateToSnooze={navigateToSnooze}
            />
            <Divider orientation="vertical" />
            <Tooltip title="Download as PDF" variant="outlined">
              <Button
                variant="plain"
                color="neutral"
                disabled={!downloadWorks}
                onClick={() => {
                  if (downloadWorks) {
                    downloadPdf();
                  } else {
                  }
                }}
                startDecorator={<DownloadOutlinedIcon />}
              >
                Download as PDF
              </Button>
            </Tooltip>
            <Tooltip title="Go to next PO" variant="outlined">
              <Button
                variant="plain"
                color="neutral"
                disabled={!hasNextPo}
                onClick={() => navigateToNextPo()}
                startDecorator={<ArrowForwardOutlinedIcon />}
              >
                Next PO
              </Button>
            </Tooltip>
          </Stack>
        </Stack>

        {/* <PageTitle>Purchase Order</PageTitle> */}
        <Box sx={{ height: "10px" }}>
          {(loading || poLoading) && <LinearProgress />}
        </Box>

        {po?.status === PurchaseOrderStatus.AtomicSuggestion && (
          <Alert variant="soft" color="warning" sx={{ mt: 2 }}>
            This PO is a suggestion by Atomic. To take it into effect, set its
            status to{" "}
            <Select
              defaultValue="CUSTOMER_OWNED"
              value={newStatus}
              onChange={(
                event: SyntheticEvent | null,
                newValue: string | null
              ) =>
                setNewStatus(
                  (newValue || "CUSTOMER_OWNED") as PurchaseOrderStatus
                )
              }
            >
              <Option value="CUSTOMER_OWNED">
                <ListItemDecorator>
                  <LocalShippingOutlinedIcon />
                </ListItemDecorator>
                Sent to Vendor
              </Option>
              <Option value="COMPLETED">
                <ListItemDecorator>
                  <AssignmentTurnedInOutlinedIcon />
                </ListItemDecorator>
                Completed
              </Option>
              <Option value="CLOSED">
                <ListItemDecorator>
                  <DeleteOutlineOutlinedIcon />
                </ListItemDecorator>
                Ignored
              </Option>
            </Select>
            <Button onClick={() => setStatus(newStatus)}>Set</Button> or{" "}
            <Button onClick={() => navigateToSnooze()}>Snooze it</Button>.
          </Alert>
        )}

        {!downloadWorks && (
          <Alert variant="soft" color="warning" sx={{ mt: 2 }}>
            To download the PO as a PDF, please specify the location's and
            vendor's addresses and upload your company logo first.
          </Alert>
        )}

        {controlPanel}
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignContent="stretch"
          flexWrap="wrap"
          spacing={2}
          sx={{ pt: 2 }}
        >
          <Typography level="body-xs">Ordered SKUs:</Typography>
          <Sheet
            variant="outlined"
            color="neutral"
            sx={{
              mt: 2,
              mb: 0,
              p: 1,
              overflow: "auto",
              minWidth: "0",
              maxWidht: "100%",
              borderRadius: "6px",
              borderBottomRightRadius: "0",
              borderBottomLeftRadius: "0",
              backgroundColor: "atomic.highlightBackground",
            }}
          >
            {skuList}
          </Sheet>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignContent="center"
          flexWrap="wrap"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Button size="lg" onClick={() => savePuchaseOrder()}>
            Update Purchase Order
          </Button>
        </Stack>
      </PageFrame>
    </>
  );
};
