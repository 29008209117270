import { useParams, useSearchParams } from "react-router-dom";
import { PoDetailsContainer } from "./container";
import { PageWithRightPane } from "../../frameworks/joy/layouts/page_with_right_pane";
import { PoEditsProvider } from "./po_edit_context/provider";

export const PoDetailsPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { id } = params;
  if ([id].includes(undefined)) {
    return null;
  }
  const supply_walk_skus = searchParams.get("supply_walk_skus");
  const supplyWalkSkus: string[] = supply_walk_skus?.split(",") || [];
  return (
    <PageWithRightPane>
      <PoEditsProvider purchaseOrderId={id!}>
        <PoDetailsContainer id={id!} supplyWalkSkus={supplyWalkSkus} />
      </PoEditsProvider>
    </PageWithRightPane>
  );
};
