import { Fragment } from "react";
import LinearProgress from "@mui/joy/LinearProgress";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Timeline from "@mui/icons-material/Timeline";
import { PowerBiReportItems } from "../../services/api/__generated__/backend_gateway-types";

export const WorkspaceReportsComponent = (props: {
  powerBiReportItems: PowerBiReportItems | null;
  loading: boolean;
  navigateToReport: (reportId: string, pageId: string) => void;
}) => {
  const { powerBiReportItems, loading, navigateToReport } = props;

  return (
    <>
      {loading && <LinearProgress />}

      {!loading &&
        powerBiReportItems?.reports?.map((report, i) => (
          <Fragment key={i}>
            <ListItem nested>
              {/* <ListItem>{report.reportName}</ListItem> */}
              <List>
                {report.pages.map((page) => (
                  <ListItemButton
                    onClick={() =>
                      navigateToReport(report.reportId, page.pageId)
                    }
                    key={`${report.reportId}-${page.pageId}`}
                  >
                    <ListItemDecorator>
                      <Timeline />
                    </ListItemDecorator>
                    <ListItemContent>{page.pageName}</ListItemContent>
                  </ListItemButton>
                ))}
              </List>
            </ListItem>
          </Fragment>
        ))}
    </>
  );
};
