import { useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../contexts/api/context";
import {
  useGetAllCustomersQuery,
  useGetLatestOrchestratorRunsQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { AllRunsComponent } from "./component";

export const AllRunsContainer = () => {
  const { apiService } = useContext(ApiContext);

  const {
    data: getAllCustomersData,
    loading: getAllCustomersLoading,
    error: getAllCustomersError,
  } = useGetAllCustomersQuery({
    client: apiService.getClient(),
  });

  const {
    data: getLatestOrchestratorRunsData,
    loading: getLatestOrchestratorRunsLoading,
    error: getLatestOrchestratorRunsError,
    refetch: getLatestOrchestratorRunsRefetch,
  } = useGetLatestOrchestratorRunsQuery({
    client: apiService.getClient(),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      getLatestOrchestratorRunsRefetch();
    }, 10000);
    return () => clearInterval(interval);
  }, [getLatestOrchestratorRunsRefetch]);

  if (getAllCustomersError) {
    console.error(getAllCustomersError);
  }

  if (getLatestOrchestratorRunsError) {
    console.error(getLatestOrchestratorRunsError);
  }

  //   const navigate = useNavigate();

  const navigateToFailedSteps = (
    customerId: string,
    inputId: string,
    workflowId: string,
    variablesId: string,
    runAttemptId: number
  ) => {
    // navigate(
    //   `/all_runs/failed_steps/${customerId}/${inputId}/${workflowId}/${variablesId}/${runAttemptId}`,
    //   { replace: true }
    // );
  };

  return (
    <AllRunsComponent
      loading={getAllCustomersLoading || getLatestOrchestratorRunsLoading}
      customers={getAllCustomersData?.getAllCustomers || []}
      runs={getLatestOrchestratorRunsData?.getLatestOrchestratorRuns || []}
      navigateToFailedSteps={navigateToFailedSteps}
    />
  );
};
