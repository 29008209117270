import { useContext } from "react";
import {
  useGetMyCustomerQuery,
  useWhoAmIQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { ApiContext } from "../../contexts/api/context";
import { RunTriggerComponent } from "./component";
import { useNavigate } from "react-router-dom";
import { DrawerContext } from "../../contexts/drawer/context";
import { WorkflowsContext } from "../../contexts/workflows/context";

export const RunTriggerContainer = (props: { enabled: boolean }) => {
  const { enabled } = props;
  const { apiService, selectedCustomerId } = useContext(ApiContext);
  const { closeDrawer } = useContext(DrawerContext);

  const { data: customerData, loading: customerLoading } =
    useGetMyCustomerQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          console.error(error);
          // addSystemMessage({
          //   level: SysMessageLevel.DANGER,
          //   message: error.message!,
          // });
        }
      },
    });

  const navigate = useNavigate();

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        console.error(error);
        // addSystemMessage({
        //   level: SysMessageLevel.DANGER,
        //   message: error.message!,
        // });
      }
    },
  });

  const {
    isDisabled,
    isLoading,
    isTriggerInProgress,
    lastEngineRun,
    lastExportRun,
    startTheEngineScriptsWithDefaults,
  } = useContext(WorkflowsContext);

  const role = whoAmIData?.whoAmI?.role || null;

  const navitageToEnginePresets = () => {
    closeDrawer();
    navigate("/engine_presets", { replace: true });
  };

  const customerId =
    (role === "ROOT" ? selectedCustomerId : whoAmIData?.whoAmI?.customerId) ||
    null;

  return (
    <RunTriggerComponent
      role={role}
      customerId={customerId}
      runningInProgress={
        isTriggerInProgress ||
        lastEngineRun?.status === "RUN_IN_PROGRESS" ||
        lastExportRun?.status === "RUN_IN_PROGRESS"
      }
      runDisabled={isDisabled}
      latestStartAt={lastEngineRun?.createdAt || null}
      latestExportedAt={lastExportRun?.createdAt || null}
      loading={customerLoading || whoAmILoading || isLoading}
      workflowTrigger={enabled ? startTheEngineScriptsWithDefaults : () => {}}
      hasEnginePresets={
        customerData?.getMyCustomer?.features?.EnginePresets || false
      }
      navitageToEnginePresets={navitageToEnginePresets}
    />
  );
};
