import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import LinearProgress from "@mui/joy/LinearProgress";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { DiffParams } from "../diff_params";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Switch from "@mui/joy/Switch";
import { JoyCompatibleDatePicker } from "../../../../components/date_picker";
import { MaterialEmbed } from "../../../../frameworks/joy/material_embed";

dayjs.extend(utc);

export const PlanningReviewControlComponent = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
  updateRuns: (params: DiffParams) => void;
  loading: boolean;
  error: string | null;
  excelExport: () => void;
}) => {
  const { diffParams, updateDiffParams, loading, excelExport } = props;

  return (
    <MaterialEmbed>
      <AccordionGroup
        size="lg"
        variant="outlined"
        sx={{
          m: 2,
          borderRadius: "sm",
          [`& .${accordionSummaryClasses.button}:hover`]: {
            borderRadius: "sm",
          },
        }}
      >
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={{
              borderBottom: (theme) =>
                `1px solid ${theme.vars.palette.divider}`,
            }}
          >
            Run Comparison - Controls
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 2, bgcolor: "white" }}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel htmlFor="select-run-0" id="select-run-0-label">
                    Select a Run
                  </FormLabel>
                  <Select
                    value={1}
                    placeholder="Demand forecast"
                    slotProps={{
                      button: {
                        id: "select-run-0",
                        "aria-labelledby": "select-label select-run-0",
                      },
                    }}
                    disabled
                  >
                    <Option
                      value={1}
                      label={
                        <>
                          <Chip variant="solid">2</Chip>{" "}
                          <Typography sx={{ ml: 1 }}>2024-05-03</Typography>
                          <Typography sx={{ ml: 1, fontWeight: 800 }}>
                            <b>DRAFT</b>
                          </Typography>
                          <Typography sx={{ ml: 1 }}></Typography>
                        </>
                      }
                    >
                      <Chip variant="outlined">1</Chip> 2024-05-03
                      <b>DRAFT</b>
                    </Option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel htmlFor="select-run-1" id="select-run-1-label">
                    Select a Run for Comparison{" "}
                    <Chip size="sm" variant="soft">
                      Optional
                    </Chip>
                  </FormLabel>
                  <Select
                    value={1}
                    placeholder="Demand forecast"
                    slotProps={{
                      button: {
                        id: "select-run-0",
                        "aria-labelledby": "select-label select-run-0",
                      },
                    }}
                    disabled
                  >
                    <Option
                      value={1}
                      label={
                        <>
                          <Chip variant="solid">1</Chip>{" "}
                          <Typography sx={{ ml: 1 }}>2024-05-01</Typography>
                          <Typography sx={{ ml: 1, fontWeight: 800 }}>
                            <b>POR</b>
                          </Typography>
                          <Typography sx={{ ml: 1 }}></Typography>
                        </>
                      }
                    >
                      <Chip variant="outlined">1</Chip> 2024-05-01
                      <b>POR</b>
                    </Option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Set the Comparison Start Date</FormLabel>
                  <JoyCompatibleDatePicker
                    disabled
                    value={dayjs.utc(diffParams.minDate)}
                    disablePast={false}
                    maxDate={dayjs("12/31/2026")}
                    onChange={(newNalue) =>
                      updateDiffParams({
                        ...diffParams,
                        minDate: newNalue?.toDate() || new Date(),
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Set the Comparison End Date</FormLabel>
                  <JoyCompatibleDatePicker
                    disabled
                    value={dayjs.utc(diffParams.maxDate)}
                    disablePast={false}
                    maxDate={dayjs("12/31/2026")}
                    onChange={(newNalue) =>
                      updateDiffParams({
                        ...diffParams,
                        maxDate: newNalue?.toDate() || new Date(),
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6} sx={{ pt: 1.5 }}>
                <FormControl>
                  <Typography
                    component="label"
                    startDecorator={
                      <Switch
                        disabled
                        checked={diffParams.heatMap || false}
                        onChange={(event) =>
                          updateDiffParams({
                            ...diffParams,
                            heatMap: event.target.checked,
                          })
                        }
                      />
                    }
                  >
                    {diffParams.heatMap
                      ? "Heatmap Enabled"
                      : "Heatmap Disabled"}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid xs={6} md={3}>
                <FormControl>
                  <Button onClick={excelExport} size="sm" variant="outlined">
                    Export Table as .xlsx
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </MaterialEmbed>
  );
};
