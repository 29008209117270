import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import LinearProgress from "@mui/joy/LinearProgress";
import Logout from "@mui/icons-material/Logout";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import { User } from "../../services/api/__generated__/backend_gateway-types";

export const WhoAmIComponent = (props: {
  loading: boolean;
  user: User | undefined;
  signOut: () => void;
  hasDarkMode: boolean;
  demoMode: boolean;
  setDemoMode: React.Dispatch<React.SetStateAction<boolean>>;
  modeString: string;
  setModeString: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    loading,
    user,
    signOut,
    hasDarkMode,
    demoMode,
    setDemoMode,
    modeString,
    setModeString,
  } = props;

  return (
    <PageFrame>
      <PageTitle>My profile</PageTitle>

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <Box>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid xs={12} sm={6}>
            <Typography>Name: {user?.name}</Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <Typography>Email: {user?.email}</Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <Typography>Role: {user?.role}</Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <Typography>CustomerId: {user?.customerId}</Typography>
          </Grid>
          {user?.role === "ROOT" && (
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Demo mode</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={demoMode || false}
                      onChange={(event) => setDemoMode(event.target.checked)}
                    />
                  }
                >
                  {demoMode ? "On" : "Off"}
                </Typography>
                <FormHelperText>
                  Turn on/off demo mode, which hides the customer selector and
                  the internal pages
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          {(user?.role === "ROOT" || hasDarkMode) && (
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>Dark mode</FormLabel>
                <Select
                  variant="soft"
                  value={modeString}
                  onChange={(event, newMode) => {
                    newMode && setModeString(newMode);
                  }}
                >
                  <Option value="system">System</Option>
                  <Option value="light">Light</Option>
                  <Option value="dark">Dark</Option>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid xs={12} sm={6}>
            <Button startDecorator={<Logout />} onClick={() => signOut()}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Box>
    </PageFrame>
  );
};
