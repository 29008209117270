import { ReactNode, useState } from "react";
import { SysMessagesContext } from "./context";
import { SysMessagesType, SysMessageWithLevel } from "./type";

const { Provider } = SysMessagesContext;

export const SysMessagesProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  const [systemMessages, setSystemMessages] = useState<SysMessageWithLevel[]>(
    []
  );

  const removeSystemMessage = (i: number) => {
    if (i < systemMessages.length) {
      setSystemMessages((m) => [...m.splice(0, i), ...m.splice(i + 1)]);
    }
  };

  const addSystemMessage = (m: SysMessageWithLevel) => {
    setSystemMessages((s) => [...s, m]);
  };

  const value: SysMessagesType = {
    systemMessages,
    addSystemMessage,
    removeSystemMessage,
  };

  return <Provider value={value}>{props.children}</Provider>;
};
