import LinearProgress from "@mui/joy/LinearProgress";
import { Fragment } from "react";
import { PowerBi } from "../../services/api/__generated__/backend_gateway-types";

import { HeaderDrawerLinksToReportsContainer } from "./header_container";

export const DrawerLinksToReportsComponent = (props: {
  loading: boolean;
  powerBis: PowerBi[];
}) => {
  const { loading, powerBis } = props;

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        powerBis.map((powerBi) => (
          <Fragment key={powerBi.id}>
            <HeaderDrawerLinksToReportsContainer
              workspaceId={powerBi.workspaceId}
            />
          </Fragment>
        ))
      )}
    </>
  );
};
