import Chip from "@mui/joy/Chip";
import Link from "@mui/joy/Link";
import List from "@mui/joy/List";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";
import { RIGHT_PLANE_WIDTH } from "../../../frameworks/joy/layouts/drawer_layout/constants";
import {
  OrchestratorStep,
  OrchestratorStepStatus,
} from "../../../services/api/__generated__/backend_gateway-types";
import { StepId } from "../step_id";
import { stepOrchestrationLogLink } from "../step_log_link";
import { stepStatusToEmoji } from "../step_status_to_emoji";
import { stepStatusToText } from "../step_status_to_text";
import { runSettingsAndStepToId } from "./run_settings_and_step_to_id";
import { stepEventLink } from "../step_event_link";
dayjs.extend(utc);

export const StepEventsComponent = (props: {
  steps: OrchestratorStep[];
  customerId: string | null;
}) => {
  const { steps, customerId } = props;
  const sortedSteps = [...steps].sort(
    (a: OrchestratorStep, b: OrchestratorStep) =>
      dayjs(a.createdAt).diff(b.createdAt) < 0 ? -1 : 1
  );

  return (
    <>
      <Typography level="body-lg" sx={{ pt: 1, fontWeight: 700 }}>
        Execution Event List (ROOT only)
      </Typography>
      <Sheet
        variant="outlined"
        sx={{
          p: 1,
          maxWidth: `${RIGHT_PLANE_WIDTH - 20}px`,
        }}
      >
        <List
          aria-labelledby="step-list"
          sx={{ "--ListItemDecorator-size": "56px" }}
        >
          {sortedSteps.map((step, i) => (
            <React.Fragment
              key={`${runSettingsAndStepToId(
                step.runSettings,
                step.stepId
              )}-${i}`}
            >
              <ListItem
                endAction={
                  <Chip size="sm">
                    {stepStatusToEmoji(step.status)}{" "}
                    {stepStatusToText(step.status)}
                  </Chip>
                }
              >
                <ListItemContent>
                  <StepId stepId={step.stepId} />
                  <Typography level="body-xs" noWrap>
                    {step.status !== OrchestratorStepStatus.ExecutionQueued && (
                      <>
                        <Link href={stepOrchestrationLogLink(step.createdAt)}>
                          Orchestration Logs
                        </Link>
                        {" | "}
                        <Link href={stepEventLink(step, customerId)}>
                          DB record
                        </Link>
                      </>
                    )}
                  </Typography>
                  <Typography level="body-xs" noWrap>
                    {dayjs
                      .utc(step.createdAt)
                      .local()
                      .format("MM/DD/YYYY hh:mm:ss a")}
                  </Typography>
                </ListItemContent>
              </ListItem>
              {i < sortedSteps.length - 1 && <ListDivider />}
            </React.Fragment>
          ))}
        </List>
      </Sheet>
    </>
  );
};
