import { useRef } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import ListItemContent from "@mui/joy/ListItemContent";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";

export const RunOrderSimulationComponent = () => {
  const loading = false;
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <Box
      sx={{
        width: "100%",
        pt: 2,
      }}
    >
      <AccordionGroup>
        <Accordion disabled>
          <AccordionSummary>
            <Avatar color="primary">
              <CloudUploadIcon />
            </Avatar>
            <ListItemContent>
              <Typography level="title-md" sx={{ display: "block" }}>
                Upload forecast
              </Typography>
              <Typography level="body-sm" sx={{ display: "block" }}>
                Latest upload: 05/01/2024
              </Typography>
            </ListItemContent>
          </AccordionSummary>
          <AccordionDetails>Content</AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary>
            <Avatar color="primary">
              <DirectionsRunIcon />
            </Avatar>
            <ListItemContent>
              <Typography level="title-md" sx={{ display: "block" }}>
                Run Settings
              </Typography>
              <Typography level="body-sm" sx={{ display: "block" }}>
                Latest run: 05/01/2024
              </Typography>
            </ListItemContent>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexFlow: "column nowrap",
                alignItems: "stretch",
                justifyContent: "center",
                minWidth: "200px",
                maxWidth: "600px",
                my: 3,
              }}
            >
              <FormControl>
                <FormLabel>Recent History to use for Take Rates</FormLabel>
                <Input
                  type="number"
                  defaultValue="60"
                  slotProps={{
                    input: {
                      ref: inputRef,
                      min: 0,
                    },
                  }}
                  endDecorator="days"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Weighting Method</FormLabel>
                <Select
                  placeholder="Select Weighting Method"
                  name="foo"
                  required
                  defaultValue="AVERAGE"
                  sx={{ minWidth: 200 }}
                >
                  <Option value="AVERAGE">Average</Option>
                  <Option value="WEIGHTED_AVERAGE">Weighted Average</Option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Apply Take Rates By</FormLabel>
                <Select
                  placeholder="Apply Take Rates By"
                  name="foo"
                  required
                  defaultValue="CHANNEL"
                  sx={{ minWidth: 200 }}
                >
                  <Option value="CHANNEL">Channel</Option>
                  <Option value="OVERALL">Overall</Option>
                </Select>
              </FormControl>
            </Box>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>

      <FormControl
        sx={{
          m: 2,
          pt: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <Button disabled={loading} color="primary" variant="solid" size="lg">
            Run Order Simulation
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};
