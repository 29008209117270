import { ReactNode } from "react";
import { PageFrame } from "../../components/page_frame";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import Sheet from "@mui/joy/Sheet";
import TabPanel from "@mui/joy/TabPanel";

export const ForecastAccuracyComponent = (props: {
  control?: ReactNode;
  alerts?: ReactNode;
  table?: ReactNode;
  loading: boolean;
}) => {
  const { control, alerts, table } = props;
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
        backgroundColor: "atomic.highlightUnderlay",
      }}
    >
      {control}
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 2,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <Tabs
          aria-label="Basic tabs"
          defaultValue={0}
          sx={{ backgroundColor: "atomic.highlightBackground" }}
        >
          <TabList>
            <Tab>By Segmentation</Tab>
            <Tab>By SKU</Tab>
          </TabList>
          <TabPanel value={0}>{table}</TabPanel>
          <TabPanel value={1}>{alerts}</TabPanel>
        </Tabs>
      </Sheet>
    </PageFrame>
  );
};
