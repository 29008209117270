import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  useEffect,
  useMemo,
  // useState
} from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
// import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
// import Switch from "@mui/joy/Switch";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";
import { RIGHT_PLANE_WIDTH } from "../../../frameworks/joy/layouts/drawer_layout/constants";
import {
  EnginePreset,
  OrchestratorRun,
  OrchestratorRunStatus,
  OrchestratorStep,
} from "../../../services/api/__generated__/backend_gateway-types";
import { runStatusToText } from "../run_status_to_text";
import { runStatusToEmoji } from "../run_status_to_emoji";
import { StepEventsComponent } from "./step_events_component";
import { StepOverviewComponent } from "./step_overview_component";
import { runDuration } from "./duration";
import { runLink } from "../run_link";
import Link from "@mui/joy/Link";
import Grid from "@mui/joy/Grid";
dayjs.extend(utc);

export const RunDetailsComponent = (props: {
  loading: boolean;
  run: OrchestratorRun | undefined;
  steps: OrchestratorStep[];
  navigateBack: () => void;
  hasFeatureStepOrchestratorStepDetails: boolean;
  rerunStep: (run: OrchestratorRun, stepId: string) => Promise<void>;
  setRunName: (run: OrchestratorRun, name: string) => Promise<void>;
  setRunDescription: (
    run: OrchestratorRun,
    description: string
  ) => Promise<void>;
  setRunLocked: (run: OrchestratorRun, locked: boolean) => Promise<void>;
  rerun: (run: OrchestratorRun) => Promise<void>;
  cancelRun: (run: OrchestratorRun) => Promise<void>;
  triggerNextSteps: (run: OrchestratorRun) => Promise<void>;
  retryRun: (run: OrchestratorRun) => Promise<void>;
  rerunInProgress: boolean;
  rerunStepId: string | null;
  presets: EnginePreset[];
  customerId: string | null;
}) => {
  const {
    loading,
    run,
    steps,
    navigateBack,
    hasFeatureStepOrchestratorStepDetails,
    rerunStep,
    // setRunName: storeRunName,
    // setRunDescription: storeRunDescription,
    // setRunLocked: storeRunLocked,
    // rerun,
    cancelRun,
    // triggerNextSteps,
    retryRun,
    rerunInProgress,
    presets,
    rerunStepId,
    customerId,
  } = props;
  // const [runName, setRunName] = useState<string>("");
  // const [runDescription, setRunDescription] = useState<string>("");
  // const [runLocked, setRunLocked] = useState<boolean>(false);
  // NOTE: Array.toSorted would be the ideal, but tsx fails

  const runParams = useMemo(() => {
    const runParamsAll = JSON.parse(run?.runParamsJson || "{}");
    const runParamsNotNull = {
      ...runParamsAll,
    };
    Object.keys(runParamsAll).forEach((k) => {
      if (
        runParamsAll[k] === null ||
        runParamsAll[k] === undefined ||
        k === "load_demand_of_run_id" ||
        k === "default"
      ) {
        delete runParamsNotNull[k];
      }
    });
    return runParamsNotNull;
  }, [run?.runParamsJson]);

  useEffect(() => {
    if (run) {
      // setRunName(run.name || "");
      // setRunDescription(run.description || "");
      // setRunLocked(run.locked || false);
    }
  }, [run]);

  const getPresetName = (id: string | null): string => {
    if (id === null) {
      return "";
    }
    const preset = presets.find((pr) => pr.id === parseInt(id));
    if (preset) {
      return `${preset.name || preset.id} ${preset.default ? "(default)" : ""}`;
    }
    return `${id}`;
  };

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>Workflow details</RightPaneTitle>
      {loading && <LinearProgress />}
      <Box
        sx={{
          py: 2,
          display: "grid",
          gap: 4,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {/* <FormControl>
          <FormLabel>Run name</FormLabel>
          <Input
            value={runName}
            placeholder="Name"
            onChange={(event) => setRunName(event.target.value)}
            endDecorator={
              <Button onClick={() => storeRunName(run!, runName)}>
                Update
              </Button>
            }
            disabled={run === undefined}
          />
          <FormHelperText>
            Short name that will appear when triggering runs
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>Run description</FormLabel>
          <Input
            value={runDescription}
            placeholder="Description"
            onChange={(event) => setRunDescription(event.target.value)}
            endDecorator={
              <Button onClick={() => storeRunDescription(run!, runDescription)}>
                Update
              </Button>
            }
            disabled={run === undefined}
          />
          <FormHelperText>Notes</FormHelperText>
        </FormControl> */}
        {/* <FormControl>
          <FormLabel>Lock demand forecast</FormLabel>
          <Sheet variant="outlined" sx={{ p: 1, borderRadius: 6 }}>
            <Typography
              component="label"
              sx={{ width: "100%" }}
              startDecorator={
                <Switch
                  checked={runLocked}
                  onChange={(event) => {
                    setRunLocked(event.target.checked);
                    storeRunLocked(run!, event.target.checked);
                  }}
                />
              }
            >
              {runLocked ? "Locked" : "Not locked"}
            </Typography>
          </Sheet>
          <FormHelperText>
            New runs will be able to re-use demand forecast of this run if
            enabled
          </FormHelperText>
        </FormControl> */}

        <Sheet
          variant="outlined"
          sx={{
            "--TableCxell-height": "40px",
            "--TableHeader-height": "40px",
            minHeight: "10vh",
            maxHeight: "70vh",
            overflow: "auto",
            background: (
              theme
            ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
                  linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
                  radial-gradient(
                    farthest-side at 50% 0,
                    ${theme.palette.atomic.fullShadowFade},
                    ${theme.palette.atomic.fullShadow}
                  ),
                  radial-gradient(
                      farthest-side at 50% 100%,
                    ${theme.palette.atomic.fullShadowFade},
                    ${theme.palette.atomic.fullShadow}
                    )
                    0 100%`,
            backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local, local, scroll, scroll",
            backgroundPosition:
              "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
            backgroundColor: (theme) =>
              `${theme.palette.atomic.highlightBackground}`,
          }}
        >
          <Table
            aria-labelledby="Run details"
            stickyHeader
            hoverRow={true}
            // stripe="odd"
            borderAxis="xBetween"
            sx={{
              maxWidth: `${RIGHT_PLANE_WIDTH - 20}px`,
              "--Table-headerUnderlineThickness": "1px",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "120px",
                  }}
                >
                  Workflow
                </th>
                <th>{run ? run.runSettings.workflowId : ""}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Input id</td>
                <td>{run ? run.runSettings.inputId : ""}</td>
              </tr>
              <tr>
                <td>Variable set id</td>
                <td>{getPresetName(run?.runSettings.variablesId || null)}</td>
              </tr>
              <tr>
                <td>Run attempt id</td>
                <td>{run ? run.runSettings.runAttemptId : ""}</td>
              </tr>
              <tr>
                <td>DB record</td>
                <td>
                  {run && customerId ? (
                    <Link href={runLink(run.runSettings, customerId)}>
                      link
                    </Link>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <td>Started at</td>
                <td>
                  {run
                    ? dayjs
                        .utc(run.createdAt)
                        .local()
                        .format("MM/DD/YYYY hh:mm:ss a")
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Last updated at</td>
                <td>
                  {run
                    ? dayjs
                        .utc(run.updatedAt)
                        .local()
                        .format("MM/DD/YYYY hh:mm:ss a")
                    : ""}
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>
                  {run ? (
                    <Chip size="sm">
                      {runStatusToEmoji(run.status)}{" "}
                      {runStatusToText(run.status)}
                    </Chip>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <td>Duration</td>
                <td>{run ? runDuration(steps) : ""}</td>
              </tr>
              <tr>
                <td>Initiated by</td>
                <td>{run ? run.initiatedBy : ""}</td>
              </tr>
              <tr>
                <td>Uses locked run</td>
                <td>
                  {run?.oldLockedRunId
                    ? `${run?.oldLockedRunId}`
                    : run?.usesLockedInputId && run?.usesLockedVariablesId
                    ? `${run?.usesLockedInputId} - ${run?.usesLockedVariablesId}`
                    : "No"}
                </td>
              </tr>
              <tr>
                <td>Run ID</td>
                <td>{run ? run.oldRunId : ""}</td>
              </tr>
            </tbody>
          </Table>
        </Sheet>

        <Sheet variant="outlined" sx={{ p: 1 }}>
          <Typography level="body-lg" sx={{ fontWeight: 700 }}>
            Variables used:
          </Typography>
          <Typography level="body-xs" sx={{ pb: 1, fontWeight: 100 }}>
            Excludes variables defined in the SQL
          </Typography>
          {Object.keys(runParams).map((k) => (
            <FormControl key={k}>
              <FormLabel>{k}</FormLabel>
              <Input value={runParams[k]} disabled variant="solid" />
            </FormControl>
          ))}
        </Sheet>

        <Grid
          container
          spacing={2}
          sx={{
            flexGrow: 1,
          }}
        >
          <Grid xs={6}>
            <FormControl
              sx={{
                mt: 2,
              }}
            >
              <Button
                color="danger"
                onClick={() => cancelRun(run!)}
                disabled={
                  loading ||
                  run === undefined ||
                  [
                    OrchestratorRunStatus.CancelInProgress,
                    OrchestratorRunStatus.CancelCompleted,
                    OrchestratorRunStatus.ToRun,
                    OrchestratorRunStatus.RunCompleted,
                  ].includes(run.status) ||
                  rerunInProgress
                }
              >
                Kill run
              </Button>
            </FormControl>
          </Grid>
          <Grid xs={6}>
            <FormControl
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => retryRun(run!)}
                disabled={
                  loading ||
                  run === undefined ||
                  ![OrchestratorRunStatus.RunFailed].includes(run.status) ||
                  rerunInProgress
                }
              >
                Retry failed steps
              </Button>
            </FormControl>
          </Grid>

          {/* <Grid xs={6}>
            <FormControl
              sx={{
                mt: 2,
              }}
            >
              <Button
                onClick={() => triggerNextSteps(run!)}
                disabled={loading || run === undefined}
              >
                Stuck? Make it proceed
              </Button>
            </FormControl>
          </Grid> */}
        </Grid>

        {/* <FormControl
          sx={{
            mt: 2,
          }}
        >
          <Button
            onClick={() => rerun(run!)}
            disabled={loading || run === undefined}
          >
            Re-run
          </Button>
        </FormControl> */}

        {hasFeatureStepOrchestratorStepDetails && run && (
          <StepOverviewComponent
            run={run}
            steps={steps}
            rerunStep={rerunStep}
            rerunStepId={rerunStepId}
          />
        )}

        {hasFeatureStepOrchestratorStepDetails && (
          <StepEventsComponent steps={steps} customerId={customerId} />
        )}
      </Box>
    </RightPaneFrame>
  );
};
