import { Page } from "../../frameworks/joy/layouts/page";
import { RunLoginDashContainer } from "./container";

export const RunLoginDashPage = () => {
  return (
    <Page>
      <RunLoginDashContainer />
    </Page>
  );
};
