import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Snackbar from "@mui/joy/Snackbar";
import { useEffect, useState } from "react";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";
import {
  Customer,
  Role,
} from "../../../services/api/__generated__/backend_gateway-types";
import { FeatureList, featuresFromObject } from "./features_type";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import Divider from "@mui/joy/Divider";
import CardContent from "@mui/joy/CardContent";
import Checkbox from "@mui/joy/Checkbox";
import CardActions from "@mui/joy/CardActions";

const SelectRole = (props: {
  feature: string;
  value: Role;
  setNewFeatures: React.Dispatch<React.SetStateAction<FeatureList>>;
}) => {
  const { feature, value, setNewFeatures } = props;
  return (
    <Select placeholder="Select minum role" value={value}>
      <Option
        value={Role.Root}
        onClick={() => {
          setNewFeatures((nf) => ({
            ...nf,
            [feature]: Role.Root,
          }));
        }}
      >
        Enabled for Atomic only
      </Option>
      <Option
        value={Role.Admin}
        onClick={() => {
          setNewFeatures((nf) => ({
            ...nf,
            [feature]: Role.Admin,
          }));
        }}
      >
        Enabled for the customer
      </Option>
      <Option
        value={Role.None}
        onClick={() => {
          setNewFeatures((nf) => ({
            ...nf,
            [feature]: Role.None,
          }));
        }}
      >
        Disabled
      </Option>
    </Select>
  );
};

type PowerBiSettings = {
  id: string;
  name: string;
  workspaceId: string;
  datasetId: string;
  enabled: boolean;
};

export const FeatureFlagsComponent = (props: {
  loading: boolean;
  errors: string[];
  clearError: (i: number) => void;
  customer: Customer | null;
  navigateBack: () => void;
  setFeatures: (features: FeatureList) => Promise<void>;
  createEmailDomain: (emailDomain: string) => void;
  deleteEmailDomain: (emailDomain: string) => void;
  createPowerbi: (
    name: string,
    workspaceId: string,
    datasetId: string,
    enabled: boolean
  ) => void;
  replacePowerbi: (
    id: string,
    name: string,
    workspaceId: string,
    datasetId: string,
    enabled: boolean
  ) => void;
}) => {
  const {
    loading,
    errors,
    clearError,
    customer,
    navigateBack,
    setFeatures,
    createEmailDomain,
    deleteEmailDomain,
    createPowerbi,
    replacePowerbi,
  } = props;
  const [newFeatures, setNewFeatures] = useState<FeatureList>(
    featuresFromObject({})
  );
  useEffect(() => {
    if (customer !== null) {
      setNewFeatures(featuresFromObject(customer.features || {}));
      setNewPowerBis(
        (customer.powerBi || []).map((pbi) => ({
          id: pbi.id || "",
          name: pbi.name || "",
          workspaceId: pbi.workspaceId || "",
          datasetId: pbi.datasetId || "",
          enabled: pbi.enabled || false,
        }))
      );
    }
  }, [customer]);

  const [newEmailDomain, setNewEmailDomain] = useState<string>("");
  const [newPowerBis, setNewPowerBis] = useState<PowerBiSettings[]>([]);
  const [newPowerBi, setNewPowerBi] = useState<PowerBiSettings>({
    id: "",
    name: "",
    workspaceId: "",
    datasetId: "",
    enabled: true,
  });

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>
        {customer?.name || customer?.id || "Customer"} Settings
      </RightPaneTitle>
      {loading && <LinearProgress />}

      {errors.map((error, i) => (
        <Snackbar
          key={i}
          variant="soft"
          color="danger"
          open={true}
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            clearError(i);
          }}
          endDecorator={
            <Button
              onClick={() => clearError(i)}
              size="sm"
              variant="soft"
              color="danger"
            >
              Dismiss
            </Button>
          }
        >
          {error}
        </Snackbar>
      ))}

      <Box
        sx={{
          py: 2,
          display: "grid",
          gap: 4,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography level="h3">Email domains</Typography>
        <Typography level="body-sm">
          Users logging in with emails from the following email domains will be
          enabled to see this customer's pages.
        </Typography>
        <List>
          {customer?.emailDomains?.map((ed, i) => (
            <ListItem
              key={i}
              endAction={
                <IconButton
                  size="sm"
                  color="danger"
                  onClick={() => deleteEmailDomain(ed)}
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemButton>
                <ListItemContent>{ed}</ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem>
            <ListItemContent>
              <Input
                endDecorator={
                  <Button
                    size="sm"
                    variant="solid"
                    color="primary"
                    onClick={() => {
                      createEmailDomain(newEmailDomain);
                    }}
                  >
                    <Add />
                  </Button>
                }
                value={newEmailDomain}
                onChange={(event) => setNewEmailDomain(event.target.value)}
              />
            </ListItemContent>
          </ListItem>
        </List>

        <Typography level="h3">Default Role</Typography>
        <Typography level="body-sm">
          Users logging in with emails from the following email domains above
          will by default have the{" "}
          <Typography color="primary" variant="outlined">
            {customer?.defaultRole}
          </Typography>{" "}
          role. (This is not editable yet)
        </Typography>

        <Typography level="h3">Feature Flags</Typography>
        <Typography level="body-sm">
          Turn on/off features. Features turned on for Atomic will be visible /
          accessible if logged in as an atomic user, but not if logged with a
          user email.
        </Typography>
        {Object.keys(newFeatures).map((feature) => (
          <FormControl key={feature}>
            <FormLabel>{feature}</FormLabel>
            <SelectRole
              feature={feature}
              value={newFeatures[feature as keyof FeatureList]}
              setNewFeatures={setNewFeatures}
            />
          </FormControl>
        ))}

        <FormControl
          sx={{
            mt: 2,
          }}
        >
          <Button onClick={() => setFeatures(newFeatures)} disabled={loading}>
            Store new Feature Flags
          </Button>
        </FormControl>

        <Typography level="h3">PowerBI</Typography>

        {newPowerBis.map((pbi) => (
          <Card
            variant="outlined"
            sx={{
              maxHeight: "max-content",
            }}
          >
            <Typography level="title-md">
              Edit PowerBi settings block
            </Typography>
            <Divider inset="none" />
            <CardContent
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
                gap: 1.5,
              }}
            >
              <FormControl sx={{ gridColumn: "1/-1" }}>
                <FormLabel>ID</FormLabel>
                <Input value={pbi.id} disabled />
              </FormControl>
              <FormControl sx={{ gridColumn: "1/-1" }}>
                <FormLabel>Name</FormLabel>
                <Input
                  value={pbi.name}
                  placeholder="My Reports"
                  onChange={(event) =>
                    setNewPowerBis((pbis) => {
                      const newPbis = [...pbis].map((p) => {
                        if (p.id !== pbi.id) {
                          return p;
                        }
                        return {
                          ...pbi,
                          name: event.target.value,
                        };
                      });
                      return newPbis;
                    })
                  }
                />
              </FormControl>
              <FormControl sx={{ gridColumn: "1/-1" }}>
                <FormLabel>Workspace ID</FormLabel>
                <Input
                  value={pbi.workspaceId}
                  onChange={(event) =>
                    setNewPowerBis((pbis) => {
                      const newPbis = [...pbis].map((p) => {
                        if (p.id !== pbi.id) {
                          return p;
                        }
                        return {
                          ...pbi,
                          workspaceId: event.target.value,
                        };
                      });
                      return newPbis;
                    })
                  }
                />
              </FormControl>
              <FormControl sx={{ gridColumn: "1/-1" }}>
                <FormLabel>Dataset ID</FormLabel>
                <Input
                  value={pbi.datasetId}
                  onChange={(event) =>
                    setNewPowerBis((pbis) => {
                      const newPbis = [...pbis].map((p) => {
                        if (p.id !== pbi.id) {
                          return p;
                        }
                        return {
                          ...pbi,
                          datasetId: event.target.value,
                        };
                      });
                      return newPbis;
                    })
                  }
                />
              </FormControl>
              <FormControl sx={{ gridColumn: "1/-1" }}>
                <Checkbox
                  label="Enabled"
                  sx={{ gridColumn: "1/-1", my: 1 }}
                  checked={pbi.enabled}
                  onChange={(event) =>
                    setNewPowerBis((pbis) => {
                      const newPbis = [...pbis].map((p) => {
                        if (p.id !== pbi.id) {
                          return p;
                        }
                        return {
                          ...pbi,
                          enabled: !pbi.enabled,
                        };
                      });
                      return newPbis;
                    })
                  }
                />
              </FormControl>
              <CardActions sx={{ gridColumn: "1/-1" }}>
                <Button
                  variant="solid"
                  color="primary"
                  onClick={() =>
                    replacePowerbi(
                      pbi.id,
                      pbi.name,
                      pbi.workspaceId,
                      pbi.datasetId,
                      pbi.enabled
                    )
                  }
                >
                  Update PowerBI Settings
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        ))}

        <Card
          variant="outlined"
          sx={{
            maxHeight: "max-content",
          }}
        >
          <Typography level="title-md">
            Add new PowerBi settings block
          </Typography>
          <Divider inset="none" />
          <CardContent
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(80px, 1fr))",
              gap: 1.5,
            }}
          >
            <FormControl sx={{ gridColumn: "1/-1" }}>
              <FormLabel>Name</FormLabel>
              <Input
                value={newPowerBi.name}
                placeholder="My Reports"
                onChange={(event) =>
                  setNewPowerBi((pbi) => ({
                    ...pbi,
                    name: event.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl sx={{ gridColumn: "1/-1" }}>
              <FormLabel>Workspace ID</FormLabel>
              <Input
                value={newPowerBi.workspaceId}
                onChange={(event) =>
                  setNewPowerBi((pbi) => ({
                    ...pbi,
                    workspaceId: event.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl sx={{ gridColumn: "1/-1" }}>
              <FormLabel>Dataset ID</FormLabel>
              <Input
                value={newPowerBi.datasetId}
                onChange={(event) =>
                  setNewPowerBi((pbi) => ({
                    ...pbi,
                    datasetId: event.target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl sx={{ gridColumn: "1/-1" }}>
              <Checkbox
                label="Enabled"
                sx={{ gridColumn: "1/-1", my: 1 }}
                checked={newPowerBi.enabled}
                onChange={(event) =>
                  setNewPowerBi((pbi) => ({
                    ...pbi,
                    enabled: !newPowerBi.enabled,
                  }))
                }
              />
            </FormControl>
            <CardActions sx={{ gridColumn: "1/-1" }}>
              <Button
                variant="solid"
                color="primary"
                onClick={() => {
                  createPowerbi(
                    newPowerBi.name,
                    newPowerBi.workspaceId,
                    newPowerBi.datasetId,
                    newPowerBi.enabled
                  );
                  setNewPowerBi({
                    id: "",
                    name: "",
                    workspaceId: "",
                    datasetId: "",
                    enabled: true,
                  });
                }}
              >
                Add New PowerBI Settings
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Box>
    </RightPaneFrame>
  );
};
