import { ReactNode } from "react";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";

export const InventoryOnHandComponent = (props: {
  loading: boolean;
  totalCogs: ReactNode;
  totalBeanCogs: ReactNode;
  stockOutRate: ReactNode;
  hasInventoryOnHandInStockRate: boolean;
  hasInventoryOnHandBeans: boolean;
}) => {
  const {
    loading,
    totalCogs,
    totalBeanCogs,
    stockOutRate,
    hasInventoryOnHandInStockRate,
    hasInventoryOnHandBeans,
  } = props;
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
      }}
    >
      <PageTitle sx={{ m: 4 }}>Inventory KPIs</PageTitle>

      {loading && (
        <Box sx={{ pt: 2, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 0,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <Tabs sx={{ backgroundColor: "atomic.highlightBackground" }}>
          <TabList>
            <Tab variant="plain" color="neutral">
              Inventory on Hand
            </Tab>
            {hasInventoryOnHandBeans && (
              <Tab variant="plain" color="neutral">
                Beans Inventory on Hand
              </Tab>
            )}
            {hasInventoryOnHandInStockRate && (
              <Tab variant="plain" color="neutral">
                In-stock Rate
              </Tab>
            )}
          </TabList>
          <TabPanel
            value={0}
            sx={{
              bgcolor: "atomic.highlightBackground",
            }}
          >
            {totalCogs}
          </TabPanel>
          {hasInventoryOnHandBeans && (
            <TabPanel
              value={1}
              sx={{
                bgcolor: "atomic.highlightBackground",
              }}
            >
              {totalBeanCogs}
            </TabPanel>
          )}
          {hasInventoryOnHandInStockRate && (
            <TabPanel
              value={hasInventoryOnHandBeans ? 2 : 1}
              sx={{
                bgcolor: "atomic.highlightBackground",
              }}
            >
              {stockOutRate}
            </TabPanel>
          )}
        </Tabs>
      </Sheet>
    </PageFrame>
  );
};
