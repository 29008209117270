import { createContext } from "react";
import { PoEditType } from "./type";

const defaultValue: PoEditType = {
  loading: false,
  purchaseOrderId: null,
  hasPoInbox: false,
  gridApiRef: undefined,
  invoiceNotes: "",
  setInvoiceNotes: () => {},
  shipByDate: null,
  setShipByDate: () => {},
  needByDate: null,
  setNeedByDate: () => {},
  savePuchaseOrder: async () => {},
};
export const PoEditContext = createContext(defaultValue);
