import ImageIcon from "@mui/icons-material/Image";
import Grid from "@mui/joy/Grid";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { JoyCompatibleDatePicker } from "../../../components/date_picker";
import {
  Location,
  PurchaseOrder,
  PurchaseOrderEdit,
  Vendor,
} from "../../../services/api/__generated__/backend_gateway-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Stack from "@mui/joy/Stack";
import Textarea from "@mui/joy/Textarea";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import LinearProgress from "@mui/joy/LinearProgress";
import { useContext, useEffect } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import { PoEditContext } from "../po_edit_context/context";

dayjs.extend(utc);

export const PoDetailsControlPanelComponent = (props: {
  loading: boolean;
  po: PurchaseOrder | null;
  latestPe: PurchaseOrderEdit | null;
  location: Location | null;
  vendor: Vendor | null;
  logoImgB64: string | null;
  logoText: string | null;
  navigateToEditLocation: () => void;
  navigateToEditVendor: () => void;
  navigateToEditLogo: () => void;
  navigateToMessages: () => void;
}) => {
  const {
    loading,
    po,
    latestPe,
    location,
    vendor,
    logoImgB64,
    logoText,
    navigateToEditLocation,
    navigateToEditVendor,
    navigateToEditLogo,
  } = props;
  // TODO: from code structuring it should live in the context instead:
  const {
    invoiceNotes,
    setInvoiceNotes,
    shipByDate,
    setShipByDate,
    needByDate,
    setNeedByDate,
  } = useContext(PoEditContext);
  useEffect(() => {
    if (latestPe) {
      setInvoiceNotes(latestPe.notes || "");
      setShipByDate(latestPe.ship_by_date || new Date().toISOString());
      setNeedByDate(latestPe.need_by_date || new Date().toISOString());
    }
  }, [latestPe, setInvoiceNotes, setShipByDate, setNeedByDate]);
  if (!po || !latestPe) {
    return null;
  }
  return (
    <>
      <MaterialEmbed>
        <Grid container spacing={2} sx={{ flexGrow: 1, mt: 2 }}>
          {loading && (
            <Grid xs={12}>
              <LinearProgress />
            </Grid>
          )}

          <Grid xs={12} sm={3} md={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignContent="stretch"
              flexWrap="wrap"
              //   spacing={1}
            >
              <Typography level="body-xs">Vendor:</Typography>
              <Typography level="h4">{po.vendor}</Typography>

              {(vendor?.address_override || vendor?.address)
                ?.split("\n")
                .map((a, i) => (
                  <Typography key={i} level="body-sm">
                    {a}
                  </Typography>
                )) || <Typography level="body-sm">Address missing</Typography>}
              <Typography level="body-sm">{vendor?.email || ""}</Typography>
              <Link level="body-xs" onClick={() => navigateToEditVendor()}>
                Edit
              </Link>
            </Stack>
          </Grid>
          <Grid xs={12} md={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignContent="stretch"
              flexWrap="wrap"
              spacing={0}
            >
              <Typography level="body-xs">Ship to:</Typography>
              <Typography level="h4">{po.location}</Typography>
              {(location?.address_override || location?.address)
                ?.split("\n")
                .map((a, i) => (
                  <Typography key={i} level="body-sm">
                    {a}
                  </Typography>
                )) || <Typography level="body-sm">Address missing</Typography>}
              <Typography level="body-sm">{location?.email || ""}</Typography>
              <Link level="body-xs" onClick={() => navigateToEditLocation()}>
                Edit
              </Link>
            </Stack>
          </Grid>
          <Grid xs={12} md={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignContent="stretch"
              flexWrap="wrap"
              spacing={2}
            >
              <FormControl>
                <FormLabel>Ship By Date</FormLabel>
                <JoyCompatibleDatePicker
                  value={dayjs.utc(shipByDate)}
                  disablePast={false}
                  minDate={dayjs.utc(po.created_at)}
                  maxDate={dayjs("2025-12-31")}
                  onChange={(newNalue) =>
                    setShipByDate(
                      (newNalue?.toDate() || new Date()).toISOString()
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Need By Date</FormLabel>
                <JoyCompatibleDatePicker
                  value={dayjs.utc(needByDate)}
                  disablePast={false}
                  minDate={dayjs.utc(po.created_at)}
                  maxDate={dayjs("2025-12-31")}
                  onChange={(newNalue) =>
                    setNeedByDate(
                      (newNalue?.toDate() || new Date()).toISOString()
                    )
                  }
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid xs={12} md={9}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignContent="stretch"
              flexWrap="wrap"
              spacing={2}
            >
              <Typography level="body-xs">Notes for the vendor:</Typography>
              <FormControl>
                <Textarea
                  minRows={6}
                  value={invoiceNotes}
                  onChange={(event) => {
                    setInvoiceNotes(event.target.value);
                  }}
                />
              </FormControl>
            </Stack>
          </Grid>
          <Grid xs={12} md={3}>
            <Typography level="body-xs">Logo on the invoice:</Typography>
            <AspectRatio
              objectFit="contain"
              minHeight={100}
              maxHeight={100}
              ratio="1"
              flex={true}
              sx={{ pt: 2 }}
              onClick={() => navigateToEditLogo()}
            >
              {logoImgB64 && (
                <img
                  src={`data:image/png;base64,${logoImgB64}`}
                  alt={logoText || ""}
                />
              )}
              {!logoImgB64 && (
                <div>
                  <ImageIcon sx={{ fontSize: "3rem", opacity: 0.2 }} />
                </div>
              )}
            </AspectRatio>
            <Link level="body-xs" onClick={() => navigateToEditLogo()}>
              Edit
            </Link>
          </Grid>
        </Grid>
      </MaterialEmbed>
    </>
  );
};
