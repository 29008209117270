import { Role } from "../../../services/api/__generated__/backend_gateway-types";

export type FeatureList = {
  EngineRuns: Role;
  EngineLegacyDatabase: Role;

  StepsUsedForEngineRuns: Role;
  StepsExperimentalEngineWorkflow: Role;
  StepsRunDetails: Role;
  StepsStepDetails: Role;
  StepsVariableSets: Role;

  EngineRunHistory: Role;
  EnginePresets: Role;
  EngineLoadLockedDemandForecast: Role;

  VarOrderToDeliveryDaysOffset: Role;
  VarWeightingMethod: Role;
  VarOrderSimulationEndDate: Role;
  VarHistoricalDatePatterns: Role;
  VarWeightingWindowDays: Role;
  VarBindToSKUOverrides: Role;
  VarBindToForecastUpload: Role;
  VarModifyExistingPos: Role;
  VarDyeYieldLoss: Role;
  VarSewYieldLoss: Role;
  VarCutYieldLoss: Role;
  VarWeeksOfSupply: Role;
  VarUseBufferForecast: Role;
  VarDesiredMinWeeksOnHand: Role;
  VarLeadTimeInWeeks: Role;
  VarAssumedCustomerGrowth: Role;
  VarExcludeForecast: Role;
  VarExcludeYieldPlan: Role;
  VarTruckOrderToPackDays: Role;
  VarOnHoldToAvailableDays: Role;
  VarProductionStartToEndDays: Role;
  VarDarkRoastRuntime: Role;
  VarMediumRoastRuntime: Role;
  VarLightRoastRuntime: Role;
  VarDefaultChangeoverTime: Role;
  VarDecafChangeoverTime: Role;
  VarTurnOnProductionBatching: Role;
  VarOrganicMillingNextProductionRun: Role;
  VarCnpNextProductionRun: Role;
  VarTaraNextProductionRun: Role;
  VarAauroraNextProductionRun: Role;
  VarFirmWindowLookAhead: Role;

  RunComparison: Role;
  ForecastAccuracy: Role;
  SkuHierarchyProductCategory: Role;
  SkuHierarchyProductType: Role;
  SkuHierarchyProductFamily: Role;
  SkuHierarchyProductTitle: Role;

  InventoryOnHand: Role;
  InventoryOnHandInStockTreshold: Role;
  InventoryOnHandForecast: Role;
  InventoryOnHandInStockRate: Role;
  InventoryOnHandBeans: Role;

  ValidationDashboard: Role;

  DarkMode: Role;

  PoInbox: Role;
};

export const featuresFromObject = (obj: any): FeatureList => {
  const features: FeatureList = {
    EngineRuns: Role.None,
    EngineLegacyDatabase: Role.None,

    StepsUsedForEngineRuns: Role.None,
    StepsExperimentalEngineWorkflow: Role.None,
    StepsRunDetails: Role.None,
    StepsStepDetails: Role.None,
    StepsVariableSets: Role.None,

    EngineRunHistory: Role.None,
    EnginePresets: Role.None,
    EngineLoadLockedDemandForecast: Role.None,

    VarOrderToDeliveryDaysOffset: Role.None,
    VarWeightingMethod: Role.None,
    VarOrderSimulationEndDate: Role.None,
    VarHistoricalDatePatterns: Role.None,
    VarWeightingWindowDays: Role.None,
    VarBindToSKUOverrides: Role.None,
    VarBindToForecastUpload: Role.None,
    VarModifyExistingPos: Role.None,
    VarDyeYieldLoss: Role.None,
    VarSewYieldLoss: Role.None,
    VarCutYieldLoss: Role.None,
    VarWeeksOfSupply: Role.None,
    VarUseBufferForecast: Role.None,
    VarDesiredMinWeeksOnHand: Role.None,
    VarLeadTimeInWeeks: Role.None,
    VarAssumedCustomerGrowth: Role.None,
    VarExcludeForecast: Role.None,
    VarExcludeYieldPlan: Role.None,
    VarTruckOrderToPackDays: Role.None,
    VarOnHoldToAvailableDays: Role.None,
    VarProductionStartToEndDays: Role.None,
    VarDarkRoastRuntime: Role.None,
    VarMediumRoastRuntime: Role.None,
    VarLightRoastRuntime: Role.None,
    VarDefaultChangeoverTime: Role.None,
    VarDecafChangeoverTime: Role.None,
    VarTurnOnProductionBatching: Role.None,
    VarOrganicMillingNextProductionRun: Role.None,
    VarCnpNextProductionRun: Role.None,
    VarTaraNextProductionRun: Role.None,
    VarAauroraNextProductionRun: Role.None,
    VarFirmWindowLookAhead: Role.None,

    RunComparison: Role.None,
    ForecastAccuracy: Role.None,
    SkuHierarchyProductCategory: Role.None,
    SkuHierarchyProductType: Role.None,
    SkuHierarchyProductFamily: Role.None,
    SkuHierarchyProductTitle: Role.None,

    InventoryOnHand: Role.None,
    InventoryOnHandInStockTreshold: Role.None,
    InventoryOnHandForecast: Role.None,
    InventoryOnHandInStockRate: Role.None,
    InventoryOnHandBeans: Role.None,

    ValidationDashboard: Role.None,

    DarkMode: Role.None,

    PoInbox: Role.None,
  };
  Object.keys(features).forEach((feature) => {
    features[feature as keyof FeatureList] =
      obj[feature as keyof FeatureList] === undefined
        ? Role.None
        : obj[feature];
  });
  return features;
};
