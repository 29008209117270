import { useSearchParams } from "react-router-dom";
import { Page } from "../../frameworks/joy/layouts/page";
import { LoginCallbackContainer } from "./container";

export const LoginCallbackPage = () => {
  let [searchParams] = useSearchParams();
  //   {https://yourApp/callback}?code=AUTHORIZATION_CODE&state=STATE
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  return (
    <Page>
      <LoginCallbackContainer code={code} state={state} />
    </Page>
  );
};
