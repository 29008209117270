import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import LinearProgress from "@mui/joy/LinearProgress";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";
import { RIGHT_PLANE_WIDTH } from "../../../frameworks/joy/layouts/drawer_layout/constants";
import { useEffect, useState } from "react";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";

export const PoDetailsEditLogoComponent = (props: {
  loading: boolean;
  navigateBack: () => void;
  logoImgB64: string | null;
  logoText: string | null;
  setLogoAndText: (imgB64: string, text: string) => Promise<void>;
}) => {
  const { loading, navigateBack, logoImgB64, logoText, setLogoAndText } = props;
  const [text, setText] = useState<string>("");
  useEffect(() => {
    if (logoText !== null) {
      setText(logoText);
    }
  }, [logoText]);
  const [imgB64, setImgB64] = useState<string | null>(null);
  useEffect(() => {
    if (logoImgB64 !== null) {
      setImgB64(logoImgB64);
    }
  }, [logoImgB64]);
  //   const [selectedFile, setSelectedFile] = useState<File | null>(null);

  return (
    <RightPaneFrame>
      <RightPaneBackButton labelText="Close" onClick={navigateBack} />
      <RightPaneTitle>Edit Logo</RightPaneTitle>

      {loading && <LinearProgress />}

      <Box sx={{ width: RIGHT_PLANE_WIDTH, borderRadius: "sm", p: 1 }}>
        <AspectRatio objectFit="contain">
          {imgB64 && (
            <img src={`data:image/png;base64,${imgB64}`} alt={text || ""} />
          )}
        </AspectRatio>
      </Box>

      <Input value={text} onChange={(event) => setText(event.target.value)} />

      <Input
        type="file"
        onChange={(e) => {
          if (!e.target.files || e.target.files.length === 0) {
            setImgB64(null);
            return;
          }
          //   setSelectedFile(e.target.files[0]);
          const reader = new FileReader();

          reader.readAsDataURL(e.target.files[0]);

          reader.onload = (event) => {
            if (event.target && event.target.result) {
              const dataUrl = event.target.result!;
              const base64 = (dataUrl as string).split(",")[1];
              setImgB64(base64);
            } else {
              setImgB64(null);
            }
          };
        }}
      />
      <Button
        disabled={imgB64 === null}
        onClick={() => {
          if (imgB64) {
            setLogoAndText(imgB64, text);
          }
        }}
      >
        Store logo
      </Button>
    </RightPaneFrame>
  );
};
