export enum SysMessageLevel {
  NEUTRAL = "NEUTRAL",
  EMPHASIZED = "EMPHASIZED",
  WARNING = "WARNING",
  DANGER = "DANGER",
  SUCCESS = "SUCCESS",
}

export type SysMessageWithLevel = {
  level: SysMessageLevel;
  message: string;
};

export type SysMessagesType = {
  systemMessages: SysMessageWithLevel[];
  addSystemMessage: (m: SysMessageWithLevel) => void;
  removeSystemMessage: (i: number) => void;
};
