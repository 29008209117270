import React, { FC, ReactNode } from "react";
import { FixedLeftDrawer } from "./fixed_left_drawer";
import { FloatingHeader } from "./floating_header";
import { FloatingLeftDrawer } from "./floating_left_drawer";
import { Root } from "./root";
import { XsFooter } from "./xs_footer";

interface LayoutComponentProps {
  children: ReactNode;
  drawerOpen: boolean;
  handleDrawerToggle: any;
  drawerWidth: number;
  drawerContent: (props: { drawerWidth: number }) => JSX.Element;
  headerContent: FC;
  footerContent: FC;
}

export const LayoutComponent: FC<LayoutComponentProps> = (props) => {
  const {
    children,
    drawerOpen,
    handleDrawerToggle,
    drawerWidth,
    drawerContent: DrawerContent,
    headerContent: HeaderContent,
    footerContent: FooterContent,
  } = props;

  return (
    <React.Fragment>
      <FloatingHeader drawerWidth={drawerWidth}>
        <HeaderContent />
      </FloatingHeader>

      <Root drawerWidth={drawerWidth} drawerOpen={drawerOpen}>
        <FixedLeftDrawer>
          <DrawerContent drawerWidth={drawerWidth} />
        </FixedLeftDrawer>

        {children}

        <XsFooter>
          <FooterContent />
        </XsFooter>
      </Root>

      <FloatingLeftDrawer
        drawerOpen={drawerOpen}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      >
        <DrawerContent drawerWidth={drawerWidth} />
      </FloatingLeftDrawer>
    </React.Fragment>
  );
};
