import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";

import Assignment from "@mui/icons-material/Assignment";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Checklist from "@mui/icons-material/Checklist";
import Logout from "@mui/icons-material/Logout";
import QueryStats from "@mui/icons-material/QueryStats";
import Warning from "@mui/icons-material/Warning";

import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import { User } from "../../services/api/__generated__/backend_gateway-types";

export const WelcomeComponent = (props: {
  loading: boolean;
  user: User | undefined;
  customerId: string | null;
  signOut: () => void;
  navigateToReports: () => void;
  navigateToRunHistory: () => void;
  navigateToEnginePresets: () => void;
  runningInProgress: boolean;
  runDisabled: boolean;
  latestStartAt: string | null;
  workflowTrigger: () => void;
  hasPlanningDemo: boolean;
  navigateToPlanOfRecord: () => void;
  navigateToValidationDashboard: () => void;
  navigateToPlanning: () => void;
  navigateToOperations: () => void;
}) => {
  const {
    loading,
    customerId,
    signOut,
    // navigateToReports,
    // navigateToRunHistory,
    // navigateToEnginePresets,
    // runningInProgress,
    // runDisabled,
    // workflowTrigger,
    hasPlanningDemo,
    navigateToPlanOfRecord,
    navigateToValidationDashboard,
    navigateToPlanning,
    navigateToOperations,
  } = props;

  return (
    <PageFrame>
      <PageTitle>Welcome to Atomic!</PageTitle>

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      {!customerId && !loading && (
        <>
          <Box sx={{ width: "100%", py: 6 }}>
            <Alert
              color="warning"
              variant="outlined"
              startDecorator={<Warning />}
              endDecorator={
                <Button
                  size="sm"
                  variant="solid"
                  color="warning"
                  startDecorator={<Logout />}
                  onClick={() => signOut()}
                >
                  Logout
                </Button>
              }
            >
              We don't recognize your email address.
            </Alert>
          </Box>
          <Typography>
            If you are an Atomic client, then please make sure that you log in
            with your company email address.
          </Typography>
          <Typography>
            If you are not an Atomic client yet, and you're interested in using
            this service then please{" "}
            <a href="mailto:neal@atomic.supply">let us know</a>.
          </Typography>
        </>
      )}

      {customerId && !loading && hasPlanningDemo && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              sm: "minmax(200px, 1fr) minmax(200px, 1fr)",
              md: "minmax(150px, 400px) minmax(150px, 400px) minmax(150px, 400px) minmax(150px, 400px)",
            },
            gridTemplateRows: "90px 90px 90px 90px",
            gap: 2,
            minWidth: "400px",
            maxWidth: "1200px",
            py: 5,
          }}
        >
          <Button
            variant="outlined"
            size="lg"
            startDecorator={<QueryStats />}
            onClick={navigateToPlanOfRecord}
          >
            Plan of Record
          </Button>
          <Button
            variant="outlined"
            size="lg"
            startDecorator={<Checklist />}
            onClick={navigateToValidationDashboard}
          >
            Validations
          </Button>
          <Button
            variant="outlined"
            size="lg"
            startDecorator={<CalendarMonth />}
            onClick={navigateToPlanning}
          >
            Planning
          </Button>
          <Button
            variant="outlined"
            size="lg"
            startDecorator={<Assignment />}
            onClick={navigateToOperations}
          >
            Operations
          </Button>
        </Box>
      )}
    </PageFrame>
  );
};
