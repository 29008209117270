import { AspectRatio, Box, Typography } from "@mui/joy";
import logo from "./logo.svg";

export const LogoText = (props: {}) => {
  return (
    <Box
      sx={{
        minHeight: "56px",
        maxHeight: "56px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AspectRatio
        objectFit="contain"
        variant="plain"
        ratio="210/55"
        sx={{
          width: "80%",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            height: "55px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              width: "55px",
            }}
          >
            <img src={logo} alt="Atomic.supply" />
          </Box>
          <Typography
            sx={{
              fontSize: "42px",
              marginLeft: "10px",
              letterSpacing: "0.05em",
              // color: colors.atomicBluePrimary,
              color: "atomic.atomicBluePrimary",
            }}
          >
            Atomic
          </Typography>
        </Box>
      </AspectRatio>
    </Box>
  );
};
