import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../contexts/api/context";
import {
  useGetMyEnginePresetsQuery,
  useGetMyOrchestratorRunsQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { RunStatusesComponent } from "./component";

export const RunStatusesContainer = () => {
  const { apiService } = useContext(ApiContext);
  const navigate = useNavigate();

  const navigateToRunStatusesWithNewRun = () => {
    navigate("/run_statuses/new_run", { replace: true });
  };

  const navigateToRunStatusesWithRunDetails = (
    inputId: string,
    workflowId: string,
    variablesId: string,
    runAttemptId: number
  ) => {
    navigate(
      `/run_statuses/run_details/${inputId}/${workflowId}/${variablesId}/${runAttemptId}`,
      { replace: true }
    );
  };

  const {
    data: runStatusesData,
    loading: runStatusesLoading,
    error: runStatusesError,
    refetch: runStatusesRefetch,
  } = useGetMyOrchestratorRunsQuery({
    client: apiService.getClient(),
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      runStatusesRefetch();
    }, 8000);
    return () => clearInterval(intervalId);
  }, [runStatusesRefetch]);

  const {
    data: presetsData,
    loading: presetsLoading,
    error: presetsError,
  } = useGetMyEnginePresetsQuery({
    client: apiService.getClient(),
  });

  if (runStatusesError) {
    console.error(
      "RunStatusesContainer: useGetMyOrchestratorRunsQuery fetch error",
      runStatusesError
    );
  }

  if (presetsError) {
    console.error(
      "RunStatusesContainer: useGetMyEnginePresetsQuery fetch error",
      presetsError
    );
  }

  return (
    <RunStatusesComponent
      loading={runStatusesLoading || presetsLoading}
      runs={runStatusesData?.getMyOrchestratorRuns || []}
      navigateToRunStatusesWithNewRun={navigateToRunStatusesWithNewRun}
      navigateToRunStatusesWithRunDetails={navigateToRunStatusesWithRunDetails}
      presets={presetsData?.getMyEnginePresets || []}
    />
  );
};
