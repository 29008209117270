import { useContext, useMemo } from "react";
import { PoDetailsComponent } from "./component";
import { ApiContext } from "../../contexts/api/context";
import { SysMessagesContext } from "../../contexts/sys_messages/context";
import { SysMessageLevel } from "../../contexts/sys_messages/type";
import {
  PurchaseOrderStatus,
  useGenerateMyPoPdfWithDownloadUrlMutation,
  useGetMyLocationsQuery,
  useGetMyLogoQuery,
  useGetMyPoWithLatestEditAndSkusQuery,
  useGetMyVendorsQuery,
} from "../../services/api/__generated__/backend_gateway-types";
import { useLocation, useNavigate } from "react-router-dom";
import { PoDetailsControlPanelContainer } from "./control_panel/container";
import { PoDetailsSupplyWalkContainer } from "./supply_walk/container";
import { PoDetailsSkuListContainer } from "./sku_list/container";
import { PosContext } from "../../contexts/pos/context";

export const PoDetailsContainer = (props: {
  id: string;
  supplyWalkSkus: string[];
}) => {
  const { id, supplyWalkSkus } = props;
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);

  const {
    hasPoInbox,
    pos: poPos,
    savePurchaseOrderSnoozeAndStatus: poSavePurchaseOrderSnoozeAndStatus,
    saving: poSaving,
  } = useContext(PosContext);

  const {
    data: poData,
    loading: poLoading,
    refetch: poRefetch,
  } = useGetMyPoWithLatestEditAndSkusQuery({
    client: apiService.getClient(),
    variables: {
      id,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const po = poData?.getMyPoWithLatestEditAndSkus?.po || null;
  const latestPe = poData?.getMyPoWithLatestEditAndSkus?.pe || null;
  const latestPs = poData?.getMyPoWithLatestEditAndSkus?.ps || [];

  const nextPoId = useMemo(() => {
    const poIndex: number | undefined = poPos.findIndex((p) => p.id === id);
    if (poIndex !== undefined && poIndex < poPos.length - 1) {
      return poPos[poIndex + 1].id;
    }
    return null;
  }, [id, poPos]);

  const setStatus = async (status: PurchaseOrderStatus) => {
    if (!po) {
      return;
    }
    await poSavePurchaseOrderSnoozeAndStatus(
      id,
      po!.snooze_start_date || new Date().toISOString(),
      po!.snooze_end_date || null,
      status
    );
    poRefetch();
  };

  const setSoozeEndDate = async (soozeEndDate: Date | null) => {
    if (!po) {
      return;
    }
    const start_date = soozeEndDate
      ? po!.snooze_start_date || new Date().toISOString()
      : null;
    const end_date = soozeEndDate?.toISOString() || null;
    const status =
      !po || !po.status || po?.status === PurchaseOrderStatus.AtomicSuggestion
        ? PurchaseOrderStatus.CustomerOwned
        : po!.status!;

    await poSavePurchaseOrderSnoozeAndStatus(id, start_date, end_date, status);
    poRefetch();
  };

  const [generatePdfUrl, { loading: generatePdfUrlLoading }] =
    useGenerateMyPoPdfWithDownloadUrlMutation({
      client: apiService.getClient(),
      variables: {
        id,
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
      onCompleted: async (data) => {
        const url = data.generateMyPoPdfWithDownloadUrl;
        if (url) {
          const pom = document.createElement("a");
          pom.href = url;
          pom.setAttribute(
            "download",
            `po-${po?.po_sim_id}-${po?.location}-${po?.vendor}.pdf`
          );
          pom.click();
          pom.remove();
        }
      },
    });

  const { data: locationsData, loading: locationsLoading } =
    useGetMyLocationsQuery({
      client: apiService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addSystemMessage({
            level: SysMessageLevel.DANGER,
            message: error.message!,
          });
        }
      },
    });

  const { data: vendorsData, loading: vendorsLoading } = useGetMyVendorsQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const { data: logoData, loading: logoLoading } = useGetMyLogoQuery({
    client: apiService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const location = useMemo(() => {
    return locationsData?.getMyLocations.find((l) => l.id === po?.location);
  }, [locationsData, po]);

  const vendor = useMemo(() => {
    return vendorsData?.getMyVendors.find((v) => v.id === po?.vendor);
  }, [vendorsData, po]);

  const downloadWorks = !!(
    (location?.address || location?.address_override) &&
    (vendor?.address || vendor?.address_override) &&
    logoData?.getMyLogo?.img_b64 &&
    (po?.status || null) !== null
  );

  const downloadPdf = async () => {
    if (downloadWorks) {
      await generatePdfUrl();
    }
  };

  const navigate = useNavigate();
  const navigateToPoBox = () => {
    navigate(`/pos?include_status=ATOMIC_SUGGESTION,CUSTOMER_OWNED`, {
      replace: true,
    });
  };
  const loc = useLocation();
  const poPathFragments = loc.pathname.split("/");
  const poPath = `/${poPathFragments[1]}/${poPathFragments[2]}`;
  const navigateToSnooze = () => {
    navigate(`${poPath}/snooze${loc.search ? "?" + loc.search : ""}`, {
      replace: true,
    });
  };

  const navigateToNextPo = () => {
    if (nextPoId !== null) {
      navigate(`/po/${nextPoId}`, { replace: true });
    }
  };

  if (!hasPoInbox) {
    return null;
  }
  return (
    <PoDetailsComponent
      id={id}
      loading={
        poLoading ||
        poSaving ||
        generatePdfUrlLoading ||
        locationsLoading ||
        vendorsLoading ||
        logoLoading
      }
      po={po}
      navigateToPoBox={navigateToPoBox}
      controlPanel={
        <PoDetailsControlPanelContainer
          id={id}
          po={po}
          latestPe={latestPe}
          latestPs={latestPs}
        />
      }
      skuList={
        <PoDetailsSkuListContainer
          id={id}
          po={po}
          latestPe={latestPe}
          latestPs={latestPs}
        />
      }
      supplyWalk={
        <PoDetailsSupplyWalkContainer id={id} skus={supplyWalkSkus} />
      }
      setStatus={setStatus}
      setSoozeEndDate={setSoozeEndDate}
      disableStatusEdit={false}
      downloadWorks={downloadWorks}
      downloadPdf={downloadPdf}
      navigateToSnooze={navigateToSnooze}
      navigateToNextPo={navigateToNextPo}
      hasNextPo={nextPoId !== null}
    />
  );
};
