export const stateMap: { [key: string]: string } = {
  DRAFT: "Not run yet",
  TO_RUN: "In progress",
  RUN_IN_PROGRESS: "In progress",
  RUN_COMPLETED: "Completed",
  RUN_FAILED: "Failed",
  TO_CANCEL: "Cencelling in progress",
  CANCEL_IN_PROGRESS: "Cencelling in progress",
  CANCEL_COMPLETED: "Cancelled",
  CANCEL_FAILED: "Failed to cancel",
};
