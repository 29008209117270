import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import LinearProgress from "@mui/joy/LinearProgress";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import { JoyCompatibleDatePicker } from "../../../components/date_picker";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";
import { RunLog } from "../../../services/api/__generated__/backend_gateway-types";
import { DiffParams, Segmentation } from "../diff_params";

dayjs.extend(utc);

export const ForecastAccuracyControlComponent = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
  updateRuns: (params: DiffParams) => void;
  loading: boolean;
  error: string | null;
  runLogs: RunLog[];
  actualsRun: RunLog | null;
  hasProductCategory: boolean;
  hasProductType: boolean;
  hasProductFamily: boolean;
  hasProductTitle: boolean;
}) => {
  const {
    diffParams,
    updateDiffParams,
    updateRuns,
    loading,
    runLogs,
    actualsRun,
    hasProductCategory,
    hasProductType,
    hasProductFamily,
    hasProductTitle,
  } = props;
  const forecastRun = runLogs.find(
    (rl) => `${rl.id}` === diffParams.forecastEngineRunId
  );

  return (
    <MaterialEmbed>
      <AccordionGroup
        size="lg"
        variant="outlined"
        sx={{
          m: 2,
          borderRadius: "sm",
          [`& .${accordionSummaryClasses.button}:hover`]: {
            borderRadius: "sm",
          },
        }}
      >
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={{
              borderBottom: (theme) =>
                `1px solid ${theme.vars.palette.divider}`,
            }}
          >
            Forecast Accuracy - Controls
          </AccordionSummary>
          <AccordionDetails
            sx={{ pt: 2, bgcolor: "atomic.highlightBackground" }}
          >
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel htmlFor="select-run-0" id="select-run-0-label">
                    Select a Run
                  </FormLabel>
                  <Select
                    value={diffParams.forecastEngineRunId}
                    placeholder="Demand forecast"
                    slotProps={{
                      button: {
                        id: "select-run-0",
                        "aria-labelledby": "select-label select-run-0",
                      },
                    }}
                  >
                    {runLogs
                      .filter((rl) => rl.id !== diffParams.actualsEngineRunId)
                      .map((runLog) => (
                        <Option
                          key={runLog.id || -1}
                          value={
                            runLog.id === null ? "INVALID" : `${runLog.id}`
                          }
                          onClick={() => {
                            updateRuns({
                              ...diffParams,
                              forecastEngineRunId:
                                runLog.id === null ? null : `${runLog.id}`,
                            });
                          }}
                          label={
                            <>
                              <Chip variant="solid">{`${runLog.id}`}</Chip>{" "}
                              <Typography sx={{ ml: 1 }}>
                                {runLog.startedAt &&
                                  ` ${
                                    new Date(runLog.startedAt).getUTCMonth() + 1
                                  }/${new Date(
                                    runLog.startedAt
                                  ).getUTCDate()}/${new Date(
                                    runLog.startedAt
                                  ).getUTCFullYear()}`}
                              </Typography>
                              <Typography sx={{ ml: 1, fontWeight: 800 }}>
                                {!runLog.name ? "" : <b>{` ${runLog.name}`}</b>}
                              </Typography>
                              <Typography sx={{ ml: 1 }}>
                                {!runLog.description
                                  ? ""
                                  : `${runLog.description}`}
                              </Typography>
                            </>
                          }
                        >
                          <Chip variant="outlined">{runLog.id}</Chip>{" "}
                          {runLog.startedAt &&
                            ` ${
                              new Date(runLog.startedAt).getUTCMonth() + 1
                            }/${new Date(
                              runLog.startedAt
                            ).getUTCDate()}/${new Date(
                              runLog.startedAt
                            ).getUTCFullYear()}`}
                          {!runLog.name ? "" : <b>{` ${runLog.name}`}</b>}
                          {!runLog.description ? "" : ` ${runLog.description}`}
                        </Option>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel htmlFor="select-run-1" id="select-run-1-label">
                    Comparing to the latest run's actuals:
                  </FormLabel>
                  <Select
                    value={1}
                    disabled
                    placeholder="Demand actuals"
                    slotProps={{
                      button: {
                        id: "select-run-1",
                        "aria-labelledby": "select-label select-run-0",
                      },
                    }}
                  >
                    <Option
                      value={1}
                      label={
                        <>
                          <Chip variant="solid">{`${diffParams.actualsEngineRunId}`}</Chip>{" "}
                          <Typography sx={{ ml: 1 }}>
                            {actualsRun?.startedAt &&
                              ` ${
                                new Date(actualsRun.startedAt).getUTCMonth() + 1
                              }/${new Date(
                                actualsRun.startedAt
                              ).getUTCDate()}/${new Date(
                                actualsRun.startedAt
                              ).getUTCFullYear()}`}
                          </Typography>
                          <Typography sx={{ ml: 1, fontWeight: 800 }}>
                            {!actualsRun?.name ? (
                              ""
                            ) : (
                              <b>{` ${actualsRun?.name}`}</b>
                            )}
                          </Typography>
                          <Typography sx={{ ml: 1 }}>
                            {!actualsRun?.description
                              ? ""
                              : `${actualsRun?.description}`}
                          </Typography>
                        </>
                      }
                    >
                      -
                    </Option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Set the Comparison Start Date</FormLabel>
                  <JoyCompatibleDatePicker
                    disabled={
                      diffParams.actualsEngineRunId === null ||
                      diffParams.forecastEngineRunId === null
                    }
                    value={dayjs.utc(diffParams.minDate)}
                    disablePast={false}
                    minDate={dayjs(forecastRun?.startedAt)}
                    maxDate={dayjs(actualsRun?.startedAt)}
                    onChange={(newNalue) =>
                      updateDiffParams({
                        ...diffParams,
                        minDate: newNalue?.toDate() || new Date(),
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Set the Comparison End Date</FormLabel>
                  <JoyCompatibleDatePicker
                    disabled={
                      diffParams.actualsEngineRunId === null ||
                      diffParams.forecastEngineRunId === null
                    }
                    value={dayjs.utc(diffParams.maxDate)}
                    disablePast={false}
                    minDate={dayjs(forecastRun?.startedAt)}
                    maxDate={dayjs(actualsRun?.startedAt)}
                    onChange={(newNalue) =>
                      updateDiffParams({
                        ...diffParams,
                        maxDate: newNalue?.toDate() || new Date(),
                      })
                    }
                  />
                </FormControl>
              </Grid>

              {/* <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel htmlFor="select-filter" id="select-filter-label">
                    Sales Measure
                  </FormLabel>
                  <Select
                    value={`${diffParams.salesMeasure}`}
                    placeholder="Select Quantity or Revenue"
                    slotProps={{
                      button: {
                        id: "select-filter",
                        "aria-labelledby": "select-label select-filter",
                      },
                    }}
                  >
                    <Option
                      value={`${SalesMeasure.QUANTITY}`}
                      onClick={() => {
                        updateDiffParams({
                          ...diffParams,
                          salesMeasure: SalesMeasure.QUANTITY,
                        });
                      }}
                    >
                      Sales Orders (Quantity)
                    </Option>
                    <Option
                      value={`${SalesMeasure.REVENUE}`}
                      onClick={() => {
                        updateDiffParams({
                          ...diffParams,
                          salesMeasure: SalesMeasure.REVENUE,
                        });
                      }}
                    >
                      Revenue
                    </Option>
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    htmlFor="select-segmentation"
                    id="select-segmentation-label"
                  >
                    Segmentation
                    <Tooltip
                      placement="right"
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: 320,
                            justifyContent: "center",
                            p: 1,
                          }}
                        >
                          Segmentation by channel yields different results as
                          forecast is assessed by (sku + channel), for all other
                          segmentations it is assessed by just sku.
                        </Box>
                      }
                      variant="soft"
                      arrow
                    >
                      <Typography sx={{ ml: 1, textDecoration: "underline" }}>
                        ?
                      </Typography>
                    </Tooltip>
                  </FormLabel>
                  <Select
                    value={`${diffParams.segmentation}`}
                    placeholder="Select Aggregation"
                    slotProps={{
                      button: {
                        id: "select-segmentation",
                        "aria-labelledby": "select-label select-segmentation",
                      },
                    }}
                  >
                    <Option
                      value={`${Segmentation.CHANNEL}`}
                      onClick={() => {
                        updateDiffParams({
                          ...diffParams,
                          segmentation: Segmentation.CHANNEL,
                        });
                      }}
                    >
                      By Channel
                    </Option>
                    <Option
                      value={`${Segmentation.LOCATION}`}
                      onClick={() => {
                        updateDiffParams({
                          ...diffParams,
                          segmentation: Segmentation.LOCATION,
                        });
                      }}
                    >
                      By Location
                    </Option>
                    {hasProductType && (
                      <Option
                        value={`${Segmentation.PRODUCT_TYPE}`}
                        onClick={() => {
                          updateDiffParams({
                            ...diffParams,
                            segmentation: Segmentation.PRODUCT_TYPE,
                          });
                        }}
                      >
                        By Product Type
                      </Option>
                    )}
                    {hasProductFamily && (
                      <Option
                        value={`${Segmentation.PRODUCT_FAMILY}`}
                        onClick={() => {
                          updateDiffParams({
                            ...diffParams,
                            segmentation: Segmentation.PRODUCT_FAMILY,
                          });
                        }}
                      >
                        By Product Family
                      </Option>
                    )}
                    {hasProductTitle && (
                      <Option
                        value={`${Segmentation.PRODUCT_TITLE}`}
                        onClick={() => {
                          updateDiffParams({
                            ...diffParams,
                            segmentation: Segmentation.PRODUCT_TITLE,
                          });
                        }}
                      >
                        By Product Title
                      </Option>
                    )}
                    {hasProductCategory && (
                      <Option
                        value={`${Segmentation.PRODUCT_CATEGORY}`}
                        onClick={() => {
                          updateDiffParams({
                            ...diffParams,
                            segmentation: Segmentation.PRODUCT_CATEGORY,
                          });
                        }}
                      >
                        By Product Category
                      </Option>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </MaterialEmbed>
  );
};
