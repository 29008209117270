import { ReactNode } from "react";
import { PageFrame } from "../../components/page_frame";

export const RunComparisonComponent = (props: {
  control?: ReactNode;
  table?: ReactNode;
  loading: boolean;
}) => {
  const { control, table } = props;
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
      }}
    >
      {control}
      {table}
    </PageFrame>
  );
};
