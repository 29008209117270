import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import LinearProgress from "@mui/joy/LinearProgress";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import { DiffParams } from "../diff_params";
import { MaterialEmbed } from "../../../../frameworks/joy/material_embed";
import { JoyCompatibleDatePicker } from "../../../../components/date_picker";

dayjs.extend(utc);

export const PlanningForecastAccuracyControlComponent = (props: {
  diffParams: DiffParams;
  updateDiffParams: (params: DiffParams) => void;
  updateRuns: (params: DiffParams) => void;
  loading: boolean;
  error: string | null;
}) => {
  const { diffParams, updateDiffParams, loading } = props;

  return (
    <MaterialEmbed>
      <AccordionGroup
        size="lg"
        variant="outlined"
        sx={{
          m: 2,
          borderRadius: "sm",
          [`& .${accordionSummaryClasses.button}:hover`]: {
            borderRadius: "sm",
          },
        }}
      >
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={{
              borderBottom: (theme) =>
                `1px solid ${theme.vars.palette.divider}`,
            }}
          >
            Forecast Accuracy - Controls
          </AccordionSummary>
          <AccordionDetails sx={{ pt: 2, bgcolor: "white" }}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel htmlFor="select-run-0" id="select-run-0-label">
                    Select a Run
                  </FormLabel>
                  <Select
                    value={1}
                    placeholder="Demand forecast"
                    slotProps={{
                      button: {
                        id: "select-run-0",
                        "aria-labelledby": "select-label select-run-0",
                      },
                    }}
                    disabled
                  >
                    <Option
                      value={1}
                      label={
                        <>
                          <Chip variant="solid">1</Chip>{" "}
                          <Typography sx={{ ml: 1 }}>2024-04-01</Typography>
                          <Typography sx={{ ml: 1, fontWeight: 800 }}>
                            <b>POR</b>
                          </Typography>
                          <Typography sx={{ ml: 1 }}></Typography>
                        </>
                      }
                    >
                      <Chip variant="outlined">1</Chip> 2024-04-01
                      <b>POR</b>
                    </Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}></Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Set the Comparison Start Date</FormLabel>
                  <JoyCompatibleDatePicker
                    disabled
                    value={dayjs.utc(diffParams.minDate)}
                    disablePast={false}
                    minDate={dayjs()}
                    maxDate={dayjs()}
                    onChange={(newNalue) =>
                      updateDiffParams({
                        ...diffParams,
                        minDate: newNalue?.toDate() || new Date(),
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <FormControl>
                  <FormLabel>Set the Comparison End Date</FormLabel>
                  <JoyCompatibleDatePicker
                    disabled
                    value={dayjs.utc(diffParams.maxDate)}
                    disablePast={false}
                    minDate={dayjs()}
                    maxDate={dayjs()}
                    onChange={(newNalue) =>
                      updateDiffParams({
                        ...diffParams,
                        maxDate: newNalue?.toDate() || new Date(),
                      })
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </MaterialEmbed>
  );
};
