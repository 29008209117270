import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const LoginCallbackContainer = (props: {
  code: string | null;
  state: string | null;
}) => {
  const { code, state } = props;
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/", { replace: true });
  }, [navigate]);
  console.info("authentication state", state);
  console.info("authentication code", code);
  return <></>;
};
