import Grid from "@mui/joy/Grid";
import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { MaterialEmbed } from "../../../frameworks/joy/material_embed";
import { TotalCogs } from "./total_cogs";
import FormControl from "@mui/joy/FormControl";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { ChartsTooltip } from "@mui/x-charts/ChartsTooltip";
import { ChartsGrid } from "@mui/x-charts/ChartsGrid";

export const InventoryOnHandTotalCogsComponent = (props: {
  totalCogs: TotalCogs[];
  loading: boolean;
  downloadSummaryCsvBlob: () => void;
  downloadDetailedCsvBlob: () => void;
}) => {
  const {
    totalCogs,
    loading,
    downloadSummaryCsvBlob,
    downloadDetailedCsvBlob,
  } = props;
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format;

  console.log("totalCogs.length", totalCogs.length);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        flexGrow: 1,
        // maxWidth: Math.min(Math.max(800, (totalCogs.length + 1) * 300)),
      }}
    >
      <Grid xl={12} lg={12} sx={{ width: '100%' }}>
        {totalCogs.length > 0 && (
          <MaterialEmbed>
            <ResponsiveChartContainer
              xAxis={[
                {
                  data: totalCogs.map((tc) => tc.date),
                  scaleType: "time",
                  valueFormatter: (date) =>
                    new Date(
                      date.getTime() + date.getTimezoneOffset() * 60000
                    ).toLocaleDateString(),
                  id: "x-axis-id",
                },
              ]}
              yAxis={[
                {
                  scaleType: "linear",
                  label: "Inventory on Hand ($)",
                  id: "y-axis-id",
                },
              ]}
              series={[
                {
                  type: "line",
                  data: totalCogs.map((tc) => tc.totalCogs),
                  showMark: true,
                  valueFormatter: (v) =>
                    v === null ? "" : currencyFormatter(v),
                },
              ]}
              height={300}
              margin={{ left: 100 }}
              sx={{
                [`& .${axisClasses.left} .${axisClasses.label}`]: {
                  transform: "translateX(-50px)",
                },
              }}
            >
              <ChartsGrid vertical horizontal />
              <LinePlot />
              <ChartsXAxis axisId="x-axis-id" />
              <ChartsYAxis axisId="y-axis-id" />
              <MarkPlot />
              <ChartsTooltip />
            </ResponsiveChartContainer>
          </MaterialEmbed>
        )}
      </Grid>
      <Grid xl={12} lg={12}>
        {totalCogs.length > 0 && (
          <FormControl
            sx={{
              m: 2,
              pt: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              <Button
                disabled={loading}
                color="primary"
                variant="solid"
                size="lg"
                onClick={downloadSummaryCsvBlob}
              >
                Export: Summary Data
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="solid"
                size="lg"
                onClick={downloadDetailedCsvBlob}
              >
                Export: Detailed Data
              </Button>
            </Box>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};
