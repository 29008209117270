import { useContext } from "react";
import {
  PurchaseOrderStatus,
  useGetMyPoWithLatestEditAndSkusQuery,
} from "../../../services/api/__generated__/backend_gateway-types";
import { PoDetailsSetSnoozeComponent } from "./component";
import { ApiContext } from "../../../contexts/api/context";
import { SysMessagesContext } from "../../../contexts/sys_messages/context";
import { SysMessageLevel } from "../../../contexts/sys_messages/type";
import { useLocation, useNavigate } from "react-router-dom";
import { PosContext } from "../../../contexts/pos/context";

export const PoDetailsSetSnoozeContainer = (props: { id: string }) => {
  const { id } = props;
  const { apiService } = useContext(ApiContext);
  const { addSystemMessage } = useContext(SysMessagesContext);
  const {
    hasPoInbox,
    savePurchaseOrderSnoozeAndStatus: poSavePurchaseOrderSnoozeAndStatus,
    saving: poSaving,
  } = useContext(PosContext);

  const {
    data: poData,
    loading: poLoading,
    refetch: poRefetch,
  } = useGetMyPoWithLatestEditAndSkusQuery({
    client: apiService.getClient(),
    variables: {
      id,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addSystemMessage({
          level: SysMessageLevel.DANGER,
          message: error.message!,
        });
      }
    },
  });

  const po = poData?.getMyPoWithLatestEditAndSkus?.po || null;

  const setPoEndDate = async (date: Date | null) => {
    const status =
      !po || !po.status || po?.status === PurchaseOrderStatus.AtomicSuggestion
        ? PurchaseOrderStatus.CustomerOwned
        : po!.status!;
    const start_date = date
      ? po?.snooze_start_date || new Date().toISOString()
      : null;
    const end_date = date?.toISOString() || null;
    await poSavePurchaseOrderSnoozeAndStatus(id, start_date, end_date, status);
    poRefetch();
  };

  const navigate = useNavigate();
  const loc = useLocation();
  const navigateBack = () => {
    const poPathFragments = loc.pathname.split("/");
    if (poPathFragments.length >= 3) {
      const poPath = `/${poPathFragments[1]}/${poPathFragments[2]}`;
      navigate(`${poPath}${loc.search ? "?" + loc.search : ""}`, {
        replace: true,
      });
    }
  };

  if (!hasPoInbox) {
    return null;
  }
  return (
    <PoDetailsSetSnoozeComponent
      loading={poLoading || poSaving}
      navigateBack={navigateBack}
      po={po}
      setPoEndDate={setPoEndDate}
    />
  );
};
