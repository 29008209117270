import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";

export const OperationsComponent = () => {
  const loading = false;
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
      }}
    >
      <PageTitle sx={{ m: 4 }}>Operations</PageTitle>
      {loading && (
        <Box sx={{ pt: 2, width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 2,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
        }}
      >
        <Typography
          fontWeight="md"
          fontSize="md"
          sx={{
            width: "100%",
            pt: 2,
            pb: 1,
          }}
        >
          Export files:
        </Typography>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "minmax(80px, 1fr)",
            gap: 1,
            maxWidth: "300px",
          }}
        >
          <Button variant="outlined" startDecorator={<CloudDownloadIcon />}>
            MRP Demand
          </Button>
          <Button variant="outlined" startDecorator={<CloudDownloadIcon />}>
            Production Schedule
          </Button>
          <Button variant="outlined" startDecorator={<CloudDownloadIcon />}>
            Routing Execution
          </Button>
        </Box>
      </Sheet>
    </PageFrame>
  );
};
