import { CustomersComponent } from "./component";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApiContext } from "../../contexts/api/context";
import { DrawerContext } from "../../contexts/drawer/context";
import { useGetAllCustomersQuery } from "../../services/api/__generated__/backend_gateway-types";

export const CustomersContainer = () => {
  const { apiService } = useContext(ApiContext);
  const { closeDrawer } = useContext(DrawerContext);

  const [errors, setErrors] = useState<string[]>([]);

  const { data: getAllCustomersData, loading: getAllCustomersLoading } =
    useGetAllCustomersQuery({
      client: apiService.getClient(),
      onError: (data) => {
        if (data.message) {
          setErrors((e) => [...e, data.message]);
        }
      },
    });

  const navigate = useNavigate();

  const navigateToCustomerDetails = (customerId: string) => {
    navigate(`/customers/${customerId}`, { replace: true });
  };

  const navigateToCreateNewCustomer = () => {
    closeDrawer();
    navigate("/customers/new", { replace: true });
  };

  const clearError = (i: number) => {
    const newErrors = [...errors];
    newErrors.splice(i, 1);
    setErrors(newErrors);
  };

  return (
    <CustomersComponent
      loading={getAllCustomersLoading}
      errors={errors}
      clearError={clearError}
      navigateToCustomerDetails={navigateToCustomerDetails}
      navigateToCreateNewCustomer={navigateToCreateNewCustomer}
      customers={getAllCustomersData?.getAllCustomers || []}
    />
  );
};
