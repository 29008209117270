import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { OrchestratorStep } from "../../../services/api/__generated__/backend_gateway-types";
dayjs.extend(duration);

export const runDuration = (steps: OrchestratorStep[]): string => {
  if (steps.length < 2) {
    return "";
  }
  const sortedSteps = [...steps].sort(
    (a: OrchestratorStep, b: OrchestratorStep) =>
      dayjs(a.createdAt).diff(b.createdAt) < 0 ? -1 : 1
  );
  const runDiff = dayjs(sortedSteps.slice(-1)[0].createdAt).diff(
    sortedSteps[0].createdAt
  );
  const runDuration = dayjs.duration(runDiff).format("HH:mm:ss.SSS");
  return `${runDuration}`;
};
