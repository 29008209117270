import Add from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import CircularProgress from "@mui/joy/CircularProgress";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import { SkuDelta } from "./sku_delta";
import { useState } from "react";
import Button from "@mui/joy/Button";

export const ForecastAccuracyAlertsComponent = (props: {
  loading: boolean;
  error: string | null;
  skuDelta: SkuDelta[];
}) => {
  const { loading, error, skuDelta } = props;
  const [listLength, setListLength] = useState<number>(10);
  return (
    <Sheet
      sx={{
        "--TableCell-height": "40px",
        "--TableHeader-height": "80px",
        minHeight: "10vh",
        maxHeight: "70vh",
        overflow: "auto",
        background: (
          theme
        ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
              linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
              radial-gradient(
                farthest-side at 50% 0,
                ${theme.palette.atomic.fullShadowFade},
                ${theme.palette.atomic.fullShadow}
              ),
              radial-gradient(
                  farthest-side at 50% 100%,
                ${theme.palette.atomic.fullShadowFade},
                ${theme.palette.atomic.fullShadow}
                )
                0 100%`,
        backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "local, local, scroll, scroll",
        backgroundPosition:
          "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
        backgroundColor: (theme) =>
          `${theme.palette.atomic.highlightBackground}`,
      }}
    >
      <Table
        aria-labelledby="Run logs"
        stickyHeader
        hoverRow={true}
        // stripe="odd"
        borderAxis="xBetween"
        sx={{
          width: "600px",
          "--Table-headerUnderlineThickness": "1px",
          "--TableCell-paddingY": "4px",
          "--TableCell-paddingX": "8px",
        }}
      >
        <thead>
          <tr style={{ height: 48, minHeight: 48, maxHeight: 48 }}>
            <th
              style={{
                width: 200,
                boxSizing: "border-box",
                borderBottom: "none",
              }}
            >
              <Typography level="body-sm" sx={{ fontWeight: 600 }}>
                Under-forecasted
              </Typography>
            </th>
            <th
              style={{
                width: 300,
                boxSizing: "border-box",
                borderBottom: "none",
              }}
              colSpan={3}
            ></th>
            <th
              style={{
                width: 200,
                boxSizing: "border-box",
                // borderLeft: `1px solid ${colors.black100}`,
                borderLeft: `1px solid black`,
                borderLeftColor: `atomic.grey2`,
                borderBottom: "none",
              }}
            >
              <Typography level="body-sm" sx={{ fontWeight: 600 }}>
                Over-forecasted
              </Typography>
            </th>
            <th
              style={{
                width: 300,
                boxSizing: "border-box",
                borderBottom: "none",
              }}
              colSpan={3}
            ></th>
          </tr>
          <tr style={{ height: 32, minHeight: 32, maxHeight: 32 }}>
            <th
              style={{
                // width: 200,
                boxSizing: "border-box",
              }}
            >
              {loading && <CircularProgress size="sm" variant="plain" />}
              {!loading && error && <ErrorIcon />}
              {!loading && !error && (
                <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                  SKU
                </Typography>
              )}
            </th>
            <th
              style={{
                width: 100,
                boxSizing: "border-box",
                textAlign: "left",
              }}
            >
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                Forecast
              </Typography>
            </th>
            <th
              style={{
                width: 100,
                boxSizing: "border-box",
                textAlign: "left",
              }}
            >
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                Actual
              </Typography>
            </th>
            <th
              style={{
                width: 100,
                boxSizing: "border-box",
                textAlign: "left",
              }}
            >
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                Delta
              </Typography>
            </th>
            <th
              style={{
                // width: 200,
                boxSizing: "border-box",
                textAlign: "left",
                // borderLeft: `1px solid ${colors.black100}`,
                borderLeft: `1px solid black`,
                borderLeftColor: `atomic.grey2`,
              }}
            >
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                SKU
              </Typography>
            </th>
            <th
              style={{
                width: 100,
                boxSizing: "border-box",
                textAlign: "left",
              }}
            >
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                Forecast
              </Typography>
            </th>
            <th
              style={{
                width: 100,
                boxSizing: "border-box",
                textAlign: "left",
              }}
            >
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                Actual
              </Typography>
            </th>
            <th
              style={{
                width: 100,
                boxSizing: "border-box",
                textAlign: "left",
              }}
            >
              <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                Delta
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from(Array(listLength).keys()).map((i) => (
            <tr key={i}>
              <td>
                <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                  {skuDelta[i]?.sku}
                </Typography>
              </td>
              <td>
                <Typography level="body-xs">
                  {skuDelta[i]?.forecastValue}
                </Typography>
              </td>
              <td>
                <Typography level="body-xs">
                  {skuDelta[i]?.actualValue}
                </Typography>
              </td>
              <td>
                <Typography level="body-xs">
                  {skuDelta[i]?.deltaValue}
                </Typography>
              </td>
              <td
                style={{
                  // borderLeft: `1px solid ${colors.black100}`,
                  borderLeft: `1px solid black`,
                  borderLeftColor: `atomic.grey2`,
                }}
              >
                <Typography level="body-xs" sx={{ fontWeight: 600 }}>
                  {skuDelta.slice(-i - 1)[0]?.sku}
                </Typography>
              </td>
              <td>
                <Typography level="body-xs">
                  {skuDelta.slice(-i - 1)[0]?.forecastValue}
                </Typography>
              </td>
              <td>
                <Typography level="body-xs">
                  {skuDelta.slice(-i - 1)[0]?.actualValue}
                </Typography>
              </td>
              <td>
                <Typography level="body-xs">
                  {skuDelta.slice(-i - 1)[0]?.deltaValue}
                </Typography>
              </td>
            </tr>
          ))}
          {listLength < Math.ceil(skuDelta.length / 2) && (
            <tr>
              <td>
                <Button
                  variant="plain"
                  startDecorator={<Add />}
                  onClick={() => {
                    const newLength = Math.min(
                      listLength + 10,
                      Math.ceil(skuDelta.length / 2)
                    );
                    setListLength(newLength);
                  }}
                >
                  Show more
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Sheet>
  );
};
