import Check from "@mui/icons-material/Check";
import Chip from "@mui/joy/Chip";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import {
  EnginePreset,
  OrchestratorRun,
} from "../../services/api/__generated__/backend_gateway-types";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import { runStatusToEmoji } from "./run_status_to_emoji";
import { runStatusToText } from "./run_status_to_text";
dayjs.extend(utc);

const runToId = (run: OrchestratorRun): string =>
  `${run.runSettings.inputId}${run.runSettings.workflowId}${run.runSettings.variablesId}${run.runSettings.runAttemptId}`;

export const EngineHistoryComponent = (props: {
  engineRuns: OrchestratorRun[];
  ongoingExportRun: OrchestratorRun | null;
  loading: boolean;
  hasLockedRuns: boolean;
  lockedRun: number | null;
  setLockedRun: (runId: number) => void;
  navigateToRunDetails: (
    inputId: string,
    workflowId: string,
    variablesId: string,
    runAttemptId: number
  ) => void;
  hasPresets: boolean;
  presets: EnginePreset[];
}) => {
  const {
    engineRuns,
    ongoingExportRun,
    loading,
    hasLockedRuns,
    // lockedRun,
    // setLockedRun,
    navigateToRunDetails,
    hasPresets,
    presets,
  } = props;
  const [selectedRunStatus, setSelectedRunStatus] = useState<string | null>(
    null
  );
  const [selectedRunId, setSelectedRunId] = useState<number | null>(null);
  const getPresetName = (id: string | null): string => {
    if (id === null) {
      return "";
    }
    const preset = presets.find((pr) => pr.id === parseInt(id));
    if (preset) {
      return `${preset.name || preset.id} ${preset.default ? "(default)" : ""}`;
    }
    return `${id}`;
  };
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
        backgroundColor: "atomic.highlightUnderlay",
      }}
    >
      <Sheet
        variant="outlined"
        color="neutral"
        sx={{
          p: 4,
          m: 2,
          overflow: "auto",
          minWidth: "0",
          maxWidht: "100%",
          borderRadius: "6px",
          backgroundColor: "atomic.highlightBackground",
        }}
      >
        <PageTitle>Run history</PageTitle>
        {loading && <LinearProgress />}

        <Sheet
          sx={{
            "--TableCxell-height": "40px",
            "--TableHeader-height": "64px",
            minHeight: "10vh",
            maxHeight: "70vh",
            overflow: "auto",
            background: (
              theme
            ) => `linear-gradient(${theme.palette.atomic.highlightBackground} 30%, ${theme.palette.atomic.highlightBackgroundFade}),
                    linear-gradient(${theme.palette.atomic.highlightBackgroundFade}, ${theme.palette.atomic.highlightBackground} 70%) 0 100%,
                    radial-gradient(
                      farthest-side at 50% 0,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                    ),
                    radial-gradient(
                        farthest-side at 50% 100%,
                      ${theme.palette.atomic.fullShadowFade},
                      ${theme.palette.atomic.fullShadow}
                      )
                      0 100%`,
            backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "local, local, scroll, scroll",
            backgroundPosition:
              "0 var(--TableHeader-height), 0 100%, 0 var(--TableHeader-height), 0 100%",
            backgroundColor: (theme) =>
              `${theme.palette.atomic.highlightBackground}`,
          }}
        >
          <Table
            aria-labelledby="Runs"
            stickyHeader
            hoverRow={true}
            // stripe="odd"
            borderAxis="xBetween"
            sx={{
              maxWidth: "1200px",
              "--Table-headerUnderlineThickness": "1px",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    padding: "12px 6px",
                    width: "50px",
                  }}
                >
                  ID
                </th>
                {hasLockedRuns && (
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "60px",
                    }}
                  >
                    Locked
                  </th>
                )}
                <th
                  style={{
                    padding: "12px 6px",
                    width: "150px",
                  }}
                >
                  Started at
                </th>
                <th
                  style={{
                    padding: "12px 6px",
                    width: "100px",
                  }}
                >
                  Status
                </th>
                {/* <th
                  style={{
                    padding: "12px 6px",
                    width: "150px",
                  }}
                >
                  Data fetched
                </th> */}
                {hasPresets && (
                  <th
                    style={{
                      padding: "12px 6px",
                      width: "100px",
                    }}
                  >
                    Preset
                  </th>
                )}
                <th
                  style={{
                    padding: "12px 6px",
                    width: "100px",
                  }}
                >
                  Started By
                </th>
                <th
                  style={{
                    padding: "12px 6px",
                    width: "100px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    padding: "12px 6px",
                    width: "100px",
                  }}
                >
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {ongoingExportRun && (
                <tr>
                  <td>{ongoingExportRun.oldRunId || ""}</td>
                  {hasLockedRuns && <td></td>}
                  <td>
                    {dayjs
                      .utc(ongoingExportRun.createdAt)
                      .local()
                      .format("MM/DD/YYYY hh:mm:ss a")}
                  </td>
                  <td>
                    <Chip size="sm">
                      {runStatusToEmoji(ongoingExportRun.status)}{" "}
                      {runStatusToText(ongoingExportRun.status)}
                    </Chip>
                  </td>
                  {hasPresets && (
                    <td>
                      {getPresetName(ongoingExportRun.runSettings.variablesId)}
                    </td>
                  )}
                  <td>{ongoingExportRun.initiatedBy || ""}</td>
                  <td>{ongoingExportRun.name || ""}</td>
                  <td>{ongoingExportRun.description || ""}</td>
                </tr>
              )}
              {engineRuns.map((run) => (
                <tr
                  key={runToId(run)}
                  onClick={() => {
                    navigateToRunDetails(
                      run.runSettings.inputId,
                      run.runSettings.workflowId,
                      run.runSettings.variablesId,
                      run.runSettings.runAttemptId
                    );
                    setSelectedRunStatus(runToId(run));
                    setSelectedRunId(run.oldRunId || null);
                  }}
                  aria-checked={run.oldRunId === selectedRunId}
                  role="checkbox"
                  style={
                    selectedRunStatus && runToId(run) === selectedRunStatus
                      ? ({
                          "--TableCell-dataBackground": "rgba(0,0,0,0.2)",
                          "--TableCell-headBackground": "rgba(0,0,0,0.2)",
                        } as React.CSSProperties)
                      : {}
                  }
                >
                  <td>{run.oldRunId || ""}</td>
                  {hasLockedRuns && (
                    <td>
                      {/* {lockedRun && run.oldRunId === lockedRun && <Check />} */}
                      {run.locked && <Check />}
                    </td>
                  )}
                  <td>
                    {dayjs
                      .utc(run.createdAt)
                      .local()
                      .format("MM/DD/YYYY hh:mm:ss a")}
                  </td>
                  <td>
                    <Chip size="sm">
                      {runStatusToEmoji(run.status)}{" "}
                      {runStatusToText(run.status)}
                    </Chip>
                  </td>
                  {/* <td>
                    {dayjs
                      .utc(run.runSettings.inputId)
                      .local()
                      .format("MM/DD/YYYY hh:mm:ss a")}
                  </td> */}
                  {hasPresets && (
                    <td>{getPresetName(run.runSettings.variablesId)}</td>
                  )}
                  <td>{run.initiatedBy || ""}</td>
                  <td>{run.name || ""}</td>
                  <td>{run.description || ""}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Sheet>
        {/* <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mt: 2 }}>
          {hasLockedRuns && (
            <Button
              disabled={loading || selectedRunId === null}
              onClick={() => setLockedRun(selectedRunId!)}
            >
              Set as locked
            </Button>
          )}
        </Box> */}
      </Sheet>
    </PageFrame>
  );
};
