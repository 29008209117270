import CircleIcon from "@mui/icons-material/Circle";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import FormControl from "@mui/joy/FormControl";
import LinearProgress from "@mui/joy/LinearProgress";
import ListItemContent from "@mui/joy/ListItemContent";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import Textarea from "@mui/joy/Textarea";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useState } from "react";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import {
  PowerBiReportPages,
  UserComment,
} from "../../services/api/__generated__/backend_gateway-types";
import "./component.css";

enum ReportApproval {
  ACCEPTED = "ACCEPTED",
  WARNING = "WARNING",
  REJECTED = "REJECTED",
}

export const ValidationDashboardComponent = (props: {
  loading: boolean;
  messagingLoading: boolean;
  reportId: string | null;
  embedUrl: string | null;
  embedToken: string | null;
  embedTokenId: string | null;
  embedTokenExpiration: string | null;

  grossProfitTracker: PowerBiReportPages | null;
  grossProfitTrackerComments: UserComment[];
  addCommentToGrossProfitTracker: (message: string) => void;
  grossProfitTrackerNewComment: string;
  setGrossProfitTrackerNewComment: React.Dispatch<React.SetStateAction<string>>;

  checks: PowerBiReportPages | null;
  checksComments: UserComment[];
  addCommentToChecks: (message: string) => void;
  checksNewComment: string;
  setChecksNewComment: React.Dispatch<React.SetStateAction<string>>;

  matchRates: PowerBiReportPages | null;
  matchRatesComments: UserComment[];
  addCommentToMatchRates: (message: string) => void;
  matchRatesNewComment: string;
  setMatchRatesNewComment: React.Dispatch<React.SetStateAction<string>>;

  dayOfSupplyOnHand: PowerBiReportPages | null;
  dayOfSupplyOnHandComments: UserComment[];
  addCommentToDayOfSupplyOnHand: (message: string) => void;
  dayOfSupplyOnHandNewComment: string;
  setDayOfSupplyOnHandNewComment: React.Dispatch<React.SetStateAction<string>>;

  routingPlan: PowerBiReportPages | null;
  routingPlanComments: UserComment[];
  addCommentToRoutingPlan: (message: string) => void;
  routingPlanNewComment: string;
  setRoutingPlanNewComment: React.Dispatch<React.SetStateAction<string>>;

  deliveryForecast: PowerBiReportPages | null;
  deliveryForecastComments: UserComment[];
  addCommentToDeliveryForecast: (message: string) => void;
  deliveryForecastNewComment: string;
  setDeliveryForecastNewComment: React.Dispatch<React.SetStateAction<string>>;

  constraintMaxBattery: PowerBiReportPages | null;
  constraintMaxBatteryComments: UserComment[];
  addCommentToConstraintMaxBattery: (message: string) => void;
  constraintMaxBatteryNewComment: string;
  setConstraintMaxBatteryNewComment: React.Dispatch<
    React.SetStateAction<string>
  >;

  constraintCaliforniaRouting: PowerBiReportPages | null;
  constraintCaliforniaRoutingComments: UserComment[];
  addCommentToConstraintCaliforniaRouting: (message: string) => void;
  constraintCaliforniaRoutingNewComment: string;
  setConstraintCaliforniaRoutingNewComment: React.Dispatch<
    React.SetStateAction<string>
  >;

  inventoryOnHand: PowerBiReportPages | null;
  inventoryOnHandComments: UserComment[];
  addCommentToInventoryOnHand: (message: string) => void;
  inventoryOnHandNewComment: string;
  setInventoryOnHandNewComment: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    loading,
    reportId,
    embedUrl,
    embedToken,

    grossProfitTracker,
    grossProfitTrackerComments,
    addCommentToGrossProfitTracker,
    grossProfitTrackerNewComment,
    setGrossProfitTrackerNewComment,

    checks,
    checksComments,
    addCommentToChecks,
    checksNewComment,
    setChecksNewComment,

    matchRates,
    matchRatesComments,
    addCommentToMatchRates,
    matchRatesNewComment,
    setMatchRatesNewComment,

    dayOfSupplyOnHand,
    dayOfSupplyOnHandComments,
    addCommentToDayOfSupplyOnHand,
    dayOfSupplyOnHandNewComment,
    setDayOfSupplyOnHandNewComment,

    routingPlan,
    routingPlanComments,
    addCommentToRoutingPlan,
    routingPlanNewComment,
    setRoutingPlanNewComment,

    // deliveryForecast,
    // deliveryForecastComments,
    // addCommentToDeliveryForecast,
    // deliveryForecastNewComment,
    // setDeliveryForecastNewComment,

    constraintMaxBattery,
    constraintMaxBatteryComments,
    addCommentToConstraintMaxBattery,
    constraintMaxBatteryNewComment,
    setConstraintMaxBatteryNewComment,

    constraintCaliforniaRouting,
    constraintCaliforniaRoutingComments,
    addCommentToConstraintCaliforniaRouting,
    constraintCaliforniaRoutingNewComment,
    setConstraintCaliforniaRoutingNewComment,

    // inventoryOnHand,
    // inventoryOnHandComments,
    // addCommentToInventoryOnHand,
    // inventoryOnHandNewComment,
    // setInventoryOnHandNewComment,
  } = props;
  const [openBlock, setOpenBlock] = useState<number | null>(null);
  const [grossProfitTrackerApproval, setGrossProfitTrackerApproval] =
    useState<ReportApproval | null>(null);
  const [checksApproval, setChecksApproval] = useState<ReportApproval | null>(
    null
  );
  const [matchRatesApproval, setMatchRatesApproval] =
    useState<ReportApproval | null>(null);
  const [dayOfSupplyOnHandApproval, setDayOfSupplyOnHandApproval] =
    useState<ReportApproval | null>(null);
  const [routingPlanApproval, setRoutingPlanApproval] =
    useState<ReportApproval | null>(null);
  // const [deliveryForecastApproval, setDeliveryForecastApproval] =
  //   useState<ReportApproval | null>(null);
  const [constraintMaxBatteryApproval, setConstraintMaxBatteryApproval] =
    useState<ReportApproval | null>(null);
  const [
    constraintCaliforniaRoutingApproval,
    setConstraintCaliforniaRoutingApproval,
  ] = useState<ReportApproval | null>(null);
  // const [inventoryOnHandApproval, setInventoryOnHandApproval] =
  //   useState<ReportApproval | null>(null);

  const blocks = [
    [
      "Gross Profit Tracking",
      "Monitor latest run's forecast vs. quarter targets",
      grossProfitTracker,
      grossProfitTrackerApproval,
      setGrossProfitTrackerApproval,
      grossProfitTrackerComments,
      addCommentToGrossProfitTracker,
      grossProfitTrackerNewComment,
      setGrossProfitTrackerNewComment,
    ],
    [
      "Checks",
      "Look for check violations and notable change run over run",
      checks,
      checksApproval,
      setChecksApproval,
      checksComments,
      addCommentToChecks,
      checksNewComment,
      setChecksNewComment,
    ],
    [
      "Match Rates",
      "Avoid builds not expected to sell this quarter (reds), especially in the near-term",
      matchRates,
      matchRatesApproval,
      setMatchRatesApproval,
      matchRatesComments,
      addCommentToMatchRates,
      matchRatesNewComment,
      setMatchRatesNewComment,
    ],
    [
      "Days of Supply on Hand",
      "Check for notable run over run changes, highlight outliers for action",
      dayOfSupplyOnHand,
      dayOfSupplyOnHandApproval,
      setDayOfSupplyOnHandApproval,
      dayOfSupplyOnHandComments,
      addCommentToDayOfSupplyOnHand,
      dayOfSupplyOnHandNewComment,
      setDayOfSupplyOnHandNewComment,
    ],
    [
      "Constraint: Max Battery",
      "Keep Max battery builds <= 125/week",
      constraintMaxBattery,
      constraintMaxBatteryApproval,
      setConstraintMaxBatteryApproval,
      constraintMaxBatteryComments,
      addCommentToConstraintMaxBattery,
      constraintMaxBatteryNewComment,
      setConstraintMaxBatteryNewComment,
    ],
    [
      "Constraint: California Routing",
      "Keep California routing <= 500/week",
      constraintCaliforniaRouting,
      constraintCaliforniaRoutingApproval,
      setConstraintCaliforniaRoutingApproval,
      constraintCaliforniaRoutingComments,
      addCommentToConstraintCaliforniaRouting,
      constraintCaliforniaRoutingNewComment,
      setConstraintCaliforniaRoutingNewComment,
    ],
    [
      "Routing Plan",
      "Check for notable run over run changes",
      routingPlan,
      routingPlanApproval,
      setRoutingPlanApproval,
      routingPlanComments,
      addCommentToRoutingPlan,
      routingPlanNewComment,
      setRoutingPlanNewComment,
    ],
    // [
    //   "Delivery Forecast",
    //   "Check for notable run over run changes",
    //   deliveryForecast,
    //   deliveryForecastApproval,
    //   setDeliveryForecastApproval,
    //   deliveryForecastComments,
    //   addCommentToDeliveryForecast,
    //   deliveryForecastNewComment,
    //   setDeliveryForecastNewComment,
    // ],
    // [
    //   "Inventory on Hand",
    //   "Check for notable run over run changes",
    //   inventoryOnHand,
    //   inventoryOnHandApproval,
    //   setInventoryOnHandApproval,
    //   inventoryOnHandComments,
    //   addCommentToInventoryOnHand,
    //   inventoryOnHandNewComment,
    //   setInventoryOnHandNewComment,
    // ],
  ];
  return (
    <PageFrame
      sx={{
        alignItems: "stretch",
        alignContent: "flex-start",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto auto",
      }}
    >
      <PageTitle sx={{ m: 4 }}>Validations</PageTitle>
      <AccordionGroup
        size="lg"
        variant="outlined"
        sx={{
          m: 2,
          borderRadius: "sm",
          [`& .${accordionSummaryClasses.button}:hover`]: {
            borderRadius: "sm",
          },
        }}
      >
        {blocks.map((block, i) => {
          const [
            title,
            subtitle,
            page,
            approval,
            setApproval,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            comments,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            addComment,
            newComment,
            setNewComment,
          ] = block as [
            title: string,
            subtitle: string,
            page: PowerBiReportPages | null,
            approval: ReportApproval | null,
            setApproval: React.Dispatch<
              React.SetStateAction<ReportApproval | null>
            >,
            comments: UserComment[],
            addComment: (message: string) => void,
            newComment: string,
            setNewComment: React.Dispatch<React.SetStateAction<string>>
          ];
          return (
            <Accordion
              key={i}
              expanded={openBlock === i}
              onChange={(event, expanded) => {
                setOpenBlock(expanded ? i : null);
              }}
            >
              <AccordionSummary
                sx={{
                  borderBottom: (theme) =>
                    `1px solid ${theme.vars.palette.divider}`,
                }}
              >
                <ListItemContent>
                  <Typography level="title-md" sx={{ display: "block" }}>
                    {title}
                  </Typography>
                  {openBlock === i && (
                    <Typography level="body-sm" sx={{ display: "block" }}>
                      {subtitle}
                    </Typography>
                  )}
                </ListItemContent>
                {openBlock !== i && (
                  <RadioGroup
                    aria-labelledby="product-color-attribute"
                    orientation="horizontal"
                    sx={{ gap: 2 }}
                    value={approval}
                    // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    //   event.preventDefault();
                    //   (
                    //     setApproval as React.Dispatch<
                    //       React.SetStateAction<ReportApproval | null>
                    //     >
                    //   )(event.target.value as ReportApproval);
                    //   event.stopPropagation();
                    //   return null;
                    // }}
                  >
                    {(
                      [
                        ReportApproval.ACCEPTED,
                        ReportApproval.WARNING,
                        ReportApproval.REJECTED,
                      ] as const
                    ).map((approvalValue, i) => {
                      let color: "success" | "warning" | "danger" = "success";
                      let title = "";
                      switch (approvalValue) {
                        case ReportApproval.ACCEPTED:
                          color = "success";
                          title = "Accepted";
                          break;
                        case ReportApproval.WARNING:
                          color = "warning";
                          title = "Warning";
                          break;
                        case ReportApproval.REJECTED:
                          color = "danger";
                          title = "Rejected";
                          break;
                      }
                      return (
                        <Tooltip arrow placement="bottom" title={title} key={i}>
                          <Sheet
                            key={approvalValue}
                            sx={[
                              {
                                position: "relative",
                                width: 32,
                                height: 32,
                                flexShrink: 0,
                                bgcolor: `${color}.solidBg`,
                                opacity:
                                  approval === approvalValue ? 0.8 : 0.35,
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                boxSizing: "border",
                                // border: `1px solid ${colors.grey2}`,
                                border: `1px solid black`,
                                borderColor: "atomic.grey2",
                              },
                              // {
                              //   "&:hover": {
                              //     opacity: approval === approvalValue ? 1 : 0.6,
                              //     width: 34,
                              //     height: 34,
                              //     border: `0px solid red`,
                              //   },
                              // },
                              {
                                "&:before": {
                                  content: '""',
                                  position: "absolute",
                                  width: 14,
                                  height: 14,
                                  bgcolor:
                                    approval === approvalValue
                                      ? "transparent"
                                      : "rgba(255, 255, 255, 0.8)",
                                  borderRadius: "50%",
                                  display: "block",
                                  boxSizing: "border",
                                  border:
                                    approval === approvalValue
                                      ? `4px solid rgba(255,255,255,0.8)`
                                      : `4px solid transparent`,
                                },
                              },
                            ]}
                          >
                            <Radio
                              overlay
                              variant="solid"
                              color={color}
                              size="sm"
                              checkedIcon={
                                <CircleIcon
                                  fontSize="small"
                                  // htmlColor="rgba(255,255,255,0.9)"
                                  // htmlColor="rgba(0,0,0,0.45"
                                  htmlColor="rgba(0,0,0,0.7)"
                                />
                              }
                              value={approvalValue}
                              slotProps={{
                                input: { "aria-label": color },
                                radio: {
                                  sx: {
                                    display: "contents",
                                    "--variant-borderWidth": "2px",
                                  },
                                },
                              }}
                              sx={{
                                "--joy-focus-outlineOffset": "4px",
                                "--joy-palette-focusVisible": (theme) =>
                                  theme.vars.palette[color][500],
                                [`& .${radioClasses.action}.${radioClasses.focusVisible}`]:
                                  {
                                    outlineWidth: "2px",
                                  },
                              }}
                            />
                          </Sheet>
                        </Tooltip>
                      );
                    })}
                  </RadioGroup>
                )}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  bgcolor: "white",
                  maxHeight: "65vh",
                }}
              >
                {loading && (
                  <Box sx={{ pt: 2, width: "100%" }}>
                    <LinearProgress />
                  </Box>
                )}
                {!loading && page && reportId && (
                  <Box
                    sx={{
                      display: "grid",
                      minHeight: "65vh",
                      maxHeight: "65vh",
                      gridTemplateColumns:
                        // "minmax(1fr, 115vw) minmax(200px, 1fr)",
                        "minmax(40vw, 65vw) auto 200px",
                      gap: 2,
                      p: 0,
                      m: 0,
                    }}
                  >
                    <PowerBIEmbed
                      embedConfig={{
                        type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                        id: reportId,
                        embedUrl: embedUrl || undefined,
                        accessToken: embedToken || undefined,
                        pageName: page.pageId,
                        tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                        settings: {
                          panes: {
                            bookmarks: {
                              visible: false,
                            },
                            fields: {
                              expanded: false,
                            },
                            filters: {
                              expanded: false,
                              visible: false,
                            },
                            pageNavigation: {
                              visible: false,
                            },
                            selection: {
                              visible: true,
                            },
                            syncSlicers: {
                              visible: true,
                            },
                            visualizations: {
                              expanded: false,
                            },
                          },
                          background: models.BackgroundType.Transparent,
                        },
                      }}
                      cssClassName="report-container-validations"
                    />
                    <Box />
                    <Box
                      sx={{
                        height: "65vh",
                        maxHeight: "65vh",
                        overflowY: "scroll",
                        display: "flex",
                        flexFlow: "column nowrap",
                        justifyContent: "flex-start",
                        alignItems: "stretch",
                        pt: 2,
                      }}
                    >
                      <Card variant="plain" sx={{ m: 1, p: 1 }}>
                        <CardContent>
                          <RadioGroup
                            aria-labelledby="product-color-attribute"
                            orientation="horizontal"
                            sx={{
                              // gap: 2
                              display: "flex",
                              flexFlow: "row nowrap",
                              justifyContent: "space-between",
                              alignItems: "center",
                              p: 0.5,
                            }}
                            value={approval}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              event.preventDefault();

                              setApproval(event.target.value as ReportApproval);
                              event.stopPropagation();
                              return null;
                            }}
                          >
                            {(
                              [
                                ReportApproval.ACCEPTED,
                                ReportApproval.WARNING,
                                ReportApproval.REJECTED,
                              ] as const
                            ).map((approvalValue, i) => {
                              let color: "success" | "warning" | "danger" =
                                "success";
                              let title = "";
                              switch (approvalValue) {
                                case ReportApproval.ACCEPTED:
                                  color = "success";
                                  title = "Mark as accepted";
                                  break;
                                case ReportApproval.WARNING:
                                  color = "warning";
                                  title = "Mark as warning";
                                  break;
                                case ReportApproval.REJECTED:
                                  color = "danger";
                                  title = "Mark as rejected";
                                  break;
                              }
                              return (
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  title={title}
                                  key={i}
                                >
                                  <Sheet
                                    key={approvalValue}
                                    sx={[
                                      {
                                        position: "relative",
                                        width: 40,
                                        height: 40,
                                        flexShrink: 0,
                                        bgcolor: `${color}.solidBg`,
                                        opacity:
                                          approval === approvalValue
                                            ? 0.8
                                            : 0.35,
                                        borderRadius: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        boxSizing: "border",
                                        // border: `2px solid ${colors.grey1}`,
                                        border: `2px solid black`,
                                        borderColor: "atomic.grey1",
                                      },
                                      {
                                        "&:hover": {
                                          opacity:
                                            approval === approvalValue
                                              ? 1
                                              : 0.6,
                                          width: 42,
                                          height: 42,
                                          border: `0px solid transparent`,
                                        },
                                      },
                                      {
                                        "&:before": {
                                          content: '""',
                                          position: "absolute",
                                          width: 18,
                                          height: 18,
                                          bgcolor:
                                            approval === approvalValue
                                              ? "transparent"
                                              : "rgba(255, 255, 255, 0.8)",
                                          borderRadius: "50%",
                                          display: "block",
                                          boxSizing: "border",
                                          border:
                                            approval === approvalValue
                                              ? `5px solid rgba(255,255,255,0.8)`
                                              : `5px solid transparent`,
                                        },
                                      },
                                    ]}
                                  >
                                    <Radio
                                      overlay
                                      variant="solid"
                                      color={color}
                                      checkedIcon={
                                        <CircleIcon
                                          fontSize="large"
                                          // htmlColor="rgba(255,255,255,0.4)"
                                          // htmlColor="rgba(0,0,0,0.45)"
                                          htmlColor="rgba(0,0,0,0.7)"
                                        />
                                      }
                                      value={approvalValue}
                                      slotProps={{
                                        input: { "aria-label": color },
                                        radio: {
                                          sx: {
                                            display: "contents",
                                            "--variant-borderWidth": "2px",
                                          },
                                        },
                                      }}
                                      sx={{
                                        "--joy-focus-outlineOffset": "4px",
                                        "--joy-palette-focusVisible": (theme) =>
                                          theme.vars.palette[color][500],
                                        [`& .${radioClasses.action}.${radioClasses.focusVisible}`]:
                                          {
                                            outlineWidth: "2px",
                                          },
                                      }}
                                    />
                                  </Sheet>
                                </Tooltip>
                              );
                            })}
                          </RadioGroup>
                        </CardContent>
                        <CardContent>
                          <Textarea
                            minRows={5}
                            size="sm"
                            placeholder="Notes"
                            value={newComment as string}
                            onChange={(event) =>
                              setNewComment(event.target.value)
                            }
                          />
                        </CardContent>
                        <CardActions>
                          <Button
                            variant="soft"
                            size="sm"
                            // onClick={() => addComment(newComment as string)}
                          >
                            Save
                          </Button>
                        </CardActions>
                      </Card>
                    </Box>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </AccordionGroup>

      <FormControl
        sx={{
          m: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button disabled={loading} color="primary" variant="solid" size="lg">
            Publish
          </Button>
          {/* <Button
            disabled={loading}
            color="primary"
            variant="outlined"
            size="lg"
          >
            Log notes
          </Button> */}
        </Box>
      </FormControl>
    </PageFrame>
  );
};
